import React from "$react";
import Select from "$layouts/Select";

export default function SelectZone({project,...props}){
    return <Select
        fetchItems = {()=>{
            return Promise.resolve(project?.affectations);
        }}
        {...props}
    />
}