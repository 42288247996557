import { Upload,Modal,Drawer,message,Button as AButton } from "antd";
import React,{useState} from "$react";
import {isNonNullString} from "$utils";
import notify from "$notify";
import {defaultObj,classNames,uniqid,getMaxZindex} from "$utils";
import { useTranslation } from "react-i18next";
import {convertToDataUrl,parseFile } from "./utils";
import Viewer from "./Viewer";

export default function UploadComponent({onChange,containerProps,allowedTypes,value,fileList:cFileList,...props}){
    const {t} = useTranslation();
    const [previewFile,setPreviewFile] = useState(null);
    containerProps = defaultObj(containerProps);
    const validateFileType = ({ type, name }) => {
        if(!isNonNullString(type)) return false;
        const _type = type.toLowerCase();
        ///faire en sorte que seuls les pdf et les images soient le type de fichier supportés qu'on peut uploader
        if(!_type.contains("image/") && !_type.contains("/pdf")){
            return false;
        }
        if (!isNonNullString(allowedTypes)) {
          return true;
        }
        if (type) {
          return allowedTypes.includes(type);
        }
    };
    const [fileList,setFileList] = useState(Array.isArray(cFileList)? cFileList:[]);
    const handleChange = (args,...rest) => {
      const { fileList: newFileList } = args;
      setFileList(newFileList)
      if(typeof onChange =='function'){
        onChange(args,...rest);
      }
    };
    const uploadProps = React.useMemo(() => ({
          beforeUpload: (file) => {
            const isAllowedType = validateFileType(file);
            if (!isAllowedType) {
              setFileList((state) => [...state]);
              notify.error(`${t("uploader.isNotAValidFile").sprintf(file.name)}`);
              return Upload.LIST_IGNORE;
            }
            setFileList((state) => [...state, file]);
            return true;
          },
          onRemove: (file) => {
            if (fileList.some((item) => item.uid === file.uid)) {
              setFileList((fileList) =>
                fileList.filter((item) => item.uid !== file.uid)
              );
              return true;
            }
            return false;
          },
          fileList,
    }), [fileList]);
   
    return <>
          <Upload action={(u)=>u} {...props} {...uploadProps} onChange={handleChange}
          onPreview = {(file)=>{
            setPreviewFile(file);
          }}
          previewFile={(file)=> {
            return convertToDataUrl(file);
          }}
        />
        <Viewer file={previewFile} onClose={(e)=>setPreviewFile(null)}/>
    </>
}