import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import { useRecoilValue, useRecoilState } from "recoil";
import DatePicker from "$components/Date";
import {
  Button,
  //DatePicker,
  Select,
  Form,
  Col,
  message,
  Row,
  Input,
  Checkbox,
  InputNumber,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { userInfos } from "../../store";
import { useGetEmployees, useAddProjects, useEditProject } from "../../api/api";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function FormProject({
  close,
  employees,
  current_element,
  is_update,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { mutateAsync: addProject } = useAddProjects();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [purchase, setPurchase] = useState(null);
  const [simplified, setSimplified] = useState(null);
  const [negotiated, setNegotiated] = useState(null);
  const startDateRef = React.useRef(null);
  if(current_element?.start_date){
    startDateRef.current = current_element?.start_date;
  } else if(!current_element){
    startDateRef.current = null;
  }
 //const isUpdate = current_element?.code;
  const [componentDisabled, setComponentDisabled] = useState(false);

  useEffect(() => {
    if (is_update) {
      setId(current_element.id);
      setPurchase(current_element.x);
      setSimplified(current_element.y);
      setNegotiated(current_element.z);
    } else {
      setId(0);
    }
  }, []);
  const { mutateAsync: updateProject } = useEditProject(id);

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        const { x, y, z, start_date, end_date, ...rest } = values;
        startDateRef.current = start_date;
        rest.start_date = moment(start_date).format("YYYY-MM-DD");
        rest.end_date = moment(end_date).format("YYYY-MM-DD");
        let tab = [];
        if (componentDisabled) {
          tab.push(x);
          tab.push(y);
          tab.push(z);
          rest.bearings = tab;
        }
        try {
          setLoading(true);
          if (is_update) {
            updateProject(rest);
          } else {
            await addProject(rest);
          }

          close();

          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          setLoading(false);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>
        <h2 className="fW600">
          {is_update ? t("projects.form.title1") : t("projects.form.title")}
        </h2>
        <div className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("validate.button")} </span>
          </Button>
        </div>
      </div>
      <div className="u-mar-vertical-xxl  w50 mauto">
        <Form
          layout="vertical"
          className="rel"
          form={form}
          initialValues={{ ...current_element }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="code"
                label={t("projects.form.label0")}
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(new Error(t("required")));
                      } else {
                        if (value.length > 7) {
                          return Promise.reject(
                            new Error(t("projects.form.Error.label01"))
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("projects.form.label1")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="manager_id"
                label={t("projects.form.label2")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  autoComplete="none"
                >
                  {employees.map((item,key) => {
                    return (
                      <Option value={item.id} key={key}>
                        {item.firstname + " " + item.lastname}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="start_date"
                label={t("projects.form.label3")}
                rules={[{ required: true, message: t("required") }]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    startDateRef.current = e;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="end_date"
                label={t("projects.form.label4")}
                className="w100 "
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(new Error(t("required")));
                      } else {
                        if (!startDateRef.current) {
                          return Promise.reject(
                            new Error(t("projects.form.Error.label41"))
                          );
                        } else {
                          if (value < startDateRef.current) {
                            return Promise.reject(
                              new Error(t("projects.form.Error.label42"))
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="paliers-specicifs-container">
              <Checkbox
                checked={componentDisabled}
                onChange={(e) => setComponentDisabled(e.target.checked)}
              >
                {t("projects.form.label5")}
              </Checkbox>
            </Col>
            <Col span={12}>
              <div>{t("projects.form.label6")}</div>
            </Col>
            <Col span={6}>
              <InputNumber disabled={!componentDisabled} value={0} />
            </Col>
            <Col span={6}>
              <Form.Item
                name="x"
                className="w100"
                rules={
                  componentDisabled && [
                    { required: true, message: t("required") },
                  ]
                }
                labelAlign="left"
              >
                <InputNumber
                  disabled={!componentDisabled}
                  onChange={(e) => {
                    setPurchase(e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div>{t("projects.form.label7")}</div>
            </Col>
            <Col span={6}>
              <InputNumber disabled value={purchase} />
            </Col>
            <Col span={6}>
              <Form.Item
                name="y"
                className="w100"
                rules={
                  componentDisabled && [
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (isNaN(value)) {
                          return Promise.reject(
                            new Error(t("projects.form.Error.label71"))
                          );
                        } else {
                          if (value <= purchase) {
                            return Promise.reject(
                              new Error(t("projects.form.Error.label72"))
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]
                }
              >
                <InputNumber
                  disabled={!componentDisabled}
                  onChange={(e) => {
                    setSimplified(e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div>{t("projects.form.label8")}</div>
            </Col>
            <Col span={6}>
              <InputNumber disabled value={simplified} />
            </Col>
            <Col span={6}>
              <Form.Item
                name="z"
                className="w100"
                rules={
                  componentDisabled && [
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (isNaN(value)) {
                          return Promise.reject(
                            new Error(t("projects.form.Error.label71"))
                          );
                        } else {
                          if (value <= simplified) {
                            return Promise.reject(
                              new Error(t("projects.form.Error.label81"))
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]
                }
              >
                <InputNumber
                  disabled={!componentDisabled}
                  onChange={(e) => {
                    setNegotiated(e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div>{t("projects.form.label9")}</div>
            </Col>
            <Col span={6}>
              <InputNumber disabled value={negotiated} />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
