import React, { useState, Fragment, useEffect } from "react";

import { Form, Input, Button, message } from "antd";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import {axios} from "$api";
import { useHistory } from "react-router";
import "./sign.css";
import { tokenData, userInfos } from "../../store";
import session from "$session";
import { setLocalUser,setToken } from "$auth/session";

export default function Reset() {
  const [loading, setLoading] = useState(false);
  const [_, setTenant] = useRecoilState(tokenData);
  const [tenant, updateTenant] = useState();
  const [__, setUser] = useRecoilState(userInfos);
  const history = useHistory();

  // /get/bysubdomain

  async function onSubmit(values) {
    try {
      setLoading(true);
      const result = await axios.post("/employees/login", values);
      setTenant(result.data.tenant.details);
      session.set("tenant", result.data.tenant.details.id);
      setToken(result.data.data.token);
      setUser(result.data.data.data);
      setLocalUser(result.data.data.data);
      history.push("/");
      message.success("Connexion réussie!!");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.warning("Erreur connexion!");
    }
  }

  return (
    <Fragment>
      <div className="flex column w100 h100  justcenter itemcenter bgwhiteres">
        <Form
          name="normal_login"
          className="login-form w25 w100res w70tab bgwhite column flex itemcenter  u-pad-horizontal-l u-pad-top-l rad8 z999"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
        >
          <img
            src="/logo.png"
            className="w50 u-mar-bottom-xl"
            style={{ borderRadius: 8 }}
          />

          <h3 className="center">
            {" "}
            Veuillez contacter le support afin de renitialiser votre mot de
            passe
          </h3>

          <div className="fW600 w100 u-mar-vertical-l av-roman center">
            <Link to={"/login"} className="login-form-forgot fs12">
              Retour à la connexion
            </Link>
          </div>
        </Form>
      </div>
    </Fragment>
  );
}
