import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import Box from "$components/Box";
import { useTranslation } from "react-i18next";
import Table from "$components/Table";
import { Button, Spin, Input, Drawer, Modal, Breadcrumb } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import DetailProject from "./DetailProject";
import {
  useDeleteProject,
  useGetEmployees,
  useGetProjects,
  useGetAllProjects,
  RETRY,
} from "../../api/api";
import LayoutPage from "../layout";
import FormProject from "./projectForm";

export default function Projects() {
  const { t } = useTranslation();
  const [reload, setReload] = useState(1);
  const [visible, setVisible] = useState(false);
  const [visibleD, setVisibleD] = useState(false);
  const [employee, setEmployee] = useState(null);
  const { mutateAsync: deleteProject } = useDeleteProject();
  const [current_element, setCurrentElement] = useState(null);
  const [is_update, setInUpdate] = useState(false);
  const { confirm } = Modal;

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });

  /*const ListEmployees = () => {
        const { data, error, isLoading, refetch } = useGetEmployees({}, {});
        setEmployee(data);
    }*/

  const { data : listData, error, isLoading, refetch } = useGetAllProjects(pagination, {},RETRY);
  const { data: employees } = useGetEmployees({}, {});

  const openEdit = (line) => {
    const { bearings, manager, start_date, end_date, ...rest } = line;
    rest.manager_id = manager.id;
    rest.start_date = moment(start_date);
    rest.end_date = moment(end_date);
    rest.x = bearings[0];
    rest.y = bearings[1];
    rest.z = bearings[2];
    setCurrentElement({ ...rest });
    setInUpdate(true);
    setVisible(true);
    //setVisibleD(false)
  };

  const openCreate = () => {
    setCurrentElement({});
    setInUpdate(false);
    setVisible(true);
    //setVisibleD(false)
  };

  const change = (r) => {
    setVisibleD(true);
    setEmployee(r);
  };

  const close = () => {
    setVisible(false);
    setReload(reload + 1);

    setVisibleD(false);
    refetch();
  };

  const confirmDeletion = (id) => {
    confirm({
      // title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: t("confirm.delation"),
      okText: t("yes"),
      cancelText: t("No"),
      onOk: () => {
        deleteProject(id).then((res) => {
          refetch();
        });
      },
    });
  };

  const columns = [
    {
      title: ("projects.form.label0"),
      dataIndex: "code",
      onFilter: (value, record) => {
        return (
          String(record.code).toLowerCase().includes(value.toLowerCase()) ||
          String(record.manager.firstname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.manager.lastname)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: ("projects.form.label1"),
      dataIndex: "name",
    },
    {
      title: ("projects.form.label2"),
      dataIndex: ["manager","firstname"],
      render: ({record}) => {
        return <div>{record?.manager?.firstname + " " + record?.manager?.lastname}</div>;
      },
    },
    {
       title : ("projects.form.label3"),
       dataIndex : "start_date",
       type : "date",
    },
    {
      title : ("projects.form.label4"),
      key : "end_date",
      type : "date",
   },
    {
      title: ("list.columns.label17"),
      key: "operation",
      fixed: "right",
      width: "8%",
      dataIndex : "id",
      render: ({record}) => {
        return (
          <Box flex flexDirection="row" flexWrap="wrap">
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                confirmDeletion(record.id);
              }}
            />
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                openEdit(record);
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <LayoutPage>
      {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[
          {
            title : t("projects.list.title")
          }
        ]}/>
        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-s   blok noshadow nobzone hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <Box w="100%" pt="10px" pr="10px" justifyContent="end" className="button-actions" flex flexDirection="row-reverse">
              <Button
                type="primary"
                size="large"
                className="rad8"
                onClick={openCreate}
              >
                <span className="fs16 av-heavy"> {t("add.button")} </span>
              </Button>
            </Box>
            <Drawer
              height={"100%"}
              placement={"bottom"}
              onClose={() => setVisible(false)}
              open={visible}
              closable={false}
              bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
              destroyOnClose={true}
            >
              <FormProject
                close={close}
                employees={employees}
                current_element={current_element}
                is_update={is_update}
              />
            </Drawer>
          </div>

          <Table
            columns={columns}
            listData={listData}

            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange={setPagination}
          />
        </div>
      </Spin>
      <Drawer
        height={"100%"}
        placement={"bottom"}
        onClose={() => setVisibleD(false)}
        open={visibleD}
        closable={false}
        rootClassName={"bgapp"}
        bodyStyle={{
          paddingBottom: 5,
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: "#eff4fa",
        }}
        destroyOnClose={true}
      >
        <DetailProject close={close} data={employee} />
      </Drawer>
    </LayoutPage>
  );
}
