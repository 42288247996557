import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import Table from "$components/Table";
import { Button, Spin, Drawer, Input, Modal, Breadcrumb } from "antd";
import Box from "$components/Box";
// appel a l'api ( react query)
import { useDeleteZone, useGetZones,useGetAllZones,RETRY } from "../../api/api";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";

import Formzone from "./form";
// import DetailsZone from './formedit';
import LayoutPage from "../layout";

moment.locale("fr");

export default function Zone() {
  const { t } = useTranslation();
  const [reload, setReload] = useState(1);
  const [visible, setVisible] = useState(false);
  const [current_element, setCurrentElement] = useState(null);
  const [is_update, setInUpdate] = useState(false);

  const { confirm } = Modal;
  
  const { mutateAsync: deleteZone } = useDeleteZone();

  const [pagination,setPagination] = useState({page:0,size:10});

  const close = () => {
    setVisible(false);
  };

  const openEdit = (line) => {
    setCurrentElement({ ...line });
    setInUpdate(true);
    setVisible(true);
  };

  const openCreate = () => {
    setCurrentElement({});
    setInUpdate(false);
    setVisible(true);
  };

  //destruction: data les données de l'api , error en cas d'erreur, isLoading pour le chargement , tout ca viens de react query
  const { data:listData, error, isLoading, refetch } = useGetAllZones(pagination,null,RETRY);
  
  useEffect(() => {
    refetch();
  }, [listData]);

  const confirmDeletionZone = (id) => {
    confirm({
      // title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: t("confirm.delation"),
      okText: t("yes"),
      cancelText: t("No"),
      onOk: () => {
        deleteZone(id).then((res) => {
          refetch();
        });
      },
    });
  };

  // dans la première colonne on configure les éléments qui peutvent être recherchés
  const columns = [
    {
      title: ("zone.form.label0"),
      dataIndex: "name",
      onFilter: (value, record) => {
        return String(i18nTObj(record)).toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: ("list.columns.label16"),
      key: "operation",
      fixed: "right",
      width: "8%",
      render: ({record}) => {
        return (
          <div style={{ display: "flex" }}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                confirmDeletionZone(record.id);
              }}
            />
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                openEdit(record);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[{title:t("zone.list.title")}]}/>

        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-s   blok noshadow nobzone hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <Box w="100%" flex flexDirection="row" justifyContent="end" className="buttons-actions">
              <Button
                type="primary"
                size="large"
                className="rad8"
                onClick={openCreate}
              >
                <span className="fs18 av-heavy">{t("add.button")}</span>
              </Button>
            </Box>

            <Drawer
              height={"100%"}
              placement={"bottom"}
              onClose={() => setVisible(false)}
              open={visible}
              closable={false}
              bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
              destroyOnClose={true}
            >
              <Formzone
                close={close}
                is_update={is_update}
                current_element={current_element}
              />
            </Drawer>
          </div>

          <Table
            columns={columns}
            listData = {listData}
            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange = {(pagination)=>setPagination(pagination)}
          />
        </div>
      </Spin>
    </LayoutPage>
  );
}
