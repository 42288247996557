import React, { useEffect, Fragment, useState } from "react";


import {
  Tabs,
  Empty,
  Spin,
  Col,
  Row,
  Breadcrumb,
  Steps,
  Divider,
  Tag,
} from "antd";
import { useGetPayment } from "../../api/api";
import moment from "moment";
import "moment/locale/fr";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { userInfos } from "../../store";
import Comments from "./components/comments";
import Validations from "./components/validations";
import Description from "./components/descriptions";
import Box from "$components/Box";
import SimpleGrid from "$components/SimpleGrid";
import Spending from "./components/spending";
//import Spending from "../tickets/components/spending";
import Attachments from "./components/attachements";

import { useParams } from "react-router";
import LayoutPage from "../layout";
import { Link } from "react-router-dom";

import { PlusCircleFilled } from "@ant-design/icons";
import AnalyticsCode from "./components/analyticsCode";
export default function PaymentRequestDetails() {
  const [payment, setPayment] = useState(null);
  const { t } = useTranslation();
  const user = useRecoilValue(userInfos);

  let { id } = useParams();

  const { data, error, isLoading, refetch } = useGetPayment(id);

  useEffect(() => {
    setPayment(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const updatePayment = (payment) => {
    setPayment(payment);
  };

  const addNewComment = (comment) => {
    console.log(comment);
    payment.comments = [comment, ...payment.comments];
    setPayment(payment);
  };

  const addNewCodes = (codes) => {
    let objIndex = payment?.items.findIndex((obj) => obj.id == codes.id);

    payment.items[objIndex] = codes;

    setPayment(payment);
  };

  const addNewAttachment = (attachment) => {
    payment.attachments = [attachment, ...payment.attachments];
    setPayment(payment);
  };

  const addNewSpending = (spending) => {
    setPayment(spending);
  };
  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Box className="w100 mauto u-mar-top-xl-res nopadres noradres ">
          <Breadcrumb title={[
            {
              title:<Link to="/payment-request">
                {t("homePage.menu.paymentRequest")}
              </Link>,
            },
            {
              title : payment?.code,
            }
          ]}/>
          <SimpleGrid 
            pr="10px" pb="10px" columns={{base:1,lg:2}}
            spacingX='13px' spacingY="8px" className="payments-details2approbations-container-grid"
          >
            <Box className="descriptions-container rad16 u-pad-s noborder" bgColor="white" >
              <Description payment={payment} t={t} />
            </Box>
            <Box className="validation-container">
                <Validations
                  iduser={user.id}
                  payment={payment}
                  updatePayment={updatePayment}
                  t={t}
                />
            </Box>
          </SimpleGrid>
          <Box className="payments-request-details-tabs rad16 u-pad-s" bgColor="white" overflowX="auto" w="100%">
                <Tabs defaultActiveKey="1" className="clienttab pay"
                  items = {[
                  {
                    label : t("paymentRequests.detail.section1.tab0.title"),
                    key : "1",
                    children : <Spending
                      payment={payment}
                      iduser={user.id}
                      addNewSpending={addNewSpending}
                      t={t}
                      idpayment={id}
                      addNewCodes={addNewCodes}
                    />,
                  },
                  {
                    label : t("paymentRequests.detail.section1.tab1.title"),
                    key : "2",
                    children : <Attachments
                      payment={payment}
                      addNewAttachment={addNewAttachment}
                      t={t}
                      onDeleteFile = {(e)=>{
                        refetch();
                      }}
                    />,
                  },
                  {
                    key : "3",
                    label : t("paymentRequests.detail.section1.tab2.title"),
                    children : <Comments
                      payment={payment}
                      idpayment={id}
                      addNewComment={addNewComment}
                      t={t}
                    />,
                  },
                  {
                    label : t("paymentRequests.detail.section1.tab3.title"),
                    key : "4",
                    children : payment?.events.length > 0 ? (
                      <Steps progressDot direction="vertical" current={0}
                        items = {payment.events.map((e,key) => ({
                          key,
                          title : moment(e.date).format("D MMM-YY HH:mm") +
                          " / " +
                          e.author?.firstname +
                          " " +
                          e.author?.lastname,
                          description : i18nTObj(e,"name"),
                        }))}/>
                    ) : (
                      <Empty className="u-mar-top-l" />
                    )
                  }
                ]}/>
              </Box>
        </Box>
      </Spin>
    </LayoutPage>
  );
}
