import { isValidUrl,defaultStr,classNames} from "$utils";
import React from "$react";
import { Link } from "react-router-dom";
import {Link as CharkraLink} from "$ui";
import {withStyles} from "$theme";
import Button from "$components/Button";

/***
 * @see : https://chakra-ui.com/docs/components/link/usage
 * @see : https://reactrouter.com/en/main/components/link
 */
const LinkComponent = React.forwardRef(({url,href,to,icon,isExternal,disabled,...props},ref)=>{
  url = isValidUrl(href) ? href : isValidUrl(url)? url : isValidUrl(to)? to : defaultStr(href,url,to);
  const isLocalRoute = (url.startsWith("/") || url.startsWith("#"));
  if(!isValidUrl(url) && !isLocalRoute){
    url = undefined;
  }
  if(isLocalRoute && url){
    isExternal = false;
    props.as = Link;
    props.to = url;
  } 
  if(!url){
      return <span {...props}/>
  }
  return <CharkraLink  {...props} className={classNames(props.className,'link-component coltext fW500 cursor')}isExternal={isExternal} href={url} ref={ref}/>
});

LinkComponent.propTypes = {
  ...Object.assign({},CharkraLink.propTypes),
  ...Object.assign({},Link.propTypes),
}

export default withStyles(LinkComponent,{
  className :"link",
  variant : "link",
  displayName : "LinkComponent"
})