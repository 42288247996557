import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import fr from "./locales/fr";
import en from "./locales/en";
import {isObj,isNonNullString} from "$utils";
import {langs} from "./utils";

export * from "./utils";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: en,
      },
      fr: {
        translations: fr,
      },
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;


/****
  @param {object|string} object, l'objet sur lequel l'on doit chercher le champ à récuperer
  @param {Array<string>|string}, les champs, pris de manière recursive, à utiliser pour prélever la valeur sur l'objet*
  @param {string|number} defaultValue, default value to return if not value found
  @return {string}
*/
export const translateObj = i18n.langObj = i18n.tObj = i18n.translateObj = function(object,fields,defaultValue){
  fields = isNonNullString(fields)? fields.trim().split(".") : Array.isArray(fields)? fields : ["name"];  
  defaultValue = typeof defaultValue =='string' || typeof defaultValue =="number" ? defaultValue : "";
  let field = fields.shift();
  if(!isNonNullString(field)){
    if(typeof object =='number' || typeof object =="string"){
      if(typeof object =='boolean') return object;
      return `${object}`.trim();
    }
    return defaultValue;
  }
  if(!isObj(object)) return defaultValue;
  field = field.trim();
  const fieldLang = getField(field);
  return translateObj(fieldLang in object ? object[fieldLang] : object[field],fields);
}

export const tObj = translateObj;
export const langObj = translateObj;

export const getLang = i18n.getLang = x=>i18n.resolvedLanguage;

/***** retourne un nom de champ à partir du nom name passé en paramètre
  @param {string} name, le nom du champ, 
  @param {string} lang, l'une des langues supportées parmis les langues langs, si lang n'est pas définie alors la langue active est exploitée
  @return {string}, chaine de caractère de la forme : [lang].[name], example : fr.[name], en.[name]
*/
export const getField = i18n.field = i18n.getField = (name,lang)=>{
  if(!isNonNullString(name)) return "";
  if(isNonNullString(lang)){
    lang = lang.toLowerCase().trim();
  } else lang = getLang();
  if(!langs[lang]){
    lang = getLang();
  }
  return `${lang}.${name.trim().ltrim(".")}`;
}
export const field = getField;

export const getLangs = i18n.getLangs = x => Object.keys(langs);

export const getFields = i18n.getFields = i18n.fields = (name,withLang)=> getLangs().map(lang=>{
    const nameL = getField(name,lang);
    const langLabel = langs[lang].nativeName;
    if(withLang){
      return {
        name : nameL,
        field : nameL,
        langCode : lang,
        langLabel,
        lang : langLabel,
      }
    }
    return nameL;
});

export const fields = getFields;

if(typeof window =='object' && window && (!window.i18nTObj || typeof window.i18nTObj !=='function') ){
  Object.defineProperties(window,{
    i18nTObj: {
      value : translateObj,
    },
  })
}