/**@see : https://ant.design/components/table/ */
import {Table, Space} from "antd";
import {CloseOutlined,FilterOutlined} from "@ant-design/icons";
import React from "$react";
import PropTypes from 'prop-types';
import {DatePicker,Switch,Select} from "antd";
import DateComponent from "$components/Date";
import { getForType,getDefaultValueForType } from "./operators";
import {flattenStyle} from "$theme";
import {defaultStr,defaultObj,extendObj,classNames,defaultNumber,isObj,isNonNullString,isFunction} from "$utils";
import notify from "$notify";
import Button from "$components/Button";
import Input from "$components/Input";
import SimpleSelect from "$components/SimpleSelect";
import session from "$session";
import Box from "$components/Box";
import { renderRowCell } from "./utils";
import { useTranslation } from "react-i18next";
import Pagination from "./Pagination";
import Column from "./Column";
import {Context} from "./hooks";
const dayjs = require('dayjs')

const sessionKey = "datagrid-session-key";
export const getSession = (name)=>{
    const ob = defaultObj(session.get(sessionKey));
    if(typeof name =="string" && name){
        return ob[name] || undefined;
    }
    return ob;
}
export const setSession = (key,value)=>{
    const ob = getSession();
    if(typeof key =='string' && key){
        ob[key] = value;
    }
    session.set(sessionKey,ob);
    return ob;
}

export const iconSize = 20;


const dataIndexSeparator = "##";

const DatagridComponent = React.forwardRef((props,ref)=>{
    const innerRef = React.useRef(null);
    const { t,i18n } = useTranslation();
    const translate = t;
    const locale = i18n.resolvedLanguage;
    const searchIn = t("main.searchIn");
    const mainCancel = t("main.cancel");
    const tFiltersOn = t("main.filtersOn");
    
    let {left,scroll,fixedHeader,onRowClick,
        rowKey,
        selectedRowsActions,
        actions,
        actionProps,
        columns,
        title,
        onRowDoubleClick,selectable,selectableMultiple,getRowKey,onRowContextMenu,onRowMouseEnter,onRowMouseLeave
        ,onRow,onChange,onPaginationChange,fixedFooter,children:customChildren,containerProps,
        disabled,disableOnLoading,fetchOptions,listData,dataSource : customData,label,refresh:cRefresh,...rest} = props;
    const hasList = hasListData(listData);
    const hasListDataArray = !hasList && Array.isArray(listData);
    columns = Array.isArray(columns)? columns : [];
    actionProps = defaultObj(actionProps);
    const paginationRef = React.useRef({pageSize: 10,});
    const sortRef = React.useRef(rest.sort)

    const filteredColumnsRef = React.useRef({});
    const sortedColumnsRef = React.useRef({});
    const refresh = ()=>{
        let fWhere = null;
        const opts = {};
        Object.map(filteredColumnsRef.current,({value,operator},key)=>{
            if(value !== undefined){
                fWhere = fWhere || {};
                fWhere[key] = value;
            }
        })
        opts.queryParams = Object.assign({},opts.queryParams);
        opts.pagination = paginationRef.current;
        opts.filteredValues = fWhere;
        if(fWhere){
            opts.queryParams.where = extendObj(true,{},opts.queryParams.where,fWhere)
        }
        opts.filters = filteredColumnsRef.current;
        if(typeof cRefresh =='function'){
            return cRefresh(opts);
        }
    };
    const preparedColumns = React.useMemo(()=>{
        const c = [];
        Object.map(columns,(col,i)=>{
            if(!isObj(col)) return;
            if(col.isColumnActions){
                c.push(col);
                hasFoundActions = true;
                return;
            }
            const {field,filterSearch,fixed,title,onFilter,filters,sortable,filter,render,checkedLabel,uncheckedLabel,checkedValue:cValue,uncheckedValue:cuValue,filterProps:cFilterProps,...rCol} = Object.clone(col);
            const dataIndex = Array.isArray(rCol.dataIndex)? rCol.dataIndex.filter(v=>isNonNullString(v)).join(dataIndexSeparator) : defaultStr(rCol.dataIndex,rCol.key);
            const titleNotR = defaultStr(title,dataIndex);
            const titleText = translate(titleNotR);
            const cFormat = defaultStr(rCol.format).trim().toLowerCase();
            const checkedValue = cValue !== undefined ? cValue : true;
            const uncheckedValue = cuValue !== undefined ? cuValue : false;
            let type = defaultStr(rCol.type);
            if(["money","number"].includes(cFormat)){
                type = "number";
                rCol.format = cFormat;
            }
            let filterType = defaultStr(rCol.filterType,type,"text");
            rCol.type = defaultStr(type,cFormat,filterType).trim();
            const _type = rCol.type.toLowerCase().replaceAll("_","");
            const isDate= _type?.contains("date") || _type?.contains("time");
            const isSwitch = _type =="switch" || _type =="checkbox";
            const Component = isDate? DatePicker : isSwitch ? SwitchFilterComponent : Input;
            const isNumber = _type =="number" || cFormat =="money";
            const restProps = isSwitch ? {
                checkedLabel,
                checkedValue,
                uncheckedLabel,
                uncheckedValue
            } : isDate ? {locale} : {};
            if(Component == Input){
                restProps.placeholder = `${searchIn} [${titleText}]...`;
            }
            const filterProps = {};
            Object.map(rCol,(p,i)=>{
                if(i in defaultObj(Component.propTypes)){
                   filterProps[i] = p; 
                }
            })
            if(isNonNullString(dataIndex)){
                if(filter !== false){
                   const defOperator = getDefaultValueForType(filterType);
                   rCol.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                       return <Box p="8px" minW="220px">
                            <Box>
                                <Box className="filter-component" mb="10px">
                                    {tFiltersOn} [{titleText}]
                                </Box>
                                <Box 
                                    as={Component}
                                    {...restProps}
                                    {...filterProps}
                                    {...defaultObj(cFilterProps)}
                                    format = {rCol.format||cFormat||undefined}
                                    defaultValue={selectedKeys[0]}
                                    onChange={(value,...rest) => {
                                        const isDate2HasNotValue = isDate && !value;
                                        if(isDate2HasNotValue){
                                            value = "";
                                        } else if(!isDate) {  
                                            if(isObj(value) && 'value' in value){
                                                value = value.value;
                                            }
                                        }
                                        const hasNotVal = value ==="" || value === undefined || isNumber && value === 0;
                                        if(hasNotVal){
                                            delete filteredColumnsRef.current[dataIndex];
                                        } else {
                                            filteredColumnsRef.current[dataIndex] = defaultObj(filteredColumnsRef.current[dataIndex]);
                                            filteredColumnsRef.current[dataIndex].value = value;
                                            filteredColumnsRef.current[dataIndex].operator = defaultObj(filteredColumnsRef.current[dataIndex].operator,defOperator);
                                        }
                                        setSelectedKeys(!hasNotVal ? [value] : []);
                                        confirm({ closeDropdown: false });
                                    }}
                                    onKeyPress ={(e) => {
                                        e = e || window.event;
                                        if(e?.keyCode == 13){
                                            handleSearch({selectedKeys, confirm, dataIndex})
                                        }
                                    }}
                                    style={{display: 'block' }}
                                    type = {filterType}
                                    mb="10px"
                                />
                            </Box>
                            <Space>
                                {false && <SimpleSelect
                                    items = {getForType(filterType)}
                                    defaultValue = {defOperator.code}
                                    itemValue = {({item,index})=>item.code}
                                    renderText = {({item,index})=>item.label}
                                    onChange = {({item})=>{
                                        filteredColumnsRef.current[dataIndex] = defaultObj(filteredColumnsRef.current[dataIndex]);
                                        filteredColumnsRef.current[dataIndex].operator = item;
                                    }}
                                />}
                                <Button
                                    type = "primary"
                                    size="small"
                                    icon = {<FilterOutlined />}
                                    onClick={(e) => {
                                        refresh();
                                        confirm();
                                        return false;
                                    }}
                                >
                                    GO
                                </Button>
                                <Button
                                    icon = {<CloseOutlined/>}
                                    children = {mainCancel}
                                    onClick = {()=>{
                                        confirm();
                                    }}
                                />
                            </Space>
                        </Box>
                   }
                   rCol.onFilter = (value,record,...rest)=>{
                        if(typeof onFilter =='function'){
                            return onFilter(value,record,...rest);
                        }
                        let v = undefined;
                        if(isObj(record)){
                            v = record;
                            dataIndex.split(dataIndexSeparator).map((k)=>{
                                if(isObj(v)){
                                    v = v[k];
                                } else {
                                    v = undefined;
                                }
                            })
                        }
                        if(isSwitch){
                            return !!(v == value);
                        }
                        if(isDate){
                            if(!isNonNullString(v)) {
                                return false;
                            }
                            const vDate = dayjs(v);
                            try {
                                return !!(value.diff(vDate,"day") ==0);
                            } catch(e){
                                return false;
                            }
                        }
                        if(typeof v ==='string' && typeof value =='string'){
                            return v.toLowerCase().trim().includes(value?.toLowerCase().trim());
                        }
                        if(typeof value ==='string'){
                            value = value.toLowerCase().trim();
                            if(v == true && (value.includes("yes")||value.includes("oui"))){
                                return true;
                            }
                            if(v ==false && (value?.includes("no"))){
                                return true;
                            }
                        }
                        return !!(v == value);
                   }
                } 
                if(sortable !== false){
                    rCol.sortDirections = ['ascending','descend'];
                }   
                rCol.dataIndex = Array.isArray(rCol.dataIndex)? rCol.dataIndex : dataIndex;
            }
            rCol.render = (text,record,index)=>{
                if(typeof render ==='function'){
                    return render({text,record,index,rowData:record,item:record});
                }
                return renderRowCell({value:text,defaultValue:text,type:defaultStr(type,filterType),rowData:record,columnDef:rCol});
            }
            c.push({...rCol,title:<Column title={titleNotR} sortable={sortable} />});
        });
        return c;
    },[columns,JSON.stringify(sortedColumnsRef.current),locale,searchIn])
    
    const [state,setState] = React.useState({
        isLoading : false,
        columns:preparedColumns,
        fixedHeader : typeof fixedHeader =='boolean'? fixedHeader : true,
        fixedFooter : typeof fixedFooter =='boolean'? fixedFooter : true,
        disabled : typeof disabled =='boolean' ? disabled : false,
    });
    const handleSearch = ({selectedKeys, confirm, dataIndex}) => {
        confirm();
        setState({
          ...state,
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
    const data = hasList ? listData?.content : hasListDataArray ? listData : Array.isArray(customData)? customData : [];
    fetchOptions = Object.assign({},fetchOptions);
    containerProps = defaultObj(containerProps);
    let loading = false;
    const {isLoading} = state;
    React.useEffect(()=>{
        if(typeof disabled =='boolean' && disabled !== state.disabled){
            setState({...state,disabled});
        }
    },[disabled])
    const updateState = (nState)=>{
        if(nState && typeof nState =='object'){
            setState({...state,...nState})
        }
    };
    const disable = ()=>{
        setState({...state,disabled:true,isLoading:true})
    }
    const enable = ()=>{
        setState({...state,disabled:disabled !== true ? false : true, isLoading:false});
    }
    const selectedRowsRef = React.useRef({});
    state.getActionsArgs = (selected)=>{
        return {
            component:'datagrid',
            data : data,
            rows : data,
            selected : typeof selected =='boolean'? selected : false,
            props,
            selectedRows : selectedRowsRef.current,
            context:state,
        }
    }
    state.renderSelectedRowsActions = (sActions)=>{
        const {size} = sActions;
        let r = [];
        let endActs = [];
        if(size <=0) {
            return r
        };
        let selectedR = selectedRowsActions;
        let sArgs = state.getActionsArgs(true);
        if(isFunction(selectedR)) {
            selectedR = selectedR.call(state,sArgs)
        }
        Object.map(sActions,(o,i)=>{
            if(isObj(o)){
                let {onClick, ...rest} = o;
                if(!isObj(rest) || !isFunction(onClick)) return null;
                rest.onClick = (event)=>{
                    if(isFunction(onClick)){
                        sArgs.event = event;
                        onClick.call(state,sArgs);
                    }
                }
                if(o.pos !== 'end'){
                    r.push(rest)
                } else {
                    endActs.push(rest);
                }
            }
        })
        Object.mapToArray(selectedR,(action,i)=>{
            if(isObj(action)){
                let {onClick, ...rest} = action;
                if(!isObj(rest)) return null;
                rest.onClick = (event)=>{
                    if(isFunction(onClick)){
                        onClick.call(state,sArgs);
                    }
                }
                r.push(rest)
            }
        })
        endActs.map((a)=>{
            r.push(a);
        })
        return r;
    }
    const _isLoading = loading || rest.loading || isLoading;
    const children = typeof customChildren =='function'? customChildren(iconProps) : customChildren;
    const isDisabled = state.disabled || disableOnLoading && _isLoading? true : false;
    scroll = isObj(scroll) && Object.size(scroll,undefined,true)? scroll : {};//{ y: 240 };
    selectable = typeof selectable =='boolean'? selectable : false;
    selectableMultiple = typeof selectableMultiple =='boolean'? selectableMultiple : true;
    const isRowKeyString = typeof rowKey =="string" && rowKey ? true : false;
    getRowKey = typeof getRowKey =='function'? getRowKey : (row,rowIndex)=>{
        return React.getKey(row,rowIndex,rowKey);
    }
    React.useEffect(()=>{
        return ()=>{
            React.setRef(ref,null);
        }
    },[])
    const handleTableChange = (pagination, filters, sorter,...rest) => {
        paginationRef.current = pagination;
        sortRef.current = sorter;
        if(typeof onChange =='function' && onChange(pagination,filters,sorter,...rest) === false) return
        refresh();
    };
    const pageable = hasList ? listData.pageable : {};
    return (<Context.Provider value={{}}>
        <Box {...containerProps} className={classNames(containerProps.className,isDisabled?"disabled":"")} style={flattenStyle([{width:'100%'},containerProps.style])}>
            <Table
                bordered
                {...defaultObj(rest)}
                title = {title}
                columns = {state.columns}
                rowKey = {rowKey}
                onChange = {handleTableChange}
                rowSelection = {!selectable?undefined:{
                    type : selectableMultiple? 'checkbox' : 'radio',
                    onChange: (selectedRowKeys, selectedRows) => {
                      if(!selectable){
                          return notify.warning("Les lignes du tableau ne sont pas selectionables");
                      }
                      if(!selectableMultiple && selectedRows.length >=1){
                          return notify.warning("impossible de sélectionner plusieurs lignes");
                      }
                      selectedRowsRef.current = {};
                      selectedRows.map((row,rowIndex)=>{
                        const key = getRowKey(row,rowIndex,rowKey);
                        if(key !== undefined && typeof key !=='object'){
                            selectedRowsRef.current[key] = row;
                        }
                      })
                    },
                    getCheckboxProps: (record) => {
                        return {
                            disabled: !selectable, // Column configuration not to be checked
                            name: isRowKeyString && record[rowKey]? record[rowKey]:"no-name-key",
                        }
                    },
                }}
                onRow={(record, rowIndex,...rest) => {
                    const rr = typeof props.onRow =="function"? props.onRow(record,rowIndex,...rest):{};
                    const result = {};
                    ["onClick","onDoubleClick","onContextMenu","onMouseEnter","onMouseLeave"].map((ev)=>{
                        result[ev] = (event)=>{
                            if(isObj(rr) && typeof rr[ev]==='function' && rr[ev](record,rowIndex,event,...rest) === false) return;
                            const k = "onRow"+ev.ucFirst();
                            if(typeof props[k] =="function"){
                                return props[k](record,rowIndex,event,...rest)
                            }
                        }
                    });
                    return result;
                }}
                scroll={scroll}
                disabled = {isDisabled}
                ref = {(el)=>{
                    if(el && typeof el =='object'){
                        el.updateState = updateState;
                        el.disable = disable;
                        el.enable = enable;
                        el.refresh = refresh;
                    }
                    React.setRef(ref,el);
                    React.setRef(innerRef,el);
                }}
                loading = {_isLoading}
                dataSource = {data}
                pagination = {hasList ? false : rest.pagination}
            />
            {children ? children : null}
            <Box w="100%" className="table-bottom-container">
                <Box className="table-pagination-container" display="flex" justifyContent="flex-end" flex-direction="row-reverse" alignItems="center">
                    {hasList && <>
                        <Box as="span" color="primary" fontWeight="bold" fontSize="17px" px="5px" py="0px" h="auto">{listData?.totalElements?.formatNumber()+" | "}</Box>
                        <Pagination
                            defaultCurrent={pageable.pageNumber+1}
                            defaultPageSize = {pageable?.pageSize}
                            total = {listData.totalElements}
                            onChange = {(current,size)=>{
                                const page = current > 0 ? current - 1 : 0;
                                const pagination = {current,page,size};
                                if(typeof onPaginationChange ==='function'){
                                    return onPaginationChange(pagination);
                                }
                            }}
                        />
                    </>}
                </Box>
            </Box>
        </Box>
    </Context.Provider>)
});

DatagridComponent.displayName = "DatagridComponent";

DatagridComponent.propTypes = {
    ...defaultObj(Table.propTypes),
    selectable : PropTypes.bool,///
    selectableMultiple : PropTypes.bool,///si le datagrid est selectable multiple
    actions : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    listData : PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
            pageable : PropTypes.shape({
                offset : PropTypes.number,//l'offset de la pagination,
                pageNumber : PropTypes.number, //le numéro de la page en cours,
                pageSize : PropTypes.number, //la taile d'une page
            }),
            content : PropTypes.array,
            size : PropTypes.number, //la taille d'une page
            totalElements : PropTypes.number, //le nombre d'éléments total
            numberOfElements : PropTypes.number, //le nombre d'éléments de la page
        })
    ]), //les données récupérées à travers la fonction useGetList de $api/request
    /***
     * Callback executed when pagination, filters or sorter is changed
     * function(pagination, filters, sorter, extra: { currentDataSource: [], action: paginate | sort | filter })
     */
    onChange : PropTypes.func, 
    onRow : PropTypes.func,
    disableOnLoading : PropTypes.bool,//si le composant est désactivé lorsqu'il charge
    fetchOptions : PropTypes.object,//les options supplémentaires pour effectuer la recherche
    fetcher : PropTypes.func, ///la fonction appelée pour retourner les données
    data : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    sort : PropTypes.shape({
        column : PropTypes.string,
        dir : PropTypes.string,
    }),
    pagination1 : PropTypes.shape({
        pageSize : PropTypes.number, ///la taille d'une page
        //size : PropTypes.number, //alias à pageSize
        page : PropTypes.number,//la page actuelle, alias à page
        current : PropTypes.number,//alias à page,
        position : PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.oneOf([
                "none",
                "topLeft",
                'topCenter',
                'topRight',
                'bottomLeft',
                'bottomCenter',
                'bottomRight',
            ])
        ])
    }),
}

export const hasListData = (listData)=> isObj(listData) && Array.isArray(listData.content) && isObj(listData.pageable);

export default DatagridComponent;

const {Option} = Select;

const SwitchFilterComponent = React.forwardRef(({checkedLabel,checkedValue,uncheckedLabel,uncheckedValue,...props},ref)=>{
    const {t} = useTranslation();
    checkedLabel = t(defaultStr(checkedLabel,"yes"));
    uncheckedLabel = t(defaultStr(uncheckedLabel,"no"));
    checkedValue = typeof checkedValue !== undefined ? checkedValue : true;
    uncheckedValue = typeof uncheckedValue !== undefined ? uncheckedValue : false;
    return <Select {...props} ref={ref}>
        <Option value = ""></Option>
        <Option value={checkedValue}>{checkedLabel}</Option>
        <Option value={uncheckedValue}>{uncheckedLabel}</Option>
    </Select>
});   

SwitchFilterComponent.displayName = "SwitchFilterComponent ";