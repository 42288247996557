import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import {
  Button,
  Select,
  Form,
  Col,
  message,
  Row,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useAddCommentForMovement } from "../../api/api";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

export default function CommentForm({
  close,
  commentator,
  movement_id,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: addComment } = useAddCommentForMovement(movement_id);
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        try {
          setLoading(true);
          console.log("+++++++ comment", values);
          await addComment(values);

          close();

          message.success("Enregistrement réussi!!");
          setLoading(false);
        } catch (e) {
          setLoading(false);
          message.warning("Erreur enregistrement!");
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>
        <h2 className="fW600"> Nouveau commentaire</h2>
        <div className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> Valider </span>
          </Button>
        </div>
      </div>
      <div className="u-mar-vertical-xxl  w50 mauto">
        <Form layout="vertical" className="rel" form={form}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="commentator_id"
                label="Emeteur"
                rules={[
                  { required: true, message: "Entrer le code du projet" },
                ]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                >
                  {commentator.map((member,key) => (
                    <Option value={member.id} key={key}>{member.firstname}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="message"
                label="Message"
                className="message-form-item"
                rules={[{ required: true, message: "Cet élément est requis" }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
