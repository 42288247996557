import React, { useState } from "react";
import {
  Dropdown,
  Menu,
  Button,
  Modal,
  Row,
  Form,
  Col,
  Divider,
  Tag,
  Input,
  message,
  Select,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import Box from "$components/Box";
import {
  useValidateMovement,
  useAutoriseMovement,
  useChangePerson,
} from "../../api/api";
import { useTranslation } from "react-i18next";
import {
  TREASURER, INITIATOR,COORDINATOR,ACCOUNTANT} from "$enums/steps";
import STATUS,{VALIDATED,APPROVED,getUsersForsStatus,ON_HOLD,WAITING_PAYMENTS,CLOSED,REJECTED,canValidate,getAvailableStatusForUser} from "../status";

const { TextArea } = Input;
const { Option } = Select;


export default function ValidationMovement({
  idUser,
  movement,
  updateDetail,
  employees,
}) {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState("");
  const [post, setPost] = useState("");
  const [grade, setGrade] = useState("");
  const [employee_id, setEmployeeId] = useState(0);
  const [open, setOpen] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [visible, setVisible] = useState(null);
  const { mutateAsync: validate } = useValidateMovement(movement?.id);
  const { mutateAsync: autorise } = useAutoriseMovement(movement?.id);
  const { mutateAsync: changePerson } = useChangePerson(
    movement?.id,
    employee_id,
    grade
  );
  const isApproved =  movement?.status?.id === APPROVED;
  const isInitiator = movement?.step?.id == INITIATOR;
  const isCoordinator = movement?.step?.id == COORDINATOR;
  const isChecker = movement?.checker?.id == idUser;
  const isAccountant = movement?.step?.id === ACCOUNTANT;
  const isRejected = movement?.status?.id == REJECTED;
  const canSubmitAgain = isInitiator && isRejected && isChecker;
  const canApproveCoordinator = isCoordinator && isChecker;
  const canApproveAccountant = isAccountant && isChecker;
  
  const openModal = (status, statusCode) => {
    setStatus(status);
    setStatusCode(statusCode);
    setVisible(true);
  };

  const OpenForChange = (post, grade) => {
    setPost(post);
    setGrade(grade);
    setOpen(true);
  };

  const error = () => {
    Modal.error({
      title: t("error"),
      content: t("error.text"),
    });
  };
  const approveOpts = {
    label : <div
      key="0"
      className="flex itemcenter"
      onClick={() => submitWithoutComment(1)}
    >
      <div className="flex column u-mar-left-s">
        <span className="av-light fs14" style={{ color: "#65676b" }}>
          {t("travelRequests.validation.approve")}
        </span>
      </div>
    </div>,
  }, authorizeOpts = {
    label : <div
      key="1"
      className="flex itemcenter rad8 "
      onClick={() => submitWithoutComment(3)}
    >
      <div className="flex column u-mar-left-s">
        <span className="av-light fs14" style={{ color: "#65676b" }}>
          {t("travelRequests.validation.authorise")}
        </span>
      </div>
    </div>
  }, rejectOpts = {
    label : <div
      key="2"
      className="flex itemcenter rad8 "
      onClick={() => openModal("Rejecter", 2)}
    >
      <div className="flex column u-mar-left-s">
        <span className="av-light fs14" style={{ color: "#65676b" }}>
          {t("travelRequests.validation.reject")}
        </span>
      </div>
    </div>
  }, dividerOpts =  {
    type: 'divider'
  };
  const menu = [];
  if(canApproveAccountant || canApproveCoordinator){
     menu.push(canApproveAccountant? approveOpts : authorizeOpts);
     menu.push(dividerOpts);
     menu.push(rejectOpts);
  }

  const submit = async (values) => {
    setEmployeeId(values.new_employee);
    if (!values) return;
    try {
      setSubmitting(true);
      let resp = await changePerson();
      updateDetail(resp);
      message.success(t("successful.recording"));
      setOpen(false);
    } catch (e) {
      setSubmitting(false);
      message.warning(t("error.recording"));
    }

    setSubmitting(false);
  };

  const submitWithoutComment = async (status) => {
    let value = {
      status: status,
      message: "",
    };

    try {
      if (status == 3) {
        if (
          movement.context == "" ||
          movement.risk == "" ||
          movement.mitigation == ""
        ) {
          error();
        } else {
          value.status = 1;
          const rep = await autorise(value);
          updateDetail(rep);
          message.success(t("successful.recording"));
        }
      } else {
        if (
          idUser == movement.coordinator.id &&
          (movement.context == "" ||
            movement.risk == "" ||
            movement.mitigation == "")
        ) {
          error();
        } else {
          const resp = await validate(value);
          updateDetail(resp);
          message.success(t("successful.recording"));
        }
      }
    } catch (e) {
      message.warning(t("error.recording"));
    }
  };

  const handleSubmit = async (values) => {
    if (!values) return;

    let payload = {
      status: statusCode,
      comment: values.comment,
    };

    try {
      setSubmitting(true);

      const resp = await validate(payload);
      updateDetail(resp);
      message.success(t("successful.recording"));

      setVisible(false);
    } catch (e) {
      setSubmitting(false);
      message.warning(t("error.recording"));
    }

    setSubmitting(false);
  };
  return (
    <>
      <div className="noshadow blok u-pad-s noborder hauto rad16 w100 ">
        <h2 className="fW600 u-mar-top-xs">
          {t("travelRequests.detail.section3.title")}
        </h2>
        <Divider style={{margin:0}}/>
        {canSubmitAgain && <Box w="100%" className="submit-again-container">
          <Box className="flex column ">
              <Box mb="10px" className="item-submitagain-text -to">
                {" "}
                {t("commitmentRequests.rejected_msg")}{" "}
              </Box>
            </Box>
        </Box>}
        {menu.length && movement?.checker?.id == idUser && movement?.status?.id != 1 ? (
          <Dropdown
            menu={{items:menu,style: { width: "170px" }}}
            className="cursor u-mar-right-xs"
            trigger={["click"]}
          >
            <div className="flex itemcenter cursor">
              <span
                className="fs15 fW500 u-mar-right-xs"
                style={{ color: "#1a1a1a" }}
              >
                {t("travelRequests.detail.section3.label0")}
              </span>
              <span style={{ marginTop: "4.5px" }}>
                <CaretDownOutlined />
              </span>
            </div>
          </Dropdown>
        ) : null}
        <div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s">
          <span className="grille2 fW500 fs14 colhead">
            {t("travelRequests.detail.section3.label1")}:{" "}
          </span>
          <span className="grille4 fW500 fs14 ">
            {movement?.checker?.firstname + " " + movement?.checker?.lastname}{" "}
            <br />
            <span className="fs10"> ({i18nTObj(movement?.checker?.post)}) </span>
          </span>
          <span className="grille">--</span>
        </div>
        <div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s">
          <span className="grille2 fW500 fs14 colhead">
            {t("travelRequests.detail.section3.label2")}:{" "}
          </span>
          <span className="grille4 fW500 fs14 ">
            {movement?.initiator?.firstname +
              " " +
              movement?.initiator?.lastname}{" "}
            <br />
            {!isApproved && <>
              <Button
                type="link"
                onClick={() => OpenForChange(i18nTObj(movement?.initiator?.post), 0)}
              >
                {t("reassignment.label")}
              </Button>{" "}
              <br />
            </>}
            <span className="fs10"> ({i18nTObj(movement?.initiator?.post)}) </span>
          </span>
          <span className="grille">--</span>
        </div>
        <div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s">
          <span className="grille2 fW500 fs14 colhead">
            {t("travelRequests.detail.section3.label3")} :{" "}
          </span>
          <span className="grille4 fW500 fs14 ">
            {movement?.accountant?.firstname +
              " " +
              movement?.accountant?.lastname}{" "}
            <br />
            {!isApproved && <>
                <Button
                type="link"
                onClick={() => OpenForChange(i18nTObj(movement?.accountant?.post), 2)}
              >
                {t("reassignment.label")}
              </Button>{" "}
              <br />
            </>}
            <span className="fs10"> ({i18nTObj(movement?.accountant?.post)}) </span>
          </span>
          <span className="grille fW500 fs14 ">
            <Tag
              className="u-mar-left-xs"
              color={
                movement?.statusAccountant?.id == 0
                  ? "#ff9e01"
                  : movement?.statusAccountant?.id == 1
                  ? "#87d068"
                  : movement?.statusAccountant?.id == 2
                  ? "#ff5500"
                  : "#108ee9"
              }
            >
              {i18nTObj(movement?.statusAccountant)}
            </Tag>
          </span>
        </div>
        <div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s">
          <span className="grille2 fW500 fs14 colhead">
            {t("travelRequests.detail.section3.label5")} :{" "}
          </span>
          <span className="grille4 fW500 fs14 ">
            {movement?.coordinator?.firstname +
              " " +
              movement?.coordinator?.lastname}{" "}
            <br />
            {!isApproved && <>
              <Button
                type="link"
                onClick={() => OpenForChange(i18nTObj(movement?.coordinator?.post), 3)}
              >
                {t("reassignment.label")}
              </Button>{" "}
              <br />
            </>}
            <span className="fs10"> ({i18nTObj(movement?.coordinator?.post)}) </span>
          </span>
          <span className="grille fW500 fs14 ">
            <Tag
              className="u-mar-left-xs"
              color={
                movement?.statusCoordinator?.id == 0
                  ? "#ff9e01"
                  : movement?.statusCoordinator?.id == 1
                  ? "#87d068"
                  : movement?.statusCoordinator?.id == 2
                  ? "#ff5500"
                  : "#108ee9"
              }
            >
              {i18nTObj(movement?.statusCoordinator)}
            </Tag>
          </span>
        </div>
      </div>
      <Modal
        onCancel={() => setVisible(false)}
        open={visible}
        //title='Veuillez entrer la raison du rejet'
        //bodyStyle={{ paddingBottom: 0,paddingTop:12,height:300}}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("travelRequests.validation.Reason.reject")}{" "}
        </h2>
        {/*<h2 className='center'>Etes vous sûre de mettre le statut de la demande à <br/> <span className='fW600'>{status}</span> ? </h2>*/}
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="comment">
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisible(false)}
            >
              <span className="fs16 av-heavy">
                {t("travelRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t("travelRequests.detail.section3.modify.popup.confirm")}{" "}
              </span>
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        onCancel={() => setOpen(false)}
        open={open}
        //bodyStyle={{ paddingBottom: 0,paddingTop:12,height:300}}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("travelRequests.detail.section3.modify.popup.title")}{" "}
        </h2>
        <h2 className="center">
          {t("travelRequests.detail.section3.modify.popup.label0")} <br />{" "}
          <span className="fW600">{post}</span>{" "}
        </h2>
        <Form layout="vertical" onFinish={submit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="new_employee" label="Ajouter un commentaire">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // placeholder="Choisir le rsponsable logistique"
                  autoComplete="none"
                >
                  {(Array.isArray(employees) && employees || []).map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.firstname + " " + item.lastname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setOpen(false)}
            >
              <span className="fs16 av-heavy">
                {t("travelRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t("travelRequests.detail.section3.modify.popup.confirm")}{" "}
              </span>
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
