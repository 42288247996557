export default {
    "notify.changes" : "Changes in {0} request(s) that concerns you",
    "auth.signIn.email" : "Enter your email",
    "auth.signIn.password" : "Enter your password",
    "main.searchIn" : "Search in",
    "main.filtersOn" : "Filters on",
    "main.cancel" : "Cancel",
    "table.yes" : "Yes",
    "table.No" : "No",
    ...require("./auth").default,
    ...require("./tickets").default,
    "homePage.menu.dashboard": "Dashboard",
    "homePage.menu.commitmentForm": "Commitment form",
    "homePage.menu.commitmentRequest": "Commitment request",
    "homePage.menu.paymentRequest": "Payment request",
    "homePage.menu.sites": "Zones",
    "homePage.menu.employees": "Employees",
    "homePage.menu.projects": "Projects",
    "homePage.menu.assignments": "Assignments",
    "homePage.menu.travelRequest": "requests for travel",
    "header.logout": "Log out",
    "homePage.header.profile.settings.label": "Account seetings",
    "homePage.header.profile.settings.page.section1.title":
      "My information",
    "homePage.header.profile.settings.page.section1.label0": "Name",
    "homePage.header.profile.settings.page.section1.label1": "Surname",
    "homePage.header.profile.settings.page.section1.label2":
      "Phone Number",
    "homePage.header.profile.settings.page.section1.button": "Save",
    "homePage.header.profile.settings.page.section2.title":
      "Change password",
    "homePage.header.profile.settings.page.section2.label0":
      "Old password",
    "homePage.header.profile.settings.page.section2.label1":
      "New Password",
    "homePage.header.profile.settings.page.section2.button": "Save",

    "dashboard.content.welcome": "Welcome",
    "dashboard.content.welcome.date": "Format date en Anglais",
    "dashboard.content.requestsCreated": "Created requests",
    "dashboard.content.requestsValidated": "Validated requests",
    "dashboard.content.requestsRejected": "Rejected requests",
    "dashboard.content.requestsToProcess": "Requests to process",
    "dashboard.content.commitments.stat": "Expenditure Commitments",
    "dashboard.content.travel.stat": "Travel",
    "dashboard.content.payment.stat": "Payments",

    "commitmentForms.list.title": "Commitment forms",
    "commitmentForms.list.search.keywords":
      "Search by initiator, code or zone ...",
    "commitmentForms.form.title": "New expenditure commitment request",
    "commitmentForms.form.section1.title": "Requisition",
    "commitmentForms.form.section1.label0": "For a project realization ?",
    "commitmentForms.form.section1.label1": "Activities",
    "commitmentForms.form.section1.label2": "Project",
    "commitmentForms.form.section1.label3": "Zone",
    "commitmentForms.form.section1.label4": "Submit to",
    "commitmentForms.form.section1.isRequestFromSite" : "Request for the head office level?",
    "commitmentForms.form.section2.title": "Market estimate",
    "commitmentForms.form.section2.label0": "Description of the article",
    "commitmentForms.form.section2.label1": "Service",
    "commitmentForms.form.section2.label2": "Quantity",
    "commitmentForms.form.section2.label3": "Unity",
    "commitmentForms.form.section2.label4": "Unit.P",
    "commitmentForms.form.section2.label5": "Total",
    "commitmentForms.form.section2.label6": "TOTAL PRICE",
    "commitmentForms.form.section2.label7": "Add an item",
    "commitmentForms.form.section2.popup.title": "Add an item",
    "commitmentForms.form.section2.popup.label0":
      "Sub service",
    "commitmentForms.form.section2.popup.label1": "Sub service for HR",
    "add.button": "Add",
    "validate.button": "Validate",
    download: "download",
    delete: "Delete",
    "confirm.delete.attachment": "Confirm delete",
    "add.attachment": "add attachment",
    "commitmentForms.form.section3.title": "Attachments",
    "commitmentForms.form.section3.title1": "type of Attachments",
    "commitmentForms.form.section3.label0": "Document type",
    "commitmentForms.form.section3.label1": "FileName",
    "commitmentForms.form.section3.label2": "Add a TOR",
    "commitmentForms.form.section3.label3": "Select a document",
    "commitmentForms.form.section3.popup.title":
      "Add a Term Of Reference",
    "commitmentForms.form.section3.popup.label0": "Select a document",
    "commitmentForms.form.detail.label0": "For a project ?",
    "commitmentForms.form.detail.label1": "Projects",
    "commitmentForms.form.detail.label2": "Date",
    "commitmentForms.form.detail.label3": "Zone",
    "commitmentForms.form.detail.label4": "Currency",
    "commitmentForms.form.detail.label5": "initiator",
    "commitmentForms.form.detail.label6": "Activities",
    "commitmentForms.form.detail.label7":
      "Requests for commitment of expenses",
    "commitmentForms.form.detail.label8": "Request code",
    "commitmentForms.form.detail.label9": "Service",
    "commitmentForms.form.detail.label10": "Category",
    "commitmentForms.form.detail.label11": "Status",
    "commitmentForms.form.detail.label12": "Objet",
    "commitmentRequests.list.title": "Commitment requests",
    "commitmentRequests.list.search.keywords":
      "Search by code, project, zone ...",
    "commitmentRequests.list.filters.filter1": "All requests",
    "commitmentRequests.list.filters.filter2": "Approved requests",
    "commitmentRequests.list.filters.filter3": "Rejected requests",
    "commitmentRequests.list.filters.filter4": "Requests to process",

    "commitmentRequests.detail.label0": "Service",
    "commitmentRequests.detail.label1": "Projects",
    "commitmentRequests.detail.label2": "Date",
    "commitmentRequests.detail.label3": "Zone",
    "commitmentRequests.detail.label4": "Procedure",
    "commitmentRequests.detail.label5": "Initiator",
    "commitmentRequests.detail.label6": "Activities",

    "commitmentRequests.detail.section1.title": "Details",
    "commitmentRequests.detail.section1.tab0.title": "Items",
    "commitmentRequests.detail.section1.tab0.label0": "Num",
    "commitmentRequests.detail.section1.tab0.label1": "Description",
    "commitmentRequests.detail.section1.tab0.label2": "Quantity",
    "commitmentRequests.detail.section1.tab0.label3": "Unity",
    "commitmentRequests.detail.section1.tab0.label4": "Unit.P",
    "commitmentRequests.detail.section1.tab0.label5": "Total",
    "commitmentRequests.detail.section1.tab0.label6": "TOTAL PRICE",
    "commitmentRequests.detail.section1.tab0.label7": "TOTAL Expense",
    "commitmentRequests.activities" : "Activities",
    "commitmentRequests.detail.section1.tab1.title": "Payments",
    "commitmentRequests.detail.section1.tab1.label0": "Code",
    "commitmentRequests.detail.section1.tab1.label1": "Amount",
    "commitmentRequests.detail.section1.tab1.label2": "Date",
    "commitmentRequests.detail.section1.tab1.label3": "Paid to",
    "commitmentRequests.detail.section1.tab1.label4": "Initiator",
    "commitmentRequests.detail.section1.tab1.label5": "Status",
    "commitmentRequests.detail.section1.tab2.title": "Attachments",
    "commitmentRequests.detail.section1.tab2.label0": "Document type",
    "commitmentRequests.detail.section1.tab2.label1": "Added date",
    "commitmentRequests.detail.section1.tab2.label2": "Added by",
    "commitmentRequests.detail.section1.tab3.title": "Comments",
    "commitmentRequests.detail.section1.tab3.button": "Add a comment",
    "commitmentRequests.detail.section1.tab4.title": "History",

    "commitmentRequests.detail.section2.title": "Remarks",
    "commitmentRequests.detail.section2.label0": "Add/Modify",
    "commitmentRequests.detail.section2.popup.title":
      "Add a remark on the items",
    "commitmentRequests.detail.section2.popup.button": "Add remark",
    "commitmentRequests.edit.procedure": "Change procedure type",
    
    "commitmentRequests.edit.project" : "Edit project",
    "commitmentRequests.edit.zone" : "Edit zone",
    "commitmentRequests.edit.activities" : "Edit activities",
    
    "commitmentRequests.type.procedure": "Procedure type",
    "commitmentRequests.detail.section3.title": "Approvals",
    "commitmentRequests.detail.section3.label0": "Change status",
    "commitmentRequests.detail.section3.label1": "Assigned to",
    "commitmentRequests.detail.section3.label2": "Requester",
    "commitmentRequests.detail.section3.label3": "Budget holder",
    "commitmentRequests.detail.section3.label4": "Financial Admin",
    "commitmentRequests.detail.section3.label5": "General Coord",
    "commitmentRequests.detail.section3.label6": "Head of Operation",
    "commitmentRequests.detail.section3.label7": "Change in progress ...",
    "commitmentRequests.status.approuved": "Approuved",
    "commitmentRequests.status.rejected": "Rejected",
    "commitmentRequests.status.waiting_payment": "Waiting payment",
    "commitmentRequests.status.Validated": "Validated",
    "commitmentRequests.status.close": "Close",
    "commitmentRequests.fill_anal": "Fill all analytics codes",
    "commitmentRequests.rejected_msg":
      " Rejected ticket, edit and submit again",
    "commitmentRequests.submit_again": "Submit again ",

    "commitmentRequests.detail.section3.modify.popup.title":
      "Change of position",
    "commitmentRequests.detail.section3.modify.popup.label0":
      "Change the position of",
    "commitmentRequests.detail.section3.modify.popup.label1":
      "Assigned to",
    "commitmentRequests.detail.section3.modify.popup.cancel": "Cancel",
    "commitmentRequests.detail.section3.modify.popup.confirm": "Confirm",

    "commitmentRequests.detail.section3.changeStatus.popup.title":
      "Change the status of the request",
    "commitmentRequests.detail.section3.changeStatus.popup.label0":
      "Are you sure to change the status to",
    "commitmentRequests.detail.section3.changeStatus.popup.label1":
      "Add a comment",
    "commitmentRequests.detail.section3.changeStatus.popup.cancel":
      "Cancel",
    "commitmentRequests.detail.section3.changeStatus.popup.confirm":
      "Confirm",

    "commitmentRequests.detail.section4.title": "Analytical code",
    "commitmentRequests.detail.section4.label0": "Num",
    "commitmentRequests.detail.section4.label1": "project",
    "commitmentRequests.detail.section4.label2": "Project line",
    "commitmentRequests.detail.section4.label3": "Fin code",
    "commitmentRequests.detail.section4.label4": "Fin line",
    "commitmentRequests.detail.section4.popup.title":
      "Add an analytical code",
    "commitmentRequests.detail.section4.popup.label0": "Project code",
    "commitmentRequests.detail.section4.popup.label1": "Project line",
    "commitmentRequests.detail.section4.popup.label2": "Financial code",
    "commitmentRequests.detail.section4.popup.label3": "Financial line",
    "commitmentRequests.delete.item": "Delete item",
    "commitmentRequests.edit.item": "Edit item",
    "commitmentRequests.confirm.delete.item":
      "confirm delete of this item",
    "reassignment.label": "Edit",
    "export.button.printOptions" : "Print options",
    "export.buton.printOptions.tableItemSize" : "Line item height",
    "export.button.label": "Export",
    "export.button.pdf.label" : "Export to pdf",
    "export.button.excel.label" : "Export to excel",
    "submit.button.label": "Submit",

    "paymentRequests.list.title": "Payment requests",
    "paymentRequests.list.search.keywords":
      "Search by code, requester ...",
    "paymentRequests.list.filters.filter1": "All payments",
    "paymentRequests.list.filters.filter2": "Approved payments",
    "paymentRequests.list.filters.filter3": "Rejected payments",
    "paymentRequests.list.filters.filter4": "Payments to process",

    transfer: "Wire transfer",
    check: "Check note",
    cash: "Cash",
    "paymentRequests.form.title": "New payment request",
    "paymentRequests.form.section1.title": "Informations",
    "paymentRequests.form.section1.label0": "Commitment code",
    "paymentRequests.form.section1.label1": "Payment type",
    "paymentRequests.form.section1.label2": "Explanations",
    "paymentRequests.form.section2.title": "Items",
    "paymentRequests.form.section2.label0": "Description of the article",
    "paymentRequests.form.section2.label1": "Service",
    "paymentRequests.form.section2.label2": "Quantity",
    "paymentRequests.form.section2.label3": "Unity",
    "paymentRequests.form.section2.label4": "Unit.P",
    "paymentRequests.form.section2.label5": "Total",
    "paymentRequests.form.section2.label6": "TOTAL PRICE",

    "paymentRequests.detail.label0": "Commmitment code",
    "paymentRequests.detail.label1": "Type",
    "paymentRequests.detail.label2": "Date",
    "paymentRequests.detail.label3": "Paid to",
    "paymentRequests.detail.label4": "Payment method",
    "paymentRequests.detail.label5": "Initiator",
    "paymentRequests.detail.label6": "Explanation",

    "paymentRequests.detail.section1.title": "Details",
    "paymentRequests.detail.section1.tab0.title": "Items",
    "paymentRequests.detail.section1.tab0.label0": "Num",
    "paymentRequests.detail.section1.tab0.label1": "Description",
    "paymentRequests.detail.section1.tab0.label2": "Quantity",
    "paymentRequests.detail.section1.tab0.label3": "Unity",
    "paymentRequests.detail.section1.tab0.label4": "Unit.P",
    "paymentRequests.detail.section1.tab0.label5": "Total",
    "paymentRequests.detail.section1.tab0.label6": "TOTAL PRICE",
    "paymentRequests.detail.section1.tab1.title": "Attachments",
    "paymentRequests.detail.section1.tab1.label0": "Document type",
    "paymentRequests.detail.section1.tab1.label1": "Added date",
    "paymentRequests.detail.section1.tab1.label2": "Added by",
    "paymentRequests.detail.section1.tab2.title": "Comments",
    "paymentRequests.detail.section1.tab2.button": "Add a comment",
    "paymentRequests.detail.section1.tab3.title": "History",

    "paymentRequests.detail.section3.title": "Approvals",
    "paymentRequests.detail.section3.label0": "Change status",
    "paymentRequests.detail.section3.label1": "Assigned to",
    "paymentRequests.detail.section3.label2": "Requester",
    "paymentRequests.detail.section3.label3": "Budget holder",
    "paymentRequests.detail.section3.label4": "Financial Admin",
    "paymentRequests.detail.section3.label5": "General Coord",

    "paymentRequests.detail.section3.modify.popup.title":
      "Change of position",
    "paymentRequests.detail.section3.modify.popup.label0":
      "Change the position of",
    "paymentRequests.detail.section3.modify.popup.label1": "Assigned to",
    "paymentRequests.detail.section3.modify.popup.cancel": "Cancel",
    "paymentRequests.detail.section3.modify.popup.confirm": "Confirm",

    "paymentRequests.detail.section3.changeStatus.popup.title":
      "change the status of the request",
    "paymentRequests.detail.section3.changeStatus.popup.label0":
      "Are you sure to change the status to",
    "paymentRequests.detail.section3.changeStatus.popup.label1":
      "Add a comment",
    "paymentRequests.detail.section3.changeStatus.popup.cancel": "Cancel",
    "paymentRequests.detail.section3.changeStatus.popup.confirm":
      "Confirm",

    "paymentRequests.detail.section4.title": "Analytical code",
    "paymentRequests.detail.section4.label0": "Num",
    "paymentRequests.detail.section4.label1": "project",
    "paymentRequests.detail.section4.label2": "Project line",
    "paymentRequests.detail.section4.label3": "Fin code",
    "paymentRequests.detail.section4.label4": "Fin line",
    "paymentRequests.detail.section4.popup.title":
      "Add an analytical code",
    "paymentRequests.detail.section4.popup.label0": "Project code",
    "paymentRequests.detail.section4.popup.label1": "Project line",
    "paymentRequests.detail.section4.popup.label2": "Financial code",
    "paymentRequests.detail.section4.popup.label3": "Financial line",

    "travelRequests.list.title": "Travel requests",
    "travelRequests.list.search.keywords": "Search for travel ...",
    "travelRequests.list.filters.filter1": "All travels",
    "travelRequests.list.filters.filter2": "Approved travels",
    "travelRequests.list.filters.filter3": "Rejected travels",
    "travelRequests.list.filters.filter4": "Travels to process",

    "travelRequests.form.title": "New travel request",
    "travelRequests.form.step1.title": "Step 1",
    "travelRequests.form.step1.label0": "Country",
    "travelRequests.form.step1.label1": "Head of logistics",
    "travelRequests.form.step1.label2": "Objectives",
    "travelRequests.form.step1.label3": "First day",
    "travelRequests.form.step1.label4": "Last day",
    "travelRequests.form.Error.step1.label41": "First enter first day",
    "travelRequests.form.Error.step1.label42" : "Invalid first date. Second day must be greater than first day.",
    "travelRequests.form.step1.Error.label42":
      "Last day must be greater than first day",
    "travelRequests.form.step1.label5": "Place of departure",
    "travelRequests.form.step1.label6": "Type of document",
    "travelRequests.form.step1.label7": "Attachments",
    "travelRequests.form.step1.label8": "Members",
    "travelRequests.form.step1.label9": "Fullmame",
    "travelRequests.form.step1.label10": "Occupation",
    "travelRequests.form.step1.button.label": "Add external member",
    "travelRequests.form.step1.nextButton.label": "Next",

    "travelRequests.form.step2.title": "Step 2",
    "travelRequests.form.step2.label0": "Destination",
    "travelRequests.form.step2.label1": "Other destination",
    "travelRequests.form.step2.label2": "Mode of departure transport",
    "travelRequests.form.step2.label3": "Mode of return transport",
    "travelRequests.form.step2.label4": "Other mode of transport",
    "travelRequests.form.step2.label5": "Main hosting",
    "travelRequests.form.step2.label6": "Type of document",
    "travelRequests.form.step2.label7": "Other hosting",
    "travelRequests.form.step2.label8": "Context",
    "travelRequests.form.step2.label9": "Risk factors",
    "travelRequests.form.step2.label10": "Mitigating elements",
    "travelRequests.form.previousButton.label": "Previous",

    "travelRequests.detail.label0": "Country",
    "travelRequests.detail.label1": "Departure",
    "travelRequests.detail.label2": "Destination",
    "travelRequests.detail.label3": "Other destination",
    "travelRequests.detail.label4": "Departure date",
    "travelRequests.detail.label5": "Arrival date",
    "travelRequests.detail.label6": "Mode of departure transport",
    "travelRequests.detail.label7": "Mode of return transport",
    "travelRequests.detail.label8": "Other mode of transport",
    "travelRequests.detail.label9": "hosting",
    "travelRequests.detail.label10": "Other hosting",
    "travelRequests.detail.label11": "Objectives",

    "travelRequests.detail.section1.title": "Details",
    "travelRequests.detail.section1.tab0.title": "Members",
    "travelRequests.detail.section1.tab0.label0": "Occupation",
    "travelRequests.detail.section1.tab0.label1": "Name",
    "travelRequests.detail.section1.tab0.label2": "Surname",
    "travelRequests.detail.section1.tab1.title": "External members",
    "travelRequests.detail.section1.tab1.label0": "Name",
    "travelRequests.detail.section1.tab1.label1": "Occupation",
    "travelRequests.detail.section1.tab2.label0": "Document type",
    "travelRequests.detail.section1.tab2.label1": "Added date",
    "travelRequests.detail.section1.tab2.label2": "Added by",
    "travelRequests.detail.section1.tab3.title": "Comments",
    "travelRequests.detail.section1.tab3.button": "Add a comment",
    "travelRequests.detail.section1.tab4.title": "History",

    "travelRequests.detail.button1": "Add attachment",
    "travelRequests.detail.addMemberText": "Add members",
    "travelRequests.detail.Description.title": "Travel information",
    "travelRequests.detail.placeholder.file": "Select file type",
    "travelRequests.upload.btn.text": "Choose",
    "travelRequests.validation.approve": "Approve",
    "travelRequests.validation.authorise": "Autorise",
    "travelRequests.validation.reject": "Reject",
    "travelRequests.validation.Reason.reject": "Please enter the rason",

    "travelRequests.detail.section3.title": "Approvals",
    "travelRequests.detail.section3.label0": "Change status",
    "travelRequests.detail.section3.label1": "Assigned to",
    "travelRequests.detail.section3.label2": "Requester",
    "travelRequests.detail.section3.label3": "Logistician",
    "travelRequests.detail.section3.label4": "Financial Admin",
    "travelRequests.detail.section3.label5": "General Coord",

    "travelRequests.detail.section3.modify.popup.title":
      "Change of position",
    "travelRequests.detail.section3.modify.popup.label0":
      "Change the position of",
    "travelRequests.detail.section3.modify.popup.label1": "Assigned to",
    "travelRequests.detail.section3.modify.popup.cancel": "Cancel",
    "travelRequests.detail.section3.modify.popup.confirm": "Confirm",

    "travelRequests.detail.section3.changeStatus.popup.title":
      "Change the status of the request",
    "travelRequests.detail.section3.changeStatus.popup.label0":
      "Are you sure to change the status to",
    "travelRequests.detail.section3.changeStatus.popup.label1":
      "Add a comment",
    "travelRequests.detail.section3.changeStatus.popup.cancel": "Cancel",
    "travelRequests.detail.section3.changeStatus.popup.confirm":
      "Confirm",

    "zone.list.title": "Zones",
    "zone.list.search.keywords": "Search by name",
    "zone.form.title": "New zone",
    "zone.form.update": "Update zone",
    "zone.form.label0": "Name",
    "zone.form.Error.label0": "The first character must be uppercase",
    "input.required": "Required",
    "successful.recording": "successful recording",
    "error.recording": "error on recording",

    "employees.list.title": "Employees",
    "employees.list.search.keywords": "Search by name",
    "employees.form.title": "New employee",
    "employees.form.header-worker": "The employee works on site?",
    "employees.form.label0": "Name",
    "employees.form.label1": "Surname",
    "employees.form.label2": "Occupation",
    "employees.form.label3": "Role",
    "employees.form.label4": "Phone Number",
    "employees.detail.label0": "Phone",
    "employees.detail.label1": "Place of Birth",
    "employees.detail.label2": "Date of Birth",
    "employees.detail.label3": "Occupation",
    "employees.detail.label4": "E-mail",
    "employees.detail.label5": "Password",
    "employees.detail.label6": "No signature recorded",
    "employees.detail.upload.button": "Upload signature",

    "employees.delete.button": "Delete account",
    "employees.edit.button": "Edit profile",

    "projects.list.title": "Projects",
    "projects.list.search.keywords": "Search by code",
    "projects.form.title": "New project",
    "projects.form.title1": "Update project",
    "projects.form.label0": "Project code",
    "projects.form.Error.label01": "Enter up to seven characters",
    "projects.form.label1": "Project name",
    "projects.form.label2": "Project Manager",
    "projects.form.label3": "Start date",
    "projects.form.Error.label3": "Enter a date greater than today",
    "projects.form.label4": "End date",
    "projects.form.Error.label41": "First enter start date",
    "projects.form.Error.label42":
      "End date must be greater than start date",
    "projects.form.label5": "Specific bearings ?",
    "projects.form.label6": "Direct purchase :",
    "projects.form.label7": "Simplified procedure :",
    "projects.form.Error.label71": "Please enter a number",
    "projects.form.Error.label72": "Value must be greater than purchase",
    "projects.form.label8": "Negociated procedure :",
    "projects.form.Error.label81":
      "Value must be greater than negociated procedure",
    "projects.form.label9": "Call for tender :",

    "assignments.list.title": "Assignments",
    "assignments.list.search.keywords": "Search by code",
    "assignments.form.title": "New assignment",
    "assignments.form.update": "Update affectation",
    "assignments.form.label0": "Project",
    "assignments.form.label1": "Zone",
    "assignments.form.label2": "Compt/Log/hr",

    "list.columns.label0": "Unique Code",
    "list.columns.label1": "Initiator",
    "list.columns.label2": "Date",
    "list.columns.label3": "Zone",
    "list.columns.label4": "Related to Project?",
    "list.columns.label5": "Request",
    "list.columns.label6": "Paid to",
    "list.columns.label7": "Type",
    "list.columns.label8": "Payement method",
    "list.columns.label9": "Status",
    "list.columns.label10": "Name",
    "list.columns.label11": "Phone Number",
    "list.columns.label12": "E-mail",
    "list.columns.label13": "Occupation",
    "list.columns.label14": "Code",
    "list.columns.label15": "Project Manager",
    "list.columns.label16": "Actions",
    "list.columns.label17": "Project",
    "list.columns.label18": "Country",
    "list.columns.label19": "Validator",
    "list.columns.label20": "Departure",
    "list.columns.label21": "Arrival",
    "list.columns.label22": "Hosting",
    "list.columns.label23": "Issuer",
    "required": "This field is required",

    "yes": "Yes",
    "No": "No",
    "no" : "No",
    "payment.type.advance": "Advance",
    "payment.type.payment": "Payment",
    "confirm.delation": "Are you sure you want to delete this item?",
    "nodata": "No data",
    "error": "Error",
    "error.text": "Please fill in the risk analysis elements",

    "post.list.title": "Posts",
    "post.list.search.keywords": "Search by name",
    "post.form.title": "New post",
    "post.form.update": "Update post",
    "post.form.label0": "Name",
    "post.form.Error.label0": "The first character must be uppercase",
    "menu.post": "Post",
    "uploader.closeModal" : "Close",
    "uploader.isNotAValidFile" : "File {0} is not a valid image or pdf. Please select a valid image or a pdf file",
    
    "userNotGrantedNotifications" : "User blocked notifications. Please allow browser to send notifications",
    "notiificationNotSupportedByBrowser" : "Browser does not support notifications.",
    "addOtherDocument" : "Add other document",
  }