import React from "$react";
import DatePicker from 'react-date-picker';
import { useTranslation } from "react-i18next";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {isNonNullString,defaultStr,classNames} from "$utils";
import flattenStyle from "$theme/flattenStyle";
import theme from "$theme";
import moment from "moment";
import Box from "$components/Box";

export const toDate = (date,format)=>{
    const d = date && moment(date,format || undefined);
    return d?.isValid()? d.toDate() : null;
}
export const compare = (a,b)=>{
    if(!a && !b) return true;
    if(!a && b || a && !b) return false;
    return moment(a).format("YYYY-MM-DD") === moment(b)?.format("YYYY-MM-DD");
}
export const defaultFormat = "DD/MM/YYYY";


/****
    retourne un objet obtenu via le parsing de la date passée en paramètre
    @param {dateObj|string} date, la date en question
    @param {string} format, le format actuel de la date si une chaine de caractère est passé
    @return {object} de la forme : 
    {
        SQLDate : la date au format SQL
        SQLTIME : l'heure au format SQL;
    }
*/
export const parse = (date,format)=>{
    const v = toDate(date);
    const ret = {};
    const parsed = {SQLDate:"YYYY-MM-DD",SQLTime : "h:mm"};
    const m = v ? moment(v) : null;
    Object.keys(parsed).map((key)=>{
        ret[key] = m? m.format(parsed[key]) : undefined;
    });
    ret.date = dateObj;
    ret.momentDate = m;
    return date;
}
/***
    @see : https://github.com/wojtekmaj/react-date-picker
*/
const DatePickerComponent = React.forwardRef(({onChange,value,style,format:dateFormat,...props},ref)=>{
    const { t,i18n } = useTranslation();
    const locale =i18n.resolvedLanguage;
    const defDate = toDate(value);
    const [date, setDate] = React.useState(defDate);
    const pevD = React.usePrevious(defDate,(a,b)=>compare(a,b,format));
    React.useEffect(()=>{
        if(pevD == defDate || compare(pevD,defDate,format)) return;
        setDate(defDate);
    },[defDate]);
    const format = defaultStr(locale).toLowerCase().trim() ==="fr"? "dd/MM/y" : "dd/MM/y";
    return <Box 
        locale={locale} 
        onChange={(date,...rest)=>{
            setDate(date);
            if(typeof onChange =='function' && onChange(date,...rest) === false) return;
        }} 
        as = {DatePicker}
        //boxShadow = {"inset 0 1px 4px 0 rgba(225,225,225,.1)!important"}
        {...props} 
        value = {date}  
        //ref = {ref}
        format={format} 
        className = {classNames(props.className,"date date-component")}
        style={flattenStyle([{borderColor:theme.colors.divider,padding:"11px 24px!important",borderWidth:'1px'},style])}
    />
});

DatePickerComponent.displayName = "DatePickerComponent";

export default DatePickerComponent;;