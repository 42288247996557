import React, { useState } from "react";
import moment from "moment";
import Upload from "$components/Upload";
import { Form,Button, Modal, message } from "antd";
import { useAddAttachmentTicket, useDeleteAttachment,useAddOtherAttachmentTicket } from "$api/api";
import { UploadOutlined,FileAddOutlined } from "@ant-design/icons";
import {AttachmentPreview,AttachmentDownload} from "$components/Upload";
import {OTHER_DOCUMENT_TYPE_ID} from "../utils";

export default function Attachments({ ticket,canAddAttachment,onDeleteFile, addNewAttachment, t }) {
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [documentId, setDocumentId] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const {mutateAsync:addOtherAttachment} = useAddOtherAttachmentTicket(ticket?.id);
  const isOtherAttachmentRef = React.useRef(false);
  const { mutateAsync: addAttachment } = useAddAttachmentTicket(
    ticket?.id,
    documentId
  );
  const { mutateAsync: deleteAttachment } = useDeleteAttachment(ticket?.id);

  const dum = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const openModal = (id, name) => {
    setDocumentId(id);
    setDocumentName(name);
    setVisible(true);
  };
  const openOtherAttachmentModal = (name) => {
    isOtherAttachmentRef.current = true;
    setVisible(true);
  };

  const onSubmit = async (values) => {
    const data = new FormData();
    data.append("file", values.upload.fileList[0].originFileObj);
    try {
      setLoading(true);
      const resp = await isOtherAttachmentRef?.current ? addOtherAttachment(data) : addAttachment(data);
      isOtherAttachmentRef.current = false;
      addNewAttachment(resp);
      setVisible(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      isOtherAttachmentRef.current = false;
      console.log(e," adding attachment");
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const openDeleteFile = async (id, name) => {
    setDocumentName(name);
    setDocumentId(id);
    setVisibleDelete(true);
  };

  const deleteFile = async () => {
    try {
      setLoading(true);
      await deleteAttachment(documentId);
      message.success(t("successful.recording"));
      setVisibleDelete(false);
      setLoading(false);
      if(typeof onDeleteFile =='function'){
        onDeleteFile(documentId);
      }
    } catch (e) {
      setLoading(false);
      message.warning(t("error.recording"));
    }
  };
  const docTitleName = isOtherAttachmentRef.current && t("addOtherDocument") || documentName;
  const renderDocType = (record,docType)=>{
      return (<li
        key={`${record?.id}-${docType?.id}`}
        className="section-cart flex itemcenter justbtw  u-pad-left-xs "
      >
        <span className="grille4 av-heavy fs14 ">{i18nTObj(docType)}</span>

        <span className="grille4 av-heavy fs14 ">
          {moment(record?.date
          ).format("D MMM-YY H:mm")}
        </span>

        <span className="grille4 av-heavy fs14 ">
          {record?.owner?.firstname +
            " " +
            record?.owner?.lastname}
        </span>
        <AttachmentPreview 
          attachment={record}
          downloadLink = {`/api/attachments/${record?.id}/download`}
        />
        <AttachmentDownload
          className="grille av-heavy fs14 coltext"
          downloadLink = {`/api/attachments/${record?.id}/download`}
          fileName = {record?.name}
        />
        <span
          className="grille av-heavy fs14 coltext u-mar-left-s"
          onClick={() =>
            openDeleteFile(
              record?.id,
              record?.name
            )
          }
        >
          {t("delete")}
        </span>
      </li>
    )
  }
  return (
    <>
      <div className="appro rel">
        <div className="w100">
          <div className="flex itemcenter">
            <div className="u-mar-right-s"></div>
          </div>

          <div className="w100">
            <div
              className="flex justbtw u-pad-vertical-xs  u-mar-top-s"
              style={{
                borderBottom: "1px #b3b3b3 solid",
              }}
            >
              <span className="grille4 fW500">
                {t("commitmentForms.form.section3.label0")}
              </span>
              <span className="grille4 fW500">
                {" "}
                {t("commitmentRequests.detail.section1.tab2.label1")}
              </span>
              <span className="grille4 fW500">
                {" "}
                {t("commitmentRequests.detail.section1.tab2.label2")}
              </span>
              <span className="grille ">
                <Button
                  disabled = {canAddAttachment===false}
                  icon = {<FileAddOutlined />}
                  className="add-other-document"
                    onClick={() => {
                      openOtherAttachmentModal();
                    }}
                  >
                    {t("addOtherDocument")}
                </Button>
              </span>
            </div>

            <ul className="cart-list" style={{ height: "auto" }}>
              {ticket?.documents.map((row) =>{
                  const records = ticket.attachments.filter((a) => a.document?.id == row?.id);
                  if(row?.id ===OTHER_DOCUMENT_TYPE_ID){
                      return <React.Fragment key={`${row?.id}-others-list-documents`}>
                        {records.map((record)=><React.Fragment key={`${row?.id}-${record?.id}`}>{renderDocType(record,row)}</React.Fragment>)}
                      </React.Fragment>
                  } 
                  const record = records[0];
                  return record ? renderDocType(record,row) : (
                  <li
                    key={row.id}
                    className="section-cart flex itemcenter justbtw  u-pad-left-xs "
                  >
                    <span className="grille4 av-heavy fs14 ">{i18nTObj(row)}</span>

                    <span className="grille4 av-heavy fs14 ">--</span>

                    <span className="grille4 av-heavy fs14 ">--</span>

                    <span
                      className="grille av-heavy fs14 coltext"
                      onClick={() => openModal(row.id, i18nTObj(row))}
                    >
                      {t("add.button")}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        width={500}
        onCancel={() => setVisible(false)}
        open={visible && canAddAttachment !== false && true || false}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 320 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center"> {t("add.attachment")}</h2>

        <h2 className="fW500 w100 center"> {docTitleName}</h2>

        <Form
          layout="vertical"
          className=" itemcenter u-mar-top-xl"
          onFinish={onSubmit}
        >
          <Form.Item
            name="upload"
            style={{ marginTop: 28 }}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Upload
              customRequest={dum}
              maxCount={1}
              onChange={handleChange}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>
                {t("commitmentForms.form.section3.label3")}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item name="btn" style={{ marginTop: 28 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
              className="rad8"
            >
              <span className="fs16 av-heavy"> {t("add.button")} </span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={500}
        onCancel={() => setVisibleDelete(false)}
        open={visibleDelete}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center"> {t("confirm.delete.attachment")}</h2>

        <h2 className="fW500 w100 center"> {docTitleName}</h2>

        <Form layout="vertical" onFinish={deleteFile}>
          <div className="flex flexend u-mar-top-l">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisibleDelete(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t("commitmentRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.modify.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
