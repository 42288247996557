import {isNonNullString,defaultStr,isNumber,defaultObj,isObj} from "$utils";
import moment from "moment";
import i18n from "$i18n";

export const defaultDateFormat = "D MMM-YY";
export const defaultTimeFormat = "HH:MM";

export const renderRowCell = ({rowData,value,type,format,rowIndex,index,columnDef,defaultValue})=>{
    rowIndex = isNumber(rowIndex)? rowIndex : isNumber(index)? index : undefined;
    if(!isObj(rowData)) return null;
    let _render = null;
    columnDef = defaultObj(columnDef);
    format = defaultStr(format,columnDef.format).toLowerCase().trim();
    let _type = defaultStr(type,columnDef.type).trim().toLowerCase().replaceAll("_","");
    _render = defaultValue !== undefined ? defaultValue : value;
    if(["date","datetime","time"].includes(_type)){
        if(defaultValue){
            moment.locale(i18n.getLang());
            return moment(defaultValue).format(defaultStr(format,_type =="time"?defaultTimeFormat:defaultDateFormat));
        }
    } else if(['switch','checkbox'].includes(_type)){
        let {checkedLabel,checkedValue,uncheckedLabel,uncheckedValue} = columnDef;
        checkedLabel = defaultStr(checkedLabel,i18n.t("table.yes"))
        uncheckedLabel = defaultStr(uncheckedLabel,i18n.t("table.no"))
        checkedValue = defaultVal(checkedValue,1); uncheckedValue = defaultVal(uncheckedValue,0)
        if(defaultValue === checkedValue){
            _render = checkedLabel;
        } else _render = uncheckedLabel;
    }
    if(columnDef.type =="password" && isNonNullString(_render)){
        let l = Math.max(_render.length,20);
        _render = "";
        for(let i=0;i<l;i++){
            _render+=".";
        }
    }
    if(Array.isArray(_render)){
        _render = _render.join(arrayValueSeparator);
    }
    if(typeof _render ==="number"){
        if(format =="money"){
            return _render.formatMoney();
        }
        return _render.formatNumber();
    }
    return _render;
}

export const arrayValueSeparator = ", ";

export const switchFilters = [

]