import { useLayoutEffect } from "react";
import UIManager from "../UIManager";
import ResizeObserver from "resize-observer-polyfill";

const DOM_LAYOUT_HANDLER_NAME = "__reactLayoutHandler";

let resizeObserver = null;

export function getResizeObserver() {
  if (resizeObserver == null) {
    resizeObserver = new ResizeObserver(function (entries) {
      entries.forEach((entry) => {
        const node = entry.target;
        const onLayout = node[DOM_LAYOUT_HANDLER_NAME];
        if (typeof onLayout === "function") {
          // We still need to measure the view because browsers don't yet provide
          // border-box dimensions in the entry
          UIManager.measure(node, (x, y, width, height, left, top) => {
            const event = {
              // $FlowFixMe
              nativeEvent: {
                layout: { x, y, width, height, left, top },
              },
              timeStamp: Date.now(),
            };
            Object.defineProperty(event.nativeEvent, "target", {
              enumerable: true,
              get: () => entry.target,
            });
            onLayout(event);
          });
        }
      });
    });
  }
  return resizeObserver;
}

export function useElementLayout(ref, onLayout) {
  const observer = getResizeObserver();
  useLayoutEffect(() => {
    const node = ref.current;
    if (node != null) {
      node[DOM_LAYOUT_HANDLER_NAME] = onLayout;
    }
  }, [ref, onLayout]);

  // Observing is done in a separate effect to avoid this effect running
  // when 'onLayout' changes.
  useLayoutEffect(() => {
    const node = ref.current;
    if (node != null && observer != null) {
      if (typeof node[DOM_LAYOUT_HANDLER_NAME] === "function") {
        observer.observe(node);
      } else {
        observer.unobserve(node);
      }
    }
    return () => {
      if (node != null && observer != null) {
        observer.unobserve(node);
      }
    };
  }, [ref, observer]);
}
