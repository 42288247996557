/**** associe la référence passée en paramètre à l'objet value
 * @param : {object|function} the reference you wish to associate
 * @param {any} value the value to be associated with the reference
 * @param {function} cb callback method
 */
const setRef = (ref, value, cb) => {
  if (typeof ref == "function") {
    ref(value);
  } else if (ref && "current" in ref) {
    ref.current = value;
  }
  if (typeof cb === "function") {
    cb(value);
  }
  return ref;
};

export default setRef;
