import React, { useState, useEffect } from "react";
import notify from "$notify";
import STATUS,{VALIDATED,APPROVED,getUsersForsStatus,ON_HOLD,WAITING_PAYMENTS,CLOSED,REJECTED,canValidate,getAvailableStatusForUser} from "../../status";
import Tabs from "$components/Tabs";
import ValidationLine,{Row as LineRow} from "$containers/ValidationLine";
import Box from "$components/Box";
import {isObj,isNonNullString,isNumber} from "$utils";
import {
  Dropdown,
  Menu,
  Button,
  Modal,
  Row,
  Form,
  Col,
  Select,
  Divider,
  Tag,
  Input,
  message,
} from "antd";
import {
  useAddVerifyPayment,
  useAddPaymentInfo,
  useAddStatusPayment,
  useAddSubmitAgainPayment,
  useGetEmployees,
  useAddMutationPayment,
  useGetZones,
} from "../../../api/api";
import { CaretDownOutlined } from "@ant-design/icons";
import {ACCOUNTANT} from "$enums/steps";

const { TextArea } = Input;
const { Option } = Select;
export default function Validations({ iduser, payment, updatePayment, t }) {
  const [submitting, setSubmitting] = useState(false);
  const [submittingAsign, setSubmittingAsign] = useState(false);

  const [submittingAgain, setSubmittingAgain] = useState(false);
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [zones, setZones] = useState(null);

  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [visible, setVisible] = useState(null);
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  const [grade, setGrade] = useState("");
  const [post, setPost] = useState("");

  const { data: dataZones, error, isLoading, refetch } = useGetZones({}, {});

  const { mutateAsync: addVerify } = useAddVerifyPayment(payment?.id);
  const { mutateAsync: addStatus } = useAddStatusPayment(payment?.id);
  const { mutateAsync: addSubmitAgain } = useAddSubmitAgainPayment(payment?.id);
  const { mutateAsync: addMutation } = useAddMutationPayment(
    payment?.id,
    employee,
    grade
  );
  const { mutateAsync: addPayInfo } = useAddPaymentInfo(payment?.id);

  const { data } = useGetEmployees({}, {});

  const openModal = (status, statusCode) => {
    setStatus(status);
    setStatusCode(statusCode);

    setVisible(true);
  };

  const ChangeAsignee = (post, grade) => {
    setGrade(grade);
    setPost(post);
    setVisibleAssignee(true);
  };

  useEffect(() => {
    setEmployees(data);
  }, [data]);

  useEffect(() => {
    setZones(dataZones);
  }, [dataZones]);
  const hasPayment = isObj(payment);
  const currentStatus = hasPayment && isObj(payment.status) && typeof payment.status.id =="number" ? payment.status.id : null;
  const hasStatus = typeof currentStatus =='number'? true : false;
  const users = hasStatus && getUsersForsStatus(currentStatus) || null;
  const isInitiator = payment?.initiator?.id == iduser;
  const isChecker = payment?.checker?.id == iduser;
  const isValidated = currentStatus === VALIDATED;
  const isAccountant = payment?.accountant.id == iduser && payment?.step.id == 2;
  const isGlobalApproved = !!(payment?.status_coordinator?.id === APPROVED);
  const menu = [];
  menu.push({
    label : isAccountant ? <Box
    key="1"
    className="flex itemcenter rad8 "
    onClick={() => openModal("Approuvé", 1)}
  >
    <Box className="flex column">
      <span className="av-light fs14" style={{ color: "#65676b" }}>
        Approuver le paiement
      </span>
    </Box>
  </Box> : <Box
          key="1"
          className="flex itemcenter rad8 "
          onClick={() => handleSubmit({ statusCode: 1 })}
        >
          <Box className="flex column ">
            <span className="av-light fs14" style={{ color: "#65676b" }}>
              Approuver
            </span>
          </Box>
        </Box>
  });
  menu.push({type:"divider"});
  if(payment?.status.id != 3){
    menu.push({
        label : <Box
          key="2"
          className="flex itemcenter rad8 "
          onClick={() => openModal("Rejeté", 2)}
        >
          <Box className="flex column ">
            <span className="av-light fs14" style={{ color: "#65676b" }}>
              Rejeter
            </span>
          </Box>
        </Box>
    });
    menu.push({type:"divider"});
  }
  
  menu.push({
    label :  <Box
        key="4"
        className="flex itemcenter rad8 "
        onClick={() => openModal("Fermé", 3)}
      >
        <Box className="flex column ">
          <span className="av-light fs14" style={{ color: "#65676b" }}>
            Fermer
          </span>
        </Box>
    </Box>
  })
  
  const isAssignedToAccountant = (payment) => {
    return payment?.accountant.id === iduser && payment?.step.id === ACCOUNTANT
  }
  
  const handleSubmit  = async (values) => {
    if (!values) return;
    let valid = true
    payment?.items.map(spent => 
     spent.financial_code === "" || spent.financial_line === "" ||  spent.project_code === "" ||  spent.project_line === "" ? valid = false : valid = true      
      )
    let payload = {
      "status": !values.statusCode ? statusCode  : values.statusCode,
      "comment": values.comment
    }
    try {
    setSubmitting(true);
    let resp = null;
    if(isAssignedToAccountant(payment) && payload.status === 1 && (values.pay_to || isNumber(values.pay_to)) && (values.pay_by|| isNumber(values.pay_by))){
        let payl = {
          "pay_to": values.pay_to,
          "pay_by": values.pay_by
        }
        resp = await addPayInfo(payl);
      }
      resp = await addVerify(payload);
      setSubmitting(false);
      updatePayment(resp)
      message.success(t("successful.recording"))
      setVisible(false)
    } catch(e) {
        setSubmitting(false);
        message.warning(t("error.recording"))
    }
    setSubmitting(false);
  }
  const handleAsignee = async (values) => {
    console.log(values);
    if (!values) return;

    setEmployee(values.employee);

    try {
      setSubmittingAsign(true);
      const resp = await addMutation();
      updatePayment(resp);
      message.success(t("successful.recording"));
      setSubmittingAsign(false);
      setVisibleAssignee(false);
    } catch (e) {
      console.log(e);
      setSubmittingAsign(false);
      setVisibleAssignee(false);
      message.warning(t("error.recording"));
    }

    setSubmitting(false);
  };

  const submitAgain = async () => {
    try {
      setSubmittingAgain(true);

      if (payment?.items.length == 0) {
        message.warning("Veuillez ajouter des items!");
        return;
      }
      const resp = await addSubmitAgain();
      updatePayment(resp);
      message.success(t("successful.recording"));
    } catch (e) {
      setSubmittingAgain(false);
      message.warning(t("error.recording"));
    }
  };
  const isUserInitiator = payment?.initiator?.id == iduser ;
  const isUserChecker = payment?.checker?.id == iduser;
  const {approbationItems,validationItems}= React.useMemo(()=>{
    const validationItems = [];
    const approbationItems = validationItems;
    const checkerContent = isUserInitiator && isUserChecker && payment?.status.id == 2 ? <LineRow className="validation-payment-checker submit-again">
    <Box className="flex column u-mar-bottom-xs">
      <span className="u-mar-right-s">
        Votre paiement a été rejeté, modifiez le , et soummettez à
        nouveau
      </span>
    </Box>
    <Button
      type="primary"
      onClick={() => submitAgain()}
      loading={submittingAgain}
    >
      {t("commitmentRequests.submit_again")}
    </Button>
  </LineRow> : null;
    if(checkerContent){
      validationItems.push(checkerContent);
      //approbationItems.push(checkerContent);
    } else if(isChecker){
      (!isGlobalApproved ? approbationItems:validationItems).push(
        <LineRow cellProps={{colSpan:3}} className={"dropdown-menu-container"} m="0" py="0" px="0" my="0px">
            <Dropdown
              menu={{items:menu}}
              className="cursor u-mar-right-xs"
              trigger={["click"]}
          >
            <Box
              className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
              style={{ height: "30px", width: "fit-content" }}
            >
              <span
                className="fs15 fW500 u-mar-right-xs"
                style={{ color: "#1a1a1a" }}
              >
                {submitting ? t("commitmentRequests.detail.section3.label7") : t("commitmentRequests.detail.section3.label0")}
              </span>
      
              <span style={{ marginTop: "4.5px" }}>
                <CaretDownOutlined />
              </span>
            </Box>
          </Dropdown>
        </LineRow>
      )
    }
    const array = !isGlobalApproved ? approbationItems : validationItems;
    //Assigned to
    if(payment?.status.id != 3){
      array.push(<LineRow>
        <span className="fW500 fs14 colhead">
          {t("paymentRequests.detail.section3.label1")}:
        </span>
        <span className="fW500 fs14 ">
          {payment?.checker?.firstname + " " + payment?.checker?.lastname}
          <span className="fs10"> ({i18nTObj(payment?.checker?.post)}) </span>
        </span>
      </LineRow>)
    }
    //demandeur
    array.push(<LineRow>
        <span className="fW500 fs14 colhead">
            {t("paymentRequests.detail.section3.label2")}:
        </span>
        <span className="fW500 fs14 ">
          {payment &&
            payment?.initiator?.firstname +
              " " +
              payment?.initiator.lastname}
          {!isGlobalApproved && payment?.status.id != 3 && (
            <Box
              className="fs11 cursor coltext"
              onClick={() => ChangeAsignee("Demandeur", 0)}
            >
              {t("reassignment.label")}
            </Box>
          )}
          <Box className="fs10">
            {payment && i18nTObj(payment?.initiator.post)}
          </Box>
        </span>
    </LineRow>)
    approbationItems.push(<LineRow>
      <span className="fW500 fs14 colhead">
        {t("paymentRequests.detail.section3.label3")} :
      </span>
      <span className="fW500 fs14 ">
        {payment?.manager.firstname + " " + payment?.manager.lastname}
        {!isGlobalApproved &&  <Box
          className="fs11 cursor coltext"
          onClick={() => ChangeAsignee("Resp. Budgétaire", 1)}
        >
          Modifier
        </Box>}
        <Box className="fs10"> ({i18nTObj(payment?.manager.post)}) </Box>
      </span>
      <Box className="fW500 fs14 ">
          <Tag
            className="u-mar-left-xs"
            color={
              payment?.status_manager.id == 0
                ? "#ff9e01"
                : payment?.status_manager.id == 1
                ? "#87d068"
                : payment?.status_manager.id == 2
                ? "#ff5500"
                : "#108ee9"
            }
          >
            {i18nTObj(payment?.status_manager)}
          </Tag>
      </Box>
    </LineRow>)
    validationItems.push(<LineRow>
        <Box className="fW500 fs14 colhead">
          {t("paymentRequests.detail.section3.label4")}:
        </Box>
        
        <Box className="fW500 fs14 ">
            {payment?.accountant.firstname + " " + payment?.accountant.lastname}
            {!isGlobalApproved && <Box
              className="fs11 cursor coltext"
              onClick={() => ChangeAsignee("Admin Financier", 2)}
            >
              Modifier
            </Box>}
            <Box className="fs10"> ({i18nTObj(payment?.accountant.post)}) </Box>
          </Box>
          <Box className="fW500 fs14 ">
            <Tag
              className="u-mar-left-xs"
              color={
                payment?.status_accountant.id == 0
                  ? "#ff9e01"
                  : payment?.status_accountant.id == 1
                  ? "#87d068"
                  : payment?.status_accountant.id == 2
                  ? "#ff5500"
                  : "#108ee9"
              }
            >
              {i18nTObj(payment?.status_accountant)}
            </Tag>
          </Box>
    </LineRow>)
    validationItems.push(<LineRow>
        <Box className="fW500 fs14 colhead">
            {t("paymentRequests.detail.section3.label5")}:
          </Box>
          <Box className="fW500 fs14 ">
            {payment?.coordinator.firstname +
              " " +
              payment?.coordinator.lastname}
            {!isGlobalApproved && <Box
              className="fs11 cursor coltext"
              onClick={() => ChangeAsignee("coord général", 3)}
            >
              Modifier
            </Box>}
            
            <Box className="fs10"> ({i18nTObj(payment?.coordinator.post)}) </Box>
          </Box>

        <Box className="fW500 fs14 ">
          <Tag
            className="u-mar-left-xs"
            color={
              payment?.status_coordinator.id == 0
                ? "#ff9e01"
                : payment?.status_coordinator.id == 1
                ? "#87d068"
                : payment?.status_coordinator.id == 2
                ? "#ff5500"
                : "#108ee9"
            }
          >
            {i18nTObj(payment?.status_coordinator)}
          </Tag>
        </Box>
    </LineRow>)
    return {validationItems,approbationItems};
  },[payment?.id,currentStatus,users,isGlobalApproved]);
  return (
    <>
      <Box className="noshadow blok u-pad-s noborder hauto rad16 w100 ">
          <ValidationLine rows={approbationItems} w="100%" className="approbation-tabs-container2approbations"/>
          {false && <Tabs
              className="validation-appprobation-tabs-container"
              defaultActiveKey={isGlobalApproved?"2":"1"}
              items = {[
                  {
                     label : t("tickets.validation.approbationTab"),
                     key : "1",
                     children : <ValidationLine rows={approbationItems} w="100%" className="approbation-tabs-container"/>
                  },
                  {
                    label : "Validation",
                    key : "2",
                    children : <ValidationLine rows={validationItems} className="validation-tabs-container"/>
                 }
              ]}
          />}
      </Box>
      <Modal
        width={550}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          
          {t("paymentRequests.detail.section3.changeStatus.popup.title")}
        </h2>

        <h2 className="center">
          {t("paymentRequests.detail.section3.changeStatus.popup.label0")}:
           <Box className="fW600">{status}</Box> ?
        </h2>

        <Form layout="vertical" onFinish={handleSubmit}>
          {statusCode == 2 && (
            <Box w="100%">
              <Form.Item
                  name="comment"
                  label={t(
                    "paymentRequests.detail.section3.changeStatus.popup.label1"
                  )}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input.TextArea />
                </Form.Item>
            </Box>
          )}

          {payment?.accountant.id == iduser &&
            statusCode != 2 &&
            !isLoading && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="pay_to"
                    label={t("paymentRequests.detail.label3")}
                    rules={[{ required: true, message: t("input.required") }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children">
                      {zones != null &&
                        zones?.map((a,key) => (
                          <Option key={key} value={i18nTObj(a)}> {i18nTObj(a)} </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="pay_by"
                    label={t("paymentRequests.detail.label4")}
                    rules={[
                      {
                        required: true,
                        message: t("input.required"),
                      },
                    ]}
                  >
                    <Select optionFilterProp="children">
                      <Option value={0}> {t("transfer")} </Option>
                      <Option value={1}> {t("check")}</Option>
                      <Option value={2}> {t("cash")}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

          <Box className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisible(false)}
              style = {{marginRight:"10px"}}
            >
              <span className="fs16 av-heavy">
                {t("paymentRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
              className="rad8"
            >
              <span className="fs16 av-heavy">
                
                {t("paymentRequests.detail.section3.modify.popup.confirm")}
              </span>
            </Button>
          </Box>
        </Form>
      </Modal>
      <Modal
        width={500}
        onCancel={() => setVisibleAssignee(false)}
        open={visibleAssignee}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 300 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          
          {t("paymentRequests.detail.section3.modify.popup.title")}
        </h2>

        <h2 className="center">
          {t("paymentRequests.detail.section3.modify.popup.label0")} 
          <span className="fW600">{post}</span>
        </h2>

        <Form layout="vertical" onFinish={handleAsignee}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="employee"
                label={t("paymentRequests.detail.section3.modify.popup.label1")}
                rules={[
                  {
                    required: true,
                    message: t("input.required"),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Assigner à ?"
                  optionFilterProp="children"
                >
                  {employees != null &&
                    employees.map((employee,key) => (
                      <Option value={employee.id} key={key}>
                        
                        {employee.firstname + " " + employee.lastname}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Box className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisibleAssignee(false)}
            >
              <span className="fs16 av-heavy">
                
                {t("paymentRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submittingAsign}
              className="rad8"
            >
              <span className="fs16 av-heavy">
                
                {t("paymentRequests.detail.section3.modify.popup.confirm")}
              </span>
            </Button>
          </Box>
        </Form>
      </Modal>
    </>
  );
}
