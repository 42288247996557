import Box from "$components/Box";
import {classNames,defaultObj,defaultStr,isObj,isPlainObject}from "$utils";
import "./styles.css";
import React from "$react";
export default function ValidationLine ({rows,bordered,whiteSpaceNoWrap,bodyProps,footerProps,headerProps,footers,headers,className,...props}){
    bodyProps = defaultObj(bodyProps);
    headerProps = defaultObj(headerProps);
    footerProps = defaultObj(footerProps);
    const content = React.useMemo(()=>{
        const content = [];
        (Array.isArray(rows) && rows || []).map((l,key)=>{
            const cell = getRowCell(l,key,Row);
            if(cell){
                content.push(cell);
            }
        });
        return content;
    },[rows,bodyProps])
    const heads = React.useMemo(()=>{
        const heads=[];
        (Array.isArray(headers) && headers || []).map((col,i)=>{
            const cell = getRowCell(col,i,RowHeader);
            if(cell){
                heads.push(cell);
            }
        });
        if(!heads.length){
            const cell = getRowCell(headers,1,RowHeader);
            if(cell){
                heads.push(cell);
            }
        }
        return heads;
    },[headers,headerProps])
    const foots = React.useMemo(()=>{
        const foots=[];;
        (Array.isArray(footers) && footers || []).map((foot,i)=>{
            const cell = getRowCell(foot,i,RowFooter);
            if(cell){
                foots.push(cell);
            }
        });
        if(!foots.length){
            const cell = getRowCell(footers,1,RowFooter);
            if(cell){
                foots.push(cell);
            }
        }
        return foots;
    },[footers,footerProps]);
    return <table {...props} className={classNames(className,"validation-line-table",whiteSpaceNoWrap && "white-space-nowrap",bordered && "bordered")}>
        {heads?.length ? <thead {...headerProps} className={classNames(headerProps.className,"validation-line-header")}>{heads}</thead> : null}
        <tbody {...bodyProps} className={classNames(bodyProps.className,"validation-line-body")}>
            {content}
        </tbody>
        {foots?.length ? <tfoot {...footerProps} className={classNames(footerProps.className,"validation-line-footer")}>{foots}</tfoot> : null}
    </table>
}

export const Row = ValidationLine.Row = ({children,className,cellComponent,cellProps,...props})=>{
    const as = typeof cellComponent =='string' && cellComponent || "td";
    cellProps = defaultObj(cellProps);
    const content = [];
    cellProps = {textAlign:"left", py:"10px" ,verticalAlign:"middle",...cellProps,className:classNames("validation-line-cell",cellProps.className)};
    (Array.isArray(children)&& children ||[children]).map((c,key)=>{
        if(React.isValidElement(c)){
            content.push(<Box {...cellProps} key={key} as={as} children={c}/>)
        } else if(isPlainObject(c)){
            content.push(<Box {...cellProps} {...c} className={classNames(cellProps.className,c.className)} key={key} as={as}/>)
        }
    });
    return <Box as="tr" w="100%" {...props} className={classNames(className,"validation-line-row")}>
        {content}
    </Box>
}
Row.displayName = "ValidationLineRowComponent";

export const RowFooter = ValidationLine.RowFooter = (props)=>{
    return <Row {...props} className={classNames(props.className,"validation-line-footer-cell")}/>
}
export const RowHeader = ValidationLine.RowHeader = (props)=>{
    return <Row
        cellComponent = {"th"}
        {...props}
        className={classNames(props.className,"validation-line-header")}
    />
}

RowFooter.displayName = "ValidationLineRowFooterComponent";

RowHeader.displayName = "ValidationLineRowHeaderComponent";

const getRowCell = (element,key,Component,cellProps)=>{
    if(!element) return null;
    if(React.isValidElement(element)){
        return (React.cloneElement(element,{key}));
    } else if(isPlainObject(element)){
        return (<Component {...defaultObj(cellProps)} {...element} key={key}/>);
    }
    return null;
}

const isRow = (el)=>["ValidationLineRowComponent",]