import { isPlainObj } from "../extendObj";
import { useRef, useEffect } from "react";
import isNonNullString from "$utils/isNonNullString";

export * from "./isComponent";

export * from "react";

export { default as isValidElement } from "./isValidElement";

export { default as setRef } from "./setRef";

export { default as useForceRender } from "./useForceRender";

export { default as useIsMounted } from "./useIsMounted";

export {default as useBreakpointValue} from "./useBreakpointValue";

export {default as useMediaQueryValue} from "./useBreakpointValue";

export * from "./usePrevious";

export { default as usePrevious } from "./usePrevious";

export * from "./useMergeRefs";

export { default as getTextContent } from "./getTextContent";

export { default as useStableMemo } from "./useStableMemo";

export { areEquals } from "../areEquals";

export * from "./resizeObserver";

export { default as UIManager } from "./UIManager";

export * from "./UIManager";

export { default as usePlatformMethods } from "./usePlatformMethods";

export const isSyntheticEvent = (event) => {
  if (
    !event ||
    typeof event !== "object" ||
    !event.constructor ||
    !isNonNullString(event.constructor.name)
  )
    return false;
  return event.constructor.name.startsWith("Synthetic") &&
    event.constructor.name.endsWith("Event")
    ? true
    : false;
};

export const isEvent = isSyntheticEvent;

export const getOnClickArgs = (args) => {
  let event = undefined,
    rest = {};
  if (isEvent(args)) {
    event = args;
  } else if (isPlainObj(args)) {
    rest = args;
    event = isEvent(rest.event);
  }
  return { ...rest, event };
};

export const stopEventPropagation = function (e) {
  if (isPlainObj(e) && isEvent(e.event)) {
    e = e.event;
  }
  if (e && typeof e == "object" && e.preventDefault) {
    e.preventDefault();
    if (typeof e.stopPropagation == "function") e.stopPropagation();
    e.defaultPrevented = true;
    if (
      e.nativeEvent &&
      typeof e.nativeEvent.stopImmediatePropagation == "function"
    ) {
      e.nativeEvent.stopImmediatePropagation();
    }
    return true;
  }
  return false;
};

/**
 *  useDidUpdate hook
 *
 *  Fires a callback on component update
 *  Can take in a list of conditions to fire callback when one of the
 *  conditions changes
 *
 * @param {Function} callback The callback to be called on update
 * @param {Array} conditions The list of variables which trigger update when they are changed
 * @returns {undefined}
 */
export const useDidUpdate = function useDidUpdate(callback, conditions) {
  const hasMountedRef = useRef(false);
  if (typeof conditions !== "undefined" && !Array.isArray(conditions)) {
    conditions = [conditions];
  } else if (Array.isArray(conditions) && conditions.length === 0) {
    console.warn(
      "Using [] as the second argument makes useDidUpdate a noop. The second argument should either be `undefined` or an array of length greater than 0."
    );
  }
  useEffect(() => {
    if (hasMountedRef.current) {
      callback();
    } else {
      hasMountedRef.current = true;
    }
  }, conditions);
};
/**
 * useDidMount hook
 * Calls a function on mount
 *
 * @param {Function} callback Callback function to be called on mount
 */
export const useDidMount = function useDidMount(callback) {
  useEffect(() => {
    if (typeof callback === "function") {
      callback();
    }
  }, []);
};
export const useOnRenderTimeout = 500;
/**
 * useOnRender hook
 * Calls a function on every render
 *
 * @param {Function} callback Callback function to be called on mount
 */
export const useOnRender = function useOnRender(callback, timeout) {
  useEffect(() => {
    callback = typeof callback == "function" ? callback : (x) => true;
    setTimeout(
      callback,
      typeof timeout == "number" ? timeout : useOnRenderTimeout
    );
  });
};

/**
 * useWillUnmount hook
 * Fires a callback just before component unmounts
 *
 * @param {Function} callback Callback to be called before unmount
 */
export const useWillUnmount = function useWillUnmount(callback) {
  // run only once
  useEffect(() => {
    return typeof callback == "function" ? callback : (x) => x;
  }, []);
};
