import Box from "$components/Box";
import PropTypes from "prop-types";
import {isObj,defaultStr} from "$utils";
import {Tag} from "antd";
import i18n from "$i18n";
export default function TicketStatusComponent({status,...props}){
    if(!isObj(status) || typeof status.id !='number') return null;
    return <Tag
        color={
          status.id == 0
            ? "#ff9e01"
            : status.id == 1
            ? "#87d068"
            : status.id == 2
            ? "#ff5500"
            : status.id == 3
            ? "red"
            : status.id == 5
            ? "#87d068"
            : "#108ee9"
        }
      >
        {i18n.tObj(status)}
  </Tag>
}

TicketStatusComponent.propTypes = {
    status : PropTypes.shape({
        id : PropTypes.number,
        name : PropTypes.string,
    }),
}