import React, { useState, useEffect } from "react";
import notify from "$notify";
import {isObj,defaultObj,defaultStr,isNonNullString} from "$utils";
import ValidationLine,{Row as LineRow} from "$containers/ValidationLine";
import {
  TREASURER, INITIATOR} from "$enums/steps";
import STATUS,{VALIDATED,APPROVED,getUsersForsStatus,ON_HOLD,WAITING_PAYMENTS,CLOSED,REJECTED,canValidate,getAvailableStatusForUser} from "../../status";
import Tabs from "$components/Tabs";

import {
  Dropdown,
  Menu,
  Button,
  Divider,
  Modal,
  Row,
  Form,
  Col,
  Select,
  Tag,
  Input,
  message,
} from "antd";
import {
  useAddVerify,
  useAddSubmitAgain,
  useGetEmployees,
  useAddMutation,
} from "$api/api";
import Box from "$components/Box";
import { CaretDownOutlined } from "@ant-design/icons";
const defStr = x=> defaultStr(x,"-");
const { Option } = Select;
export default function Validations({ iduser,user, ticket, updateTicket, t }) {
  user = defaultObj(user);
  const [submitting, setSubmitting] = useState(false);
  const [submittingAsign, setSubmittingAsign] = useState(false);

  const [submittingAgain, setSubmittingAgain] = useState(false);
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);

  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [visible, setVisible] = useState(null);
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  const [grade, setGrade] = useState("");
  const [post, setPost] = useState("");

  const { mutateAsync: addVerify } = useAddVerify(ticket?.id);
  const { mutateAsync: addSubmitAgain } = useAddSubmitAgain(ticket?.id);
  const { mutateAsync: addMutation } = useAddMutation(
    ticket?.id,
    employee,
    grade
  );

  const { data } = useGetEmployees({}, {});

  const openModal = (status, statusCode) => {
  
    setStatus(status);
    setStatusCode(statusCode);

    setVisible(true);
  };

  const ChangeAsignee = (post, grade) => {
    setGrade(grade);
    setPost(post);
    setVisibleAssignee(true);
  };

  useEffect(() => {
    setEmployees(data);
  }, [data]);
  const hasTicket = isObj(ticket);
  const currentStatus = hasTicket && isObj(ticket.status) && typeof ticket.status.id =="number" ? ticket.status.id : null;
  const hasStatus = typeof currentStatus =='number'? true : false;
  const users = hasStatus && getUsersForsStatus(currentStatus) || null;
  const isInitiator = ticket?.step?.id == INITIATOR;
  const isChecker = ticket?.checker?.id == iduser;
  const isValidated = currentStatus === VALIDATED;
  const isApproved = currentStatus === APPROVED;
  const hasApprovedMneu = ticket?.status?.id != WAITING_PAYMENTS && ticket?.status?.id != VALIDATED && ticket?.status?.id != APPROVED;
  const menu = [],menuKeys=[];
  const isTreasurerStep = !!(ticket?.step?.id === TREASURER);
  let hasOptions = false;
  const statutes = {
    [APPROVED] : {
        color : "#65676b",
        id : APPROVED,
        label : t("commitmentRequests.status.approuved"),
        onClick: (e)=> handleSubmit({ statusCode: APPROVED })
    },
    [REJECTED] : {
      color : "#65676b",
      id : REJECTED,
      label : t("commitmentRequests.status.rejected"),
    },
    [WAITING_PAYMENTS] : {
      id : WAITING_PAYMENTS,
      color : "#65676b",
      label : t("commitmentRequests.status.waiting_payment"),
    },
    [VALIDATED] : {
      id : VALIDATED,
      color : "#65676b",
      label : t("commitmentRequests.status.Validated"),
    },
    [CLOSED] : {
      id : CLOSED,
      color : "#65676b" ,
      label: t("commitmentRequests.status.close"),
    },
  
  }
  if(ticket?.status_coordinator?.id === APPROVED && isTreasurerStep ){
    if(!menuKeys.length){
      //options ( En attente de paiement ou Rejeter)
      menuKeys.push(statutes[WAITING_PAYMENTS]);
      menuKeys.push(statutes[REJECTED]);
    }
  } else if(isTreasurerStep && ticket?.status === REJECTED){
      menuKeys.push(statutes[WAITING_PAYMENTS]);
  } else if(isInitiator){
    if(currentStatus === REJECTED){//si rejet alors demandeur n'a que l'option close
      menuKeys.push(statutes[CLOSED]);
    } else if(currentStatus === WAITING_PAYMENTS){ //si en attente de paiement alors le demandeur a les options valider ou rejeter
       menuKeys.push(statutes[VALIDATED]);
       menuKeys.push(statutes[REJECTED]);
       menuKeys.push(statutes[CLOSED]);
    }
 }
  if(!menuKeys.length){
    [APPROVED,REJECTED,CLOSED].map(o=>{
      menuKeys.push(statutes[o]);
    });
  }
  menuKeys.map((m,index)=>{
      menu.push({
        key : index,
        label : (
          <Box
              key="2"
            className="flex itemcenter rad8 "
            onClick={() => m.onClick && m.onClick() ||
              openModal(m.label, m.id)
            }
          >
            <Box className="flex column u-mar-left-s">
              <span className="av-light fs14"  style={{ color: m.color}}>
                {m.label}
              </span>
            </Box>
          </Box>)
      });
      if(index < menuKeys.length -1){
        menu.push({type:"divider",key:index+"_divider"})
      }
  });
  /*if(hasApprovedMneu){
      menu.push({
        label : <Box
          key="1"
          className="flex itemcenter rad8"
          onClick={() => handleSubmit({ statusCode: APPROVED })}
        >
          <Box className="flex column u-mar-left-s">
            <span className="av-light fs14"  style={{ color: "#65676b" }}>
              {t("commitmentRequests.status.approuved")}
            </span>
          </Box>
        </Box>
      })
      menu.push({type : "divider"})
  }
  if(ticket?.status?.id != VALIDATED){
    menu.push({
      label : (
        <Box
            key="2"
          className="flex itemcenter rad8 "
          onClick={() =>
            openModal(t("commitmentRequests.status.rejected"), REJECTED)
          }
        >
          <Box className="flex column u-mar-left-s">
            <span className="av-light fs14"  style={{ color: "#65676b" }}>
              {t("commitmentRequests.status.rejected")}
            </span>
          </Box>
        </Box>)
     });;
     menu.push({type : "divider"} );
  }
  if(ticket?.status?.id == APPROVED){
    menu.push({
      label : (
        <Box
            className="flex itemcenter rad8 "
            onClick={() =>
              openModal(t("commitmentRequests.status.waiting_payment"), WAITING_PAYMENTS)
            }
          >
            <Box className="flex column u-mar-left-s">
              <span className="av-light fs14"   style={{ color: "#65676b" }}>
                {t("commitmentRequests.status.waiting_payment")}
              </span>
            </Box>
          </Box>
      ),
    });
    menu.push({type : "divider"} );
  }
  if(ticket?.status?.id == WAITING_PAYMENTS ){
    menu.push({
      label : (
        <Box
            key="3"
          className="flex itemcenter rad8"
          onClick={() =>
            openModal(t("commitmentRequests.status.Validated"), VALIDATED)
          }
        >
          <Box className="flex column u-mar-left-s">
            <span className="av-light fs14"  style={{ color: "#65676b" }}>
              {t("commitmentRequests.status.Validated")}
            </span>
          </Box>
        </Box>
      ) 
    })
    menu.push({type : "divider"})
  }
  menu.push({
    label : <Box
      key="4"
      className="flex itemcenter rad8"
      onClick={() => openModal(t("commitmentRequests.status.close"), 3)}
    >
      <Box className="flex column u-mar-left-s">
        <span className="av-light fs14"  style={{ color: "#65676b" }}>
          {t("commitmentRequests.status.close")}
        </span>
      </Box>
    </Box>,
  });*/
 
  const handleSubmit = async (values) => {
    if (!values) return;
    let valid = true;
    if(Array.isArray(ticket?.items)){
       for(let i in ticket.items){
          const spent = ticket.items[i];
          !isNonNullString(spent?.financial_code) 
          || !isNonNullString(spent?.financial_line)
          || !isNonNullString(spent?.project_code)
          || !isNonNullString(spent?.project_line)
          ? valid = false : valid;
          if(!valid) break;
       }
    }
    let payload = {
      status: !values.statusCode ? statusCode : values.statusCode,
      comment: values.comment,
    };
    try {
      let resp = null;
      if (valid === false && payload.status === APPROVED) {
        notify.error({
          message: t("commitmentRequests.fill_anal"),
        });
        return;
      }
      setSubmitting(true);
      resp = await addVerify(payload);
      setSubmitting(false);
      updateTicket(resp);
      message.success(t("successful.recording"));
      setVisible(false);
    } catch (e) {
      setSubmitting(false);
      message.warning(t("error.recording"));
    }

    setSubmitting(false);
  };

  const handleAsignee = async (values) => {
    if (!values) return;
    setEmployee(values.employee);
    try {
      setSubmittingAsign(true);
      const resp = await addMutation();
      updateTicket(resp);
      message.success(t("successful.recording"));
      setVisibleAssignee(false);
      setSubmittingAsign(false);
    } catch (e) {
      console.log(e);
      setSubmittingAsign(false);
      message.warning(t("error.recording"));
    }
    setSubmitting(false);
  };

  const submitAgain = async () => {
    try {
      setSubmittingAgain(true);

      if (ticket?.items.length == 0) {
        message.warning("Veuillez ajouter des items!");
        return;
      }
      const resp = await addSubmitAgain();
      updateTicket(resp);
      message.success(t("successful.recording"));
    } catch (e) {
      setSubmittingAgain(false);
      message.warning(t("error.recording"));
    }
  };
  const isGlobalApproved = !!(ticket?.status_coordinator?.id === APPROVED);
  const canSubmitAgain = isInitiator && ticket?.status?.id == REJECTED && isChecker;
  const {approbationItems,validationItems}= React.useMemo(()=>{
    const validationItems = [], approbationItems = [];
    const checkerContent =  canSubmitAgain? (
      <LineRow className="submit-again-container">
        <Box className="flex column ">
          <Box className="item-submitagain-text -to">
            {" "}
            {t("commitmentRequests.rejected_msg")}{" "}
          </Box>
        </Box>
        <Button
          type="primary"
          onClick={() => submitAgain()}
          loading={submittingAgain}
        >
          {" "}
          {t("commitmentRequests.submit_again")}
        </Button>
      </LineRow>
    ) :null;
    if(checkerContent){
      validationItems.push(checkerContent);
      approbationItems.push(checkerContent);
    } else {
       if(isChecker){
          (!isGlobalApproved ? approbationItems:validationItems).push(
            <LineRow colSpan="3" className={"dropdown-menu-container"} m="0" py="0" px="0" my="0px">
                <Dropdown
                  menu={{items:menu,style:{ width: "235px" }}}
                  className="cursor u-mar-right-xs"
                  trigger={["click"]}
                >
                  <Box
                    className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
                    style={{ height: "30px", width: "fit-content" }}
                  >
                    <span
                      className="fs15 fW500 u-mar-right-xs"
                      style={{ color: "#1a1a1a" }}
                    >
                      {submitting
                        ? t("commitmentRequests.detail.section3.label7")
                        : t("commitmentRequests.detail.section3.label0")}
                    </span>
      
                    <span style={{ marginTop: "4.5px" }}>
                      <CaretDownOutlined />
                    </span>
                  </Box>
                </Dropdown>
            </LineRow>
          )
       }
    }
    const array = !isGlobalApproved ? approbationItems : validationItems;
    //Assigned to
    if(currentStatus != CLOSED && currentStatus != VALIDATED && ticket?.checker){
      array.push(<LineRow  className="assigned-to-container">
          <Box className="assigned-to-content-container fW500 fs14 colhead" >
            {t("commitmentRequests.detail.section3.label1")} :{" "}
          </Box>
          <Box className="assigned-to-title fW500 fs14 " >
            {defStr(ticket?.checker?.firstname) + " " + defStr(ticket?.checker?.lastname)}{" "}
            
            <Box className="fs10"  > {i18nTObj(ticket?.checker?.post)} </Box>
          </Box>
          <Box className="grille">--</Box>
        </LineRow>
      )
    }
    
    //responsable budgétaire 
    approbationItems.push(
      <LineRow className="validation-manager-content">
        <Box className="grille2 fW500 fs14 colhead" >
          {t("commitmentRequests.detail.section3.label3")}:{" "}
        </Box>
        <Box className="fW500 fs14 " >
          {ticket &&
            defStr(ticket?.manager?.firstname) + " " + defStr(ticket?.manager?.lastname)}{" "}
          {!isApproved && ticket?.status?.id != 3 && ticket?.status?.id != 5 && (
            <Box
              className="fs11 item-actions coltext" 
              onClick={() =>
                ChangeAsignee(
                  t("commitmentRequests.detail.section3.label3"),
                  1
                )
              }
            >
              {t("reassignment.label")}
            </Box>
          )}{" "}
          
          <Box className="fs10" >
            {" "}
            {i18nTObj(ticket?.manager?.post)}{" "}
          </Box>
        </Box>
  
        <Box className="fW500 fs14 " >
          <Tag
            className="u-mar-left-xs"
            color={
              ticket?.status_manager.id == 0
                ? "#ff9e01"
                : ticket?.status_manager.id == 1
                ? "#87d068"
                : ticket?.status_manager.id == 2
                ? "#ff5500"
                : "#108ee9"
            }
          >
            {i18nTObj(ticket?.status_manager)}
          </Tag>
        </Box>
      </LineRow>
    )
    //admin financier : 
    approbationItems.push(
      <LineRow className="validation-accountant-container">
        <Box className="grille2 fW500 fs14 colhead" >
          {t("commitmentRequests.detail.section3.label4")}:{" "}
        </Box>
        <Box className="grille4 fW500 fs14 " >
          {ticket &&
            defStr(ticket?.accountant?.firstname) +
              " " +
              defStr(ticket?.accountant?.lastname)}{" "}
          {!isApproved && ticket?.status?.id != 3 && ticket?.status?.id != 5 && (
            <Box
              className="fs11 item-actions coltext" 
              onClick={() =>
                ChangeAsignee(
                  t("commitmentRequests.detail.section3.label4"),
                  2
                )
              }
            >
              {t("reassignment.label")}
            </Box>
          )}{" "}
          <Box className="fs10" > {i18nTObj(ticket?.accountant?.post)} </Box>
        </Box>
        <Box className="fW500 fs14 " >
          <Tag
            className="u-mar-left-xs"
            color={
              ticket?.status_accountant.id == 0
                ? "#ff9e01"
                : ticket?.status_accountant.id == 1
                ? "#87d068"
                : ticket?.status_accountant.id == 2
                ? "#ff5500"
                : "#108ee9"
            }
          >
            {i18nTObj(ticket?.status_accountant)}
          </Tag>
        </Box>
      </LineRow>
    )
    ///coordonateur général
    approbationItems.push(
      <LineRow className="validation-coordinator-content">
          <Box className="grille2 fW500 fs14 colhead" >
            {t("commitmentRequests.detail.section3.label5")}:{" "}
          </Box>
          <Box className="grille4 fW500 fs14 " >
            {ticket &&
              defStr(ticket?.coordinator?.firstname)+
                " " +
                defStr(ticket?.coordinator?.lastname)}{" "}
            {!isApproved && ticket?.status?.id != 3 && ticket?.status?.id != 5 && (
              <Box
                className="fs11 item-actions coltext" 
                onClick={() =>
                  ChangeAsignee(
                    t("commitmentRequests.detail.section3.label5"),
                    3
                  )
                }
              >
                {t("reassignment.label")}
              </Box>
            )}{" "}
            
            <Box className="fs10" > {i18nTObj(ticket?.coordinator?.post)} </Box>
          </Box>
    
          <Box className="grille fW500 fs14" >
            <Tag
              className="u-mar-left-xs"
              color={
                ticket?.status_coordinator.id == 0
                  ? "#ff9e01"
                  : ticket?.status_coordinator.id == 1
                  ? "#87d068"
                  : ticket?.status_coordinator.id == 2
                  ? "#ff5500"
                  : "#108ee9"
              }
            >
              {i18nTObj(ticket?.status_coordinator)}
            </Tag>
          </Box>
        </LineRow>
    )
    ///responsable opérationnel
    validationItems.push(
      <LineRow className="validation-treasurer-content">
          <Box className="fW500 fs14 colhead" pr="15px">
            {t("commitmentRequests.detail.section3.label6")}:{" "}
          </Box>
          <Box className="fW500 fs14 " >
            {ticket &&
              defStr(ticket?.treasurer?.firstname) +
                " " +
                defStr(ticket?.treasurer?.lastname)}{" "}
            {!isValidated && ticket?.status?.id != 3 && ticket?.status?.id != 5 && (
              <Box
                className="fs11 item-actions coltext" 
                onClick={() =>
                  ChangeAsignee(
                    t("commitmentRequests.detail.section3.label6"),
                    4
                  )
                }
              >
                {t("reassignment.label")}
              </Box>
            )}{" "}
            
            <Box className="fs10"  > {i18nTObj(ticket?.treasurer?.post)} </Box>
          </Box>
    
          <Box className="fW500 fs14 " >
            <Tag
              className="u-mar-left-xs"
              color={
                ticket?.status_treasurer.id == 0
                  ? "#ff9e01"
                  : ticket?.status_treasurer.id == 1
                  ? "#87d068"
                  : ticket?.status_treasurer.id == 2
                  ? "#ff5500"
                  : "#108ee9"
              }
            >
              {i18nTObj(ticket?.status_treasurer)}
            </Tag>
          </Box>
        </LineRow>
    )
    //initiateur de la demande
    validationItems.push(
      <LineRow className="validation-initiator-content">
          <Box className="fW500 fs14 colhead" pr="15px" >
            {t("commitmentRequests.detail.section3.label2")}:{" "}
          </Box>
          <Box className="fW500 fs14 " >
            {ticket &&
              defStr(ticket?.initiator?.firstname )+
                " " +
                defStr(ticket?.initiator?.lastname)}{" "}
            {!isValidated && ticket?.status?.id != 3 && ticket?.status?.id != 5 && (
              <Box
                className="fs11 item-actions coltext" 
                onClick={() =>
                  ChangeAsignee(
                    t("commitmentRequests.detail.section3.label2"),
                    0
                  )
                }
              >
                {t("reassignment.label")}
              </Box>
            )}{" "}
            
            <Box className="fs10" >
              {" "}
              {i18nTObj(ticket?.initiator?.post)}{" "}
            </Box>
          </Box>
          <Box className="fW500 fs14 " >
            <Tag
              className="u-mar-left-xs"
              color={
                ticket?.status_initiator?.id == 0
                  ? "#ff9e01"
                  : ticket?.status_initiator?.id == 1
                  ? "#87d068"
                  : ticket?.status_initiator?.id == 2
                  ? "#ff5500"
                  : "#108ee9"
              }
            >
              {i18nTObj(ticket?.status_initiator)}
            </Tag>
          </Box>
        </LineRow>
    )
    return {validationItems,approbationItems};
  },[ticket?.id,currentStatus,users,isGlobalApproved]);
  return (
    <>
      <Box className="noshadow noborder hauto rad16 w100 validations-container">
      {/**<h2 className="fW600">
          {" "}
          {t("commitmentRequests.detail.section3.title")}
        </h2> */}
        <Tabs
            className="validation-appprobation-tabs-container"
            defaultActiveKey={isGlobalApproved?"2":"1"}
            items = {[
                {
                   label : t("tickets.validation.approbationTab"),
                   key : "1",
                   children : <ValidationLine rows={approbationItems} w="100%" className="approbation-tabs-container">
                      
                   </ValidationLine>
                },
                {
                  label : "Validation",
                  key : "2",
                  children : <ValidationLine rows={validationItems} className="validation-tabs-container"/>
               }
            ]}
        />
      </Box>
      <Modal
        width={500}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 12,
          height: statusCode == 2 ? 300 : 230,
        }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.detail.section3.changeStatus.popup.title")}
        </h2>

        <h2 className="center">
          {t("commitmentRequests.detail.section3.changeStatus.popup.label0")}:{" "}
           <span className="fW600">{status}</span> ?{" "}
        </h2>

        <Form layout="vertical" onFinish={handleSubmit}>
          {statusCode == 2 && (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="comment"
                  label={t(
                    "commitmentRequests.detail.section3.changeStatus.popup.label1"
                  )}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input.TextArea  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Box className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisible(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.changeStatus.popup.cancel"
                )}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.changeStatus.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </Box>
        </Form>
      </Modal>
      <Modal
        width={500}
        onCancel={() => setVisibleAssignee(false)}
        open={visibleAssignee}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 300 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.detail.section3.modify.popup.title")}
        </h2>

        <h2 className="center">
          {t("commitmentRequests.detail.section3.modify.popup.label0")} {" "}
          <span className="fW600">{post}</span>{" "}
        </h2>

        <Form layout="vertical" onFinish={handleAsignee}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="employee"
                label={t(
                  "commitmentRequests.detail.section3.modify.popup.label1"
                )}
                rules={[
                  {
                    required: true,
                    message: t("input.required"),
                  },
                ]}
              >
                <Select showSearch allowClear optionFilterProp="children">
                  {employees != null &&
                    employees.map((employee,key) => (
                      <Option value={employee.id} key={key}>
                        {" "}
                        {employee.firstname + " " + employee.lastname}{" "}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Box className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisibleAssignee(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.changeStatus.popup.cancel"
                )}{" "}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submittingAsign}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.changeStatus.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </Box>
        </Form>
      </Modal>
    </>
  );
}
