import React, { useState } from "react";
import {fetchBlob} from "$api";
import moment from "moment";
import Link from "$components/Link";
import {
  Descriptions,
  Divider,
  Tag,
} from "antd";
import {axios} from "$api";

import {
  ExportOutlined,
} from "@ant-design/icons";

export default function Description({ payment, t }) {
  const getFile = async (id) => {
    const result = await fetchBlob(`/api/payments/${id}/print`);
    const url = window.URL.createObjectURL(
      new Blob([result.data], { type: "application/pvnd.ms-excel" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${payment?.code}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div className="flex itemcenter u-mar-bottom-s">
        <h2 className="fW600 nomar">{payment?.code} - </h2>

        <Tag
          className="u-mar-left-xs"
          color={
            payment?.status.id == 0
              ? "#ff9e01"
              : payment?.status.id == 1
              ? "#87d068"
              : payment?.status.id == 2
              ? "#ff5500"
              : payment?.status.id == 3
              ? "red"
              : payment?.status.id == 5
              ? "#87d068"
              : "#108ee9"
          }
        >
          {i18nTObj(payment?.status)}
        </Tag>
      </div>

      <div className="flex">
        <div
          className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
          style={{ height: "30px", width: "fit-content" }}
          onClick={() => getFile(payment?.id)}
        >
          <span
            className="fs15 av-heavy u-mar-right-xs"
            style={{ color: "#1a1a1a" }}
          >
            <ExportOutlined /> {t("export.button.label")}
          </span>
        </div>
      </div>

      <Divider />

      <Descriptions column={3}>
        <Descriptions.Item label={t("paymentRequests.detail.label0")}>
          <Link href={payment?.ticket?.code?`/tickets/${payment?.ticket?.id}`:''}>{payment?.ticket?.code}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="type">
          {payment?.advance ?     t("payment.type.advance"):t("payment.type.payment")}
        </Descriptions.Item>

        {
          <Descriptions.Item label={t("paymentRequests.detail.label3")}>
            {payment?.pay_to}
          </Descriptions.Item>
        }

        {
          <Descriptions.Item
            label={t("paymentRequests.detail.label4")}
            style={{ paddingTop: 30 }}
          >
            {i18nTObj(payment?.pay_by)}
          </Descriptions.Item>
        }

        <Descriptions.Item
          label={t("paymentRequests.detail.label5")}
          style={{ paddingTop: 30 }}
        >
          {payment?.initiator.firstname + " " + payment?.initiator.lastname}
        </Descriptions.Item>

        {
          <Descriptions.Item label="Date" style={{ paddingTop: 30 }}>
            {moment(payment?.date).format("D MMM-YY")}
          </Descriptions.Item>
        }

        {
          <Descriptions.Item
            label={t("paymentRequests.detail.label6")}
            style={{ paddingTop: 30 }}
          >
            {payment?.explanation}
          </Descriptions.Item>
        }
      </Descriptions>
    </>
  );
}
