/** retourne un  */
import theme from "./theme";
import defaultStr from "$utils/defaultStr";
import "$utils/string";
import classNames from "$utils/classNames";
import * as React from "react";
import flattenStyle from "./flattenStyle";
import isComponent from "$utils/react/isComponent";

const cols = ['primary','secondary',"info",'success','error','danger','warning','normal']
const colorsAlias = {};
cols.map((c)=>{
    colorsAlias[c] = true;
});

const sides = ["top","left","right","bottom"];

/***@see : https://chakra-ui.com/docs/components/button */
export const variants = ["solid", "ghost", "outline", "link"];

/**** 
 *   create a styled component base on theme colors, base on one of colors alias above.
 *   allows to specify if despite the color defined in the component, the props style color|background will be calculated according to the properties of ColorsAlias
 *    @param {React.Component} Component, the react component we want to styles on
 *    @param {object|string} 
 *          if string, it represent the display name of styled component
 *          if object, it should have on of the attributes below
 *    attributes of options param : 
 *    {
 *      displayName {string}, the display name of styled component
 *      variant {string} : the variant field is used to determine how the primary, secondary, ...and other properties will be applied
 *          if variant is different from contained then the primary or secondary or success attribute only gives 
 *      the primary or success color of the component. otherwise, this attribute gives as backgroundColor, the primary or success color, ...
 *      and as color, the value of the text linked to this color
 *      propsMutator : ({...props})=> function to mutate final props
 * 
 * }
 * @return {React.Component}, the styled component
 */
export default function withStyles(Component,options){
    Component = isComponent(Component)? Component : defaultStr(Component);
    options = typeof options =='string' && options && {displayName:options} || 'object' && options && !Array.isArray(options)? options : {};
    const {variant:customVariant,displayName,propsMutator:cPropsMutator,className:componentClassName,withRef, ...opts} = options;
    const propsMutator = typeof cPropsMutator =='function'? cPropsMutator : null;
    const shouldForwardProp = typeof opts.shouldForwardProp =='function'? opts.shouldForwardProp : x=>true;
    if(!isComponent(Component)) return React.Fragment;
    const ComponentWithTheme = (withRef !== false ? React.forwardRef : x=>x)(({bgColor,variant,rounded,isRounded,noPadding,noMargin,borderColor,normal,color,bold,_hover,className,disabled,readOnly,loading,splitText,...props},ref)=>{
        _hover = Object.assign({},_hover);
        const cV = (typeof variant=='string' && variant || typeof customVariant =='string' && customVariant && customVariant || '').trim().toLowerCase();
        const variantProp = cV && variants.includes(cV) ? cV : "solid";
        const isSolid = variantProp ==="solid";
        const isOutline = variantProp ==="outline";
        const isLink = variantProp =="link";
        rounded = typeof rounded =="boolean"? rounded : typeof isRounded =='boolean'? isRounded : undefined;
        className = classNames(componentClassName,className,"variant-{0}".sprintf(variantProp));
        const rest = React.useMemo(()=>{
            const rest = {};
            let currentColor = undefined, currentBgColor = undefined;
            for(let i in props){
                if(i in colorsAlias){
                    if(props[i]){
                        const colorText = (i+"Text");
                        const col = isSolid ? colorText : i;
                        const bgCol = isSolid? i : colorText;
                        if(col && bgCol){
                            currentColor = col;
                            currentBgColor = bgCol;
                        }
                    }
                } else if(shouldForwardProp(i,props[i])){
                    rest[i] = props[i];
                }
            }
            if(normal){
                color = color || isSolid ? "text" : "surface";
                bgColor = bgColor || (isSolid? "surface" : undefined);
            } else {
                color = color || currentColor;
                bgColor = bgColor || isSolid && currentBgColor || undefined;   
            }
            if(!color){
                color = "text";
            }
            if(isSolid && !bgColor){
                bgColor = "surface";
            }
            if(isOutline){
                borderColor = borderColor || color;
            }
            return rest;
        },[props,variantProp])
        if(bgColor && theme.colors[bgColor]){
            const hv = bgColor+"Hover";
            if(theme.colors[hv]){
                _hover.backgroundColor = _hover.backgroundColor || _hover.bgColor || _hover.background || theme.colors[hv];
            }
        }
        
        if(noPadding){
            sides.map(s=>{
                const p = "padding"+s.ucFirst();
                rest[p] = 0;
                _hover[p] = _hover[p] || 0;
            });
        }
        if(noMargin){
            sides.map(s=>{
                const m = "margin"+s.ucFirst();
                rest[m] = 0;
                _hover[m] = _hover[m] || 0;
            });
        }
        if(shouldForwardProp("variant",variantProp)){
            rest.variant = variantProp;
        }
        if(isLink){
            _hover.textDecoration = _hover.textDecoration || "underline";
        }
        if(borderColor && shouldForwardProp("borderColor",borderColor)){
            rest.borderColor = borderColor;
        }
        if(bgColor && shouldForwardProp("bgColor",bgColor)){
            rest.bgColor = bgColor;
            if(theme.colors[bgColor]){
                className = classNames(className,"bg-color-"+bgColor);
            }
        }
        if(color && shouldForwardProp("color",color)){
            rest.color = color;
            if(theme.colors[color]){
                className = classNames(className,"color-"+color);
            }
        }
        if(shouldForwardProp("_hover",_hover)){
            rest._hover = _hover;
        }
        if(disabled){
            rest.isDisabled = !!disabled;
        }
        className = classNames(className,disabled || props.isDisabled ?"disabled":"not-disabled");
        if(typeof readOnly =='boolean'){
            rest.isReadOnly = true;
            className = classNames(className,readOnly ? "readonly":"not-readonly");
        }
        if(bold && shouldForwardProp("bold",bold)){
            rest.fontWeight = typeof bold =="number" && bold || typeof bold =="string" && bold || bold =="true" && "bold" || undefined;
        }
        if(splitText){
            className = classNames(className,"text-ellipsis");
        }
        if(rounded){
            rest.borderRadius = rest.borderRadius || typeof(theme.roundness)==="number" && (theme.roundness+"px") || theme.roundness;
        }
        rest.className = className;
        propsMutator && propsMutator(rest);
        if(Array.isArray(rest.style)){
            rest.style = flattenStyle(rest.style);
        }
        return <Component {...rest}  ref={withRef !== false && ref || undefined}/>
    });
    ComponentWithTheme.displayName = defaultStr(Component.displayName,displayName);
    ComponentWithTheme.propTypes = ComponentWithTheme.propTypes || Component.propTypes;
    return ComponentWithTheme;
}