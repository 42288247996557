import React, { useEffect, useState, useRef} from "react";
import "moment/locale/fr";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Comment } from "@ant-design/compatible";
import Box from "$components/Box";
import {APPROVED,VALIDATED} from "$status";
import Icon from "$components/Icon";
import {isNonNullString} from "$utils";
import {fetchBlob} from "$api";
import {
  Button,
  Spin,
  Input,
  Drawer,
  Modal,
  Descriptions,
  Divider,
  ConfigProvider,
  Empty,
  Space,
  Form,
  Row,
  Col,
  message,
  Breadcrumb,
  List,
  Tabs,
  Tag,
  Select,
  Upload,
  Steps,
} from "antd";
import Table from "$components/Table";
import {
  UploadOutlined,
  EditOutlined,
  PaperClipOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  useAddCommentForMovement,
  useGetEmployees,
  useGetMovement,
  useGetDocumentsTypes,
  useAddDocument,
  useDeleteMovementAttachment,
  useDeleteMovementExternalMember,
  useDeleteMovementMember,
  deleteMovementExternalMember,
  AVOID_RETRY,
} from "../../api/api";
import { useTranslation } from "react-i18next";
import LayoutPage from "../layout";
import MemberForm from "./memberForm";
import CommentForm from "./commentForm";
import ExternalMemberForm from "./externalMember";
import Avatar from "antd/es/avatar";
import { userInfos } from "../../store";
import { useRecoilValue } from "recoil";
import ValidationMovement from "./validationMovement";
import MovementForm from "./movementForm";
import {AttachmentPreview,AttachmentDownload} from "$components/Upload";

const { Option } = Select;

const DELETE_EXTERNAL_MEMBER = "delete_extenralmameber";
const DELETE_MEMBER = "delete_member";
const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "Réponses" : "Répponse"
    }`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...getCommentProps(props)} />}
  />
);

const getCommentProps = (element) => {
  return {
    author: element.commentator.firstname + " " + element.commentator.lastname,
    content: <p>{element.message}</p>,
    datetime: <span> {moment(element.date).format("D MMM-YY")}</span>,
    //datetime: moment(element.date).fromNow(),
    avatar: <img src="/avatar.png" alt="avatar" height={40} width={40} />,
    //avatar:<Avatar/>
  };
};
const { TextArea } = Input;

export default function DetailMovement() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(null);
  const [showAddComment,setShowAddComment] = useState(false);
  const [movement, setMovement] = useState(null);
  const [member_form, setMembersForm] = useState(false);
  const [attachement, setAttachement] = useState(false);
  const [document_id, setDocumentId] = useState(0);
  const [comment_form, setCommentsForm] = useState(false);
  const [file, setFile] = useState(null);
  const [ext_form, setExtForm] = useState(false);
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const commentContentRef = React.useRef("");
  let { id } = useParams();
  const { mutateAsync: addComment } = useAddCommentForMovement(id);
  const { data, error, isLoading, refetch } = useGetMovement(id,showAddComment||visible?AVOID_RETRY : undefined);
  const { data: members } = useGetEmployees({}, {});
  const { data: documents } = useGetDocumentsTypes({}, {});
  const { mutateAsync: submitAttachments } = useAddDocument(id, document_id);
  const user = useRecoilValue(userInfos);

  const currentElementRef = useRef(null);
  const current_element = currentElementRef.current;
  const [is_update, setIsUpdate] = useState(false);
  const isValidated = !!(movement?.status?.id === VALIDATED || moment?.status?.id === APPROVED);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const toDeleteRef = React.useRef(null);
  const toDeleteMemberRef = React.useRef(null);
  const toDeleteActionRef  = useRef(null);
  const willDeleteExternalMember = toDeleteActionRef.current === DELETE_EXTERNAL_MEMBER;
  const willDeleteMember = toDeleteActionRef.current = DELETE_MEMBER;
  const { mutateAsync: deleteAttachment } = useDeleteMovementAttachment(movement?.id);
  const {mutateAsync: apiDeleteExternalMember} = useDeleteMovementExternalMember(movement?.id);
  const {mutateAsync: apiDeleteMember} = useDeleteMovementMember(movement?.id);

  const Editor = ({ onChange, onSubmit,disabled }) => disabled?null :  (
    <div
      style={{
        height: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Form.Item>
        <TextArea rows={3} disabled = {disabled} onChange={(e)=>{
            commentContentRef.current = e.target.value;
            if(typeof onChange =='function'){
              onChange(e);
            }
        }} defaultValue={commentContentRef.current} />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
        >
          {t("add.button")}
        </Button>
      </Form.Item>
    </div>
  );

  const customizeRenderEmpty = () => (
    <Empty description={<span>{t("nodata")}</span>}></Empty>
  );

  const membersColumns = [
    {
      title: ("travelRequests.detail.section1.tab0.label1"),
      dataIndex: "firstname",
    },
    {
      title: ("travelRequests.detail.section1.tab0.label2"),
      dataIndex: "lastname",
    },
    {
      title: ("travelRequests.detail.section1.tab0.label0"),
      dataIndex: "post",
      render: ({record}) => {
        return <div>{i18nTObj(record?.post)}</div>;
      },
    },
    {
      title: "",
      key: "operation",
      render: ({record}) => {
        return isValidated ? null :  (
          <Box flex justifyContent="center" flexWrap>
            <span
              className="grille av-heavy fs14 coltext u-mar-left-s"
              onClick={(e)=>{
                openDeleteMember(record);
              }}
            >
              {t("delete")}
            </span>
          </Box>
        );
      },
    },
  ];

  const externalMembersColumns = [
    {
      title: ("travelRequests.form.step1.label9"),
      dataIndex: "name",
    },
    {
      title: ("travelRequests.form.step1.label10"),
      dataIndex: "profession",
    },
    {
      title: "",
      key: "operation",
      render: ({record}) => {
        return isValidated ? null :  (
          <Box flex justifyContent="center" flexWrap>
            <span
              className="grille av-heavy fs14 coltext u-mar-left-s"
              onClick={(e)=>{
                openDeleteExternalMember(record);
              }}
            >
              {t("delete")}
            </span>
          </Box>
        );
      },
    },
  ];
  
  const openDeleteFile = async (document) => {
    toDeleteRef.current = document;
    toDeleteActionRef.current = null;
    setVisibleDelete(true);
  };
  const openDeleteExternalMember = async (record) => {
    toDeleteActionRef.current  = DELETE_EXTERNAL_MEMBER;
    toDeleteMemberRef.current = record;
    setVisibleDelete(true);
  };
  
  const openDeleteMember = async (record) => {
    toDeleteActionRef.current  = DELETE_MEMBER;
    toDeleteMemberRef.current = record;
    setVisibleDelete(true);
  };

  const attachmentsColumn = [
    {
      title: t("travelRequests.detail.section1.tab2.label0"),
      dataIndex: "document",
      render: ({record:document}) => {
        return <div>{i18nTObj(document)}</div>;
      },
    },
    {
      title: t("travelRequests.detail.section1.tab2.label1"),
      dataIndex: "date",
      render: ({record}) => {
        return <div>{moment(record?.date).format("D MMM-YY")}</div>;
      },
    },
    {
      title: t("travelRequests.detail.section1.tab2.label2"),
      dataIndex: "owner",
      render: ({record}) => {
        return <div>{record?.owner.firstname + " " + record?.owner.lastname}</div>;
      },
    },
    {
      title: "",
      key: "operation",
      render: ({record}) => {
        return isValidated ? null : (
          <Box flex justifyContent="center" alignItems="center" flexWrap>
            <AttachmentPreview 
              attachment={record}
              downloadLink = {`/api/attachments/${record.id}/download`}
            />
            <AttachmentDownload 
              attachment={record}
              downloadLink = {`/api/attachments/${record.id}/download`}
              fileName = {i18nTObj(record)}
            />
            <span
              className="grille av-heavy fs14 coltext u-mar-left-s"
              onClick={(e)=>{
                openDeleteFile(record);
              }}
            >
              {t("delete")}
            </span>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    setMovement(data);
    setComments(data?.comments);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);


  const closeMember = () => {
    setMembersForm(false);
  };

  const closeComment = () => {
    setCommentsForm(false);
  };

  const closeExt = () => {
    setExtForm(false);
  };

  const closeAtt = () => {
    setAttachement(false);
  };

  const addMembers = (resp) => {
    setMovement(resp);
  };

  const onChange = (value) => {
    //console.log(`selected ${value}`);
    setDocumentId(value);
  };

  const close = () => {
    setVisible(false);
  };

  const updateCommentState = (resp) => {
    let { comments, ...rest } = movement;
    comments.push(resp);
    rest.comments = comments;
    setMovement(rest);
  };

  const addAttachments = (resp) => {
    let { attachments, ...rest } = movement;
    attachments.push(resp);
    rest.attachments = attachments;
    setMovement(rest);
  };

  const downloadFile = async (id) => {  
    const result = await fetchBlob(`/api/movements/${id}/print`);
    const url = window.URL.createObjectURL(
      new Blob([result.data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${movement?.code}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  const commentsColumns = [
    {
      title: t("list.columns.label23"),
      dataIndex: "commentator",
      render: ({record:commentator}) => {
        return <div>{commentator.firstname + " " + commentator.lastname}</div>;
      },
    },
    {
      title: "Message",
      dataIndex: "message",
    },
  ];

  const openEdit = () => {
    const {
      start_date,
      end_date,
      accountant,
      checker,
      coordinator,
      initiator,
      objectives,
      status,
      statusAccountant,
      statusCoordinator,
      members,
      externalMembers,
      attachments,
      comments,
      ...rest
    } = movement;
    rest.accountant_id = accountant.id;
    rest.accountantId = accountant.id;
    rest.initiator_id = initiator.id;
    rest.objectives = objectives[0];
    rest.start_date = moment(start_date);
    rest.end_date = moment(end_date);
    currentElementRef.current = rest;
    setIsUpdate(true);
    setVisible(true);
  };

  const handleSubmit = async () => {
    if (!isNonNullString(commentContentRef.current)) return;
    setSubmitting(true);
    try {
      let values = {
        commentator_id: user.id,
        message: commentContentRef.current,
      };
      const resp = await addComment(values);
      let { comments, ...rest } = movement;
      comments.unshift(resp);
      rest.comments = comments;
      setMovement(rest);
      setShowAddComment(false);
      message.success(t("successful.recording"));
      //setLoading(false)
    } catch (e) {
      //setLoading(false)
      message.warning(t("error.recording"));
    }
    setSubmitting(false);
    commentContentRef.current = "";
  };

  const handleSubmitForAttachments = async () => {
    try {
      let formData = new FormData();
      formData.append("file ", file, file.name);
      let resp = await submitAttachments(formData);
      let { attachments, ...rest } = movement;
      attachments.push(resp);
      rest.attachments = attachments;
      //console.log('rest+++++++++++++++++',rest)
      setMovement(rest);
      //addAttachments(resp)

      message.success(t("successful.recording"));
      //setLoading(false)
    } catch (e) {
      //setLoading(false)
      message.warning(t("error.recording"));
    }

    setAttachement(false);
  };
  const deleteFile = async () => {
    try {
      setSubmitting(true);
      await deleteAttachment(toDeleteRef.current?.id);
      message.success(t("successful.recording"));
      setVisibleDelete(false);
      setSubmitting(false);
      toDeleteActionRef.current = null;
    } catch (e) {
      setSubmitting(false);
      console.error(e," is eee")
      message.warning(t("error.recording"));
      toDeleteActionRef.current = null;
    }
  };
  const deleteExternalMember = async () => {
    try {
      setSubmitting(true);
      await apiDeleteExternalMember(`${toDeleteMemberRef.current?.id}/externalMembers`);
      message.success(t("successful.recording"));
      setVisibleDelete(false);
      setSubmitting(false);
      toDeleteActionRef.current = null;
    } catch (e) {
      setSubmitting(false);
      console.error(e," is eee")
      message.warning(t("error.recording"));
      toDeleteActionRef.current = null;
    }
  };
  const deleteMember = async () => {
    try {
      setSubmitting(true);
      await apiDeleteMember(`${toDeleteMemberRef.current?.id}/members`);
      message.success(t("successful.recording"));
      setVisibleDelete(false);
      setSubmitting(false);
      toDeleteActionRef.current = null;
    } catch (e) {
      setSubmitting(false);
      console.error(e," is eee")
      message.warning(t("error.recording"));
      toDeleteActionRef.current = null;
    }
  };
  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <div className="grille w100    mauto   u-mar-top-xl-res nopadres noradres">
          <Breadcrumb items={[{
            title : <Link to="/movement_request">
              {t("travelRequests.list.title")}
              </Link>,
            },
            {
              title : movement?.code,
            }
          ]}/>
        </div>
        <Row className="u-mar-top-s">
          <Col span={16} className="hauto blok rad16">
            <div className="flex column  u-pad-s h100">
              <div className="flex itemcenter u-mar-bottom-s">
                <h2 className="fW600 nomar">{movement?.code} - </h2>
                <Tag
                  className="u-mar-left-xs"
                  color={
                    movement?.status?.id == 0
                      ? "#ff9e01"
                      : movement?.status?.id == 1
                      ? "#87d068"
                      : movement?.status?.id == 2
                      ? "#ff5500"
                      : "#108ee9"
                  }
                >
                  {i18nTObj(movement?.status)}
                </Tag>
                {/*<Button icon={<EditOutlined />} onClick={openEdit}/>*/}
              </div>
              <div className="flex">
                <div
                  className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
                  style={{ height: "30px", width: "fit-content" }}
                >
                  <span
                    className="fs15 av-heavy u-mar-right-xs edit-mouvement-icon-contentainer"
                    style={{ color: "#1a1a1a" }}
                    onClick={openEdit}
                  >
                    <EditOutlined /> {t("reassignment.label")}
                  </span>
                </div>
                <div
                  className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
                  style={{ height: "30px", width: "fit-content" }}
                >
                  <span
                    className="fs15 av-heavy u-mar-right-xs"
                    style={{ color: "#1a1a1a" }}
                    onClick={() => setAttachement(true)}
                  >
                    <PaperClipOutlined /> {t("travelRequests.detail.button1")}
                  </span>
                </div>
                <div
                  className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
                  style={{ height: "30px", width: "fit-content" }}
                >
                  <span
                    className="fs15 av-heavy u-mar-right-xs"
                    style={{ color: "#1a1a1a" }}
                    onClick={() => downloadFile(movement?.id)}
                  >
                    <ExportOutlined /> {t("export.button.label")}
                  </span>
                </div>
              </div>
              <Divider />
              <Descriptions
                title={t("travelRequests.detail.Description.title")}
              >
                <Descriptions.Item label={t("travelRequests.detail.label0")}>
                  {movement?.country}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label1")}>
                  {movement?.starting}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label2")}>
                  {movement?.destination}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label3")}>
                  {movement?.otherDestination}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label4")}>
                  {movement?.startDate}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label5")}>
                  {movement?.endDate}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label6")}>
                  {movement?.transportModeGone}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label7")}>
                  {movement?.transportModeReturn}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label8")}>
                  {movement?.transportModeOther}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label9")}>
                  {movement?.hosting}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label10")}>
                  {movement?.otherHosting}
                </Descriptions.Item>
                <Descriptions.Item label={t("travelRequests.detail.label11")}>
                  {movement?.objectives
                    ? movement?.objectives[0]
                    : "Pas d'élément"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("travelRequests.form.step2.label9")}
                >
                  {movement?.risk}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("travelRequests.form.step2.label8")}
                >
                  {movement?.context}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("travelRequests.form.step2.label10")}
                >
                  {movement?.mitigation}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
          <Col span={8} className="u-pad-left-s">
            {movement && user ? (
              <ValidationMovement
                idUser={user.id}
                movement={movement}
                updateDetail={addMembers}
                employees={members}
              />
            ) : null}
          </Col>
          <Divider />
          <Col className="hauto blok rad16" span={24}>
            <h2 className="fW600 nomar">
              {t("travelRequests.detail.section1.title")}
            </h2>
            <Tabs
              defaultActiveKey="1"
              className="clienttab pay"
              centered={true}
              items = {[
                  {
                     key : "1",
                     label : t("travelRequests.detail.section1.tab0.title"),
                     children : <div>
                        <div style={{ textAlign: "right" }}>
                            <Button
                              type="link"
                              onClick={() => {
                                setMembersForm(true);
                              }}
                            >
                              {t("add.button")}
                            </Button>
                          </div>
                          {movement &&
                          movement.members &&
                          movement.members.length !== 0 ? (
                            <>
                              <Table
                                columns={membersColumns}
                                dataSource={movement.members}
                              />
                            </>
                          ) : (
                            <>
                              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                <Table columns={membersColumns} dataSource={[]} />
                              </ConfigProvider>
                            </>
                          )}
                     </div>
                     
                  },
                  {
                    key : "2",
                    label : t("travelRequests.detail.section1.tab1.title"),
                    children : <div>
                        <div style={{ textAlign: "right" }}>
                            <Button
                              type="link"
                              onClick={() => {
                                setExtForm(true);
                              }}
                            >
                              {t("add.button")}
                            </Button>
                          </div>
                          {movement &&
                          movement.externalMembers &&
                          movement.externalMembers.length !== 0 ? (
                            <>
                              <Table
                                columns={externalMembersColumns}
                                dataSource={movement?.externalMembers}
                              />
                            </>
                          ) : (
                            <>
                              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                <Table columns={commentsColumns} dataSource={[]} />
                              </ConfigProvider>
                            </>
                          )}
                    </div>
                  },
                  {
                     key : "3",
                     label : t("travelRequests.form.step1.label7"),
                     children : <div>
                      {movement &&
                        movement.attachments &&
                        movement.attachments.length > 0 ? (
                          <>
                            <Table
                              columns={attachmentsColumn}
                              dataSource={movement.attachments}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <Empty description={<span>{t("nodata")}</span>} />
                        )}
                     </div>
                  },
                  {
                    key : "4",
                    label : t("travelRequests.detail.section1.tab3.title"),
                    children : <div>
                      <Modal
                         title={t("travelRequests.detail.section1.tab3.button")}
                         open={showAddComment}
                         footer = {null}
                         onCancel={(e)=>{setShowAddComment(false)}}
                      >
                          <Box className="add-comment-modal-content">
                            <Comment
                            	avatar={<Avatar />}
                            	content={
                            	  <Editor
                            		  onSubmit={handleSubmit}
                            		  //disabled = {isValidated}
                            	  />
                            	}
                            />
                        </Box>
                      </Modal>  
                      
                      {!isValidated && <Button type="primary" icon={<Icon name="material-add"/>} children={t("travelRequests.detail.section1.tab3.button")} onClick={()=>{setSubmitting(false);setShowAddComment(true)}}/>}
                      {movement &&
                        movement.comments &&
                        movement.comments.length > 0 && (
                          <CommentList comments={movement.comments} />
                        )}
                      </div>
                  },
                  {
                     key : "5",
                     label : t("travelRequests.detail.section1.tab4.title"),
                     children  : <div>
                        {movement?.events?.length > 0 ? (
                          <Steps progressDot current={0} direction="vertical"
                            items = {movement.events.map((e,key) => (
                              {
                                key,
                                title : moment(e.date).format("D MMM-YY HH:mm") +
                                " / " +
                                e.author?.firstname +
                                " " +
                                e.author?.lastname,
                                description : i18nTObj(e,"name")
                              }
                            ))}
                          />
                        ) : (
                          <Empty className="u-mar-top-l" />
                        )}
                     </div>
                  }
              ]}/>
          </Col>
        </Row>
        <Drawer
          height={"100%"}
          placement={"bottom"}
          onClose={() => setMembersForm(false)}
          open={member_form}
          closable={false}
          bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
          destroyOnClose={true}
        >
          <MemberForm
            close={closeMember}
            members={members}
            movement_id={id}
            addMembers={addMembers}
          />
        </Drawer>
        <Drawer
          height={"100%"}
          placement={"bottom"}
          onClose={() => setCommentsForm(false)}
          open={ext_form}
          closable={false}
          bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
          destroyOnClose={true}
        >
          <ExternalMemberForm
            close={closeExt}
            movement_id={id}
            addElement={addMembers}
          />
        </Drawer>
        <Modal
        width={500}
        onCancel={() => setVisibleDelete(false)}
        open={visibleDelete}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center"> {t("confirm.delete.attachment")}</h2>

        {!willDeleteExternalMember && <h2 className="fW500 w100 center"> {toDeleteRef.current?.name }</h2>}
        {willDeleteExternalMember || willDeleteMember && <h2 className="fW500 w100 center"> {toDeleteMemberRef.current?.name }</h2>}
        <Form layout="vertical" onFinish={willDeleteExternalMember ? deleteExternalMember : willDeleteMember ? deleteMember : deleteFile}>
          <div className="flex flexend u-mar-top-l">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisibleDelete(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t("commitmentRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.modify.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </div>
        </Form>
      </Modal>
        <Modal
          title={t("travelRequests.detail.button1")}
          onCancel={() => setAttachement(false)}
          open={attachement}
          //bodyStyle={{ paddingBottom: 0,paddingTop:12,height:300}}
          destroyOnClose={true}
          closable={true}
          okText={t("add.button")}
          cancelText={t("travelRequests.detail.section3.modify.popup.cancel")}
          //footer=""
          maskClosable={true}
          onOk={handleSubmitForAttachments}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("travelRequests.detail.placeholder.file")}
                onChange={onChange}
              >
                {documents &&
                  documents.map((doc,key) => (
                    <Option value={doc.id} key={key}>{i18nTObj(doc)}</Option>
                  ))}
              </Select>
            </Col>
            <Col span={24}>
              <Upload
                listType="picture-circle"
                //multiple={true}
                //fileList={fileList}
                beforeUpload={(file) => {
                  //console.log(file);
                  setFile(file);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>
                  {t("travelRequests.upload.btn.text")}
                </Button>
              </Upload>
            </Col>
          </Row>
        </Modal>
        <Drawer
          height={"100%"}
          placement={"bottom"}
          onClose={() => setVisible(false)}
          open={visible}
          closable={false}
          bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
          destroyOnClose={true}
        >
          <MovementForm
            close={close}
            is_update={is_update}
            current_element={current_element}
            initiator={members}
            documents={documents}
            updateElement={addMembers}
            updateAttachment={addAttachments}
          />
        </Drawer>
      </Spin>
    </LayoutPage>
  );
}
