import React, {useEffect, useState } from "$react";
import Box from "$components/Box";
import Input from "$components/Input";
import {defaultNumber,isObj} from "$utils";
import Icon from "$components/Icon";
import ValidationLine, {Row as RowLine,RowHeader} from "$containers/ValidationLine";
import { SelectService,fetchServices } from "$enums";
import {
  Modal,
  Form,
  Col,
  Row,
  Button,
  Select,
  Spin,
  Input as AndInput,
  message,
} from "antd";

import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;
export default function Step2Form({
  chargeItems,
  catLogistic,
  catRh,
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showSub1, setShowSub1] = useState(false);
  const activeItemRef = React.useRef(null);
  const [productcart, setProductcart] = useState([]);
  const [total, setTotal] = useState(0);
  const [subCategories,setSubCategories] = useState([]);
  const [services,setServices] = React.useState([]);
  const categoriesByServicesRef = React.useRef({});
  const servicesRef = React.useRef({});

  useEffect(() => {
    gettotal();
  }, [productcart]);
  useEffect(()=> {
      fetchServices(true).then((items)=>{
        const services = [];
        categoriesByServicesRef.current = {};
        servicesRef.current = {};
        items.map((it)=>{
          if(!isObj(it)) return;
          const {service,categories} = it;
          categoriesByServicesRef.current[service.id] = categories;
          servicesRef.current[service.id] = service;
          services.push({
            ...service,
            categories,
          })
        });
        setServices(services);
        return services;
      });
  },[])
  const addProd = (prod) => {
    ["quantity","u_price"].map((v)=>{
      if(typeof prod[v] !== 'number'){
        prod[v] = parseFloat(prod[v]) || 0;
      }
    });
    let pItems = [];
    if(activeItemRef.current && typeof activeItemRef.current.index =='number' && activeItemRef.current.index>=0 && activeItemRef.current.index<productcart.length){
      pItems = [...productcart];
      pItems[activeItemRef.current.index] = prod;
    } else {
      pItems = productcart.concat(prod);
    }
    setProductcart(pItems);
    chargeItems(pItems);
  };

  const gettotal = () => {
    var total = 0;
    for (var i = 0, _len = productcart.length; i < _len; i++) {
      productcart[i]["quantity"] != undefined
        ? (total += productcart[i]["quantity"] * productcart[i]["u_price"])
        : (total += 1 * productcart[i]["u_price"]);
    }

    setTotal(total);
  };

  const removeProd = (id) => {
    const objIndex = productcart.findIndex((obj) => obj._id == id);

    /*   let obji = products.findIndex((obj => obj._id == id));
  products[obji].num = 1 */

    //setProducts(products)

    let newcart = productcart.filter((u, i) => i != objIndex);
    setProductcart(newcart);

    chargeItems(newcart);
  };

  const onChangeService = (v, t) => {
    const categories = Array.isArray(categoriesByServicesRef.current[v]) ? categoriesByServicesRef.current[v] : [];
    setSubCategories(categories);
    return;
    if (t.children[1] == "LOGISTIQUE") {
      setShowSub(true);
      setShowSub1(false);
    } else if (t.children[1] == "RESSOURCES HUMAINES") {
      setShowSub(false);
      setShowSub1(true);
    } else {
      setShowSub1(false);
      setShowSub(false);
    }
  };

  const onChange = (e) => {
    return;
    const v = parseInt(e.target.value) || 0;
    if(v>=0){
      productcart[e.target.id].quantity = v;
      setProductcart(productcart.concat());
      chargeItems(productcart.concat());
    }
  };

  const onChangePrice = (e) => {
    return;
    if (parseInt(e.target.value) >=0) {
      productcart[e.target.id].u_price = parseInt(e.target.value);

      setProductcart(productcart.concat());

      chargeItems(productcart.concat());
    }
  };

  function onSubmit(values) {
    addProd(values);
    setVisible(false);
    setShowSub(false);
  }
  const formRef = React.useRef(null);
  const activeItem = activeItemRef.current;
  const initialValues = activeItem? {...activeItem} : {};
  return (
    <Box className="appro rel form-prod-container">
      <Box className="flex justbtw w100">
        <h3 className="fW600 nomar">
          {t("commitmentForms.form.section2.title")}
        </h3>

        <h3
          className="fW600 coltext cursor nomar"
          onClick={() => {
            activeItemRef.current = null;
            setVisible(true)
          }}
        >
          {" "}
          <PlusCircleFilled /> {t("commitmentForms.form.section2.label7")}
        </h3>
      </Box>
      <Box w="100%" className="products-cats-list-container">
          <ValidationLine
              whiteSpaceNoWrap
              bordered
              className={"products-cat-items-container"}
              headers ={<RowHeader cellProps={{px:"10px"}}>
                <span className="fW500">
                  {" "}
                  {t("commitmentForms.form.section2.label0")}{" "}
                </span>
                <span className="fW500">
                  {" "}
                  {t("commitmentForms.form.section2.label1")}{" "}
                </span>
                <span className="fW500 ">
                  {" "}
                  {t("commitmentForms.form.section2.label2")}{" "}
                </span>
                <span className="fW500 ">
                  {" "}
                  {t("commitmentForms.form.section2.label3")}{" "}
                </span>
                <span className="fW500 ">
                  {" "}
                  {t("commitmentForms.form.section2.label4")}{" "}
                </span>
                <span className="fW500 ">
                  {" "}
                  {t("commitmentForms.form.section2.label5")}{" "}
                </span>
                <span className="fW500 ">Actions</span>
              </RowHeader>}
              rows = {productcart?.map((row, id) => {
                return <RowLine
                    key={id}
                    className="u-pad-left-xs "
                    cellProps={{px:"10px",py:"0"}}
                  >
                    <span className="av-heavy fs14 ">
                      {row.description}
                    </span>
                    <span className="av-heavy fs14 ">
                      {i18nTObj(servicesRef.current[row.service])}{" "}
                    </span>
                    <span
                      className="c-input qte "
                      children={defaultNumber(row.quantity).formatNumber()}
                      style={{
                        height: "35px",
                        marginRight: "10px",
                        border: 0,
                      }}
                    />
                    <span className="c-input qte ">
                      {" "}
                      {row.unity}{" "}
                    </span>
  
                    <span
                      className="c-input qte"
                      children={defaultNumber(row.u_price)?.formatMoney()}
                      //id={id}
                      style={{ height: "35px", width: "30px", border: 0 }}
                    />
                    <span className="av-heavy fs14 grille ">
                      {(
                        (row.quantity ? row.quantity : 1) * row.u_price
                      ).formatMoney()}
                    </span>
                    <span className="actions">
                      <Icon name="material-edit" size="20px" color="primary" onClick={() => {
                        activeItemRef.current = {...row,index:id,id};
                        setVisible(true);
                      }}/>
                      <Icon name="material-close" size="20px" color="error" onClick={() => removeProd(row._id)}/>
                    </span>
                  </RowLine>
              })}
              footers = {<ValidationLine.RowFooter
                children = {[
                  {
                     colSpan : 3,
                     children : null,
                     p:0,
                  },
                  {
                    colSpan :4,
                    p:0,
                    children : <Box flex alignItems="center"  p="10px" className="bggray  footer-total-container">
                      <Box as="span" mr="5px"> {t("commitmentForms.form.section2.label6")} </Box>
                      <Box as="span" fontWeight="bold">{" :  "}{total?.formatMoney()} </Box>
                  </Box>
                  }
                ]}
              />}
          />
      </Box>
      <Modal
        width={700}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 450 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentForms.form.section2.label7")}
        </h2>

        <Form
          layout="vertical"
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmit}
          ref = {formRef}
          initialValues={activeItemRef.current}
        >
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="service"
                  label="Service"
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <SelectService
                    optionFilterProp="children"
                    onSelect={(v, t) => onChangeService(v, t)}
                    items = {services}
                    fetch = {false}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                {Array.isArray(subCategories) && subCategories.length && (
                  <Form.Item
                    name="subservice"
                    label={t("commitmentForms.form.section2.popup.label0")}
                    rules={[{ required: true, message: t("input.required") }]}
                  >
                    <Select optionFilterProp="children">
                      {subCategories.map((sub, index) => (
                          <Option value={sub.id} key={index}>
                            {" "}
                            {i18nTObj(sub)}{" "}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                ) || null}

                {false && showSub1 && (
                  <Form.Item
                    name="subservice1"
                    label={t("commitmentForms.form.section2.popup.label1")}
                  >
                    <Select
                      optionFilterProp="children"
                      rules={[{ required: true, message: t("input.required") }]}
                    >
                      {catRh != null &&
                        catRh.map((sub, key) => (
                          <Option value={i18nTObj(sub)} key={key}>
                            {" "}
                            {i18nTObj(sub)}{" "}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label={t("commitmentForms.form.section2.label0")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input defaultValue={initialValues?.description}/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="unity"
                  label={t("commitmentForms.form.section2.label3")}
                >
                  <Input defaultValue={initialValues?.unity}/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="u_price"
                  label={t("commitmentForms.form.section2.label4")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" format="money" min={0} defaultValue={initialValues?.u_price}/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label={t("commitmentForms.form.section2.label2")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} defaultValue={initialValues?.quantity}/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className=" u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="rad8"
                >
                  <span className="fs16 av-heavy"> {t("add.button")} </span>
                </Button>
              </Col>
            </Row>

            <Box></Box>
          </>
        </Form>
      </Modal>
    </Box>
  );
}
