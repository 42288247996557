export const steps = "steps";

export const statutes = "statutes";

export const services = "services";

export const roles = "roles";

export const procedures = "procedures";

export const paymentModes = "payment-modes";

export const documents = "documents";

export const categories = "categories";

export default {
    steps,
    statutes,
    status : statutes,
    services,
    roles,
    procedures,
    paymentModes,
    documents,
    categories,
}