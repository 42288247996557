import React from "$react";
import Select from "$layouts/Select";
import {fetch} from "$api/request";

export default function SelectProject({...props}){
    return <Select
        fetchItems = {()=>fetch("/api/projects")}
        //renderItem = {({item,index})=>`[${item.code}] ${}`}
        {...props}
    />
}