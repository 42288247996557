import React, { useState } from "react";
import {fetchBlob,fetchTicketPdfContent} from "$api";
import moment from "moment";
import i18n from "$i18n";
import {SelectProcedure} from "$enums";
import SelectProject from "./SelectProject";
import SelectZone from "./SelectZone";
import {VALIDATED,APPROVED,WAITING_PAYMENTS,REJECTED} from "$status";
import Box from "$components/Box";
import exportTicket from "$src/pdf/ticket/export";
import {isObj} from "$utils";
import ExportPdfButton from "./ExportPdfButton";
import {
  Form,
  Upload,
  Dropdown,
  Button,
  Modal,
  Row,
  Col,
  Select,
  message,
  Descriptions,
  Divider,
  Tag,
} from "antd";

import { useAddAttachmentTicket, useAddProcedure } from "../../../api/api";

import {
  ExportOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const supportedComponent = {
  procedure : "procedure",
  project : "project",
  zone : "zone",
}
export default function Description({
  ticket,
  addNewAttachment,
  updateTicket,
  t,
}) {
  const [visible, setVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [documentId, setDocument] = useState(null);
  const [project,setProject] = useState(null);
  const projectsRef = useState([]);
  const [procedure, setProcedure] = useState(null);
  const { mutateAsync: addAttachment } = useAddAttachmentTicket(
    ticket?.id,
    documentId
  );
  const isValidated = ticket?.status?.id === VALIDATED || ticket?.status?.id === APPROVED || ticket?.status?.id == WAITING_PAYMENTS;
  const isRejected = false;//ticket?.status?.id ===REJECTED;
  const { mutateAsync: addProcedure } = useAddProcedure(ticket?.id, procedure);

  const dum = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const handleChange = ({ fileList }) => {
    console.log(fileList);
    setFileList(fileList);
  };
  const activeFormRef = React.useRef(supportedComponent.procedure);
  const activeFormPropsRef = React.useRef({});
  const openForm = (form,props) => {
    if(form && form in components){
      activeFormRef.current = form;
    }
    activeFormPropsRef.current = {};
    if(isObj(props)){
      activeFormPropsRef.current = props;
    }
    setVisibleForm(true);
  };
  const components = {
    procedure : {
      Component : SelectProcedure,
      name : "procedure",
      label : "commitmentRequests.type.procedure",
      rules : [],
      props : {allowClear:true,optionFilterProp:"children"},
      submit : async (values)=>{
        setProcedure(values.procedure);
        return addProcedure();
      }
    },
    project : {
      Component : SelectProject,
      name : "project",
      label : "commitmentRequests.edit.project",
      rules : [],
      props : {allowClear:true,optionFilterProp:"children",onFetchItems:({items})=>{
        projectsRef.current = items;
      }},
      submit : async (values)=>{
        const project = (Array.isArray(projectsRef.current) && projectsRef.current || []).filter((p)=>p?.id == values.project)[0];
        if(!project) return;
        console.log(project," is project heeein ",ticket);
        setProject(project);
      }
    },
    zone : {
      Component : SelectZone,
      name : "zone",
      label : "commitmentRequests.edit.zone",
      rules : [],
      props : {allowClear:true,optionFilterProp:"children"},
    },
  }
  const AForm = activeFormRef?.current && activeFormRef.current  in components ? components[activeFormRef.current] : null;
  const submitForm = async (values) => {
    console.log(values, " are values to submit ",activeFormRef.current);
    if (!values) return;
    const com = components[activeFormRef.current];
    if(!com || typeof com.submit !='function') return;
    try {
      setLoading(true);
      const resp = await com.submit(values);
      if(isObj(resp) && typeof resp.id =='number'){
        updateTicket(resp);
      }
      //message.success("Modification reussie!!");
      setVisibleForm(false);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.warning("Erreur !");
    }

    setLoading(false);
  };

  const onSubmit = async (values) => {
    setDocument(values.doctype);

    const data = new FormData();

    data.append("file", values.upload.fileList[0].originFileObj);

    try {
      setLoading(true);
      const resp = await addAttachment(data);
      addNewAttachment(resp);
      setVisible(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const getFile = async (id) => {
    const result = await fetchBlob(`/api/tickets/${id}/print`);
    const url = window.URL.createObjectURL(
      new Blob([result.data], { type: "application/pvnd.ms-excel" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${ticket?.code}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <div className="flex ticket-details-description-status itemcenter u-mar-bottom-s">
        <h2 className="fW600 nomar">{ticket?.code} - </h2>
        <Tag
          className="u-mar-left-xs"
          color={
            ticket?.status.id == 0
              ? "#ff9e01"
              : ticket?.status.id == 1
              ? "#87d068"
              : ticket?.status.id == 2
              ? "#ff5500"
              : ticket?.status.id == 3
              ? "red"
              : ticket?.status.id == 5
              ? "#87d068"
              : "#108ee9"
          }
        >
          {i18nTObj(ticket,"status.name")}
        </Tag>
      </div>
      {isValidated && <div className="flex">
        <div
          className="flex itemcenter cursor rad4 btnfilter u-mar-right-s"
          style={{ height: "30px", width: "fit-content" }}
        >
              <ExportPdfButton
                   download={(d)=>fetchTicketPdfContent(ticket.id)}
              />
        </div>
      </div>}
      <Divider />
      <Descriptions column={3}>
        <Descriptions.Item label={t("commitmentRequests.detail.label0")}>
          {i18n.tObj(ticket,"service.name")}
        </Descriptions.Item>
        <Descriptions.Item label={t("commitmentRequests.detail.label1")}>
          {<Box flex justifyContent="start" alignItems="center" direction="row" flexWrap="wrap" className="tickets-details-project">
            {ticket?.demand.projects.map((r,key) => (
              <h4 className="ticket-description-project-code" key={key}>{r.code}</h4>
            ))}
            {isRejected && <span
              className="fs11 cursor u-mar-left-s coltext"
              onClick={() => openForm(supportedComponent.project)}
            >
              {t("reassignment.label")}
            </span>}
          </Box>}
        </Descriptions.Item>

        {
          <Descriptions.Item label={t("commitmentRequests.detail.label3")}>
            {<Box flex justifyContent="start" alignItems="center" direction="row" flexWrap="wrap" className="tickets-details-project">
              {i18n.tObj(ticket?.demand.affectation?.zone,"name")}
              {isRejected && <span
                className="fs11 cursor u-mar-left-s coltext"
                onClick={() => openForm(supportedComponent.zone)}
              >
                {t("reassignment.label")}
              </span>}
          </Box>}
          </Descriptions.Item>
        }

        <Descriptions.Item
          label={t("commitmentRequests.detail.label5")}
          style={{ paddingTop: 30 }}
        >
          {ticket &&
            ticket.demand?.initiator.firstname +
              " " +
              ticket.demand.initiator.lastname}
        </Descriptions.Item>

        {
          <Descriptions.Item label="Date" style={{ paddingTop: 30 }}>
            {ticket && moment(ticket?.date).format("D MMM-YY")}
          </Descriptions.Item>
        }

        {
          <Descriptions.Item
            label={t("commitmentRequests.detail.label4")}
            style={{ paddingTop: 30 }}
          >
            {i18n.tObj(ticket,"procedure.name")} <br />
            {!isValidated && <span
              className="fs11 cursor u-mar-left-s coltext"
              onClick={() => openForm(supportedComponent.procedure)}
            >
              {t("reassignment.label")}
            </span>}
          </Descriptions.Item>
        }

        {
          <Descriptions.Item
            label={t("commitmentRequests.detail.label6")}
            style={{ paddingTop: 30 }}
          >
            <div className="flex column">
              {ticket?.demand.activities.map((a,key) => (
                <span key={key}>
                  <h4>{i18n.tObj(a,"name")}</h4>
                </span>
              ))}
            </div>
          </Descriptions.Item>
        }
      </Descriptions>
      <Modal
        width={400}
        onCancel={() => setVisibleForm(false)}
        open={visibleForm && AForm}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.edit.procedure")}{" "}
        </h2>

        <Form layout="vertical" onFinish={submitForm}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={AForm.name}
                label={t(AForm.label)}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <AForm.Component {...AForm.props} {...activeFormPropsRef.current}/>
              </Form.Item>
            </Col>
          </Row>

          <div className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisibleForm(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t("commitmentRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.modify.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
