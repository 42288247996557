import React, { useState, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Layout, Spin } from "antd";
import LayoutPage from "../layout";
import {userInfos } from "../../store";
import {useGetMetricsForDashboard} from "$api/api";
import { useTranslation, Trans } from "react-i18next";
import Box from "$components/Box";
import SimpleGrid from "$components/SimpleGrid";

const { Content } = Layout;

export default function Home() {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.resolvedLanguage);
  const u = useRecoilValue(userInfos);
  const history = useHistory();
  const {
    data: metrics,
    error,
    isLoading,
    refetch,
  } = useGetMetricsForDashboard({}, {});

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "areaspline",
      backgroundColor: "transparent",
    },

    tooltip: {
      shared: true,
      valueSuffix: " Fcfa",
    },
    credits: {
      enabled: false,
    },

    title: {
      text: "",
      enabled: false,
    },

    legend: {
      enabled: false,
    },

    yAxis: {
      gridLineColor: "rgba(0,0,0,0.05)",
      allowDecimals: false,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "rgba(0,0,0,0.8)",
        },
      },
    },

    xAxis: {
      categories: ["0", "1", "2", "3", "4", "5", "6", "7"],
      labels: {},
    },
    series: [
      {
        data: [0, 5, 4, 6, 10, 8],
      },
    ],
  });
  const itemsProps = {border:"1px solid #d1d7de",bgColor:"white",p:"20px",m:"5px",borderRadius:"12px"}
  return (
    <LayoutPage>
      <>
        <Box className="u-pad-horizontal-l nopadres">
          <Box className="welcome-container flex itemcenter  justbtw nopadres  flexendres  ">
            <Box className="textcenterres noneres">
              <h2 className=" u-mar-right-s colhead av-roman u-mar-top-xxs fs20 fs18res u-mar-bottom-xxs nomarres">
                {" "}
                {t("dashboard.content.welcome")},{" "}
                <span className="av-heavy"> {u.firstname}</span>
              </h2>
              <span className="fs15 fs12res colhead">
                {" "}
                {moment().format("dddd , Do MMMM  YYYY")}{" "}
              </span>
            </Box>

            <Box className="flex itemcenter ">
              <Box className="separator nonedisplayres"></Box>
            </Box>
          </Box>

          <Box className="grille blokdash flex  itemcenter column ">
            <Box className=" nopadres u-mar-top-m u-mar-bottom-s hauto rad16">
              <Box className=" flex  order2  itemcenter justbtw w100">
                <SimpleGrid className=" w100 dashboard-content-container">
                  <Box {...itemsProps} className="block1 dashboard-item-container rad8res">
                    <Box className="w100 created-requests-container">
                      <h3
                        className="nomar colhead av-medium "
                        style={{ paddingBottom: 5, fontWeight: "bold" }}
                      >
                        {" "}
                        {t("dashboard.content.requestsCreated")}{" "}
                      </h3>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.commitments.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/tickets");
                          }}
                        >
                          {isLoading ? "--" : metrics?.created_demand}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.travel.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/movement_request");
                          }}
                        >
                          {isLoading ? "--" : metrics?.created_movement}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {" "}
                          {t("dashboard.content.payment.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/payment-request");
                          }}
                        >
                          {isLoading ? "--" : metrics?.created_payment}
                        </h4>
                      </Box>
                      {/*{isLoading ?
                                                <Box className="placeholder u-mar-right-s u-mar-top-m "
                                                     style={{height: '18px', width: '120px'}}>
                                                    <Box className="animated-background"></Box>
                                                </Box>
                                                :
                                                <h2 className=" u-mar-top-s u-mar-bottom-xs-res  fs20 fs15res fW500 colhead ">
                                                    {created_ticket}
                                                </h2>
                                            }*/}
                    </Box>
                  </Box>
                  <Box {...itemsProps} className="block1 dashboard-item-container rad8res">
                    <Box className="w100">
                      <h3
                        className="nomar colhead av-medium "
                        style={{ paddingBottom: 5, fontWeight: "bold" }}
                      >
                        {t("dashboard.content.requestsValidated")}
                      </h3>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.commitments.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/tickets?status=5");
                          }}
                        >
                          {isLoading ? "--" : metrics?.approved_demand}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.travel.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/movement_request?status=1");
                          }}
                        >
                          {isLoading ? "--" : metrics?.approved_movement}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.payment.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/payment-request?status=1");
                          }}
                        >
                          {isLoading ? "--" : metrics?.approved_payment}
                        </h4>
                      </Box>
                    </Box>
                  </Box>
                  <Box {...itemsProps} className="block1 dashboard-item-container rad8res">
                    <Box className="w100">
                      <h3
                        className="nomar colhead av-medium "
                        style={{ paddingBottom: 5, fontWeight: "bold" }}
                      >
                        {t("dashboard.content.requestsRejected")}{" "}
                      </h3>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium">
                          {t("dashboard.content.commitments.stat")}:{" "}
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/tickets?status=2");
                          }}
                        >
                          {isLoading ? "--" : metrics?.rejected_demand}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.travel.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/movement_request?status=2");
                          }}
                        >
                          {isLoading ? "--" : metrics?.rejected_movement}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.payment.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push("/payment-request?status=2");
                          }}
                        >
                          {isLoading ? "--" : metrics?.rejected_payment}
                        </h4>
                      </Box>
                    </Box>
                  </Box>
                  <Box {...itemsProps} className="block1 dashboard-item-container rad8res">
                    <Box className="w100">
                      <h3
                        className="nomar colhead av-medium "
                        style={{ paddingBottom: 5, fontWeight: "bold" }}
                      >
                        {t("dashboard.content.requestsToProcess")}{" "}
                      </h3>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.commitments.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push(`/tickets?checker_id=${u.id}`);
                          }}
                        >
                          {isLoading ? "--" : metrics?.assigned_demand}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.travel.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push(
                              `/movement_request?checker_id=${u.id}`
                            );
                          }}
                        >
                          {isLoading ? "--" : metrics?.assigned_movement}
                        </h4>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="nomar colhead av-medium ">
                          {t("dashboard.content.payment.stat")}:
                        </h4>
                        <h4
                          className="cursor"
                          onClick={() => {
                            history.push(`payment-request?checker_id=${u.id}`);
                          }}
                        >
                          {isLoading ? "--" : metrics?.assigned_payment}
                        </h4>
                      </Box>
                    </Box>
                  </Box>
                </SimpleGrid>
              </Box>
              <Box {...itemsProps} className="block1 dashboard-item-container cardvente u-pad-horizontal-s  u-mar-top-l  u-pad-top-s rad12 rad8res noneres">
                <h2 className="fs20 av-medium colhead"> Graph.... </h2>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </Box>
              <Box className="flex rescolumn"></Box>
            </Box>
          </Box>
        </Box>
      </>
    </LayoutPage>
  );
}
