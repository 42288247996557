import { roles } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchRoles = (...args)=>{
    return fetch(roles,...args);
}

export const getRoles = (...args)=>{
    return get(roles,...args);
}

export const SelectRole = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {roles}
        ref = {ref}
    />
});

SelectRole.displayName = "SelectRole";

export const ADMIN = 0;

export const RAF = 1;

export const ACCOUNTING_HR_MANAGER = 2;

export const LOGISTIC_MANAGER = 3;

export const COORDO_PROGRAM = 4;

export const ROLE_MANAGER = 5;

export const USER = 6;

export default {
    ADMIN,
    RAF,
    ACCOUNTING_HR_MANAGER,
    LOGISTIC_MANAGER,
    COORDO_PROGRAM,
    ROLE_MANAGER,
    USER,
    Select : SelectComponent,
    fetch : fetchRoles,
    get : getRoles,
}

export const ALL_ROLES = [
    ADMIN,
    RAF,
    ACCOUNTING_HR_MANAGER,
    LOGISTIC_MANAGER,
    COORDO_PROGRAM,
    ROLE_MANAGER,
    USER
]

export const ADMIN_ROLES = [ADMIN,RAF];