/***
 *  @used to handle notification on application
 *  @see : https://fkhadra.github.io/react-toastify/introduction
 *  @see : https://fkhadra.github.io/react-toastify/api/toast for toast options
 */
 import React from "react";
 import {isNonNullString,defaultNumber,defaultObj,defaultVal,isObj,defaultStr} from "$utils";
 import getTextContent from "$react/getTextContent";
 import {toast } from 'react-toastify';
 import i18next from 'i18next';

 const messageRefs = {};
 const messageTimeouts = {};

 export const TYPES = {
     info : "info",
     error : "error",
     success : "success",
     warning : 'warn',
 }

  /**** supprted positions :
  *   POSITIONS.TOP_RIGHT || top-right, //default position 
  *   POSITIONS.TOP_CENTER || top-center,
  *   POSITIONS.TOP_LEFT || top-left,
  *   POSITIONS.BOTTOM_LEFT || bottom-left,
  *   POSITIONS.BOTTOM_CENTER || bottom-center,
  *   POSITIONS.BOTTOM_RIGHT || bottom-right
  */
  export const POSITIONS = toast.POSITION;

 /*** send application notification
  * @param {string|object} message
  *     if message is object then, all others parameters like, type, title, are picked up from message, settings will be the rest part of message
  * @param {string} type, message type : one of type [error,info,success,warning,toast]
  * @param {object} settings, notification settings
  */
 export const notify = function(message,type,title,settings){
     type = defaultStr(type).toLowerCase();
     if(isObj(message)){
         settings = message;
         type = type || settings.type;
         title = title || settings.title || undefined;
         message = settings.message || settings.msg;
     }
     if(isObj(title)){
         settings = isObj(settings)? settings : title;
         type = type || settings.type;
         title = settings.title;
     }
     settings = defaultObj(settings);
     type = defaultStr(type,settings.type);
     if(type =="warning"){
         type = TYPES.warning;
     } else if(!TYPES[type]){
         type = TYPES.info;
     }
     if(!(message)) message = ''
     if(!(title)) title = ''
     message = defaultVal(message,settings.message,settings.msg);
     if(isNonNullString(message)){
         message = message.trim();
     }
     if(!message) return;
     settings.title = defaultVal(title,settings.title);
     if(isNonNullString(title)){
         title = title.trim();
     }
     let _t = defaultStr(settings.type,type);
     let defInterval = 5000;
     switch(_t.toLowerCase().trim()){
         case TYPES.error:
             message = defaultVal(message,settings.error,settings.errorText)
             defInterval = 12000;
             break;
         case TYPES.warning:
             defInterval = 10000;
             break;
     }
     if(!title){
         if(type ===TYPES.error){
             title = "Erreur";
         }else if(type ==TYPES.warning){
             title = "Alerte";
         } else title = defaultStr(TYPES[type]).toUpperCase();
     }
     const messageText = getTextContent(message);
     let interval = 5000;//defaultNumber(settings.autoClose,settings.interval,settings.timeout,defInterval);
     if(Math.abs(interval,defInterval)<=200){
        //we define the default interval according to the length of the message
        interval =5000;// Math.max(defInterval,(messageText.length*100));
     }
     const cb = toast[type] || toast['info'];
     /***used to avoid duplication on messages */
     const mId = type+messageText+getTextContent(title);
     if(messageRefs[mId]) return null;//this notification already sent
     messageRefs[mId] = true;
     clearTimeout(messageTimeouts[mId]);
     return cb ? cb(message,{
        position : POSITIONS.TOP_CENTER,
        ...settings,
        message : title,
        description : message,
        autoClose : typeof settings.autoClose =='boolean'? settings.autoClose : interval,
        onClose : (...args)=>{
           settings.onClose && settings.onClose(...args);
           messageTimeouts[mId] = setTimeout(()=>{
            delete messageRefs[mId];
           },5000);
        },
    }) : null;
 }
 export const error = (message,title,set)=>{
     return notify(message,TYPES.error,title,set)
 }
 
 export const success = (message,title,set)=>{
     return notify(message,TYPES.success,title,set)
 }
 
 export const info = (message,title,set)=>{
     return notify(message,TYPES.info,title,set)
 }
 
 export const warning = (message,title,set)=>{
     return notify(message,"warning",title,set)
 }
 
 export const sendDesktop = (message,options)=>{
    if (!window?.Notification) {
        return Promise.reject({message:i18next.t('notiificationNotSupportedByBrowser')});
    } else {
        const cb = ()=>{
            options = isObj(options)? options : {};
            return new Notification(message,options);
        }
        // check if permission is already granted
        if (Notification.permission === 'granted') {
            // show notification here
            return cb();
        } else {
            // request permission from user
            return Notification.requestPermission().then(function(p) {
               if(p === 'granted') {
                   return cb(p);
               } else {
                   throw i18next.t('userNotGrantedNotifications');
               }
            });
        }
    }
 }
 export default {error,success,warning,info,toast:info,notify,sendDesktop};
 
 