export default {
    "auth.signIn.youWillRedirectDueToSignIn" : "Vous n'êtes pas connecté et avez été rédirigé vers la page de connexion",
    "auth.signIn.forgotPassword" : "mot de passe oublié",
    "auth.signIn.login" : " Connexion",
    "auth.signIn.enterOTP" : "Entrez le code que vous avez réçu par mail",
    "auth.signIn.checkOTP" : "Vérifier",
    "auth.signIn.returnToLogin" : "Retour au login",
    "auth.signIn.otpChecking" : "Vérification OTP",
    "auth.signIn.enterOTP" : "Entrer l'OTP",
    
    "auth.notAlowed.notAllowed" : "Non autorisé",
    "auth.notAlowed.header" : "Désolé, Vous n'êtes pas autorisé à visualiser le contenu demandé.",
    "auth.notAlowed.homepage" : "Page D'acceuil",
    "auth.notAlowed.returnTo" : "Vous pouvez retourner à la",
    "auth.notAlowed.content" : "Si vous ne parvenez pas à visualiser le contenu demandé"
}