import React from "$react";
import {classNames,isObj,isNonNullString} from "$utils";
import Box from "$components/Box";
import Dimensions from "$dimensions";
import {NavLink} from "react-router-dom";
import { Menu, Layout, Divider, Tabs } from "antd";
import sidebarItems from "./items";
import { hasRoles } from "$auth/roles";

import { useTranslation} from "react-i18next";

import "./nav.css";

export default function LeftSide() {
  const { t } = useTranslation();
  const Sider = Layout.Sider;
  const isMobile = Dimensions.isMobileOrTabletMedia();
  const [collapsed, setCollapsed] = React.useState(isMobile);
  const dim = Dimensions.get("window");
  const collapsedRef = React.useRef(collapsed);
  collapsedRef.current = collapsed;
  const changeDimRef = React.useRef(null);
  const {width} = dim;
  const menuItems = [];
  sidebarItems.map((item)=>{
      if(!isObj(item)  || !isNonNullString(item.label) || !hasRoles(item.roles)) return;
      const className = `sidebar-nav-item-${item.key}`
      menuItems.push({
        key : item.key,
        label : <Box as = {NavLink} to={item.path} activeClassName="selected" exact flex className = {classNames(className,"itemcenter")}>
             {React.isValidElement(item.icon) && item.icon}
            <span className="av-medium  colnav fs14">
              {t(item.label)}{" "}
            </span>
        </Box>
      })
  });
  React.useEffect(()=>{
    const onChangeSize = ()=>{
        clearTimeout(changeDimRef.current);
        changeDimRef.current = setTimeout(()=>{
          const isMobile = Dimensions.isMobileOrTabletMedia();
          if(!isMobile && collapsedRef.current){
            setCollapsed(false);
          } else if(isMobile && !collapsedRef.current){
            setCollapsed(true);
          }
        },10);
    }
    Dimensions.addEventListener("change",onChangeSize);
    return ()=>{
      Dimensions.removeEventListener("change",onChangeSize);
    }
  },[]);
  const collapsedWidth = collapsed ? 0 : Math.max(Math.min(16*width/100),Math.min(250,width),250);
  return (
    <Sider
      className={classNames(isMobile?"mobile":"desktop",collapsed?"collased":"not-collapsed","site-layout-background bgwhite   flex column  justbtw h100")}
      width={collapsedWidth}
      breakpoint="lg"
      zeroWidthTriggerStyle={{}}
      collapsedWidth={0}
      collapsible
      collapsed={collapsed}
      trigger = {!isMobile?null:undefined}
      defaultCollapsed = {collapsed}
      onCollapse={(ncollapsed)=>{
        if(ncollapsed !== collapsedRef.current){
          if(isMobile){
            setCollapsed(ncollapsed);
          }
        }
      }}
    >
     <Box className="sidebar-content">
       <Box pt="10px" className="bgwhite sidebar-logo-container" px="16px">
          <Box className="flex itemcenter cursor u-mar-top-xs">
            <img src="/logo.png" alt="logo" width={100} />
          </Box>
          <Divider className="u-mar-vertical-s" />
        </Box>
        <Menu
          mode="inline"
          defaultOpenKeys={["sub1"]}
          style={{ borderRight: 0 }}
          className="bgwhite menuside grille u-pad-vertical-s"
          items={menuItems}
        />
     </Box>
    </Sider>
  );
}
