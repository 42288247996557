import React, { Fragment, useEffect, useState, useCallback } from "react";
import moment from "moment";
import "moment/locale/fr";

import { Col, Row, Spin } from "antd";
import FormRequestPayment from "./form/form";

import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";

export default function AddRequestPayment({ close, initiator, t }) {
  const [tickets, setTickets] = useState(null);

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>

        <h2 className="fW600"> {t("paymentRequests.form.title")}</h2>
      </div>

      <Spin tip="Chargment..." size="large" spinning={false}>
        <Row
          gutter={32}
          className=" w100  u u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres "
        >
          <Col
            span={22}
            offset={1}
            className=" w100  u-pad-top-s u-pad-horizontal-s   h100 blok rad16 u-mar-top-xl-res nopadres noradres "
          >
            <div
              className="flex mauto w90 column u-mar-top-xl  bgwhite  nomarres u-pad-s-res"
              style={{ height: "max-content" }}
            >
              <FormRequestPayment userId={initiator} close={close} t={t} />
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
