import React, { Fragment, useEffect,useRef, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Button,
  Input,
} from "antd";
import ChakraInput from "$components/Input";

export default function Step2Form({ chargeItems,onInputBlur,onInputFocus, spending, t }) {

  const [visible, setVisible] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const valuesRef = useRef({});

  const [productcart, setProductcart] = useState(spending);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setProductcart(spending);
    chargeItems(spending);
  }, [spending]);

  useEffect(() => {
    gettotal();
  }, [productcart]);

  const addProd = (prod) => {
    setProductcart(productcart.concat(prod));

    chargeItems(productcart.concat(prod));
  };

  const gettotal = () => {
    var total = 0;
    for (var i = 0, _len = productcart?.length; i < _len; i++) {
      productcart[i]["quantity"] != undefined
        ? (total += productcart[i]["quantity"] * productcart[i]["price"])
        : (total += 1 * productcart[i]["price"]);
    }

    setTotal(total);
  };

  const removeProd = (id) => {
    const objIndex = productcart.findIndex((obj) => obj.id == id);

    /*   let obji = products.findIndex((obj => obj._id == id));
  products[obji].num = 1 */

    //setProducts(products)

    let newcart = productcart.filter((u, i) => i != objIndex);
    setProductcart(newcart);

    chargeItems(newcart);
  };

  const onChange = ({row,value,index}) => {
      if(value >=0){
        productcart[index].quantity = value;
        setProductcart(productcart.concat());
        chargeItems(productcart.concat());
      }
  };

  const onChangePrice = ({row,value,index}) => {
    console.log(index," is index",row," is row ",value," isddddd");
    if (value >= 0) {
      productcart[index].price = value;

      setProductcart(productcart.concat());

      chargeItems(productcart.concat());
    }
  };

  function onSubmit(values) {
    values.item = {};

    addProd(values);
    setVisible(false);
    setShowSub(false);
  }
  const chakraInputProps = {borderRadius :"0",border: "0px!important",_hover : {borderRadius:0,borderColor:0}};
  return (
    <div className="appro rel">
      <div className="flex justbtw w100">
        <h3 className="fW600 nomar">items</h3>

        {/* <h3 className="fW600 coltext cursor nomar" onClick={()=>setVisible(true)}> <PlusCircleFilled /> Ajouter un item</h3>
         */}
      </div>
      <div className="w100">
        <div
          className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s"
          style={{
            borderTop: "1px #f2f2f2 solid",
            borderBottom: "1px #b3b3b3 solid",
          }}
        >
          <span className="grille4 fW500">
            {" "}
            {t("paymentRequests.form.section2.label0")}{" "}
          </span>
          <span className="grille3 fW500">
            {" "}
            {t("paymentRequests.form.section2.label1")}{" "}
          </span>
          <span className="grille07 fW500 right">
            {" "}
            {t("paymentRequests.form.section2.label2")}{" "}
          </span>
          <span className="grille09  fW500 right">
            {" "}
            {t("paymentRequests.form.section2.label3")}{" "}
          </span>
          <span className="grille09  fW500 right">
            {" "}
            {t("paymentRequests.form.section2.label4")}{" "}
          </span>
          <span className="grille  fW500 right">
            {" "}
            {t("paymentRequests.form.section2.label5")}{" "}
          </span>
        </div>

        {productcart?.length != 0 && (
          <Fragment>
            <ul className="cart-list" style={{ height: "auto" }}>
              {productcart?.map((row, id) => (
                <li
                  key={id}
                  className="section-cart flex itemcenter justbtw  u-pad-left-xs "
                >
                  <span className="grille4 av-heavy fs14 ">
                    {row.description}
                  </span>

                  <span className="grille3 av-heavy fs14 ">
                    {i18nTObj(row.service)}
                  </span>

                  <ChakraInput
                    className="c-input qte grille07 right"
                    defaultValue={row.quantity ? row.quantity : 1}
                    id={id}
                    type = "number"
                    onChange={(a)=>{
                      onChange({...a,row,index:id});
                    }}
                    {...chakraInputProps}
                  />

                  <span className="c-input qte grille07 right">
                    {" "}
                    {row.unity}{" "}
                  </span>

                  <ChakraInput
                    className="c-input qte grille09 right"
                    defaultValue={row.price}
                    type = "number"
                    format = "money"
                    id={id}
                    onChange={(a)=>onChangePrice({...a,row,index:id})}
                    {...chakraInputProps}
                  />

                  <span className="av-heavy fs14 grille right">
                    {Intl.NumberFormat("fr-FR").format(
                      (row.quantity ? row.quantity : 1) * row.price
                    )}
                  </span>
                  {/* <span className="abs" style={{right:-30}}>
                               <CloseOutlined  className="rmv cursor fs15" onClick = {()=>removeProd(row.id)} />
                           </span> */}
                </li>
              ))}
            </ul>
          </Fragment>
        )}

        <div className="flex  w100 flexend">
          <div
            className="w40"
            style={{ border: "1px #d9d9d9 solid", borderTop: 0 }}
          >
            <div className="flex justbtw  bggray u-pad-xs fW600 ">
              {" "}
              <span> {t("paymentRequests.form.section2.label6")} </span>{" "}
              <span>
                {Intl.NumberFormat("fr-FR").format(parseInt(total))} Fcfa
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={700}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 350 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {t("commitmentForms.form.section2.popup.title")}
        </h2>

        <Form
          layout="vertical"
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmit}
        >
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label={t("commitmentForms.form.section2.label0")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="unity"
                  label={t("commitmentForms.form.section2.label3")}
                >
                  <Input/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="price"
                  label={t("commitmentForms.form.section2.label4")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0}/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label={t("commitmentForms.form.section2.label2")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0}/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="rad8"
                >
                  <span className="fs16 av-heavy">
                    {" "}
                    {t("validate.button")}{" "}
                  </span>
                </Button>
              </Col>
            </Row>

            <div></div>
          </>
        </Form>
      </Modal>
    </div>
  );
}
