import {isObj} from "$utils";
import Colors from "./colors";
import styled  from "./styled";
import updateCss from "./updateCss";
import defTheme,{lightColors,darkColors} from "./defaultTheme";
import flattenStyle  from "./flattenStyle";
import {extendObj} from "$utils";
import {breakpoints} from "$dimensions/utils";

export const ALPHA = 0.6;

const isValidColors = colors => isObj(colors) && (Colors.isValid(colors.primary) || Colors.isValid(colors.primaryColor)) ? true : false;
const isValid = theme => isObj(theme) && isValidColors(theme.colors) ? true : false;

/*** update colors from param
 * @param {object} theme object or simply the colors taken in parameter, valid according to the function isValid
 * @return {object} theme whose colors have been changed
    
    ---- update current theme: 
 *  theme object : {
    primary - primary color for your app, usually your brand color.
    secondary - secondary color for your app which complements the primary color.
    background - background color for pages, such as lists.
    surface - background color for elements containing content, such as cards.
    text - text color for content.
    disabled - color for disabled elements.
    placeholder - color for placeholder text, such as input placeholder.
    backdrop - color for backdrops of various components such as modals.
    notification - background color for badges
    error : error color for notification
    warning : warning color for notification
    info : info color for notification

    each color has its variant representing the text color when it is in background
    example 
        - primaryText : represent the text colors on primary surface
        - secondaryText : the text colors on secondary surface
        - errorText : the text color on error surface
        ...and so on
    all of theses colors has also hover variant, representing the hover backgroundColor
 * }
 * 
 * @return {object} an updated theme object
*/
export const updateColors  = (theme,force)=>{
    if(force !== true){
        const cTheme = getTheme();
        if(isValidColors(theme)){
            theme = {...cTheme,colors:{...cTheme.colors,...theme}};
        }
    }
    if(!isValid(theme)) return theme;
    const dColors = theme.dark ? darkColors : lightColors;
    const colors = theme.colors = {...dColors,...theme.colors};
    ///la couleur secondaire
    colors.primary = Colors.isValid(colors.primary)? colors.primary : cTheme.primary;
    colors.secondary = Colors.isValid(colors.secondary)? colors.secondary : cTheme.colors.secondary;
    colors.primaryText = Colors.isValid(colors.primaryText)? colors.primaryText : Colors.getContrast(colors.primary);
    colors.secondaryText = Colors.isValid(colors.secondaryText)? colors.secondaryText : Colors.getContrast(colors.secondary);
    colors.surfaceText = Colors.getContrast(colors.surface);
    colors.info = Colors.isValid(colors.info)? colors.info : lightColors.info;
    colors.warning = Colors.isValid(colors.warning)? colors.warning : lightColors.warning;
    colors.error = Colors.isValid(colors.error)? colors.error : lightColors.error;
    colors.success = Colors.isValid(colors.success)? colors.success : lightColors.success;
    ['error','success','info','warning'].map((c)=>{
        const key = c+"Text";
        colors[key] = Colors.isValid(colors[key])? colors[key] : Colors.getContrast(colors[c])
    });
   
    if(!Colors.isValid(colors.primaryOnSurface)){
        if(Colors.getContrast(colors.primary) === Colors.getContrast(colors.surface)){
            colors.primaryOnSurface = colors.primary
        } else {
            colors.primaryOnSurface = colors.secondary;
        }
    }
    ['primary',"secondary","surface","background","error","warning","success","info"].map((c)=>{
        const cc = c+"Hover";
        if(!colors[cc] || !Colors.isValid(colors[cc])){
            const col  = colors[c];
            //const brightness = Colors.getBrightness(col);
            colors[cc] = Colors.isLight(col,75)? Colors.darken(col) : Colors.lighten(col);
        }
    })
    if(!Colors.isValid(colors.secondaryOnSurface)){
        if(Colors.getContrast(colors.secondary) === Colors.getContrast(colors.surface)){
            colors.secondaryOnSurface = colors.secondary
        } else {
            colors.secondaryOnSurface = colors.primary;
        }
    }
    return theme;
}

export const defaultTheme = updateColors(defTheme,true);

export {Colors,lightColors,darkColors};

const Theme = {
    current : defaultTheme,
}

export const getTheme = x => Theme.current ? Theme.current : defaultTheme;

export const getColors  =  x => getTheme().colors;

export const updateTheme = (theme)=>{
    theme = updateColors(theme);
    if(isValid(theme)){
        Object.map(defaultTheme,(v,i)=>{
            if(i !=='colors' && !(i in theme)){
                theme[i] = v;
            }
        })
        updateCss(theme);
        Theme.current = theme;
    }
    extendObj(breakpoints,theme.breakpoints);
    theme.breakpoints = breakpoints;
    return theme;
} 

export const isDark = x => Theme.current.dark ? true : false;
export const isLight = x=> !isDark(x);


const theme = {
    get colors () {return Theme.current.colors},
    get dark () { return Theme.current.dark},
    get isDark () { return isDark},
    get isLight (){ return isLight},
    get roundness () { return Theme.current.roundness},
    get flattenStyle(){return flattenStyle},
    get Colors (){return Colors},
    get styled(){return styled;},
}


export default theme;

export  {theme};

export {styled,flattenStyle};