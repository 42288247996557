import {fetch as rFetch} from "$api/request";
import Select from "$components/Select";
import { useState,useEffect,forwardRef } from "react";
import i18n from "$i18n";
import { Spinner } from '@chakra-ui/react'
import Box from "$components/Box";
import {classNames,isNonNullString} from "$utils";
import types from "./types";

export const _enumsRef = {}

export const getType = (type)=>{
    if(!isNonNullString(type)) return "";
    type = type.toLowerCase().trim();
    if(types[type]){
        return type;
    }
    return "";
}

export const fetch = (type,force)=>{
    const all = get();
    type = getType(type);
    if(!type){
        console.error("Invalid enum type",type);
        return Promise.reject({message:"Invalid enum type "});
    }
    if(!force && !all.length){
        force = true;
    }
    if(!force) return Promise.resolve(all);
    return rFetch("/api/{0}".sprintf(type)).then((r)=>{
        _enumsRef[type] = r;
        return r;
    }).catch(e=>{
        console.log(e," is fetching _enum");
        throw e;
    });
}

export const get = (type)=>{
    type = getType(type);
    if(!type){
        return [];
    }
    return Array.isArray(_enumsRef[type]) && _enumsRef[type] || [];
}
export default {
    fetch,
    get,
}

const SelectComponent = forwardRef(({optionProps,items,fetch:fetchItems,render,type,containerProps,fetchItemsMutator,onFetchItems,loadingProps,force,...props},ref)=>{
    const defType = type;
    optionProps = Object.assign({},optionProps);
    type = getType(type);
    if(!type){
        console.error("Invalid enum type of select component ",type," and props ",props," for type ",defType,". Type's field must be one of the list ",types);
        return null;
    }
    containerProps = Object.assign({},containerProps);
    loadingProps = Object.assign({},loadingProps);
    const [_enums,setEnums]= useState(Array.isArray(items)? items : []);
    const [isLoading,setIsLoading] = useState(fetchItems !== false ? true : false);
    useEffect(()=>{
        if(fetchItems !== false){
            fetch(type,typeof force =="boolean"? force : true).then((i)=>{
                if(typeof onFetchItems =='function'){
                    onFetchItems(i);
                }
                if(typeof fetchItemsMutator ==='function'){
                    const it = fetchItemsMutator(i);
                    if(Array.isArray(it)){
                        i = it;
                    }
                }
                setEnums(i);
            }).finally((e)=>{
                setIsLoading(false);
            });
        }
    },[]);
    useEffect(()=>{
        if(fetchItems !== false && Array.isArray(items)){
            setEnums(items);
        }
    },[items])
    return <Box flex={isLoading} gap={isLoading?"10px":undefined} minW="250px" {...containerProps}  className={classNames(containerProps.className,"select-_enum-container")}>
        {typeof render =='function'? render({...props,optionProps,enums:_enums},ref) : <Select multiple={false} {...props} ref={ref}>
          {(Array.isArray(_enums) && _enums || []).map((_enum,key)=>{
              return <Select.Option key={_enum?.id||key} value={_enum.id}>
                {i18n.tObj(_enum,"name")}
              </Select.Option>
          })}
        </Select>} 
        {isLoading ? <Spinner {...loadingProps}/> : null}
    </Box>
});

SelectComponent.displayName = "SelectEnumComponent";

export {SelectComponent as Select,SelectComponent as SelectEnum,SelectComponent};