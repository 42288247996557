/*** porwerfull session library
 * can use LocalStorage when browser support it or cookies if not
 * usage : get : (key)=>any, key {string}
 *         set : (key,value)=>Promise<any>, key {string}; value {any}
 */
const hasLocalStorage =
  window.localStorage &&
  window.localStorage.getItem &&
  window.localStorage.setItem;
export default hasLocalStorage
  ? require("./localstorage").default
  : require("./web/cookies").default;
