import "$utils/string";
import isNonNullString from "$utils/isNonNullString";
import session from "$session";

const configRef = { current: {} };

const sessionDatatKey = "app-config-session-data-key";

export const getConfig = (x) =>
  typeof configRef.current == "object" && configRef.current
    ? configRef.current
    : {};

export const setConfig = (configValue) => {
  if (
    typeof configValue == "object" &&
    configValue &&
    !Array.isArray(configValue)
  ) {
    configRef.current = {
      ...configRef.current,
      ...configValue,
    };
  }
};
export const setConfigValue = (key, value) => {
  const conf = getConfig();
  if (isNonNullString(key)) {
    if (key in config && key !== "current") {
      config[key] = value;
    } else {
      conf[key] = value;
    }
  }
  return conf;
};

export const getSessionData = (key) => {
  const d = session.get(sessionDatatKey);
  const sData = d && typeof d == "object" && !Array.isArray(d) ? d : {};
  if (isNonNullString(key)) return sData[key];
  return sData;
};
export const setSessionData = (key, value) => {
  let data = getSessionData();
  if (key && typeof key == "object" && !Array.isArray(key)) {
    data = { ...data, ...key };
  } else if (isNonNullString(key)) {
    data[key] = value;
  }
  session.set(sessionDatatKey, data);
  return data;
};

export const getConfigValue = function () {
  const conf = getConfig();
  const args = Array.prototype.slice.call(arguments, 0);
  let hasKey = false;
  for (let i in args) {
    const a = args[i];
    if (isNonNullString(a)) {
      hasKey = true;
      if (conf.hasOwnProperty(a)) {
        return conf[a];
      }
    }
  }
  return hasKey ? undefined : conf;
};

const config = {
  get current() {
    return getConfig();
  },
  set current(configValue) {
    return setConfig(configValue);
  },
  get get() {
    return getConfigValue;
  },
  get getValue() {
    return getConfigValue;
  },
};

export default config;
