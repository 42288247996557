import React from "$react";
import Select from "$components/Select";
import {classNames,defaultStr,isObj} from "$utils";
const { Option } = Select;
export default function SelectComponentLayout({fetchItems,renderItem,onFetchItems,itemValue,className,multiple,...rest}){
    const [items,setItems] = React.useState([]);
    const fetch =()=>{
        if(typeof fetchItems =='function'){
            return fetchItems().then((r)=>{
                const items = Array.isArray(r)?r : Array.isArray(r?.items) ? r.items : Array.isArray(r?.data) ? r.data : [] ;
                if(typeof onFetchItems =='function'){
                    onFetchItems({...(isObj(r)? r:{}),items})
                }
                setItems(items);
            }).catch((e)=>{
                console.log(e," fetching items of select component layout")
            })
        }
        return Promise.reject({message:"fetchItems not defined as a function"})
    }
    React.useEffect(()=>{
        fetch();
    },[])
    const rItem = ({item,...rest})=>{  
        if(typeof renderItem =='function') return renderItem({item,items,...rest});
        const code = defaultStr(item.code);
        return `${code ? `[${code}] `:""}${i18nTObj(item)}`;
    }
    const itValue = ({item,...rest})=>{
        if(typeof itemValue =='function') return itemValue({item,items,...rest});
        return "id" in item ? item.id : item.code || undefined;
    }
    return <Select
        showSearch
        className = {classNames("select-component-layout",className)}
        allowClear
        multiple = {multiple}
        optionFilterProp="children"
        {...rest}
      >
        {items.map((item, index) => {
            const value = itValue({item,index});
            const label = rItem({item,index});
            return (
                <Option value={value} key={`${value}-${index}`}>
                  {label}
                </Option>
              );
        })}
    </Select>
}