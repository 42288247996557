import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "$components/Table";
import {
  Button,
  Spin,
  Drawer,
  Input,
  Tag,
  Breadcrumb,
  Menu,
  Dropdown,
} from "antd";
import { useGetPaymentRequest,RETRY } from "../../api/api";
import { CaretDownOutlined } from "@ant-design/icons";

import { useRecoilValue } from "recoil";

import { useHistory } from "react-router";
import Formorder from "./addpaymentrequest";
import LayoutPage from "../layout";
import { useTranslation } from "react-i18next";
import Box from "$components/Box";

import { userInfos } from "../../store";

moment.locale("fr");

export default function PaymentRequest() {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const [paymentRequest, SetpaymentRequest] = useState(null);
  const user = useRecoilValue(userInfos);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [paymentStatus, setPaymentStatus] = useState(params.get("status"));
  const [checker, setChecker] = useState(params.get("checker_id"));

  const resetFilters = () => {
    setChecker(null);
    setPaymentStatus(null);
  };

  const setStatus = (t) => {
    setChecker(null);
    setPaymentStatus(t);
  };
  const filterMenu = [
    {
      label: <div
        key="1"
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => resetFilters()}
      >
        <div className="  itemcenter justbtw flex">
          <span className="av-heavy fs14">
            {" "}
            {t("paymentRequests.list.filters.filter1")}
          </span>
        </div>
      </div>
    },
    {
      label : <div
        key="2"
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setStatus(1)}
      >
        <span className="av-heavy fs14">
          {t("paymentRequests.list.filters.filter2")}
        </span>
      </div>
    },
    {
      label : <div
        key="3"
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setStatus(2)}
      >
        <span className="av-heavy fs14">
          {t("paymentRequests.list.filters.filter3")}
        </span>
      </div>,
    },
    {
      label : <div
        key=""
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setChecker(user.id)}
      >
        <span className="av-heavy fs14">
          {t("paymentRequests.list.filters.filter4")}
        </span>
      </div>
    }
  ]
  const close = () => {
    setVisible(false);
  };

  const [pagination,setPagination] = useState(null);
  const { data:listData, error, isLoading, refetch } = useGetPaymentRequest(
    paymentStatus,
    checker,
    pagination,
    {},
    RETRY,
  );
  useEffect(() => {
    if(pagination && (pagination?.page || pagination?.size)){
      setPagination({...pagination,current:0,page:0});
      setTimeout(refetch,200);
      return;
    }
    refetch();
  }, [paymentStatus, checker]);

  const change = (r) => {
    history.push("/payment-request/" + r.id);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      onFilter: (value, record) => {
        return (
          String(record.id).toLowerCase().includes(value.toLowerCase()) ||
          String(record.code).toLowerCase().includes(value.toLowerCase()) ||
          String(record.initiator?.lastname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.initiator?.firstname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: ("paymentRequests.detail.label0"),
      dataIndex: ["ticket","code"],
      render: ({item}) => <>{item.ticket.code}</>,
    },
    {
      title: ("paymentRequests.detail.label5"),
      dataIndex: ["initiator","lastname"],
      onFilter : (value,item)=>{
        return "{0} {1}".sprintf(item?.initiator?.lastname,item?.initiator?.firstname).toLowerCase().trim().includes(String(value).toLowerCase());
      },
      render: ({item}) => (
        <>{item.initiator.lastname + " " + item.initiator.firstname}</>
      ),
    },
    {
      title: ("commitmentRequests.detail.section3.label1"),
      dataIndex : ["checker","lastname"],
      onFilter : (value,item)=>{
        return "{0} {1}".sprintf(item?.checker?.lastname,item?.checker?.firstname).toLowerCase().trim().includes(String(value).toLowerCase());
      },
      render: ({item}) => {
        return (
          <>
            {item?.checker
              ? item?.checker?.lastname + " " + item?.checker?.firstname
              : "--"}
          </>
        );
      }
    },
    {
      title: ("paymentRequests.detail.label2"),
      dataIndex: "date",
      type : "date",
    },

    {
      title: ("paymentRequests.detail.label3"),
      dataIndex: "pay_to",
    },

    {
      title: ("paymentRequests.detail.label1"),
      dataIndex: "advance",
      type :"switch",
      checkedLabel : "Avance",
      uncheckedLabel : "Paiement",
      render: ({item}) => <>{item.advance ? "Avance" : "Paiement"}</>,
    },

    {
      title: ("paymentRequests.detail.label4"),
      dataIndex: "pay_by",
      onFilter : (value,record)=>{
        return String(i18nTObj(record.pay_by)).toLowerCase().includes(String(value).toLowerCase());
      },
      render: ({item}) => <>{i18nTObj(item.pay_by)}</>,
    },
    {
      title: ("commitmentForms.form.detail.label11"),
      dataIndex: ["status","id"],
      onFilter : (value,record)=>{
        return String(i18nTObj(record?.status)).toLowerCase().includes(String(value).toLowerCase().trim());
      },
      render: ({item}) => (
        <>
          <Tag
            className="u-mar-left-xs"
            color={
              item?.status?.id == 0
                ? "#ff9e01"
                : item?.status?.id == 1
                ? "#87d068"
                : item?.status?.id == 2
                ? "#ff5500"
                : item?.status.id == 3
                ? "red"
                : item?.status.id == 5
                ? "#87d068"
                : "#108ee9"
            }
          >
            {i18nTObj(item?.status)}
          </Tag>
        </>
      ),
    },
  ];

  return (
    <LayoutPage>
      {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[{
          title : t("homePage.menu.paymentRequest"),
        }]}/>

        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <Box flex flexDirection="row" w="100%" pt="5px" px="5px">
              <Dropdown
                menu={{items:filterMenu}}
                style={{ width: "200px" }}
                className="cursor u-mar-right-xs"
                trigger={["click"]}
              >
                <div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
                  <span
                    className="fs15 av-heavy u-mar-right-xs"
                    style={{ color: "#1a1a1a" }}
                  >
                    {paymentStatus == 2
                      ? t("paymentRequests.list.filters.filter3")
                      : paymentStatus == 1
                      ? t("paymentRequests.list.filters.filter2")
                      : checker != null
                      ? t("paymentRequests.list.filters.filter4")
                      : t("paymentRequests.list.filters.filter1")}
                  </span>
                  <span style={{ marginTop: "4.5px" }}>
                    <CaretDownOutlined />
                  </span>
                </div>
              </Dropdown>
            </Box>

            <div className="flex itemcenter">
              <div className="flex itemcenter ">
                {/*   <SelectDate /> */}

                <div className="separator nonedisplayres"></div>
              </div>

              <Button
                type="primary"
                size="large"
                className="rad8"
                onClick={() => setVisible(true)}
              >
                <span className="fs18 av-heavy"> {t("add.button")}</span>
              </Button>
            </div>

            <Drawer
              height={"100%"}
              placement={"bottom"}
              onClose={() => setVisible(false)}
              open={visible}
              closable={false}
              bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
              destroyOnClose={true}
            >
              <Formorder initiator={user.id} t={t} close={close} />
            </Drawer>
          </div>

          <Table
            columns={columns}
            listData={listData}
            onRow={(record) => ({
              onClick: () => {
                change(record);
              },
            })}
            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange={setPagination}
          />
        </div>
      </Spin>
    </LayoutPage>
  );
}
