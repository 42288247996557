import React, { useEffect, useState } from "react";
import Table from "$components/Table";
import { Button, Spin, Drawer, Breadcrumb, Input } from "antd";
import { useGetExpensesCommitment,RETRY } from "../../api/api";
import DateLib from "$date";

import { useRecoilValue } from "recoil";

import { useHistory } from "react-router";
import Formorder from "./addexpense";
import LayoutPage from "../layout";
import { useTranslation } from "react-i18next";
import Box from "$components/Box";

import { userInfos } from "../../store";

export default function ExpensesCommitment() {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const [pagination, setPagination] = useState(null);
  const user = useRecoilValue(userInfos);
  const close = () => {
    setVisible(false);
  };

  const { data:listData, error, isLoading, refetch } = useGetExpensesCommitment(pagination,null,RETRY);
  useEffect(() => {
    refetch();
  }, []);

  const change = (r) => {
    history.push("/expense-commitment/" + r.id);
  };

  const columns = [
    {
      title: ("list.columns.label0"),
      dataIndex: "code",
      onFilter: (value, record) => {
        return (
          String(record.code).toLowerCase().includes(value.toLowerCase()) ||
          String(record.initiator?.firstname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.initiator?.lastname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(i18nTObj(record.affectation?.zone))
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: ("list.columns.label1"),
      dataIndex: "",
      render: ({item}) => (
        <>{item?.initiator?.lastname + " " + item?.initiator?.firstname}</>
      ),
    },

    {
      title: "Date ",
      dataIndex: "",
      render: ({item}) => <>{DateLib.format(item.date)}</>,
    },

    {
      title: ("list.columns.label4"),
      dataIndex: "for_project",

      render: ({item}) => (
        <>
          {item?.for_project == true ? (
            <span className="u-pad-xxs greenstatus rad4 fs12"> {t("yes")} </span>
          ) : (
            <span className="u-pad-xxs graystatus rad4 fs12"> {t("no")} </span>
          )}
        </>
      ),
    },

    {
      title: ("list.columns.label3"),
      dataIndex: "",
      render: ({item}) => <>{i18nTObj(item?.affectation?.zone)}</>,
    },
  ];

  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[{title:t("commitmentForms.list.title")}]}/>
        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <Box w="100%" flex flexDirection="row" justifyContent="end" className="button-actions">
              <Button
                type="primary"
                size="large"
                className="rad8"
                onClick={() => setVisible(true)}
              >
                <span className="fs18 av-heavy">{t("add.button")}</span>
              </Button>
            </Box>

            <Drawer
              height={"100%"}
              placement={"bottom"}
              onClose={() => setVisible(false)}
              open={visible}
              closable={false}
              bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
              destroyOnClose={true}
            >
              <Formorder initiator={user.id} close={close} />
            </Drawer>
          </div>

          <Table
            columns={columns}
            listData = {listData}
            onRow={(record) => ({
              onClick: () => {
                change(record);
              },
            })}
            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange = {setPagination}
          />
        </div>
      </Spin>
    </LayoutPage>
  );
}
