import {
  isNonNullString,
  isDOMElement,
  removeClassName,
  isTouchDevice,
  addClassName,
} from "$utils";
import APP from "$app";

const dimensions = {
  window: {
    fontScale: 1,
    height: 0,
    scale: 1,
    width: 0,
  },
  screen: {
    fontScale: 1,
    height: 0,
    scale: 1,
    width: 0,
  },
};
const listeners = {};

let shouldInit = true;
export class Dimensions {
  static get(dimension) {
    if (shouldInit) {
      shouldInit = false;
      Dimensions._update();
    }
    return dimensions[dimension];
  }

  static set(initialDimensions) {}

  static _update() {
    const win = window;
    const docEl = win.document.documentElement;

    dimensions.window = {
      fontScale: 1,
      height: docEl.clientHeight,
      scale: win.devicePixelRatio || 1,
      width: docEl.clientWidth,
    };

    dimensions.screen = {
      fontScale: 1,
      height: win.screen.height,
      scale: win.devicePixelRatio || 1,
      width: win.screen.width,
    };
    updateDeviceClassName();
    APP.trigger(APP.EVENTS.RESIZE_PAGE, Dimensions.get());
    if (Array.isArray(listeners["change"])) {
      listeners["change"].forEach((handler) => handler(dimensions));
    }
  }

  static addEventListener(type, handler) {
    listeners[type] = listeners[type] || [];
    listeners[type].push(handler);
    return {
      remove: () => {
        this.removeEventListener(type, handler);
      },
    };
  }

  static removeEventListener(type, handler) {
    if (Array.isArray(listeners[type])) {
      listeners[type] = listeners[type].filter(
        (_handler) => _handler !== handler
      );
    }
  }
}

const isMathMediaSupport =
  typeof window.matchMedia == "function" ? true : false;

export const breakpoints = {
  xs: "20rem", //very small devieces
  sm: "30rem", //480px
  md: "48rem", //768px
  lg: "62rem", //992px
  xl: "80rem", //1280px
  "2xl": "96rem", //1536px
};

export const breakpointsKeys = Object.keys(breakpoints);

export const breakpointsInPixcel = {
  xs: 320, //very small devieces
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  "2xl": 1536,
};

export const isBreakpointMath = (breakpoint) => {
  if (!isNonNullString(breakpoint)) {
    return false;
  }
  breakpoint = breakpoint.toLowerCase().trim();
  if (!(breakpoint in breakpoints)) return false;
  const index = breakpointsKeys.indexOf(breakpoint);
  if (index < 0) return false;
  const brs = breakpoints[breakpoint];
  const width = Dimensions.get("window").width;
  return isMathMediaSupport
    ? (window.matchMedia(`(max-width: ${brs})`)?.matches && true) || false
    : (width <= breakpointsInPixcel[breakpoint] && true) || false;
};
/*** get current media */
export const getCurrentMedia = () => {
  for (let breakpoint in breakpoints) {
    if (isBreakpointMath(breakpoint)) {
      return breakpoint;
    }
  }
  return "";
};
export const isPhoneMedia = () => {
  return isBreakpointMath("xs") || isBreakpointMath("sm");
};

export const isMobileMedia = () => {
  return isPhoneMedia();
};
export const isTabletMedia = () => {
  return isBreakpointMath("md");
};
export const isDesktopMedia = () => {
  return (
    isBreakpointMath("lg") || isBreakpointMath("xl") || isBreakpointMath("2xl")
  );
};

export const isMobileOrTabletMedia = () => {
  return isMobileMedia() || isTabletMedia();
};
export const isTabletOrDeskTopMedia = () => {
  return isTabletMedia() || isDesktopMedia();
};

export const getMediaType = () => {
  return isMobileMedia() ? "mobile" : isTabletMedia() ? "tablet" : "desktop";
};
export const updateDeviceClassName = () => {
  if (
    typeof document !== "undefined" &&
    document &&
    isDOMElement(document.body)
  ) {
    let b = document.body;
    const deviceKey = "data-device-name";
    let c = b.getAttribute(deviceKey);
    if (c) {
      removeClassName(b, c);
    }
    const className = getMediaType();
    addClassName(b, className);
    b.setAttribute(deviceKey, className);
    removeClassName(b, "not-touch-device");
    removeClassName(b, "is-touch-device");
    addClassName(b, isTouchDevice() ? "is-touch-device" : "not-touch-device");
    return className;
  }
  return false;
};

window.addEventListener(
  "resize",
  () => {
    Dimensions._update();
  },
  false
);
