import {Tabs} from "antd";
import React from "$react";
import {defaultStr} from "$utils";

export default function TabsComponent({activeKey:cActiveKey,onChange,defaultActiveKey,...props}){
    const [activeKey,setActiveKey]= React.useState(defaultStr(cActiveKey,defaultActiveKey));
    React.useEffect(()=>{
        if(defaultActiveKey !== activeKey && defaultActiveKey){
            setActiveKey(defaultActiveKey);
        }
    },[defaultActiveKey]);
    React.useEffect(()=>{
        if(cActiveKey && cActiveKey !== activeKey){
            setActiveKey(cActiveKey);
        }
    },[cActiveKey])
    return <Tabs 
        type="line"
        {...props}
        activeKey={activeKey}
        onChange = {(k,...rest)=>{
            if(onChange && onChange(k,...rest) === false){
                return;
            }
            setActiveKey(k);
        }}
    />
}   