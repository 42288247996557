import React, { useEffect, useState } from "react";
import moment from "moment";
import Divider from "$components/Divider";
import Status from "$components/Status";

import {
  Button,
  Spin,
  Drawer,
  Col,
  Row,
  Descriptions,
  Breadcrumb,
  Tag,
} from "antd";
import { useGetExpensesCommitmentDetails } from "../../api/api";
import { useTranslation } from "react-i18next";


import { useParams } from "react-router";
import LayoutPage from "../layout";
import { Link } from "react-router-dom";
import Box from "$components/Box"

export default function ExpensesCommitmentDetails() {
  const { t } = useTranslation();

  const [expense, setExpense] = useState(null);

  let { id } = useParams();
  const { data, error, isLoading, refetch } =
    useGetExpensesCommitmentDetails(id);

  useEffect(() => {
    setExpense(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <LayoutPage>
      {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Box className="grille w100    mauto   u-mar-top-xl-res nopadres noradres ">
          <Breadcrumb items={[
            {
              title : <Link to="/expense-commitment">
                {t("commitmentForms.list.title")}
              </Link>,
            },
            {
              title : expense?.code,
            }
          ]}/>

          <Row className="u-mar-top-s">
            <Col span={24} className="hauto blok rad16 justcenter itemcenter ">
              <Box className="flex column justcenter" h="100%" p="10px">
                <h2 className="fW600">{expense?.code}</h2>
                <Box className="flex" my="5px"></Box>
                <Divider />

                <Descriptions column={3}>
                  <Descriptions.Item
                    label={t("commitmentForms.form.detail.label0")}
                  >
                    {expense?.for_project ? t("yes") : t("no")}
                  </Descriptions.Item>
                  {
                    <Descriptions.Item
                      label={t("commitmentForms.form.detail.label1")}
                    >
                      {expense?.projects.map((r,key) => (
                        <h4 key={key}>{r.code}</h4>
                      ))}
                    </Descriptions.Item>
                  }

                  {
                    <Descriptions.Item
                      label={t("commitmentForms.form.detail.label3")}
                    >
                      {i18nTObj(expense?.affectation?.zone)}
                    </Descriptions.Item>
                  }

                  {
                    <Descriptions.Item
                      label={t("commitmentForms.form.detail.label2")}
                    >
                      {moment(expense?.date).format("D MMM-YY")}
                    </Descriptions.Item>
                  }

                  {
                    <Descriptions.Item
                      label={t("commitmentForms.form.detail.label5")}
                    >
                      {expense?.initiator.lastname}{" "}
                      {expense?.initiator.firstname}
                    </Descriptions.Item>
                  }

                  {
                    <Descriptions.Item
                      label={t("commitmentForms.form.detail.label4")}
                    >
                      {expense?.devise}
                    </Descriptions.Item>
                  }

                  {
                    <Descriptions.Item
                      label={t("commitmentForms.form.detail.label6")}
                    >
                      <Box className="flex column">
                        {expense?.activities.map((a,key) => (
                          <h4 key={key}>{i18nTObj(a)}</h4>
                        ))}
                      </Box>
                    </Descriptions.Item>
                  }
                </Descriptions>

                <Box className="appro rel">
                  <Box className="flex justbtw w100" mt="15px">
                    <h3 className="fW600 nomar">
                      {t("commitmentForms.form.detail.label7")}(
                      {expense?.tickets.length})
                    </h3>
                  </Box>

                  <Box className="w100">
                    <Box className="flex itemcenter">
                      <Box className="u-mar-right-s"></Box>
                    </Box>

                    <Box className="w100">
                      <Box
                        className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s"
                        style={{
                          borderBottom: "1px #b3b3b3 solid",
                        }}
                      >
                        <span className="grille4 fW500 fs12 colhead">
                          {t("commitmentForms.form.detail.label8")}
                        </span>
                        <span className="grille4 fW500 fs12 colhead">
                          {" "}
                          Service
                        </span>
                        <span className="grille4 fW500 fs12 colhead">
                          {" "}
                          {t("commitmentForms.form.detail.label10")}
                        </span>
                        <span className="grille4 fW500 fs12 colhead">
                          {" "}
                          {t("commitmentForms.form.detail.label11")}
                        </span>
                      </Box>

                      <ul className="cart-list" style={{ height: "auto" }}>
                        {expense?.tickets.map((u,key) => (
                          <li key={key} className="section-cart flex itemcenter justbtw  u-pad-left-xs ">
                            <span className="grille4 av-heavy fs14 coltext fW500 ">
                              <Link to={"/tickets/" + u.id}>{u.code}</Link>
                            </span>

                            <span className="grille4 av-heavy fs14 ">
                              {i18nTObj(u.service)}
                            </span>

                            <span className="grille4 av-heavy fs14 ">
                              {i18nTObj(u.category)}
                            </span>

                            <span className="grille4 av-heavy fs14 ">
                              <Status status={u.status}/>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      </Spin>
    </LayoutPage>
  );
}
