import * as ROLES from "./roles";
import {isNonNullString} from "$utils";
import { normalizePath } from "$features/sign/utils";
import {
    ProjectOutlined,
    DollarOutlined,
    CarOutlined,
    SolutionOutlined,
    SnippetsOutlined,
    SwapOutlined,
    LineChartOutlined,
    UserOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";

const items = [
    {
      key: "DASHBOARD",
      path : "/",
      roles : ROLES.DASHBOARD,
      label : "homePage.menu.dashboard",
      icon : <LineChartOutlined style={{ fontSize: 16 }} />,
    },
    {
      key: "EXPENSE_COMMITMENT",
      path : "/expense-commitment",
      roles : ROLES.EXPENSE_COMMITMENT,
      label: "homePage.menu.commitmentForm",
      icon : <SnippetsOutlined style={{ fontSize: 16 }} />,
    },
    {
      key: "COMMITMENT_REQUEST",
      path : "/tickets",
      roles : ROLES.COMMITMENT_REQUEST,
      label : "homePage.menu.commitmentRequest",
      icon : <SolutionOutlined style={{ fontSize: 16 }} />,
    },
    {
      key: "PAYMENTS_REQUESTS",
      path : "/payment-request",
      roles : ROLES.PAYMENTS_REQUESTS,
      label : "homePage.menu.paymentRequest",
      icon : <DollarOutlined style={{ fontSize: 16 }} />,
    },
    {
        key : "BASES",
        path : "/bases",
        label : "homePage.menu.sites",
        icon  : <HomeOutlined style={{ fontSize: 16 }} />,
        roles : ROLES.BASES,
    },
    {
        key : "PROJECTS",
        path : "/projects",
        roles : ROLES.PROJECTS,
        label :"homePage.menu.projects",
        icon : <ProjectOutlined style={{ fontSize: 16 }} />,
    },
    {
        key : "EMPLOYEES",
        path : "/employees",
        label : "homePage.menu.employees",
        roles : ROLES.EMPLOYEES,
        icon : <UserOutlined style={{ fontSize: 16 }} />,
    },
    {
        key : "ASSIGNMENTS",
        path : "/affections",
        roles : ROLES.AFFECTATIONS,
        icon : <SwapOutlined style={{ fontSize: 16 }} />,
        label : "homePage.menu.assignments",
    },
    {
        key : "MOVEMENT_REQUESTS",
        path : "/movement_request",
        label : "homePage.menu.travelRequest",
        roles : ROLES.MOVEMENT_REQUESTS,
        icon : <CarOutlined style={{ fontSize: 16 }} />,
    },
    {
        key : "POSTS",
        roles : ROLES.POSTS,
        icon : <UsergroupAddOutlined style={{ fontSize: 16 }} />,
        label : "menu.post",
        path : "/post",
    },
]
  
  
export default items;

export const itemsByPath = {};

items.map((it)=>{
  itemsByPath[it.path] = it;
});

export const getRolesByPath = (path)=>{
  if(!isNonNullString(path)) {
    path = "/";
  }
  path = normalizePath(path);
  if(!path){
    path = "/";
  }
  for(let i in items){
    const it = items[i];
    if(!it) continue;
    const p = normalizePath(it.path);
    if(!p && "/" === path || (p && path && path.includes(p))) {
      return it.roles;
    }
  }
  return [];
}