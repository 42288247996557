import {defaultStr} from "$utils";
import Op from "./Op";
export const operatorsAliases = {
    $$eq: Op.eq,
    $$ne: Op.ne,
    $$gte: Op.gte,
    $$gt: Op.gt,
    $$lte: Op.lte,
    $$lt: Op.lt,
    $$not: Op.not,
    $$in: Op.in,
    $$notIn: Op.notIn,
    $$is: Op.is,
    $$like: Op.like,
    $$notLike: Op.notLike,
    $$iLike: Op.iLike,
    $$notILike: Op.notILike,
    $$regexp: Op.regexp,
    $$notRegexp: Op.notRegexp,
    $$iRegexp: Op.iRegexp,
    $$notIRegexp: Op.notIRegexp,
    $$between: Op.between,
    $$notBetween: Op.notBetween,
    $$overlap: Op.overlap,
    $$contains: Op.contains,
    $$substring : Op.substring,
    $$contained: Op.contained,
    $$adjacent: Op.adjacent,
    $$strictLeft: Op.strictLeft,
    $$strictRight: Op.strictRight,
    $$noExtendRight: Op.noExtendRight,
    $$noExtendLeft: Op.noExtendLeft,
    $$and: Op.and,
    $$or: Op.or,
    $$any: Op.any,
    $$all: Op.all,
    $$values: Op.values,
    $$col: Op.col,
    $$startsWith : Op.startsWith,
    $$endsWith : Op.endsWith,
};
export const operatorsAliasesValues = {};
Object.map(operatorsAliases,(i,v)=>{
    operatorsAliasesValues[v] = i;
})

export const EQ = {
    code : '==',
    label : 'Egal',
    alias : ["==="],
    squelizeKey : "$$eq",
    valueType : "number|string|boolean",
};
export const NEQ = {
    code : '!=',
    label : 'Différent',
    squelizeKey : "$$ne",
    alias : ['!=='],
    valueType : "number|string|boolean",
};
export const GT = {
    code : '>',
    label : 'Strictement supérieur',
    squelizeKey : "$$gt",
    valueType : "number",
};
export const GTE= {
    code : ">=",
    label : 'Supérieur où égal',
    squelizeKey : "$$gte",
    valueType : "number",
};
export const LT = {
    code : "<",
    label : 'Strictement inférieur',
    squelizeKey : "$$lt",
    valueType : "number",
};
export const LTE = {
    code : "<=",
    label : 'Inférieur ou égal',
    squelizeKey : "$$lte",
    valueType : "number",
};

export const MATCH  =  {
    code : "contient",
    label : "Contient",
    squelizeKey : "$$like",
    valueType : "string",
    parseValue : (v)=>{
        return "%"+defaultStr(v)+"%"
    }
}

export const NO_MATCH  =  {
    code : "notcontains",
    label : "Ne Contient",
    squelizeKey : "$$notLike",
    valueType : "string",
    parseValue : (v)=>{
        return defaultStr(v)
    }
}
export const STARTS_WITH  =  {
    code : "startsWith",
    label : "Commence par",
    //enabled : false,
    squelizeKey : "$$startsWith",
    valueType : "string|date",
    parseValue : (v)=>{
        return defaultStr(v)
    }
}

export const ENDS_WITH  =  {
    code : "endsWith",
    label : "se termine par",
    //enabled : false,
    squelizeKey : "$$endsWith",
    valueType : "string|date",
    parseValue : (v)=>{
        return defaultStr(v)
    }
}

export const CONTAINS  =  {
    code : "contient",
    label : "Contient",
    enabled : false,
    squelizeKey : "$$substring",
    valueType : "string|date",
    parseValue : (v)=>{
        return defaultStr(v)
    }
}


const allOperators =  {
    EQ,
    NEQ,
    GT,
    GTE,
    LT,
    LTE,
    //MATCH,
    //NO_MATCH,
    CONTAINS,
    STARTS_WITH,
    ENDS_WITH,
}

export const getDefaultValueForType  = allOperators.getDefaultValueForType = (opts)=>{
    if(typeof opts ==='string'){
        opts = {type:opts};
    }
    opts = Object.assign({},opts);
    const type = defaultStr(opts.type,"string").toLowerCase();
    switch(type){
        case "number":
            return EQ;
        default : 
            return CONTAINS;
    }
}
export const getForType = allOperators.getDefaultValueForType = (type)=>{
    type = defaultStr(type,"string").toLowerCase().trim();
    const r = [];
    Object.map(allOperators,(op,i)=>{
        if(op.enabled === false) return;
        const opType = defaultStr(op.valueType).toLowerCase();
        if(opType.contains(type) || opType =='all'){
            r.push({...op,parseValue:undefined});
        }
    })
    return r;
}
export default allOperators;