import React, { Fragment, useEffect, useState } from "react";
import notify from "$notify";
import {
  Form,
  Button,
  Col,
  Row,
  Divider,
  Select,
  Input,
  message,
} from "antd";
import "moment/locale/fr";

import {
  useGetValidTickets,
  useGetExpensesCommitmentTicket,
  useAddPayRequest,
} from "../../../api/api";
import FormProd from "./step2form";
import { AVOID_RETRY } from "../../../api/api";

const { Option } = Select;

export default function Step1Form({ userId, close, t }) {
  const [tickets, setTickets] = useState([]);
  const [items, setItems] = useState(null);

  const [loading, setLoading] = useState(false);
  
  const [idTicket, setIdTicket] = useState(null);

  const [activitydisabled, setActivydisabled] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState([]);

  const { data} = useGetValidTickets({}, {});
  const { data: ticket, refetch: refetchId } =
    useGetExpensesCommitmentTicket(idTicket,AVOID_RETRY);

  const [form] = Form.useForm();

  const { mutateAsync: addPayRequest } = useAddPayRequest(idTicket);

  const changeItem = (items) => {
    setItems(items);
  };

  const onChangeTicket = async (v) => {
    console.log(v);

    setIdTicket(v);
    setActivydisabled(false);
  };

  const handleSubmit = async () => {
    form.submit();

    if (items == null || items?.length == 0) {
      notify.error({
        title: `Remplir tous les champs`,
        message: "Veuiller vous assurer que tous les champs sont remplis",
      });
    } else {
      form
        .validateFields()
        .then(async function (values) {
          let obj = {};

          let temp = [];

          items.map((p, id) => {
            let item = {
              quantity: parseInt(p.quantity),
              price: parseInt(p.price),

              description: p.description,
              unity: p.unity,
              service: selectedTicket?.service.id,
              project_code: p.project_code,
              project_line: p.project_line,
              financial_code: p.financial_code,
              financial_line: p.financial_line,
            };
            if (selectedTicket?.category?.id != null) {
              item.category = selectedTicket?.category.id;
            }

            temp.push(item);
          });

          obj.items = temp;
          obj.explanation = values.explanation;
          obj.payTo = values.pay_to;
          obj.advance = values.advance;
          obj.pay_by = values.pay_by;

          try {
            setLoading(true);

            const request = await addPayRequest(obj);

            close();
            message.success(t("successful.recording"));
            //history.push('/orders')
            setLoading(false);
          } catch (e) {
            setLoading(false);
            message.warning(t("error.recording"));
          }
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  useEffect(() => {
    setTickets(data);
  }, [data]);

  useEffect(() => {
    setSelectedTicket(ticket);
  }, [ticket]);

  useEffect(() => {
    refetchId();
  }, [idTicket]);

  return (
    <Form form={form} autoComplete="off" layout="vertical">
      <Fragment>
        <Row gutter={16} className="u-mar-bottom-l">
          <Col span={24}>
            <h3 className="fW600 nomar">Informations</h3>
            <Divider className="u-mar-top-s" />
          </Col>

          <Col span={4}>
            <Form.Item
              name="ticket"
              label={t("paymentRequests.form.section1.label0")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                onSelect={(v, t) => onChangeTicket(v, t)}
              >
                {tickets != null &&
                  tickets?.map((project, key) => (
                    <Option value={project.id} key={key}>
                      {" "}
                      {project.code}{" "}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="advance"
              label={t("paymentRequests.form.section1.label1")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <Select optionFilterProp="children">
                <Option value={true}> {t("payment.type.advance")}</Option>
                <Option value={false}> {t("payment.type.payment")}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="explanation"
              label={t("paymentRequests.form.section1.label2")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <>
          <FormProd
            chargeItems={changeItem}
            spending={selectedTicket?.items}
            t={t}
          />
        </>
      </Fragment>

      <div
        className="flex itemcenter fixed"
        style={{ right: 34, top: 10, zIndex: 999 }}
      >
        <Button
          type="primary"
          size="large"
          className="rad8"
          loading={loading}
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          <span className="fs16 av-heavy"> {t("validate.button")}</span>
        </Button>
      </div>
    </Form>
  );
}
