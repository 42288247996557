import React, { Suspense, useMemo } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import {PortalProvider} from "$components/Portal";
//import socketIOClient from "socket.io-client";
import {setQueryParams,getQueryParams} from "$utils/uri";
//import { AppContext } from "./libs/contextLib";
import { ChakraProvider,extendTheme, CSSReset,} from '@chakra-ui/react'
import  {updateTheme,defaultTheme} from "$theme";
import axios, { AxiosContext } from "./api/request";
import { RecoilRoot } from "recoil";
import Login from "./features/sign/Login";
import Reset from "./features/reset/reset";
import Home from "./features/dashboard/dashboard";
import {isNonNullString,defaultStr} from "$utils";
import {getRolesByPath} from "$components/sider";
import NotAllowed from "$auth/NotAllowed";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { isSignedIn,isAdmin,hasRoles } from "$auth";
import "$dimensions";

import Settings from "./features/parametres-compte/compte";

import Employees from "./features/employees/employees";
import Employee from "./features/employees/employee";

import Projects from "./features/project/Projects";

import Affectations from "./features/Affectation/Affectation";

import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';

import Zones from "./features/bases/zone";

import Profile from "./features/profile/profile";

import Movements from "./features/movement_request/movementList";
import DetailMovement from "./features/movement_request/detailMovement";

import ExpenseCommitment from "./features/expense-commitment/expenses";
import ExpenseCommitmentDetails from "./features/expense-commitment/expensesDetails";

import PaymentRequest from "./features/payment-request/payment-request";
import PaymentRequestDetails from "./features/payment-request/payment-requestDetails";

import Tickets from "./features/tickets/tickets";
import TicketsDetails from "./features/tickets/ticketDetails";

import SuspendFallbackLoading from "./components/fallback";

import "antd/dist/reset.css";

import "./App.css";

import Post from "./features/post/post";

const AxiosProvider = ({ children }) => {
  const axiosValue = useMemo(() => {
    return axios;
  }, []);

  return (
    <AxiosContext.Provider value={axiosValue}>{children}</AxiosContext.Provider>
  );
};

function Base() {
  const [theme,setTheme] = React.useState(updateTheme(defaultTheme));
  const appTheme = useMemo(()=>{
     return extendTheme(theme)
  },[theme])
  
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
          suspense: false,
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          refetchOnReconnect: true,
          refetchInterval: false,
        },
      },
    });
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();
    const path = defaultStr(location?.pathname);
    const href = "{0}?{1}".sprintf(path,defaultStr(location?.search));
    return (
    <Route
      {...rest}
      render={(props) => {
        if(!isSignedIn()){
          return <Redirect
            to={{
              pathname: setQueryParams("/login",{callbackPath:href}),
              state: { from: props.location },
            }}
          />;
        }
        const roles = getRolesByPath(path);
        if(!hasRoles(roles)){
          return <NotAllowed/>
        }
        return <Component {...props} />
      }}
    />
  )};
  
  return (
    <PortalProvider>
    <div className="hv100 bgapp" style={{ overflowY: "auto" }}>
    <ChakraProvider theme={appTheme}>
      
      <AxiosProvider>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<SuspendFallbackLoading />}>
            <RecoilRoot>
              <Router>
                <Switch>
                  <PrivateRoute exact path="/" component={Home} />

                  <PrivateRoute exact path="/employees" component={Employees} />
                  <PrivateRoute
                    exact
                    path="/employees/:id"
                    component={Employee}
                  />

                  <PrivateRoute exact path="/projects" component={Projects} />

                  <PrivateRoute
                    exact
                    path="/expense-commitment"
                    component={ExpenseCommitment}
                  />
                  <PrivateRoute
                    exact
                    path="/expense-commitment/:id"
                    component={ExpenseCommitmentDetails}
                  />

                  <PrivateRoute
                    exact
                    path="/payment-request"
                    component={PaymentRequest}
                  />
                  <PrivateRoute
                    exact
                    path="/payment-request/:id"
                    component={PaymentRequestDetails}
                  />

                  <PrivateRoute exact path="/tickets" component={Tickets} />
                  <PrivateRoute
                    exact
                    path="/tickets/:idticket"
                    component={TicketsDetails}
                  />

                  <PrivateRoute
                    exact
                    path="/affections"
                    component={Affectations}
                  />

                  <Route exact path="/settings" component={Settings} />

                  <PrivateRoute exact path="/profile" component={Profile} />

                  <PrivateRoute exact path="/bases" component={Zones} />
                  <PrivateRoute exact path="/post" component={Post} />

                  <PrivateRoute
                    exact
                    path="/movement_request"
                    component={Movements}
                  />
                  <PrivateRoute
                    exact
                    path="/movement_request/:id"
                    component={DetailMovement}
                  />
                  <Route exact path="/login:callbackPath" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/reset" component={Reset} />
                </Switch>
              </Router>
            </RecoilRoot>
          </Suspense>
        </QueryClientProvider>
      </AxiosProvider>
      <ToastContainer/>
    </ChakraProvider>
    </div>
    </PortalProvider>
  );
}

export default Base;
