import React, { useState, useEffect } from "react";
import "moment/locale/fr";
import {
  Button,
  Select,
  Form,
  Col,
  message,
  Row,
} from "antd";
import ImgCrop from "antd-img-crop";
import { CloseOutlined } from "@ant-design/icons";
import { useAddAffectation, useEditAffectation } from "../../api/api";
import { useTranslation } from "react-i18next";

const { Option } = Select;
export default function FormAffectaion({
  close,
  current_element,
  is_update,
  projects,
  zones,
  accountant,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { mutateAsync: addAffectation } = useAddAffectation();

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (is_update) {
      setId(current_element.id);
    } else {
      setId(0);
    }
  }, []);
  const { mutateAsync: updateAffectation } = useEditAffectation(id);

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        try {
          setLoading(true);
          if (is_update) {
            updateAffectation(values);
          } else {
            await addAffectation(values);
            //console.log(values)
          }

          close();

          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          setLoading(false);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>
        <h2 className="fW600">
          {is_update ? t("zone.form.update") : t("assignments.form.title")}
        </h2>
        <div className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("validate.button")} </span>
          </Button>
        </div>
      </div>
      <div className="u-mar-vertical-xxl  w50 mauto">
        <Form
          layout="vertical"
          className="rel"
          form={form}
          initialValues={{ ...current_element }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="project_id"
                label={t("assignments.form.label0")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  //placeholder="Choisir le projet"
                  autoComplete="none"
                >
                  {projects.map((item) => {
                    return <Option value={item.id}>{item.code}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="zone_id"
                label={t("assignments.form.label1")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  //placeholder="Choisir la zone"
                  autoComplete="none"
                >
                  {zones.map((item) => {
                    return <Option value={item.id}>{i18nTObj(item)}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="accountant_id"
                label={t("assignments.form.label2")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  //placeholder="Choisir le responsable"
                  autoComplete="none"
                >
                  {accountant.map((item) => {
                    return (
                      <Option value={item.id}>
                        {item.firstname + " " + item.lastname}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
