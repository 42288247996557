import UIManager from "../UIManager";
import { useRef } from "react";
import { classNames } from "$utils";
import flattenStyle from "$theme/flattenStyle";

const UNINITIALIZED =
  typeof Symbol === "function" && typeof Symbol() === "symbol"
    ? Symbol()
    : Object.freeze({});

function useStable(getInitialValue) {
  const ref = useRef(UNINITIALIZED);
  if (ref.current === UNINITIALIZED) {
    ref.current = getInitialValue();
  }
  // $FlowFixMe (#64650789) Trouble refining types where `Symbol` is concerned.
  return ref.current;
}
const emptyObject = {};

function setNativeProps(
  node,
  nativeProps,
  classList,
  pointerEvents,
  style,
  previousStyleRef
) {
  if (node != null && nativeProps) {
    const domProps = {
      ...nativeProps,
      pointerEvents:
        typeof pointerEvents == "string" ? pointerEvents : undefined,
      classNames: classNames(nativeProps.className),
      style: flattenStyle([style, nativeProps.style]),
    };

    const nextDomStyle = domProps.style;

    if (previousStyleRef.current != null) {
      if (domProps.style == null) {
        domProps.style = {};
      }
      for (const styleName in previousStyleRef.current) {
        if (domProps.style[styleName] == null) {
          domProps.style[styleName] = "";
        }
      }
    }

    previousStyleRef.current = nextDomStyle;

    UIManager.updateView(node, domProps);
  }
}

/**
 * Adds non-standard methods to the hode element. This is temporarily until an
 * API like `ReactNative.measure(hostRef, callback)` is added to React Native.
 */
export default function usePlatformMethods({
  classList,
  pointerEvents,
  style,
}) {
  const previousStyleRef = useRef(null);
  const setNativePropsArgsRef = useRef(null);
  setNativePropsArgsRef.current = { classList, pointerEvents, style };
  // Avoid creating a new ref on every render. The props only need to be
  // available to 'setNativeProps' when it is called.
  const ref = useStable(() => (hostNode) => {
    if (hostNode != null) {
      hostNode.measure = (callback) => UIManager.measure(hostNode, callback);
      hostNode.measureLayout = (relativeToNode, success, failure) =>
        UIManager.measureLayout(hostNode, relativeToNode, failure, success);
      hostNode.measureInWindow = (callback) =>
        UIManager.measureInWindow(hostNode, callback);
      hostNode.setNativeProps = (nativeProps) => {
        const { classList, style, pointerEvents } =
          setNativePropsArgsRef.current || emptyObject;
        setNativeProps(
          hostNode,
          nativeProps,
          classList,
          pointerEvents,
          style,
          previousStyleRef
        );
      };
    }
  });

  return ref;
}
