import React, { useEffect, useState } from "react";
import "moment/locale/fr";
import Upload from "$components/Upload";
import Box from "$components/Box";
import SimpleGrid from "$components/SimpleGrid";
import {
  Button,
  Layout,
  message,
  Drawer,
  Modal,
  Form,
  Descriptions,
  Row,
  Col,
} from "antd";

import {
  useGetEmployee,
  useAddSignature,
  useGetEmployeeSignature,
  useDeleteAccount,
} from "../../api/api";
import EditEmployee from "./EditEmployee";
import { FormOutlined, CloseOutlined, UploadOutlined } from "@ant-design/icons";
const { Content } = Layout;
export default function Employee({ dataUser, close, t }) {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(1);
  const [fileList, setFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visiblePay, setVisiblePay] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [factureId, setFactureId] = useState(null);
  const [employeeDataId, setUserId] = useState(null);
  const [errored, updateError] = useState();
  const [signature, setSignature] = useState();
  const { mutateAsync: addSignature } = useAddSignature(dataUser?.id);
  const { mutateAsync: deleteAccount } = useDeleteAccount();
  const { data, error, isLoading, refetch } = useGetEmployee(dataUser?.id);
  const { data: dataSignature, isLoading: loadingSignature } =
    useGetEmployeeSignature(dataUser?.id);
  useEffect(() => {
    setEmployeeData(data);
  }, [data]);

  useEffect(() => {
    setSignature(dataSignature?.signaturePath);
  }, [dataSignature]);

  useEffect(() => {
    refetch();
  }, []);

  const doreload = () => {
    setReload(reload + 1);
    setVisiblePay(false);
  };
  const change = (r) => {
    setVisible(true);
    setFactureId(r.sale_id);
  };

  const changeTrans = (r) => {
    setVisiblePay(true);
    setUserId(r);
  };

  const dum = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const deleteEmployee = async () => {
    try {
      setLoading(true);

      let res = await deleteAccount(dataUser?.id);

      message.success(t("successful.recording"));
      close();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.warning(t("error.recording"));
    }
  };

  const handleChange = async ({ fileList }) => {
    const data = new FormData();

    data.append("file", fileList[0].originFileObj);

    try {
      setLoading(true);

      let res = await addSignature(data);
      setSignature(res.signaturePath);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.warning(t("error.recording"));
    }
  };

  return (
    <>
      <Drawer
        width={600}
        onClose={() => doreload()}
        open={visiblePay}
        closable={false}
        bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
        destroyOnClose={true}
      >
        <EditEmployee employeeData={employeeData} close={close} t={t} />
      </Drawer>
      <Box className="grille w100  u-pad-top-s  u-mar-top-m   noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">
        <Box
          className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-vertical-l"
          style={{ top: 0, borderBottom: "1px solid #DDD" }}
        >
          <Box
            className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
            style={{ left: 24 }}
            onClick={() => close()}
          >
            <span className="fs15  " style={{ color: "#1a1a1a" }}>
              <CloseOutlined />
            </span>
          </Box>

          <h2 className="fW600">
            {" "}
            {employeeData?.firstname} {employeeData?.lastname}{" "}
          </h2>

          <Box className="flex itemcenter abs" style={{ right: 24 }}>
            <Box className="flex">
              <Button
                type="danger"
                size="large"
                className="rad8 u-mar-right-s"
                onClick={() => setVisible(true)}
              >
                <span className="fs16 av-heavy">
                  {" "}
                  {t("employees.delete.button")}
                </span>
              </Button>

              <Box className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
                <span
                  className="fs15 modify-employee-data-btn av-heavy u-mar-right-xs"
                  style={{ color: "#1a1a1a" }}
                  disabled = {!!!(employeeData)}
                  onClick={() => {
                    if(!employeeData) return;
                    changeTrans(employeeData.id)
                  }}
                >
                  {t("employees.edit.button")}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="flex column ">
          {employeeData != null && (
            <Box className="grille w100  u-pad-top-s w70 mauto   u-mar-top-xl-res nopadres noradres ">
              <Row className="u-mar-top-xl ">
                <Col
                  span={17}
                  className="hauto blok rad16 justcenter itemcenter "
                >
                  <Box className="flex column justcenter itemcenter h100 ">
                    <Descriptions column={3}>
                      <Descriptions.Item
                        label="Phone"
                        style={{ paddingTop: 30, paddingLeft: 35 }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="icon"
                          style={{
                            position: "absolute",
                            left: -34,
                            top: 5,
                            color: "#0050dc",
                          }}
                        >
                          <path
                            d="M9.366 10.682C10.3043 12.3305 11.6695 13.6957 13.318 14.634L14.202 13.396C14.3442 13.1969 14.5543 13.0569 14.7928 13.0023C15.0313 12.9478 15.2814 12.9825 15.496 13.1C16.9103 13.8729 18.4722 14.3378 20.079 14.464C20.3298 14.4839 20.5638 14.5975 20.7345 14.7823C20.9052 14.9671 21 15.2094 21 15.461V19.923C21.0001 20.1706 20.9083 20.4094 20.7424 20.5932C20.5765 20.777 20.3483 20.8927 20.102 20.918C19.572 20.973 19.038 21 18.5 21C9.94 21 3 14.06 3 5.5C3 4.962 3.027 4.428 3.082 3.898C3.10725 3.6517 3.22298 3.42352 3.40679 3.25763C3.5906 3.09175 3.82941 2.99995 4.077 3H8.539C8.79056 2.99997 9.0329 3.09475 9.21768 3.26545C9.40247 3.43615 9.51613 3.67022 9.536 3.921C9.66222 5.52779 10.1271 7.08968 10.9 8.504C11.0175 8.71856 11.0522 8.96874 10.9977 9.2072C10.9431 9.44565 10.8031 9.65584 10.604 9.798L9.366 10.682ZM6.844 10.025L8.744 8.668C8.20478 7.50409 7.83535 6.26884 7.647 5H5.01C5.004 5.166 5.001 5.333 5.001 5.5C5 12.956 11.044 19 18.5 19C18.667 19 18.834 18.997 19 18.99V16.353C17.7312 16.1646 16.4959 15.7952 15.332 15.256L13.975 17.156C13.4287 16.9437 12.898 16.6931 12.387 16.406L12.329 16.373C10.3676 15.2567 8.74328 13.6324 7.627 11.671L7.594 11.613C7.30691 11.102 7.05628 10.5713 6.844 10.025Z"
                            fill="currentColor"
                          ></path>
                        </svg>

                        {employeeData.phone}
                      </Descriptions.Item>

                      {
                        <Descriptions.Item
                          label={t("employees.form.label2")}
                          style={{ paddingTop: 30, paddingLeft: 35 }}
                        >
                          <svg
                            style={{
                              position: "absolute",
                              left: -34,
                              top: 5,
                              color: "#0050dc",
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              data-v-20586b3a=""
                              data-v-24a92908=""
                              d="M3 19H21V21H3V19Z"
                              fill="#0075FF"
                            ></path>
                            <path
                              data-v-20586b3a=""
                              data-v-24a92908=""
                              d="M9 14V8H5V10H7V14H5V16H11V14H9Z"
                              fill="#0075FF"
                            ></path>
                            <path
                              data-v-20586b3a=""
                              data-v-24a92908=""
                              d="M13 6V11H19V9H15V8H19V3H13V5H17V6H13Z"
                              fill="#0075FF"
                            ></path>
                          </svg>

                          {employeeData.post ? i18nTObj(employeeData.post) : "--"}
                        </Descriptions.Item>
                      }

                      {
                        <Descriptions.Item
                          label="Email"
                          style={{ paddingTop: 30 }}
                        >
                          <svg
                            style={{
                              position: "absolute",
                              left: -34,
                              top: 5,
                              color: "#0050dc",
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              data-v-20586b3a=""
                              data-v-24a92908=""
                              d="M3 19H21V21H3V19Z"
                              fill="#0075FF"
                            ></path>
                            <path
                              data-v-20586b3a=""
                              data-v-24a92908=""
                              d="M9 14V8H5V10H7V14H5V16H11V14H9Z"
                              fill="#0075FF"
                            ></path>
                            <path
                              data-v-20586b3a=""
                              data-v-24a92908=""
                              d="M13 6V11H19V9H15V8H19V3H13V5H17V6H13Z"
                              fill="#0075FF"
                            ></path>
                          </svg>

                          {employeeData.user ? employeeData.user.email : "--"}
                        </Descriptions.Item>
                      }
                    </Descriptions>
                  </Box>
                </Col>

                <Col
                  span={6}
                  offset={1}
                  className="noshadow blok noborder hauto rad16 "
                >
                  <Box className="justcenter p-relative h100 cursor-pointer nomar flex column itemcenter u-pad-s rel">
                    <Box className="flex column itemcenter">
                      <Box className="flex itemcenter column  cursor">
                        <Upload
                          customRequest={dum}
                          maxCount={1}
                          onChange={handleChange}
                        >
                          <Box className="flex column">
                            {signature ? (
                              <img src={signature} height={170} width={180} />
                            ) : (
                              <span> {t("employees.detail.label6")} </span>
                            )}

                            <Button icon={<UploadOutlined />}>
                              {t("employees.detail.upload.button")}
                            </Button>
                          </Box>
                        </Upload>
                      </Box>
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        width={400}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center"> {t("employees.delete.button")}</h2>

        <h2 className="center"> {t("confirm.delation")} </h2>

        <Form layout="vertical" onFinish={deleteEmployee}>
          <Box className="flex flexend u-mar-top-s">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisible(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.modify.popup.cancel"
                )}{" "}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.modify.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </Box>
        </Form>
      </Modal>
    </>
  );
}
