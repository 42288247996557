import React from "$react";
import Text from "$components/Text";
import Box from "$components/Box";
import Link from "$components/Link";
import {Heading} from "$ui";
import LayoutPage from "$features/layout";
import { useTranslation } from "react-i18next";

export default function NotAllowedAuthComponent(){
    const { t, i18n } = useTranslation();
    return <LayoutPage>
            <Box display="flex" py="3rem" textAlign="center" alignItems="center" flexDirection="column" direction="column" justifyContent="center" h="100%" w="100%">
            <Heading className="text-bold" fontSize="5xl" color="error">{t("auth.notAlowed.notAllowed")}.</Heading>
            <Text fontSize="2xl" lineHeight="30px">
                {t("auth.notAlowed.header")}
            </Text>
            <Box display="flex" textAlign="center" lineHeight="30px" fontSize="18px" direction="column" alignItems="center" justifyContent="center" flexWrap="wrap">
                {t("auth.notAlowed.returnTo")} <Link mr="5px" className="color-error text-bold" ml="5px" to="/" >{" "} {t("auth.notAlowed.homepage")}{"  "}</Link>
                {t("auth.notAlowed.content")}.
            </Box>
        </Box>
    </LayoutPage>
}