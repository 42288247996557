import React, { useState } from "react";
import moment from "moment";
import Upload from "$components/Upload";
import { Form, Button, Modal, Select, message } from "antd";
import {AttachmentPreview,AttachmentDownload} from "$components/Upload";

import { useAddAttachmentpayment,useAddOtherAttachmentPayment, useDeletePaymentAttachment } from "../../../api/api";

import { PlusCircleFilled,FileAddOutlined, UploadOutlined } from "@ant-design/icons";
import { docs } from "../staticData/data";

const { Option } = Select;

export default function Attachments({ payment,onDeleteFile, addNewAttachment, t }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);


  const [fileList, setFileList] = useState([]);
  const [documentId, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);

  const { mutateAsync: addAttachment } = useAddAttachmentpayment(
    payment?.id,
    documentId
  );
  const { mutateAsync: deleteAttachment } = useDeletePaymentAttachment(payment?.id);
  const {mutateAsync:addOtherAttachment} = useAddOtherAttachmentPayment(payment?.id);
  const isOtherAttachmentRef = React.useRef(false);
  
  const openOtherAttachmentModal = (name) => {
    isOtherAttachmentRef.current = true;
    setVisible(true);
  };
  
  const openAddDocument = ()=>{
    isOtherAttachmentRef.current = false;
    setVisible(true)
  }

  const dum = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const onSubmit = async (values) => {
    setDocument(values.doctype);

    const data = new FormData();

    data.append("file", values.upload.fileList[0].originFileObj);

    try {
      setLoading(true);
      const resp = isOtherAttachmentRef?.current ? await addOtherAttachment(data) : await addAttachment(data);
      addNewAttachment(resp);
      isOtherAttachmentRef.current = false;
      setVisible(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
      isOtherAttachmentRef.current = false;
    }
  };

  const openDeleteFile = async (id, name) => {
    setDocumentName(name);
    setDocument(id);
    setVisibleDelete(true);
  };

  const deleteFile = async () => {
    try {
      setLoading(true);

      let res = await deleteAttachment(documentId);

      message.success(t("successful.recording"));
      setVisibleDelete(false);
      setLoading(false);
      if(typeof onDeleteFile =='function'){
        onDeleteFile(document);
      }
    } catch (e) {
      setLoading(false);
      message.warning(t("error.recording"));
    }
  };

  return (
    <>
      <div className="appro rel">
        <div className="w100">
          <div className="flex itemcenter">
            <div className="u-mar-right-s"></div>
          </div>

          <div className="w100">
            <div
              className="flex justbtw u-pad-vertical-xs  u-mar-top-s"
              style={{
                borderBottom: "1px #b3b3b3 solid",
              }}
            >
              <span className="grille4 fW500">
                {t("commitmentForms.form.section3.label0")}
              </span>
              <span className="grille21 fW500">
                {" "}
                {t("commitmentRequests.detail.section1.tab2.label1")}
              </span>
              <span className="grille4 fW500">
                {" "}
                {t("commitmentRequests.detail.section1.tab2.label2")}
              </span>
              <span className="grille21 ">
                <h3
                  className="fW600  coltext cursor nomar"
                  onClick={() => openAddDocument()}
                >
                  {" "}
                  <PlusCircleFilled /> {t("add.button")}
                </h3>
              </span>
              <Button
                  icon = {<FileAddOutlined />}
                  className="add-other-document"
                    onClick={() => {
                      openOtherAttachmentModal();
                    }}
                  >
                    {t("addOtherDocument")}
                </Button>
            </div>

            <ul className="cart-list" style={{ height: "auto" }}>
              {payment.attachments.map((row) => (
                <li
                  key={row.id}
                  className="section-cart flex itemcenter justbtw  u-pad-left-xs "
                >
                  <span className="grille4 av-heavy fs14 ">
                    {i18nTObj(row.document)}
                  </span>

                  <span className="grille21 av-heavy fs14 ">
                    {moment(row.date).format("D MMM-YY H:mm")}
                  </span>

                  <span className="grille4 av-heavy fs14 ">
                    {row.owner.firstname + " " + row.owner.lastname}
                  </span>
                  <AttachmentPreview 
                    attachment={row}
                    className="grille4 av-heavy fs14 "
                    downloadLink = {`/api/attachments/${row?.id}/download`}
                  />
                  <AttachmentDownload 
                    downloadLink = {`/api/attachments/${row.id}/download`}
                    fileName = {i18nTObj(row)}
                  />

                  <span
                    className="grille av-heavy fs14 coltext u-mar-left-s"
                    onClick={() => openDeleteFile(row.id, i18nTObj(row.document))}
                  >
                    {t("delete")}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        width={500}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 400 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center"> {t(isOtherAttachmentRef.current ? "addOtherDocument" : "add.attachment")}</h2>

        <Form
          layout="vertical"
          className=" itemcenter u-mar-top-xl"
          onFinish={onSubmit}
        >
          {!isOtherAttachmentRef?.current && <Form.Item
            name="doctype"
            label={t("commitmentForms.form.section3.title1")}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Select
              placeholder="Selectionner le type de document"
              optionFilterProp="children"
            >
              {docs != null &&
                docs.map((doc,key) => <Option key={key} value={doc.id}> {i18nTObj(doc)} </Option>)}
            </Select>
          </Form.Item>}

          <Form.Item
            name="upload"
            style={{ marginTop: 28 }}
            rules={[{ required: true, message: "Entrer document" }]}
          >
            <Upload
              customRequest={dum}
              maxCount={1}
              onChange={handleChange}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>
                {t("commitmentForms.form.section3.label3")}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item name="btn" style={{ marginTop: 28 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
              className="rad8"
            >
              <span className="fs16 av-heavy"> {t("add.button")} </span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={500}
        onCancel={() => setVisibleDelete(false)}
        open={visibleDelete}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center"> {t("confirm.delete.attachment")}</h2>

        <h2 className="fW500 w100 center"> {documentName}</h2>

        <Form layout="vertical" onFinish={deleteFile}>
          <div className="flex flexend u-mar-top-l">
            <Button
              type="default"
              htmlType="submit"
              size="large"
              className="rad8"
              onClick={() => setVisibleDelete(false)}
            >
              <span className="fs16 av-heavy">
                {" "}
                {t("commitmentRequests.detail.section3.modify.popup.cancel")}
              </span>
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className="u-mar-left-s rad8"
            >
              <span className="fs16 av-heavy">
                {" "}
                {t(
                  "commitmentRequests.detail.section3.modify.popup.confirm"
                )}{" "}
              </span>
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
