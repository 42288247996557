import React, { useState, useEffect } from "$react";
import moment from "moment";
import Upload from "$components/Upload";
import Select from "$components/Select";
import {Input as ChakraInput} from "$ui";
import {extendObj} from "$utils";
import {
  Button,
  DatePicker,
  Form,
  Col,
  message,
  Row,
  Divider,
  Input,
  AutoComplete,
  Steps,
  Space,
} from "antd";
import {
  CloseOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  useAddDocument,
  useAddExternalMember,
  useAddMember,
  useAddMovement,
  useEditMovement,
} from "../../api/api";
import { useTranslation } from "react-i18next";
const { Option } = Select;
import {isObj,isNonNullString} from "$utils";
const dateFormat = 'YYYY-MM-DD';
import dayjs from 'dayjs';
import DateComponent,{parse} from "$components/Date";

export default function MovementForm({
  close,
  current_element,
  is_update,
  initiator,
  documents,
  updateElement,
  updateAttachment,
  members,
}) {
  const { t,i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [elements, setElements] = useState(null);
  const [document_id, setDocumentId] = useState(0);
  const [movement_id, setMovementId] = useState(0);
  const dataUpdateRef = React.useRef(null);
  const [id, setId] = useState(0);
  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);
  const { mutateAsync: addMovement } = useAddMovement();
  const { mutateAsync: addAttachments } = useAddDocument(
    movement_id,
    document_id
  );
  const { mutateAsync: addMember } = useAddMember(movement_id);
  const { mutateAsync: addExternalMember } = useAddExternalMember(movement_id);
  useEffect(() => {
    if (is_update) {
      startDateRef.current = current_element.startDate;
      endDateRef.current = current_element.endDate;
      setId(current_element.id);
    } else {
      setId(0);
    }
  }, [is_update]);
  React.useEffect(()=>{
      ///STATE
      if(typeof movement_id !== 'number' || !isObj(dataUpdateRef.current)) return;
      const {formData, members,external_members} = dataUpdateRef.current;
      dataUpdateRef.current = null;
      addAttachments(formData);
      addMember(members);
      addExternalMember(external_members);
  },[movement_id,dataUpdateRef]);
  
  const { mutateAsync: updateMovement } = useEditMovement(id);
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        try {
          let final_values = { ...values, ...elements };
          let formData = new FormData();
          const {
            external_members,
            members,
            documentType,
            attachments,
            context,
            risk,
            mitigation,
            objectives,
            startDate,
            endDate,
            ...rest
          } = final_values;
          rest.startDate = moment(startDate).format("YYYY-MM-DD");
          rest.endDate = moment(endDate).format("YYYY-MM-DD");
          let tab = [];
          tab.push(objectives);
          rest.objectives = tab;

          if (risk) {
            tab = [];
            tab.push(risk);
            rest.risk = tab;
          }

          if (context) {
            tab = [];
            tab.push(context);
            rest.context = tab;
          }

          if (mitigation) {
            tab = [];
            tab.push(mitigation);
            rest.mitigation = tab;
          }

          setLoading(true);

          if (is_update) {
            let rep = await updateMovement(rest);
            updateElement(rep);
          } else {
            const file = attachments.file?.originFileObj;
            formData.append("file ", file);
            const resp = await addMovement(rest);
            setMovementId(resp.id);
            dataUpdateRef.current = {
              formData, members,external_members
            }
          }
          setTimeout(()=>{
            message.success(t("successful.recording"));
            setLoading(false);
            close();
          },500);
        } catch (e) {
          setLoading(false);
          console.log(e);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  const previous = () => {
    setCurrent(current - 1);
  };

  const nextStep = () => {
    form
      .validateFields()
      .then((values) => {
        setElements(values);
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        console.log(errorInfo," is validating error infos");
      });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setDocumentId(value);
  };
  console.log(current_element," is current")
  const steps = [
    {
      title: t("travelRequests.form.step1.title"),
      key : "1",
      content: (
        <Form
          layout="vertical"
          className="rel"
          form={form}
          initialValues={{ ...current_element }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="country"
                label={t("travelRequests.form.step1.label0")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="accountantId"
                label={t("travelRequests.form.step1.label1")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  //onChange={onChange}
                  //onFocus={onFocus}
                  //onBlur={onBlur}
                  //onSearch={onSearchZipCode}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  autoComplete="none"
                  // disabled={true}
                >
                  {initiator.map((item,key) => (
                    <Option key={item.id} value={item.id}>
                      {item.firstname + " " + item.lastname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="objectives"
                label={t("travelRequests.form.step1.label2")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"startDate"}
                label={t("travelRequests.form.step1.label3")}
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if(!value) return Promise.reject(new Error(t("required")));
                      if (value < new Date()) {
                        return Promise.reject(
                          new Error(t("projects.form.Error.label3"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DateComponent
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(startDate) => {
                    startDateRef.current = startDate;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="endDate"
                label={t("travelRequests.form.step1.label4")}
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(new Error(t("required")));
                      } else {
                        if (!startDateRef.current) {
                          return Promise.reject(
                            new Error(
                              t("travelRequests.form.Error.step1.label41")
                            )
                          );
                        } else {
                          if (value < startDateRef.current) {
                            return Promise.reject(
                              new Error(
                                t("travelRequests.form.Error.step1.label42")
                              )
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DateComponent
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(startDate) => {
                    startDateRef.current = startDate;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="starting"
                label={t("travelRequests.form.step1.label5")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {!is_update && (
              <>
                <Col span={12}>
                  <Form.Item
                    name="documentType"
                    label={t("travelRequests.form.step1.label6")}
                    rules={
                      !is_update && [{ required: true, message: t("required") }]
                    }
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      onChange={onChange}
                    >
                      {documents.map((doc,key) => (
                        <Option value={doc.id} key={key}>{i18nTObj(doc)}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="attachments"
                    label={t("travelRequests.form.step1.label7")}
                    rules={
                      !is_update && [{ required: true, message: t("required") }]
                    }
                  >
                    <Upload
                      //onChange={handleChange}
                      listType="picture-circle"
                      //fileList={fileList}
                      beforeUpload={(file) => {
                        //console.log(file);
                        //console.log('picture',this.state.picture)
                        return false;
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Choisir</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="members"
                    label={t("travelRequests.form.step1.label8")}
                    rules={[{ required: true, message: t("required") }]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                    >
                      {members.map((member,key) => (
                        <Option value={member.id} key={key}>
                          {member.firstname + " " + member.lastname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.List name="external_members">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                { required: true, message: t("required") },
                              ]}
                            >
                              <Input
                                placeholder={t(
                                  "travelRequests.form.step1.label9"
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "profession"]}
                              rules={[
                                { required: true, message: t("required") },
                              ]}
                            >
                              <Input
                                placeholder={t(
                                  "travelRequests.form.step1.label10"
                                )}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            {t("travelRequests.form.step1.button.label")}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </>
            )}
          </Row>
        </Form>
      ),
    },
    {
      title: t("travelRequests.form.step2.title"),
      key : "2",
      content: (
        <Form
          layout="vertical"
          className="rel"
          form={form}
          initialValues={{ ...current_element }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="destination"
                label={t("travelRequests.form.step2.label0")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="otherDestination"
                label={t("travelRequests.form.step2.label1")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="transportModeGone"
                label={t("travelRequests.form.step2.label2")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="transportModeReturn"
                label={t("travelRequests.form.step2.label3")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="transportModeOther"
                label={t("travelRequests.form.step2.label4")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="hosting"
                label={t("travelRequests.form.step2.label5")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="otherHosting"
                label={t("travelRequests.form.step2.label7")}
                rules={[{ required: true, message: t("required") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="context"
                label={t("travelRequests.form.step2.label8")}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="risk"
                label={t("travelRequests.form.step2.label9")}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mitigation"
                label={t("travelRequests.form.step2.label10")}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>
        <h2 className="fW600">
          {" "}
          {is_update
            ? "Nouvelle demande Déplacement"
            : "Modification de la demande de déplacement"}
        </h2>
        {current === steps.length - 1 && (
          <div className="flex itemcenter abs" style={{ right: 34 }}>
            <Button
              type="primary"
              size="large"
              className="rad8"
              onClick={() => handleSubmit()}
              loading = {loading}
            >
              <span className="fs16 av-heavy"> {t("validate.button")} </span>
            </Button>
          </div>
        )}
      </div>
      <div className="u-mar-vertical-xxl  w50 mauto">
        <Steps current={current}
          items = {steps.map((item,key) => ({
            key,
            title :item.title,
            description  : item.description,
          }))}
        />
        {steps[current].content}
        {current > 0 && (
          <Button onClick={previous}>
            {t("travelRequests.form.previousButton.label")}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={nextStep}>
            {t("travelRequests.form.step1.nextButton.label")}
          </Button>
        )}
      </div>
    </div>
  );
}
