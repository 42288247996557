import React, { useState } from "react";
import ValidationLine, {Row as RowLine,RowHeader} from "$containers/ValidationLine";

import {
  Dropdown,
  Menu,
  Form,
  Divider,
  Modal,
  Button,
  Input,
  message,
} from "antd";
import { useAddAnalytic } from "../../../api/api";
import Box from "$components/Box";
import { useAddAnalRemark } from "../../../api/api";

export default function AnalyticsCode({ idticket,showProjectCode,withTitle, ticket,canUpsert, addNewCodes, t,...rest }) {
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [remark, setRemark] = useState(null);
  const [visible, setVisible] = useState(null);
  const [visibleRemark, setVisibleRemark] = useState(null);
  const [spending, setSpending] = useState(null);
  const [loading, setLoading] = useState(false);

  const { mutateAsync: addAnalytic } = useAddAnalytic(idticket);
  const { mutateAsync: addRemark } = useAddAnalRemark(ticket?.id, remark);

  const onSubmitRemark = async (values) => {
    setRemark(values.remark);

    try {
      setLoading(true);

      await addRemark();
      setVisible(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const setCode = (row) => {
    setSpending(row);
    setVisible(true);
  };

  const onSubmit = async (values) => {
    if (!values) return;

    values.id = spending?.id;
    try {
      setSubmitting(true);
      let paylaod = [];
      paylaod.push(values);
      const resp = await addAnalytic(paylaod);

      addNewCodes(resp[0]);

      setVisible(false);
      message.success(t("successful.recording"));
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      message.warning(t("error.recording"));
    }

    setSubmitting(false);
    setValue("");
  };
  const canBeUpsert = canUpsert !== false;
  const rows = [];
  const lineA = Array.isArray(ticket?.items) && ticket?.items || null;
  if(lineA){
    lineA.map((row,id)=>{
        rows.push(<RowLine key={id} className={"analytic-code-line"}>
              {showProjectCode !== false && <Box as="span" whiteSpace="nowrap" px="10px" className="av-heavy fs14 ">{id + 1}</Box>}
              {<Box as="span" whiteSpace="nowrap" px="10px" className="av-heavy fs14 ">
                  {row.project_code}
              </Box>}
              <Box as="span" whiteSpace="nowrap" px="10px" className="av-heavy ">
                {row.project_line}
              </Box>

              <Box as="span" whiteSpace="nowrap" px="10px" className="">
                {" "}
                {row.financial_code}{" "}
              </Box>

              <Box as="span" whiteSpace="nowrap" px="10px" className="right">
                {" "}
                {row.financial_line}{" "}
              </Box>

              {canBeUpsert && <Box as="span" whiteSpace="nowrap" px="10px"
                className=" coltext fs12 fW500  cursor nomar"
                onClick={() => setCode(row)}
              >
                {t("reassignment.label")}
              </Box>}
        </RowLine>)
    })
  }
  return (
    <>
      <Box className="noshadow noborder w100 " px="10px">
        {withTitle !== false && <Box className="flex  itemcenter w100 justbtw">
          <h2 className="fW600 u-mar-top-xs">
            {t("commitmentRequests.detail.section4.title")}
          </h2>
        </Box>}
        <ValidationLine
            whiteSpaceNoWrap
            {...rest}
            headers={<RowHeader>
              {showProjectCode !== false && <Box as="span" whiteSpace="nowrap" px="10px" className="fW500">
                {" "}
                {t("commitmentRequests.detail.section4.label0")}{" "}
              </Box>}
              <Box as="span" whiteSpace="nowrap" px="10px" className="fW500">
                {" "}
                {t("commitmentRequests.detail.section4.label1")}{" "}
              </Box>
              <Box as="span" whiteSpace="nowrap" px="10px" className="fW500">
                {" "}
                {t("commitmentRequests.detail.section4.label2")}
              </Box>
              <Box as="span" whiteSpace="nowrap" px="10px" className="fW500">
                {" "}
                {t("commitmentRequests.detail.section4.label3")}
              </Box>
              <Box as="span" whiteSpace="nowrap" px="10px" className=" fW500">
                {" "}
                {t("commitmentRequests.detail.section4.label4")}
              </Box>
              {canBeUpsert && <Box className="fW500" px="10px" as ="span">Actions</Box>}
          </RowHeader>}
            rows = {rows}
        />
      </Box>
      <Modal
        width={500}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 600 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.detail.section4.popup.title")}
        </h2>

        <Form
          layout="vertical"
          initialValues={{
            project_code: spending?.project_code
              ? spending?.project_code
              : ticket?.demand?.projects?.map((u) => u.code)[0],
            project_line: spending?.project_line,
            financial_code: spending?.financial_code,
            financial_line: spending?.financial_line,
          }}
          className=" itemcenter u-mar-top-xl"
          onFinish={onSubmit}
        >
          <h3 className="fW600"> {spending?.description} </h3>
          <Form.Item
            name="project_code"
            label={t("commitmentRequests.detail.section4.popup.label0")}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="project_line"
            style={{ marginTop: 5 }}
            label={t("commitmentRequests.detail.section4.popup.label1")}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="financial_code"
            label={t("commitmentRequests.detail.section4.popup.label2")}
            style={{ marginTop: 5 }}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="financial_line"
            label={t("commitmentRequests.detail.section4.popup.label3")}
            style={{ marginTop: 5 }}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Input />
          </Form.Item>

          <Box className="flex flexend">
            <Form.Item style={{ marginTop: 20 }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={submitting}
                className="rad8"
              >
                <Box as="span" whiteSpace="nowrap" px="10px" className="fs16 av-heavy"> {t("validate.button")} </Box>
              </Button>
            </Form.Item>
          </Box>
        </Form>
      </Modal>
      <Modal
        width={500}
        onCancel={() => setVisibleRemark(false)}
        open={visibleRemark}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 320 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <Box className="modal-content-container" overflow="auto">
          <h2 className="fW500 w100 center">
            {" "}
            Ajouter une remarque sur <br />
            les codes analytiques
          </h2>
  
          <Form
            layout="vertical"
            className=" itemcenter u-mar-top-xl"
            onFinish={onSubmitRemark}
          >
            <Form.Item
              name="remark"
              style={{ marginTop: 28 }}
              rules={[{ required: true, message: "Entrer document" }]}
            >
              <Input />
            </Form.Item>
  
            <Form.Item name="btn" style={{ marginTop: 28 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                size="large"
                className="rad8"
              >
                <Box as="span" whiteSpace="nowrap" px="10px" className="fs16 av-heavy"> AJouter la remarque</Box>
              </Button>
            </Form.Item>
          </Form>
        </Box>
      </Modal>
    </>
  );
}
