import { steps } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchSteps = (...args)=>{
    return fetch(steps,...args);
}

export const getSteps = (...args)=>{
    return get(steps,...args);
}

export const SelectStep = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {steps}
        ref = {ref}
    />
});

SelectStep.displayName = "SelectStep";

const   INITIATOR = 0,MANAGER = 1,
ACCOUNTANT = 2,
COORDINATOR = 3,
TREASURER = 4;

export {INITIATOR,MANAGER,ACCOUNTANT,COORDINATOR,TREASURER};

export default {INITIATOR,MANAGER,ACCOUNTANT,COORDINATOR,TREASURER,fetch:fetchSteps,get:getSteps}