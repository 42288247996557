import React, { useState, useEffect } from "react";
import "moment/locale/fr";
import Select from "$components/Select";
import {
  Button,
  Form,
  Col,
  message,
  Row,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useAddMember } from "../../api/api";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function MemberForm({
  close,
  members,
  movement_id,
  addMembers,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: addMember } = useAddMember(movement_id);
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        try {
          setLoading(true);

          let resp = await addMember(values.members);
          addMembers(resp);

          close();

          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          setLoading(false);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>
        <h2 className="fW600"> {t("travelRequests.detail.addMemberText")}</h2>
        <div className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("add.button")} </span>
          </Button>
        </div>
      </div>
      <div className="u-mar-vertical-xxl  w50 mauto">
        <Form layout="vertical" className="rel" form={form}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="members"
                label={t("travelRequests.form.step1.label8")}
                rules={[{ required: true, message: "Entrer les membres" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  //placeholder="Please select"
                >
                  {members.map((member,key) => (
                    <Option value={member.id} key={key}>
                      {member.firstname + " " + member.lastname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
