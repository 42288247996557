import "./string";
import isNonNullString from "./isNonNullString";
export * from "./string";

export { default as isNonNullString } from "./isNonNullString";
export { default as isDateObj } from "./isDateObj";
export { default as isPromise } from "./isPromise";
export * from "./extendObj";
export { default as extendObj } from "./extendObj";
export { default as defaultStr } from "./defaultStr";
export { default as isTouchDevice } from "./isTouchDevice";
export { default as uniqid } from "./uniqid";
export { default as sprintf } from "./sprintf";
export { default as StringBuilder } from "./StringBuilder";
export * from "./dom";
export { default as classNames } from "./classNames";
export { default as isValidEmail } from "./isValidEmail";
export * from "./uri";
export { default as Queue } from "./queue";
export { default as debounce } from "./debounce";
export * from "./numberToLocaleString";
export { default as isDataURL } from "./isDataURL";
export { default as parseDecimal } from "./parseDecimal";

export * from "./areEquals";

export const isFunction = (x) => typeof x == "function";

export const isObjectOrArray = (a) => isObj(a) || Array.isArray(a);

export function isObj(obj) {
  if (!obj) return false;
  return obj &&
    Object.prototype.toString.call(obj) === "[object Object]" &&
    !(obj instanceof RegExp)
    ? true
    : false;
}

export const isNumber = function (n) {
  if (typeof n === "number" && !isNaN(n)) return true;
  return n === +n && n === (n | 0);
};

export function isInteger(x) {
  return typeof x === "number" && isFinite(x) && Math.floor(x) === x;
}

export const isFloat = function (n) {
  if (isNumber(n)) return true;
  if (isNaN(n)) return false;
  return n === +n && n !== (n | 0);
};

export const isDecimal = isFloat;

export const ObjectMapToArray = (Object.mapToArray = function (obj, fn) {
  if (Array.isArray(obj)) {
    return obj.map(fn);
  }
  return Object.toArray(Object.map(obj, fn));
});
export const mapObj = (Object.map = function (obj, fn) {
  if (typeof fn != "function") {
    return {};
  }
  if (Array.isArray(obj))
    return obj.map((item, index) => {
      return fn(item, index, index);
    });
  if (!obj || typeof obj !== "object") {
    return {};
  }
  var oReturn = {};
  let mapI = 0;
  for (let sCurObjectPropertyName in obj) {
    if (obj.hasOwnProperty(sCurObjectPropertyName)) {
      oReturn[sCurObjectPropertyName] = fn.call(
        obj,
        obj[sCurObjectPropertyName],
        sCurObjectPropertyName,
        mapI,
        true
      );
      mapI++;
    }
  }
  return oReturn;
});

export function defaultVal() {
  var args = Array.prototype.slice.call(arguments, 0);
  let nullV = undefined;
  for (let i in args) {
    if (args[i] !== undefined && args[i] !== "") {
      if (args[i] === null) {
        nullV = null;
      } else {
        return args[i];
      }
    }
  }
  return nullV;
}

export function defaultBool() {
  var args = Array.prototype.slice.call(arguments, 0);
  for (var i in args) {
    if (typeof args[i] === "boolean") return args[i];
  }
  return false;
}

export function defaultDecimal() {
  var args = Array.prototype.slice.call(arguments, 0);
  for (var i in args) {
    if (isDecimal(args[i]) && args[i] !== 0) return args[i];
  }
  return 0;
}

export const defaultNumber = function () {
  var args = Array.prototype.slice.call(arguments, 0);
  for (var i in args) {
    if (isNumber(args[i]) && args[i] !== 0) return args[i];
  }
  return 0;
};

export const isUndefined = (str) => {
  return typeof str === "undefined";
};

export const defaultFunc = function () {
  var args = Array.prototype.slice.call(arguments, 0);
  for (var i in args) {
    if (typeof args[i] === "function") return args[i];
  }
  return () => {};
};
/*** fix bug, defaultObj, où l'on perd la référence lorsque l'orsqu'une seule variable passée en paramètre est de type object */
export const defaultObj = function () {
  var args = Array.prototype.slice.call(arguments, 0);
  if (args.length === 1) return isObj(args[0]) ? args[0] : {};
  for (var i in args) {
    const x = args[i];
    if (isObj(x) && Object.size(x, true) > 0) return x;
  }
  return {};
};
export const isObjOrArray = (x) => (x && typeof x == "object" ? true : false);
export const defaultObjOrArray = function () {
  var args = Array.prototype.slice.call(arguments, 0);
  for (var i in args) {
    let x = args[i];
    if (isObjOrArray(x)) return x;
  }
  return null;
};

export const defaultArray = function () {
  var args = Array.prototype.slice.call(arguments, 0);
  if (args.length === 1 && Array.isArray(args[0])) return args[0];
  for (var i in args) {
    let x = args[i];
    if (x && Array.isArray(x) && x.length > 0) return x;
  }
  return [];
};

/**** 
* determines the size of an array/object
* @param {object|Array} obj the object to determine the size
  @param {boolean} breakonFirstElementFound, returns immediately after the first element found
*/
export const objectSize = (Object.size = function (
  obj,
  breakonFirstElementFound
) {
  if (!obj || typeof obj != "object") return 0;
  if (Array.isArray(obj)) {
    return obj.length;
  }
  if (typeof breakonFirstElementFound !== "boolean") {
    breakonFirstElementFound = false;
  }
  let size = 0;
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
      if (breakonFirstElementFound === true) return size;
    }
  }
  return size;
});


/*** suites des constantes et fonctions utiles */
/**
    retourne l'extension du fichier passé en paramètre
    @param string le chemin du fichier
    @param bool withoutDash, précise si l'extension sera retourné avec le point ou pas
*/
export const getFileExtension = function(path,withoutDash){
  if(!path || typeof path != 'string') return '';
  if(path.indexOf(".") ===-1) return '';
  var er = path.split(".");
  var e = er[er.length-1];
  //var e = path.slice(1).split('.').pop();
  withoutDash = (withoutDash == undefined) ? true : withoutDash;
  e = e.trim().ltrim(".");
  if(e.startsWith("/")){
      return '';
  }
  if(!withoutDash){
      e = "."+e;
  } 
  return e;
}
const blobObjectUrlRegex = /^blob:(?<origin>[\w\+]+:\/\/(?=.{1,254}(?::|$))(?:(?!\d|-)(?![a-z0-9\-]{1,62}-(?:\.|:|$))[a-z0-9\-]{1,63}\b(?!\.$)\.?)+(:\d+)?)\/(?<uuid>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;

export function isBlobOrObjectUrl( url ) {
    return ( typeof url === 'string' ) && blobObjectUrlRegex.test( url );
}