import {isObj,defaultObj,isNumber} from "$utils";
import { isSignedIn,getLocalUser } from "./session";
import {ADMIN_ROLES} from "$roles";

export function hasRoles(userRoles){
    if(isNumber(userRoles)){
        userRoles = [userRoles];
    }
    if(!Array.isArray(userRoles)) return false;
    if(!isSignedIn()) return false;
    const user = getLocalUser();
    const roles = defaultObj(user?.userInfos,user?.user).role;
    if(!roles || typeof roles !=='object') return false;
    if(hasRole(roles?.id,userRoles)) return true;
    if(Array.isArray(roles)){
        for(let i in roles){
            if(isObj(roles[i]) && hasRole(roles[i].id,userRoles)) return true;
        }
    }
    return false;
}

export {ADMIN_ROLES};

const hasRole = (id,roles)=> isNumber(id) && roles.includes(id);