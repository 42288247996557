//default base component for each element
import * as React from "react";

export const mergeRefs = (...args) => {
  return function forwardRef(node) {
    args.forEach((ref) => {
      if (ref == null || !ref) {
        return;
      }
      if (typeof ref === "function") {
        ref(node);
        return;
      }
      if (typeof ref === "object") {
        ref.current = node;
        return;
      }
      console.error(
        `mergeRefs cannot handle Refs of type boolean, number or string, received ref ${String(
          ref
        )}`
      );
    });
  };
};

export const useMergeRefs = (...args) => {
  return React.useMemo(() => mergeRefs(...args), [...args]);
};
