import * as Dim from "./utils";
import { Dimensions } from "./utils";

export * from "./utils";

export { default as useWindowDimensions } from "./useWindowDimensions";

["get", "addEventListener", "removeEventListener", "set"].map((v) => {
  if (typeof Dimensions[v] == "function") {
    Dim[v] = Dimensions[v].bind(Dimensions);
  }
});

export default Dim;
