import React, { useState, Fragment } from "react";

import { Form, Input, Button,Dropdown } from "antd";
import { Link } from "react-router-dom";
import {langs} from "$i18n";
import LangSelector from "$components/LangSelector";
import { useLoginUser } from "../../../api/api";
import { useTranslation } from "react-i18next";
import Box from "$components/Box";

export default function FirstForm({ handleOtp, handleUser }) {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { mutateAsync: login } = useLoginUser();

  async function onSubmit(values) {
    try {
      setLoading(true);
      const result = await login(values);
      handleUser(result);
      handleOtp(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      handleOtp(false);
    }
  }
  const activeLang = i18n.resolvedLanguage;
  return (
    <Fragment>
      <Form
        name="normal_login"
        className="login-form w25 w100res w70tab bgwhite column flex itemcenter  u-pad-horizontal-l u-pad-top-l rad8 z999"
        size="large"
        onFinish={onSubmit}
        style = {{padding:"30px"}}
      >
        <img
          src="/logo.png"
          className="w50 u-mar-bottom-xl"
          style={{ borderRadius: 8 }}
        />
        <LangSelector renderAsMenu my="10px"/>
        <Box mb="15px" w="100%">
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: t("auth.signIn.email"),
              },
            ]}
            className="u-mar-bottom-m w100"
          >
            <Input placeholder={t("auth.signIn.email")}/>
          </Form.Item>
        </Box>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t("auth.signIn.password"),
            },
          ]}
          className="u-mar-bottom-xs w100"
        >
          <Input type="password" placeholder={t("auth.signIn.password")} />
        </Form.Item>

        <div
          className="fl-r w100 u-mar-bottom-l av-roman "
          style={{ textAlign: "right" }}
        >
          <Link to={"/reset"} className="login-form-forgot fs12">
            {t("auth.signIn.forgotPassword")} ?
          </Link>
        </div>

        <Form.Item className="w100">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            className="rad8 w100"
          >
            <span className="fs18 av-heavy">{t("auth.signIn.login")}</span>
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
