import { tableLayouts } from "./createPDF";
export const tableHeaderBackgroundColor = "#C0C0C0";

export const textColor = "#C0C0C0";
export const fillColor =  "#C0C0C0";


export const underlined = {decoration:"decoration"};

export const decoration = "underline";


const ticketItemHeader =  {
    fillColor : "#C0C0C0",
    marginTop : 3,
    marginBottom:3,
    fontSize : 8,
    bold:true,
    alignment:"center"
};
export const styles = {
    title : {
        fontSize : 14,
        bold : true,
        marginLeft:5,
        marginRight:5,
        marginTop :5,
    },
    elementTitle : {
        bold:true,
        fontSize : 9,
        marginLeft:5,
        marginRight:5,
        marginTop :5,
    },
    tableHeader : {
        fillColor,
    },
    tableHeaderTitle : {
        fontSize: 10,
        bold: true,
        marginLeft:5,
        marginRight:5,
        marginTop :3,
    },
    tableHeaderSubtitle : {
        fontSize:7,
        marginLeft:5,
        marginRight:5,
        marginTop :0,
    },
    codeCommandeItem : {
        fontSize:8,
    },
    ticketItemHeader,
    pageHeaderTitle : {
        fontSize : 15,
        bold : true,
    },
    ticketItem : {
        fontSize : 8,
        marginLeft : 3,
        marginTop : 3,
    },
    ticketValidationRule : {
        fontSize : 6.5,
    },
    ticketValidationKey : {
        fontSize : 9,
        italics : true,
    },
    ticketValidationValue : {
        fontSize : 9,
        decoration,
        paddingBottom : 0,
    },
    //les titre des annexe du dossier
    ticketAnnexTableTitle : {
        fontSize : 8,
        bold : true,
        marginTop : 2,
    },
    ticketAnnexTableLabel : {
        fontSize : 8,
        marginTop : 1,
    },
    ticketAnnexTableValue : {
        fontSize : 10,
        alignment : "center",
        bold : true,
    },
    ticketItemAnalyticsHeader : {
        ...ticketItemHeader,
        marginTop : 5,
    },
}


/*** each unit is pt
    595 × 842 points, for A4 page size
*/
export const pageSettings = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [ 12, 12, 12, 12 ],
}

export const pdfDocInfo  = {
    styles,
    ...pageSettings,
}
export const line = {
    canvas : [
        {
            type: 'line',
            x1: 40, y1: 60,
            x2: 260, y2: 60,
            lineWidth: 1
        },
    ]
}

export const tableCellNoBorder = [false,false,false,false];