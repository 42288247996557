import { paymentModes } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchPaymentModes = (...args)=>{
    return fetch(paymentModes,...args);
}

export const getPaymentModes = (...args)=>{
    return get(paymentModes,...args);
}

export const SelectPaymentMode = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {paymentModes}
        ref = {ref}
    />
});

SelectPaymentMode.displayName = "SelectPaymentMode";