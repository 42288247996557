import { getRequestHeaders,handle401Error } from "./request";

import axios  from "axios";
export * from "./api";
export * from "./request";

const getOPtions = (opts)=>{
    opts = Object.assign({},opts);
    opts.headers = getRequestHeaders(opts?.headers);
    opts.responseType = opts.responseType || "blob";
    return opts;
}
export const fetchBlob = (url,opts)=>{
    return axios.get(`${process.env.REACT_APP_URL_API}${url}`,getOPtions(opts)).catch((e)=>{
      console.log(e," trhowwing");
      throw e;
    });
}