import isNonNullString from "./isNonNullString";
const isDataURLRegex =
  /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)$/i;

export default function isDataURL(s) {
  return (
    isNonNullString(s) &&
    !s.contains("data:image/x-icon") &&
    !!s.match(isDataURLRegex)
  );
}
