import React, { Fragment, useState } from "react";
import ValidationLine, {Row as RowLine,RowHeader} from "$containers/ValidationLine";
import CButton from "$components/Button";
import AnalyticsCode from "./analyticsCode";
import { Form, Button, Input, Modal, message, Row, Col } from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  useAddPaymentSpending,
  useUpdatePaymentSpending,
  useDeletePaymentSpending,
} from "$api/api";
import Box from "$components/Box";

export default function Spending({ payment, iduser,addNewCodes, addNewSpending, t }) {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);

  const [loading, setLoading] = useState(false);
  const [spending, setSpending] = useState(null);

  const { mutateAsync: addSpending } = useAddPaymentSpending(payment?.id);
  const { mutateAsync: updateSpend } = useUpdatePaymentSpending(payment?.id);
  const { mutateAsync: deleteSpend } = useDeletePaymentSpending(payment?.id);

  const editSpending = (row) => {
    setSpending(row);
    setVisibleEdit(true);
  };

  const deleteSpending = (row) => {
    console.log(row);
    setSpending(row);
    setVisibleDelete(true);
  };

  const onSubmitAdd = async (values) => {
    try {
      setLoading(true);

      values.service = payment?.ticket.service?.id;
      values.category = payment?.ticket.category?.id;
      values.price = parseInt(values.price);
      values.quantity = parseInt(values.quantity);

      let res = await addSpending([values]);
      addNewSpending(res);
      setVisibleAdd(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      console.log(e);
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const onSubmitEdit = async (values) => {
    try {
      values.id = spending?.id;
      values.price = parseInt(values.price);
      values.quantity = parseInt(values.quantity);
      setLoading(true);

      let res = await updateSpend([values]);
      addNewSpending(res);
      setVisibleEdit(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      console.log(e);
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const onSubmitDelete = async (values) => {
    try {
      setLoading(true);

      let payload = [];

      payload.push(spending.id);

      let res = await deleteSpend(payload);
      addNewSpending(res);
      setVisibleDelete(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };
  const isPaymentInitiator = payment?.initiator?.id == iduser && payment?.status.id == 2;
  const rows = (Array.isArray(payment?.items) && payment.items || []).map((row, id)=>{
      return <ValidationLine.Row cellProps={{px:"10px"}}>
          <span className="grille21 av-heavy fs14 ">{row.id}</span>
          <span className="grille4 av-heavy fs14 ">
            {row.description}
          </span>
          <span className="c-input qte">
            {row.quantity}
          </span>
          <span className="c-input qte">
            {row.unity}
          </span>
          <span className="c-input qte">
            {row.price}
          </span>
          <span className="av-heavy fs14">
            {Intl.NumberFormat("fr-FR").format(
              (row.quantity ? row.quantity : 1) * row.price
            )}
          </span>
          {isPaymentInitiator && (
            <Box display="flex" justifyContent="start" alignItems="center" className="pending-payment-requests-">
              <EditOutlined
                className="rmv cursor fs18"
                onClick={() => editSpending(row)}
              />
              <CloseOutlined
                className="rmv cursor fs18"
                onClick={() => deleteSpending(row)}
              />
            </Box>
          )}
      </ValidationLine.Row>
  })
  return (
    <>
      <Box className="appro rel">
        <Box className="w100 validation-lines-container">
          <Box
            display={'flex'} w="100%" flexWrap={{base:"wrap",lg:"nowrap"}} justifyContent="start"  flexDirection="row" alignItems="start" className="tickets-items-analytic-codes"
          >
            <Box className="validation-payments-request-only" mr="10px" textAlign="center">
              <CButton 
                onClick={!isPaymentInitiator?undefined : () => setVisibleAdd(true)}
                variant = "solid"
                border = "0"
                py="0"
                mb="10px"
                w="100%"
                _hover = {{bgColor:"transparent"}}
                borderRadius = {0}
                right={!isPaymentInitiator ? undefined : (p)=>{
                    return <Box {...p} as="span" ml="15px">
                      {"| "} <PlusCircleFilled />{t("commitmentForms.form.section2.label7")}
                    </Box>
                }}
                h="auto"
                outline={"0"}
              >
                  {"Payments"}
              </CButton>
              <ValidationLine
                  whiteSpaceNoWrap
                  bordered
                  className={"payment-requests-items-container"}
                  headers = {<ValidationLine.RowHeader cellProps={{px:"10px"}}>
                      <span className="fW500">Id </span>
                      <span className="fW500">Description </span>
                      <span className="fW500">
                        {t("commitmentForms.form.section2.label2")}
                      </span>
                      <span className="fW500">
                        {t("commitmentForms.form.section2.label3")}
                      </span>
                      <span className="fW500">
                        {t("commitmentForms.form.section2.label4")}
                      </span> 
                      <span className="fW500">Total</span>
                      {isPaymentInitiator && (
                        <span className="fW500">Actions</span>
                      )}
                  </ValidationLine.RowHeader>}
                  rows= {rows}
                  footers = {<ValidationLine.RowFooter
                    cellProps={{px:"10px"}}
                    children = {[
                      {
                         colSpan : 2,
                         children : null,
                         p:0,
                      },
                      {
                        colSpan :isPaymentInitiator ? 5:4,
                        p:0,
                        children : <Box className="flex justbtw  bggray u-pad-s fW600 ">
                          <span> TOTAL </span>
                          <span>
                            {Intl.NumberFormat("fr-FR").format(parseInt(payment?.total))}
                            Fcfa
                          </span>
                        </Box>
                      }
                    ]}
                  />}
              />
            </Box>
            <Box className="analytic-codes-only-container">
              <Box w="100%" mb="6px" className="analytic-codes-only-title" textAlign="center">
                <h2 className="fW600" style={{padding:0,margin:0}}>
                {t("commitmentRequests.detail.section4.title")}
                </h2>
              </Box>
              <AnalyticsCode
                  idpayment={payment?.id}
                  payment={payment}
                  t={t}
                  addNewCodes={addNewCodes}
                  bordered
                  showProjectCode={false}
                  withTitle={false}
                  px="10px"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        width={700}
        onCancel={() => setVisibleEdit(false)}
        open={visibleEdit}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 300 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          
          {t("commitmentRequests.edit.item")}
        </h2>

        <h3 className="fW600 u-mar-left-s w100 "> {spending?.description} </h3>

        <Form
          layout="vertical"
          initialValues={{
            price: spending?.price,
            quantity: spending?.quantity,
          }}
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmitEdit}
        >
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="price"
                  label={t("commitmentForms.form.section2.label4")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label={t("commitmentForms.form.section2.label2")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  className="rad8"
                >
                  <span className="fs16 av-heavy">
   
                    {t("validate.button")}
                  </span>
                </Button>
              </Col>
            </Row>

            <Box></Box>
          </>
        </Form>
      </Modal>
      <Modal
        width={700}
        onCancel={() => setVisibleAdd(false)}
        open={visibleAdd}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 350 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          
          {t("commitmentForms.form.section2.label7")}
        </h2>

        <Form
          layout="vertical"
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmitAdd}
        >
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label={t("commitmentForms.form.section2.label0")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="unity"
                  label={t("commitmentForms.form.section2.label3")}
                  rules={[{ message: t("input.required") }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="price"
                  label={t("commitmentForms.form.section2.label4")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label={t("commitmentForms.form.section2.label2")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="rad8"
                >
                  <span className="fs16 av-heavy">
   
                    {t("validate.button")}
                  </span>
                </Button>
              </Col>
            </Row>

            <Box></Box>
          </>
        </Form>
      </Modal>
      <Modal
        width={400}
        onCancel={() => setVisibleDelete(false)}
        open={visibleDelete}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 250 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          
          {t("commitmentRequests.delete.item")}
        </h2>

        <h3 className="fW600  w100 "> {spending?.description} </h3>

        <Form
          layout="vertical"
          initialValues={{
            price: spending?.price,
            quantity: spending?.quantity,
          }}
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmitDelete}
        >
          <>
            <Row gutter={16}>
              <h3> {t("commitmentRequests.confirm.delete.item")} </h3>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  className="rad8"
                >
                  <span className="fs16 av-heavy">
   
                    {t("validate.button")}
                  </span>
                </Button>
              </Col>
            </Row>

            <Box></Box>
          </>
        </Form>
      </Modal>
    </>
  );
}
