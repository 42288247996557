import React, { useState, useEffect } from "$react";
import "moment/locale/fr";
import notify from "$notify";
import Box from "$components/Box";
import {SelectRole} from "$enums";
import SimpleGrid from "$components/SimpleGrid";
import {
  Button,
  Select,
  Form,
  Switch,
  Col,
  message,
  Row,
  Divider,
  Input,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { userInfos } from "../../store";
import { useAddEmployee, useGetPosts } from "../../api/api";
import i18n from "$i18n";
import { services } from "../expense-commitment/staticData/data";

const { Option } = Select;
export default function FormEmploye({ close, t }) {
  const [form] = Form.useForm();
  const [roles,setRoles] = React.useState([]);
  const { mutateAsync: addEmployee } = useAddEmployee();

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState(null);

  const [fileList, setFileList] = useState([]);

  const { data, error, isLoading, refetch } = useGetPosts({}, {});

  useEffect(() => {
    setPosts(data);
  }, [data]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsData(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    file.preview = await getBase64(file.originFileObj);
  };
  const dum = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        let payload = {
          firstname: values.firstname,
          lastname: values.lastname,
          user: {
            email: values.email,
            role: values.role,
          },
          phone: values.phone,
          /*       date_of_birth: values.date_of_birth?.format('YYYY-MM-DD'),
        place_of_birth: values.place_of_birth, */
          service: values.service,
          post: posts.find((post) => {
            return post.id === values.post;
          }),
        };
        try {
          setLoading(true);

          await addEmployee(payload);

          close();
          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          console.log(e.response.data.details[0]);

          if (e.response?.data?.details[0] == "email already used") {
            notify.error({
              title: `Employé existant`,
              message: "Veuillez ajouter un nouveau",
            });
          } else {
            message.warning(t("error.recording"));
          }

          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  return (
    <Box className="flex  column rel h100">
      <Box
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <Box
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </Box>

        <h2 className="fW600"> {t("employees.form.title")} </h2>

        <Box className="flex itemcenter fixed" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            className="rad8"
            loading={loading}
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("validate.button")} </span>
          </Button>
        </Box>
      </Box>

      <Box className="form-container u-mar-vertical-xxl mauto" w="100%">
        {!isLoading && (
          <Form
            layout="vertical"
            className="rel"
            form={form}
            style={{ padding: "0px 150px" }}
          >
            <Box w="100%" flex className="header-worker-item" >
                <Box className="form-item-header-worker-label fs18 av-roman u-mar-right-l">
                  {t("employees.form.header-worker")}
                </Box>
                <Form.Item
                      name="headquarters_Worker"
                  >
                    <Switch
                      size="large"
                    />
                  </Form.Item>
             </Box>
            <SimpleGrid className="form-content">
               
              <Form.Item
                    name="firstname"
                    label={t("employees.form.label0")}
                    rules={[{ required: true, message: t("input.required") }]}
              >
                    <Input />
              </Form.Item>
              <Form.Item
                    name="lastname"
                    label={t("employees.form.label1")}
                    rules={[{ required: true, message: t("input.required") }]}
                  >
                    <Input />
              </Form.Item>
              <Box minW="250px" className="post-container">
                  <Form.Item
                    name="post"
                    label={t("employees.form.label2")}
                    rules={[{ required: true, message: t("input.required") }]}
                  >
                     <Select optionFilterProp="children">
                      {posts != null &&
                        posts.map((post,key) => (
                          <Option value={post.id} key={post.id}> {i18n.tObj(post,"name")} </Option>
                        ))}
                  </Select>
                  </Form.Item>
              </Box>
             
              <Form.Item
                name="role"
                label={t("employees.form.label3")}
                rules={[{ required: true, message: t("input.required") }]}
              >
                <SelectRole name="role"/>
              </Form.Item>
              <Form.Item
                  name="email"
                  label={t("employees.detail.label4")}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("input.required"),
                    },
                  ]}
                >
                  <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label={t("employees.form.label4")}
                rules={[{ required: true, message: t("input.required") }]}
              >
                <Input />
              </Form.Item>
            </SimpleGrid>
          </Form>
        )}
      </Box>
    </Box>
  );
}
