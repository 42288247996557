import {isNonNullString} from "$utils";
/***les status de validation */

export const ON_HOLD = 0;//en attente

export const APPROVED = 1;

export const REJECTED = 2;

export const CLOSED = 3;

export const WAITING_PAYMENTS = 4;

export const VALIDATED = 5;

export const STATUS = {
    ON_HOLD,
    APPROVED,
    REJECTED,
    CLOSED,
    WAITING_PAYMENTS,
    VALIDATED,
}

/**** retourne les personnes autorisées à faire la validation en fonction de l'étappe qu'on se trouve
    @param {number} le status à l'étappe
    @return {Array}, le tableau des champs des personnes à valider dans l'objet tiquets
*/
export const getValidatingUserFieldsByStep = (status)=>{
    if(typeof status !='number' || status < 1 || status> 5) return [];
    switch(status){
        case ON_HOLD : 
            break;
        
    }
}
/**** retourne les status possible pour l'utilisateur dont la clé est passé en paramètre
    @param {string}, userFieldName le nom du champ associé à l'utilisateur
    @return {Array}, le tableau des status possibles pour le dit utilisateur
*/
export const getAvailableStatusForUser = (userFieldName)=>{
    if(!isNonNullString(userFieldName)) return [];
    userFieldName = userFieldName.toLowerCase().trim();
    switch(userFieldName){
        case "manager": //Resp Budgétaire
            return [APPROVED,REJECTED].sort();
        case "accountant"://L’administrateur financier 
            return [APPROVED,REJECTED,CLOSED].sort();
        case "coordinator"://Le coordinateur général
            return [APPROVED,REJECTED,CLOSED].sort();
        case "treasurer"://Resp Opérationnel 
            return [WAITING_PAYMENTS,REJECTED].sort();
        case "initiator" : //Le demandeu
            return [VALIDATED,REJECTED,CLOSED].sort();
    }
    return [];
}

/*** spéficie si le ticket est validable
    lorsque le status du ticket est à approuvé
*/
export const canValidate = (status)=>{
    return status === APPROVED;
}

export const userFields = ["manager","accountant","coordinator","treasurer","initiator"]
export const getUsersForsStatus = (status)=>{
    const result = {};
    const cV =canValidate(status); 
    const canApprove = status == 0;
    userFields.map((field)=>{
        result[field] = {
            status : getAvailableStatusForUser(field),
            canValidate : cV,
            canApprove : !!canApprove,
            [field] : true,
        }
    });
    return result;
}