import React from "$react";
import {Button} from "antd";
import {classNames,parseDecimal,isObj,defaultObj,uniqid} from "$utils";
import exportTicket from "../../../pdf/ticket/export";
import Box from "$components/Box";
import {
    FilePdfOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import {fetch} from "$api";
import { useTranslation } from "react-i18next";
import {Modal,Form,InputNumber} from "antd";
import Input from "$components/Input"
  
  export default function AttachementDownloadComponent({className,downloadPath,download,downloadOptions,...props}){
    const [isLoading,setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [data,setData] = React.useState(null);
    const formNameRef = React.useRef(uniqid("modal-formName"))
    const valuesRef = React.useRef({tableItemHeight:18})
    const openModal = () => {
      setOpen(true);
    };
    const closeModal = () => {
      setOpen(false);
    };
    const {t} = useTranslation();
    const print = ()=>{
       setIsLoading(true);
       return (typeof download =='function'? download():fetch(downloadPath,defaultObj(downloadOptions))).then((data)=>{
        setData(data);
      }).finally(()=>{
        setIsLoading(false);
      });
    }
    React.useEffect(()=>{
      if(isObj(data) && Object.size(data,true) && !open){
          openModal();
      }
    },[data]);
    const [form] = Form.useForm();
    React.useEffect(()=>{
      if(isObj(valuesRef.current)){
        form?.setFieldsValue && form.setFieldsValue(valuesRef.current);
      }
    },[JSON.stringify(valuesRef.current)])
    return <>
        <Button
        type="text"
        style = {{paddingLeft:0}}
        className={classNames(className,"attachment-download")}
        loading = {isLoading}
        icon={<FilePdfOutlined style={{ fontSize: '20px', color: 'red',marginRight:"5px" }}  />}
        title={t("export.button.pdf.label")}
        onClick={(e) => {
          React.stopEventPropagation(e);
          print();
        }}
        children = {t("export.button.pdf.label")}
      />
      <Modal
        title="Modal"
        open={open}
        onOk={()=>{
          const options = form.getFieldsValue();
          valuesRef.current = options;
          setIsLoading(true);
          exportTicket(data,options);
          setTimeout(()=>{
            setIsLoading(false);
            closeModal();
          },500);
        }}
        onCancel={closeModal}
        okText={t("export.button.pdf.label")}
        cancelText={t("main.cancel")}
        confirmLoading = {isLoading}
      >
          <Box w="100%" className="export-btn-modal-container">
              <Box fontWeight="bold" fontSize="15">{t("export.button.printOptions")}</Box>
              <Form
                form={form}
                name={formNameRef.current}
                style={{ maxWidth: 600 }}
                initialValues={valuesRef.current}
              >
                  <Form.Item name="tableItemHeight" label={t("export.buton.printOptions.tableItemSize")}>
                    <InputNumber/>
                  </Form.Item>
              </Form>
          </Box>
      </Modal>
    </>   
  }
  
  AttachementDownloadComponent.propTypes = {
    downloadLink : PropTypes.string,//le lien de téléchargement du fichier
    download : PropTypes.func,
    downloadPath : PropTypes.string,//le chemin de téléchargement du pdf
  }