import { documents } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchDocuments = (...args)=>{
    return fetch(documents,...args);
}

export const getDocuments = (...args)=>{
    return get(documents,...args);
}

export const SelectDocument = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {documents}
        ref = {ref}
    />
});

SelectDocument.displayName = "SelectDocument";