import logo from "./logo"
export default function(title){
    return {
            
        columns : [
            {
                image: logo,
                width: 50,
            },
            {
                text: title || `DEMANDE D'ENGAGEMENT DE DEPENSES`,
                style: 'pageHeaderTitle',
                margin : [10,20,0,0]
            },
        ],
        marginTop : -10,
    }
}