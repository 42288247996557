import React from "$react";
import Viewer from "./Viewer";
import PropTypes from "prop-types";
import {getFileExtension,defaultStr,isBlobOrObjectUrl,isNonNullString,defaultObj,classNames} from "$utils";
import { pdfDataUrl } from "./utils";
import Icon from "$components/Icon";
import Box from "$components/Box";
import {Image,Spinner} from "$ui";
import { useGetDownload } from "./hooks";
import "./styles.css";

const imageProps = {
    maxWidth : "40px",
}
const pdfProps = {maxWidth:"28px"};
const iconProps = {as:Icon,size:"40px"}

export default function FilePreviewComponent({attachment,className,...props}){
    const [isLoading,setIsLoading] = React.useState(false);
    const [file,setFile] = React.useState(null);
    const type = parseType(attachment);
    const hasType = type.pdf;
    const imageRef = React.useRef(null);
    const hasImage = false;
    const downloadFile = useGetDownload({...props,save:false});
    
    const fetchFile = ()=>{
        if(!type.image && !type.pdf) return;
        setIsLoading(true);
        downloadFile().then((file)=>{
            imageRef.current = null;
            if(isBlobOrObjectUrl(file)){
                //imageRef.current = file;
                setFile({
                    ...defaultObj(attachment),
                    ...type,
                    url : file,
                })
            } else {
                setFile(null);
            }
            setIsLoading(false);
        }).finally(()=>{
            setIsLoading(false);
        });
    };
    const restProps = hasImage ? {...imageProps,src:imageRef.current} : hasType ? {...pdfProps,src:type.pdf && pdfDataUrl || null} : {icon : `material-${type.image?"MdOutlineImage":"MdInsertDriveFile"}`,...iconProps};
    return <Box w="60px" cursor = "pointer" mr="10px" title="Click to preview" flex justifyContent="center" alignItems="center" flexDirection="column" onClick={fetchFile} className={classNames("attachment-preview",className)} position="relative">
        {isLoading && <Spinner size='lg' />}
        {!isLoading && <>
            {<Image className="attachment-preview-image"
                {...restProps}
                cursor="pointer"
            />}
        </>}
        <Viewer
            file = {file}
            visible = {!!(file && !isLoading)}
            onClose={(e)=>setFile(null)}
        />
    </Box>
}

FilePreviewComponent.propTypes = {
    attachment : PropTypes.shape({
        name : PropTypes.string,//le nom de la pièce jointe
        
    }),
}
export const parseType = (attachment)=>{
    if(!attachment || !isNonNullString(attachment?.name)){
        return {};
    }
    const ext = defaultStr(getFileExtension(attachment?.name,true)).toUpperCase().trim();
    if(!isNonNullString(ext)) return {};
    return {
        image : ["PNG","GIF","TIFF","JPEG","JPG"].includes(ext),
        pdf : ["PDF"].includes(ext),
        excel : ["XLSX","XLS"].includes(ext),
    }
}