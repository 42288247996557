import { statutes } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchStatutes = (...args)=>{
    return fetch(statutes,...args);
}

export const getStatutes = (...args)=>{
    return get(statutes,...args);
}

export const SelectStatus = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {statutes}
        ref = {ref}
    />
});

SelectStatus.displayName = "SelectStatus";