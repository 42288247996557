import React from "react";
import "moment/locale/fr";
import {
  Descriptions,
  Divider,
} from "antd";
import {
  CloseOutlined,
} from "@ant-design/icons";

export default function DetailProject({ data, close }) {
  return (
    <>
      <div className="grille w100  u-pad-top-s  u-mar-top-m   noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">
        <div
          className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-vertical-l"
          style={{ top: 0, borderBottom: "1px solid #DDD" }}
        >
          <div
            className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
            style={{ left: 24 }}
            onClick={() => close()}
          >
            <span className="fs15  " style={{ color: "#1a1a1a" }}>
              <CloseOutlined />
            </span>
          </div>
          <h2 className="fW600"> Detail </h2>
        </div>
      </div>
      <div className="grille w100  u-pad-vertical-s noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">
        <Descriptions title="Project Info">
          <Descriptions.Item label="Code">
            {data.code ? data.code : "Pas d'element"}
          </Descriptions.Item>
          <Descriptions.Item label="Nom">
            {i18nTObj(data) || "Pas d'element"}
          </Descriptions.Item>
          <Descriptions.Item label="Objectif">
            {data.objective ? data.objective : "Pas d'element"}
          </Descriptions.Item>
          <Descriptions.Item label="Chef de projet">
            {data.manager ? data.manager.firstname : "Pas d'element"}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
      </div>
    </>
  );
}
