import React from "$react";
import { isNonNullString } from "$utils";
import { useTranslation } from "react-i18next";
import Box from "$components/Box";
import {useTable} from "./hooks";
export default function Column({title,sortable}){
    const { t:translate} = useTranslation();
    //const {...rest} = useTable();
    return <Box as="span" className="datagrid-column-title-wrapper">
        {isNonNullString(title)? translate(title) : React.isValidElement(title)? title : null}
    </Box>
}