import React, { Fragment, useState } from "react";
import ValidationLine, {Row as RowLine,RowHeader} from "$containers/ValidationLine";
import {VALIDATED,CLOSED} from "$status";
import Box from "$components/Box";
import CButton from "$components/Button";
import {defaultNumber} from "$utils";
import { Input } from "antd";
import { Form, Button, Modal, Select, message, Row, Col } from "antd";
import {
  useDeleteTicketSpending,
  useAddTicketSpending,
  useUpdateTicketSpending,
} from "$api/api";
import {
  PlusCircleFilled,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { services } from "../staticData/data";
import AnalyticsCode from "./analyticsCode";

const { Option } = Select;
export default function Spending({ ticket,idticket,addNewCodes, iduser, addNewSpending, t }) {
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spending, setSpending] = useState(null);

  const { mutateAsync: addSpending } = useAddTicketSpending(ticket?.id);
  const { mutateAsync: updateSpend } = useUpdateTicketSpending(ticket?.id);
  const { mutateAsync: deleteSpend } = useDeleteTicketSpending(ticket?.id);

  const editSpending = (row) => {
    setSpending(row);
    setVisibleEdit(true);
  };

  const deleteSpending = (row) => {
    setSpending(row);
    setVisibleDelete(true);
  };

  const onSubmitAdd = async (values) => {
    try {
      setLoading(true);

      values.service = ticket?.service ? ticket?.service?.id : values.service;
      values.category = ticket?.category
        ? ticket?.category?.id
        : values.category;
      values.price = parseInt(values.price);
      values.quantity = parseInt(values.quantity);

      let res = await addSpending([values]);
      addNewSpending(res);
      setVisibleAdd(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      console.log(e);
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const onSubmitEdit = async (values) => {
    try {
      values.id = spending?.id;
      values.price = parseInt(values.price);
      values.quantity = parseInt(values.quantity);
      setLoading(true);

      let res = await updateSpend([values]);
      addNewSpending(res);
      setVisibleEdit(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };

  const onSubmitDelete = async (values) => {
    try {
      setLoading(true);

      let payload = [];

      payload.push(spending.id);

      let res = await deleteSpend(payload);
      addNewSpending(res);
      setVisibleDelete(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };
  const border = "1px solid gray";
  const hasTinitialized = ticket?.initiator?.id == iduser && ticket?.status.id == 2;
  const isInitiator = (ticket?.initiator?.id == iduser) && ticket?.status?.id == 2;
  const isClosed = ticket?.status?.id == CLOSED, isValidated = ticket?.status?.id == VALIDATED;
  const rows = [];
  (Array.isArray(ticket?.items) && ticket.items || []).map((row,id)=>{
      rows.push(<RowLine
        key={row.id}
        className="spending-item "
        cellProps={{px:"10px"}}
      >
          <span className="grille21 av-heavy fs14 ">{id + 1}</span>
          <span className="grille4 av-heavy fs14 ">
            {row.description}
          </span>
          <span className="c-input qte grille07 right">
            {" "}
            {defaultNumber(row.quantity).formatNumber()}{" "}
          </span>
          <span className="c-input qte grille07 right">
            {" "}
            {row.unity}{" "}
          </span>
          <span className="c-input qte grille09 right">
            {" "}
            {row.price}{" "}
          </span>
          <span className="av-heavy fs14 grille right">
            {Intl.NumberFormat("fr-FR").format(
              (row.quantity ? row.quantity : 1) * row.price
            )}
          </span>
          {isInitiator && (
              <span className="av-heavy fs14 grille right">
                <EditOutlined
                  className="rmv cursor fs18"
                  onClick={() => editSpending(row)}
                />
          
                <CloseOutlined
                  className="rmv cursor fs18"
                  onClick={() => deleteSpending(row)}
                />
              </span>
            )}
      </RowLine>)
  })
  const colSpanIncrement = 0;//isInitiator ? 1 : 0;
  return (
    <>
      
      <Box display={'flex'} w="100%" flexWrap={{base:"wrap",lg:"nowrap"}} justifyContent="start"  flexDirection="row" alignItems="start" className="tickets-items-analytic-codes">
        <Box className="items-only-container" mr="10px" textAlign="center">
          <CButton 
              onClick={() => setVisibleAdd(true)}
              variant = "solid"
              border = "0"
              py="0"
              mb="10px"
              w="100%"
              _hover = {{bgColor:"transparent"}}
              borderRadius = {0}
              right={isValidated || isClosed ? undefined : (p)=>{
                return <Box {...p} as="span" ml="15px">
                  {"| "} <PlusCircleFilled />{t("commitmentForms.form.section2.label7")}
                </Box>
            }}
              h="auto"
              outline={"0"}
            >
                {"Estimation du marche"}
            </CButton>
          <ValidationLine
              whiteSpaceNoWrap
              bordered
              className={"tickets-items-container"}
              bodyProps = {{border}}
              footerProps = {{border}}
              headers ={[
                <RowHeader cellProps={{px:"10px"}}>
                  <span className="grille21 fW500">
                    {t("commitmentRequests.detail.section4.label0")}{" "}
                  </span>
                  <span className="grille4 fW500"> Description </span>
                  <span className="grille07 fW500 right">
                    {" "}
                    {t("commitmentForms.form.section2.label2")}
                  </span>
                  <span className="grille09  fW500 right">
                    {" "}
                    {t("commitmentForms.form.section2.label3")}
                  </span>
                  <span className="grille09  fW500 right">
                    {" "}
                    {t("commitmentForms.form.section2.label4")}
                  </span>
                  <span className="grille  fW500 right">
                    {" "}
                    {t("commitmentForms.form.section2.label5")}
                  </span>
      
                  {hasTinitialized && (
                    <span className="grille  fW500 right">Actions </span>
                  )}
                </RowHeader>
              ]}
              rows = {rows}
              footers = {<ValidationLine.RowFooter
                children = {[
                  {
                     colSpan : 2,
                     children : null,
                     p:0,
                  },
                  {
                    colSpan :(hasTinitialized ? 5 : 4)+colSpanIncrement,
                    p:0,
                    children : <Box  p="5px" className="bggray  footer-total-container">
                      <Box pb="10px" className="flex justbtwfW600 " textAlign="left">
                          <span className="u-pad-right-s"> TOTAL : </span>{" "}
                          <span>
                            {Intl.NumberFormat("fr-FR").format(parseInt(ticket?.total))}{" "}
                            Fcfa
                          </span>
                      </Box>
                       <Box className="flex fW600 " textAlign="left">
                        <span className="u-pad-right-s">
                          {t("commitmentRequests.detail.section1.tab0.label7")}:{" "}
                        </span>{" "}
                        <span>
                          {Intl.NumberFormat("fr-FR").format(parseInt(ticket?.expense))}{" "}
                          Fcfa
                        </span>
                      </Box>
                  </Box>
                  }
                ]}
              />}
          />
        </Box>
        <Box className="analytic-codes-only-container">
          <Box w="100%" mb="6px" className="analytic-codes-only-title" textAlign="center">
            <h2 className="fW600" style={{padding:0,margin:0}}>
            {t("commitmentRequests.detail.section4.title")}
            </h2>
          </Box>
          <AnalyticsCode
              idticket={idticket}
              ticket={ticket}
              bordered
              showProjectCode={false}
              addNewCodes={addNewCodes}
              canUpsert = {!isValidated && !isClosed && true || false}
              t={t}
              withTitle={false}
              px="10px"
          />
        </Box>
    </Box>
      <Modal
        width={700}
        onCancel={() => setVisibleEdit(false)}
        open={visibleEdit}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 300 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.edit.item")}
        </h2>

        <h3 className="fW600 u-mar-left-s w100 "> {spending?.description} </h3>

        <Form
          layout="vertical"
          initialValues={{
            price: spending?.price,
            quantity: spending?.quantity,
          }}
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmitEdit}
        >
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="price"
                  label={t("commitmentForms.form.section2.label4")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label={t("commitmentForms.form.section2.label2")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  className="rad8"
                >
                  <span className="fs16 av-heavy">
                    {" "}
                    {t("validate.button")}{" "}
                  </span>
                </Button>
              </Col>
            </Row>

            <div></div>
          </>
        </Form>
      </Modal>
      <Modal
        width={700}
        onCancel={() => setVisibleAdd(false)}
        open={visibleAdd}
        bodyStyle={{ paddingBottom: 12, paddingTop: 12, height: "auto" }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentForms.form.section2.label7")}
        </h2>

        <Form
          layout="vertical"
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmitAdd}
        >
          <>
            {!ticket?.service && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="service"
                    label="Service"
                    rules={[{ required: true, message: t("input.required") }]}
                  >
                    <Select
                      optionFilterProp="children"
                    >
                      {services != null &&
                        services.map((service,key) => (
                          <Option value={service.id} key={key}> {i18nTObj(service)} </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label={t("commitmentForms.form.section2.label0")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="unity"
                  label={t("commitmentForms.form.section2.label3")}
                  rules={[{ message: t("input.required") }]}
                >
                  <Input placeholder="Unité" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="price"
                  label={t("commitmentForms.form.section2.label4")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label={t("commitmentForms.form.section2.label2")}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="rad8"
                >
                  <span className="fs16 av-heavy">
                    {" "}
                    {t("validate.button")}{" "}
                  </span>
                </Button>
              </Col>
            </Row>

            <div></div>
          </>
        </Form>
      </Modal>
      <Modal
        width={400}
        onCancel={() => setVisibleDelete(false)}
        open={visibleDelete}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 250 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.delete.item")}{" "}
        </h2>

        <h3 className="fW600  w100 "> {spending?.description} </h3>

        <Form
          layout="vertical"
          initialValues={{
            price: spending?.price,
            quantity: spending?.quantity,
          }}
          className="rel"
          style={{ padding: "0px 15px" }}
          onFinish={onSubmitDelete}
        >
          <>
            <Row gutter={16}>
              <h3> {t("commitmentRequests.confirm.delete.item")} </h3>
            </Row>

            <Row gutter={16} className="right u-mar-top-l">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  className="rad8"
                >
                  <span className="fs16 av-heavy"> {t("validate.button")}</span>
                </Button>
              </Col>
            </Row>

            <div></div>
          </>
        </Form>
      </Modal>
    </>
  );
}
