import React, { useState, useEffect } from "$react";
import "moment/locale/fr";
import Box from "$components/Box";
import { useRecoilValue, useRecoilState } from "recoil";
import {SelectRole} from "$enums";
import i18n from "$i18n";
import {
  Button,
  Select,
  Form,
  Switch,
  message,
  Input,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { userInfos } from "../../store";

import { useEditEmployee, useEditCred, useGetPosts } from "../../api/api";

const { Option } = Select;
export default function FormEmploye({ employeeData, close, t }) {
  const [form] = Form.useForm();
  const [__, setUserR] = useRecoilState(userInfos);
  const [posts, setPosts] = useState(null);
  const { mutateAsync: updateEmployee } = useEditEmployee(employeeData?.id);
  const { data, error, isLoading, refetch } = useGetPosts({}, {});

  const [loading, setLoading] = useState(false);

  const user = useRecoilValue(userInfos);

  useEffect(() => {
    setPosts(data);
  }, [data]);
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        try {
          setLoading(true);

          let res = await updateEmployee(values);

          if (res.id == user.id) {
            setUserR(res);
          }

          close();
          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          setLoading(false);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  const initialValues = {
    firstname: employeeData.firstname,
    lastname: employeeData.lastname,
    role: employeeData?.user.role.id,
    phone: employeeData.phone,
    post_id: employeeData?.post.id,
    headquarters_Worker : employeeData?.headquarters_Worker,
  };
  return (
    <Box className="flex  column rel h100">
      <Box
        className="flex itemcenter justcenter rel u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 15 }}
      >
        <Box
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </Box>

        <h3 className="fW600"> {t("employees.edit.button")} </h3>

        <Box className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            loading={loading}
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("validate.button")} </span>
          </Button>
        </Box>
      </Box>

      <Box className="u-mar-vertical-l  w100" display="flex" flexDirection="row" justifyContent="start" alignItems="center" flexWrap="wrap" gap="10px">
        {!isLoading && (
          <Form
            layout="vertical"
            className="rel"
            form={form}
            initialValues={initialValues}
            style={{ padding: "0px 15px" }}
          >
            <Box w="100%" flex className="header-worker-item" >
                <Box className="form-item-header-worker-label fs18 av-roman u-mar-right-l">
                  {t("employees.form.header-worker")}
                </Box>
                <Form.Item
                      name="headquarters_Worker"
                  >
                    <Switch
                      size="large"
                      defaultChecked={initialValues?.headquarters_Worker}
                    />
                  </Form.Item>
             </Box>
            <Form.Item
                name="firstname"
                label={t("employees.form.label0")}
                rules={[{ required: true, message: t("input.required") }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastname"
              label={t("employees.form.label1")}
              rules={[{ required: true, message: t("input.required") }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label={t("employees.form.label4")}
              rules={[{ required: true, message: t("input.required") }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="post_id"
              label={t("employees.form.label2")}
              rules={[{ required: true, message: t("input.required") }]}
            >
              <Select optionFilterProp="children">
                {posts != null &&
                  posts.map((post,key) => (
                    <Option value={post.id} key={key}> {i18n.tObj(post,"name")} </Option>
                  ))}
              </Select>
            </Form.Item>
             <Form.Item
                name="role"
                label={t("employees.form.label3")}
                rules={[{ required: true, message: t("input.required") }]}
              >
                <SelectRole/>
              </Form.Item>
              <Form.Item
                name="new_password"
                label={t("employees.detail.label5")}
              >
                <Input />
              </Form.Item>
          </Form>
        )}
      </Box>
    </Box>
  );
}
