import { ADMIN_ROLES,ALL_ROLES} from "$roles";


export const ALL = [...ALL_ROLES];

export const DASHBOARD = ALL;
export const EXPENSE_COMMITMENT = ALL; //formulaires d'engatements
export const TICKETS = ALL;
export const COMMITMENT_REQUEST = TICKETS;
export const PAYMENTS_REQUESTS = ALL;
export const PROJECTS = ADMIN_ROLES;
export const AFFECTATIONS = ADMIN_ROLES;
export const BASES = ADMIN_ROLES;
export const POSTS = ADMIN_ROLES;
export const EMPLOYEES = ADMIN_ROLES;
export const MOVEMENT_REQUESTS = ALL_ROLES;