import React, { Fragment, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {defaultNumber} from "$utils";

import { Input } from "antd";
import { Tag } from "antd";

export default function Payment({ ticket, t }) {
  console.log(ticket?.payments," is payments")
  return (
    <>
      <div className="appro rel">
        <div className="w100">
          <div
            className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s"
            style={{
              borderBottom: "1px #b3b3b3 solid",
            }}
          >
            <span className="grille09 fW500"> Code </span>
            <span className="grille09 fW500 right">
              {" "}
              {t("commitmentRequests.detail.section1.tab1.label1")}
            </span>
            <span className="grille09  fW500 right">
              {" "}
              {t("commitmentRequests.detail.section1.tab1.label2")}{" "}
            </span>
            <span className="grille09  fW500 right">
              {" "}
              {t("commitmentRequests.detail.section1.tab1.label3")}
            </span>
            <span className="grille09  fW500 right">
              {" "}
              {t("commitmentRequests.detail.section1.tab1.label4")}
            </span>
            <span className="grille  fW500 right">
              {" "}
              {t("commitmentRequests.detail.section1.tab1.label5")}
            </span>
          </div>

          {ticket?.payments.length != 0 && (
            <Fragment>
              <ul className="cart-list" style={{ height: "auto" }}>
                {ticket?.payments.map((row, id) => (
                  <li
                    key={row.id}
                    className="section-cart flex itemcenter justbtw  u-pad-left-xs "
                  >
                    <span className="grille09 av-heavy fs14 coltext ">
                      <Link to={"/payment-request/" + row.id}>
                        {" "}
                        {row.code}{" "}
                      </Link>
                    </span>

                    <span className="c-input qte grille09 right">
                      {" "}
                      {defaultNumber(row?.total).formatMoney()}
                    </span>

                    <span className="c-input qte grille09 right">
                      {" "}
                      {moment(row.date).format("D MMM-YY")}{" "}
                    </span>

                    <span className="c-input qte grille09 right">
                      {" "}
                      {row.pay_to}{" "}
                    </span>

                    <span className="av-heavy fs14 grille09 right">
                      {row.initiator.lastname + " " + row.initiator.firstname}
                    </span>

                    <span className="av-heavy fs14 grille right">
                      <Tag
                        className="u-mar-left-xs"
                        color={
                          row.status?.id == 0
                            ? "#ff9e01"
                            : row?.status?.id == 1
                            ? "#87d068"
                            : row?.status?.id == 2
                            ? "#ff5500"
                            : row?.status.id == 3
                            ? "red"
                            : row?.status.id == 5
                            ? "#87d068"
                            : "#108ee9"
                        }
                      >
                        {i18nTObj(row?.status)}
                      </Tag>
                    </span>
                  </li>
                ))}
              </ul>
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
}
