import { Select } from "antd";
import React from "$react";
import PropTypes from "prop-types";

const SelectComponent = React.forwardRef(({multiple,mode,...props},ref)=>{
    if(multiple && !mode){
        mode = "multiple"
    } else if(multiple === false){
        mode = "";
    }
    return <Select
        {...props}
        mode = {mode}
        ref = {ref}
    />
});

SelectComponent.propTypes = {
    ...Object.assign({},Select.propTypes),
}

SelectComponent.displayName ="SelectComponent";

export default SelectComponent;

Object.map(Select,(s,i)=>{
    if(!(i in SelectComponent)){
        SelectComponent[i] = s;
    }
})