import { parseJSON, stringify } from "$utils/json";
import isNonNullString  from "$utils/isNonNullString";

export const appId = "com.mdm.session";

export const sanitizeKey = (key) => {
  if (!isNonNullString(key)) return "";
  return prefix(key);
};
export const handleSetValue = (value, decycle) => {
  value = value ? stringify(value, decycle) : value;
  if (value === null || value === undefined) value = "";
  return value;
};
export const handleGetValue = (value) => {
  if (value !== null && value !== undefined) {
    return parseJSON(value);
  }
  return undefined;
};

const prefix = (text, sep) => {
  if (typeof text !== "string") return appId;
  sep = typeof sep == "string" ? sep : "-";
  const r = appId + sep;
  return r + text.trim().ltrim(r);
};

export default { handleGetValue, handleSetValue };
