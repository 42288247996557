import React from "$react";
import Box from "$components/Box";
import {langs as lngs} from "$i18n";
import { useTranslation } from "react-i18next";
import {classNames} from "$utils";
import PropTypes from "prop-types";
import Icon from "$components/Icon";
import Button from "$components/Button";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '$ui'

export default function LanguageDetector({renderAsMenu,...props}){
    const {i18n } = useTranslation();
    const changeLang = (lang) => i18n.changeLanguage(lang);
    const activeLang = i18n.resolvedLanguage;
    const [update,setUpdate] = React.useState(false);
    const content = React.useMemo(()=>{
      const C = renderAsMenu? MenuItem : Box
      return Object.keys(lngs)
      .filter((lng) => renderAsMenu ? true : lng != activeLang)
      .map((lang, key) => {
        const rProps = renderAsMenu && activeLang == lang ? {
          icon : <Icon name="check"/>
        } : {};
        return (<C
            key={key}
            className="cursor"
            {...rProps}
            onClick={renderAsMenu?(e)=>{
              changeLang(lang);
              setUpdate(!update);
            }:()=>{
              changeLang(lang);
            }}
            as = {renderAsMenu? undefined : "span"}
          >
            {lngs[lang].nativeName}
          </C>)
      })
    },[renderAsMenu,activeLang])
    return <Box {...props}
        className = {classNames(props.className,"language-selector")}
    >
      {renderAsMenu ? <Menu>
          <MenuButton as={Button} rightIcon={<Icon name="chevron-down"/>}
            borderRadius = "0"
            py="0"
            border = "0"
          >
          {activeLang && lngs[activeLang]?.nativeName}
        </MenuButton>
        <MenuList>{content}</MenuList>
      </Menu>
      : content}
    </Box>
}

LanguageDetector.propTypes = {
  ...Box.propTypes,
  renderAsMenu : PropTypes.bool,//si le contenu sera rendu en utilisant un menu
}