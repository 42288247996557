export default function parseDecimal(x) {
  if (typeof x === "number" && !isNaN(x)) return x;
  if (x && typeof x == "string") {
    if (!x.includes(".")) {
      x = x.replace(",", ".");
    }
    return parseFloat(x) || 0;
  }
  return 0;
}
