import session from "$session";
import {isObj,isNonNullString} from "$utils";

export const USER_SESSION_KEY = "recoil-persist";
const accessTokenKey = "access-token-skey";

export const setToken = (token)=>{
    return session.set(accessTokenKey,isNonNullString(token) && token||null);
}
export const getToken = ()=>{
    const accessToken = session.get(accessTokenKey);
    return isNonNullString(accessToken) && accessToken || "";
}

export const removeToken = ()=>{
    return setToken(null);
  }
  export const isValidToken = (token)=>{
    return isNonNullString(token) || false;
  }

export const isValidUserId = (id)=> isNonNullString(id) || typeof id =='number'? true : false;
export const isSignedIn = x => {
    const u = getLocalUser();
    return isObj(u) && isValidUserId(u.id) && isValidToken(getToken())? true : false;
}

export const getLocalUser = x=> {
    const u = session.get(USER_SESSION_KEY);
    return isObj(u) && u.id ? u : null;
};

export const getSignedUser = getLocalUser;
export const getLoggedUser = getLocalUser;


export const setLocalUser = u => session.set(USER_SESSION_KEY,u || null);

/**** vérifie si l'employé est du siège */
export const isHeadQuarter = ()=>{
    if(!isSignedIn()) return false;
    const  u = getSignedUser();
    return !!u.headquarters_Worker;
}

export const isProjectManager = ()=>{
    if(!isSignedIn()) return false;
    const u = getSignedUser();
    return !!u.is_project_manager;
}

export const isWorkingOnSite = isHeadQuarter;