// Copyright 2022 @fto-consult/Boris Fouomene. All rights reserved.
// Use of this source code is governed by a BSD-style
// license that can be found in the LICENSE file.

import Colors from "./colors";
import { black, white } from './colors';

export const transparent = "transparent";

export const lightColors = {
    info : '#1890FF',
    infoText : "white",
    success : '#4caf50',
    successText : "white",
    warning : '#FFC107',
    error: '#B00020',
    errorText : "white",
    text: black,
    background: '#f6f6f6',
    surface: white,
    surfaceText: '#000000',
    disabled: Colors.setAlpha(black,0.26),
    backdrop: Colors.setAlpha(black,0.5),
    placeholder: Colors.setAlpha(black,0.54),
    divider : Colors.setAlpha(black,0.18),
}
export const darkColors = {
    info : '#39c0ed',
    infoText: black,
    success : '#00b74a',
    warning : '#ffa900',
    warningText: black,
    error : "#EF4E69",
    errorText: black,
    background : "#111b21",
    surface : "#343a40",
    surface : "#202c33",
    surfaceText: '#FFFFFF',
    text: white,
    disabled: Colors.setAlpha(white,0.5),
    placeholder: Colors.setAlpha(white,0.6),
    backdrop: Colors.setAlpha(black,0.5),
    divider : Colors.setAlpha(white,0.18),
}

const defaultTheme = {
    dark: false,
    roundness: 10,
    colors : {
        primary: "#043d75",
        primaryText : "#FFFFFF",
        secondary : "#E7D413",
        secondaryText : "black",
        background: '#EFF1F3',
        surface: white,
        error: '#B00020',
        text: black,
        ...lightColors,
    }
}

export default defaultTheme;