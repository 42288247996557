export const observable = function (el) {
  if (isObservable(el)) return el; ///avoid redefine observable
  /**
   * Extend the original object or create a new empty one
   * @type { Object }
   */

  el = el || {};

  /**
   * Private variables
   */
  var callbacks = {},
    finallyCallback = {},
    slice = Array.prototype.slice;

  /**
   * Public Api
   */
  el._____isObservable = true;
  // extend the el object adding the observable methods
  Object.defineProperties(el, {
    /**
     * Listen to the given `event` ands
     * execute the `callback` each time an event is triggered.
     * @param  { String } event - event id
     * @param  { Function } fn - callback function
     * @returns { Object } el
     */
    on: {
      value: function (event, fn, priority) {
        if (typeof fn == "function" && event && typeof event === "string") {
          (callbacks[event] = callbacks[event] || []).push(fn);
        }
        return el;
      },
      enumerable: false,
      writable: false,
      configurable: false,
    },
    finally: {
      value: function (event, fn) {
        if (typeof fn == "function")
          (finallyCallback[event] = finallyCallback[event] || []).push(fn);
        return el;
      },
      enumerable: false,
      writable: false,
      configurable: false,
    },

    /**
     * Removes the given `event` listeners
     * @param   { String } event - event id; if event is null, all callback will be removed
     * @param   { Function } fn - callback function
     * @returns { Object } el
     */
    off: {
      value: function (event, fn) {
        if (!event || typeof event !== "string") return this;
        if (event == "*" && !fn) callbacks = {};
        else {
          if (fn) {
            var arr = callbacks[event];
            for (var i = 0, cb; (cb = arr && arr[i]); ++i) {
              if (cb == fn) {
                arr.splice(i--, 1);
              }
            }
          } else delete callbacks[event];
        }
        return el;
      },
      enumerable: false,
      writable: false,
      configurable: false,
    },
    offAll: {
      value: function () {
        LIB_OBSERVABLE.removeObserver(this);
        callbacks = [];
        finallyCallback = [];
      },
      enumerable: false,
      writable: false,
      configurable: false,
    },

    /**
     * Listen to the given `event` and
     * execute the `callback` at most once
     * @param   { String } event - event id
     * @param   { Function } fn - callback function
     * @returns { Object } el
     */
    one: {
      value: function (event, fn) {
        function on() {
          el.off(event, on);
          fn.apply(el, arguments);
        }
        return el.on(event, on);
      },
      enumerable: false,
      writable: false,
      configurable: false,
    },

    /**
     * Execute all callback functions that listen to
     * the given `event`. if the last argument is function then il will be considered as the
     * final callback function to be execute after alls callbacks'execution (
     * Exemeple : obj.trigger(even,arg1,arg2,...argN,function(){
     *    code will be execute after event executed
     * The execution callback takes the result of the execution of all the triggers as a parameter
     * and the set of arguments passed as parameters to all these triggers
     * If this parameter has no arguments, then no trigger has been found in the observer
     *
     * })
     * @param   { String } event - event id
     * @returns { Object } el
     */
    trigger: {
      value: function (event) {
        // getting the arguments
        var arglen = arguments.length - 1,
          args = new Array(arglen <= 0 ? 0 : arglen - 1),
          fns,
          fn,
          i;
        var finaly = null;
        if (typeof arguments[arglen] == "function") {
          finaly = arguments[arglen];
          arglen -= 1;
        } else {
          args.push(arguments[arglen]);
        }
        for (i = 0; i < arglen; i++) {
          args[i] = arguments[i + 1]; // skip first argument
        }

        fns = slice.call(callbacks[event] || [], 0);
        let fnsReturns = [];
        let hasTriggering = false;
        for (i = 0; (fn = fns[i]); ++i) {
          if (typeof fn === "function") {
            fnsReturns.push(fn.apply(el, args));
            hasTriggering = true;
          }
        }
        if (callbacks["*"] && event != "*") {
          el.trigger.apply(el, ["*", event].concat(args));
        }
        //finaly events callback
        var finalCals = slice.call(finallyCallback[event] || [], 0);
        // le premier paramètres, représente un tableau des différents résultats retournés par les écouteurs de l'évènemet
        // Le deuxième paramètre est le tableau contenant toute la liste de tous les arguments qui ont été passés à la fonction trigger
        for (i = 0; (fn = finalCals[i]); ++i) {
          fn.call(el, fnsReturns, args);
        }
        //le callback de fin d'exécution de l'évènement trigger, prend en paramètres:
        // le premier paramètres, représente un tableau des différents résultats retournés par les écouteurs de l'évènemet
        // Le deuxième paramètre est le tableau contenant toute la liste de tous les arguments qui ont été passés à la fonction trigger
        if (finaly) {
          finaly.call(el, fnsReturns, args);
        }
        return el;
      },
      enumerable: false,
      writable: false,
      configurable: false,
    },
  });
  return el;
};
var LIB_OBSERVABLE =
  typeof window != "undefined" && window ? window.GLOBAL_LIB_OBSERVABLE : {};
const isFunction = (x) => typeof x == "function";
if (
  LIB_OBSERVABLE &&
  isFunction(LIB_OBSERVABLE.add) &&
  isFunction(LIB_OBSERVABLE.removeObserver) &&
  isFunction(LIB_OBSERVABLE.reset)
) {
} else
  LIB_OBSERVABLE = {
    _stores: [],
    add: function (store) {
      try {
        if (store._____isObserver === true) return;
        store._____isObserver = true;
      } catch (e) {}
      LIB_OBSERVABLE._stores.push(store);
    },
    removeObserver: function (store) {
      var newStore = [];
      for (var i in LIB_OBSERVABLE._stores) {
        if (LIB_OBSERVABLE._stores[i] === store) {
          continue;
        } else {
          newStore.push(LIB_OBSERVABLE._stores[i]);
        }
      }
      delete LIB_OBSERVABLE._stores;
      LIB_OBSERVABLE._stores = newStore;
    },
    reset: function () {
      LIB_OBSERVABLE._stores = [];
    },
  };
export const addObserver = LIB_OBSERVABLE.add;
const removeObserver = LIB_OBSERVABLE.remove;
["on", "one", "off", "trigger"].forEach(function (api) {
  LIB_OBSERVABLE[api] = function () {
    var args = [].slice.call(arguments);
    this._stores.forEach(function (el) {
      el[api].apply(el, args);
    });
  };
});

/*** check if store is observable or not */
export const isObservable = (LIB_OBSERVABLE.isObservable = function (obj) {
  if ((obj == undefined) | (typeof obj != "object")) obj = this;
  if ((obj == false) | (obj == null)) return false;
  return obj._____isObservable === true && typeof (obj.on === "function");
});

/***
 * check if store is observer
 */
export const isObserver = (LIB_OBSERVABLE.isObserver = function (obj) {
  if ((obj == undefined) | (typeof obj != "object")) obj = this;
  if ((obj == false) | (obj == null)) return false;
  return obj._____isObserver === true;
});
if (!LIB_OBSERVABLE.isObservable(LIB_OBSERVABLE)) {
  LIB_OBSERVABLE.add(
    new (function () {
      observable(this);
    })()
  );
}

if (typeof window == "object" && window) {
  if (
    !window.GLOBAL_LIB_OBSERVABLE ||
    typeof window.GLOBAL_LIB_OBSERVABLE != "object"
  ) {
    Object.defineProperties(window, {
      GLOBAL_LIB_OBSERVABLE: { value: LIB_OBSERVABLE },
    });
  }
}

LIB_OBSERVABLE.observable = observable;

export default LIB_OBSERVABLE;
