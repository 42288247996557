import {defaultStr,isDecimal,parseDecimal as NParseDecimal,defaultNumber,isNonNullString} from "$utils";

export const parseDecimal = (v,type,preserveDecimalLength)=>{
    type = defaultStr(type).trim().toLowerCase();
    if((type =='number' || type =='decimal') && !isDecimal(v)){
        if(v == undefined || v == null){
            v = '';
        }
        v = defaultNumber(NParseDecimal(v,preserveDecimalLength),0);
    }
    return v;
}