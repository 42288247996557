import React, { useEffect, Fragment, useState } from "$react";
import Box from "$components/Box";
import SimpleGrid from "$components/SimpleGrid";
import { CLOSED,VALIDATED } from "$status";

import Divider from "$components/Divider";
import Spin from "$layouts/Spin";

import {
  Tabs,
  Empty,
  Form,
  Steps,
  Button,
  Modal,
  Input,
  message,
  Breadcrumb,
} from "antd";
import {
  useGetExpensesCommitmentTicket,
  useAddTicketRemark,
} from "../../api/api";
import moment from "moment";
import "moment/locale/fr";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { userInfos } from "../../store";
import Comments from "./components/comments";
import Validations from "./components/validations";
import Description from "./components/descriptions";
import Spending from "./components/spending";
import Attachments from "./components/attachements";
import Payments from "./components/payment";
import { useParams } from "react-router";
import LayoutPage from "../layout";
import { Link } from "react-router-dom";

export default function TicketDetails() {
  const { t } = useTranslation();

  const [ticket, setTicket] = useState(null);
  const [visible, setVisible] = useState(false);
  const [remark, setRemark] = useState(null);
  const [loading, setLoading] = useState(false);

  const user = useRecoilValue(userInfos);

  let { idticket } = useParams();
  const hasInitializedRef = React.useRef(false);
  const { data, error, isLoading, refetch } = useGetExpensesCommitmentTicket(idticket);

  const { mutateAsync: addRemark } = useAddTicketRemark(ticket?.id, remark);

  useEffect(() => {
    setTicket(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const addNewComment = (comment) => {
    console.log(comment);
    ticket.comments = [comment, ...ticket.comments];
    setTicket(ticket);
  };

  const addNewCodes = (codes) => {
    let objIndex = ticket.items.findIndex((obj) => obj.id == codes.id);

    ticket.items[objIndex] = codes;

    setTicket(ticket);
  };

  const updateTicket = (ticket) => {
    setTicket(ticket);
  };

  const addNewAttachment = (attachment) => {
    ticket.attachments = [attachment, ...ticket.attachments];
    setTicket(ticket);
  };

  const addNewSpending = (spending) => {
    setTicket(spending);
  };
  const onSubmit = async (values) => {
    setRemark(values.remark);

    try {
      setLoading(true);

      let resp = await addRemark();
      setTicket(resp);
      setVisible(false);
      message.success(t("successful.recording"));
      setLoading(false);
    } catch (e) {
      message.warning(t("error.recording"));
      setLoading(false);
    }
  };
  const isClosed = ticket?.status?.id == CLOSED;
  return (
    <LayoutPage>
      {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}
      <Spin
        tip="Chargment..."
        size="large"
        className="tickets-details-content-container"
        spinning={isLoading}
      >
        <Box className="grille w100 mauto u-mar-top-xl-res nopadres noradres ">
          <Breadcrumb items={[
            {
              title : <Link to="/tickets">{t("commitmentRequests.list.title")}</Link>,
            },
            {
              title : ticket?.code,
            }
          ]}/>
          <Box className="details2approbations-container" mt="10px" >
            <SimpleGrid 
              pr="10px" pb="10px" columns={{base:1,lg:2}}
              spacingX='13px' spacingY="8px" className="details2approbations-container-grid"
            >
              <Box className="description-container" bgColor="white" p="15px" borderRadius="15px 15px 0px 0px">
                <Description
                    ticket={ticket}
                    updateTicket={updateTicket}
                    t={t}
                  />
              </Box>
              <Box className="validations-container" bgColor="white" p="15px" borderRadius="15px 15px 0px 0px">
                <Validations
                    iduser={user.id}
                    user = {user}
                    ticket={ticket}
                    updateTicket={updateTicket}
                    t={t}
                  />
              </Box>
            </SimpleGrid>
          </Box>
          <Box w="100%" className="divider-container">
            <Divider w="100%" />
          </Box>
          <Box bgColor="white" className="u-pad-s h100 remarqs-container" mr="10px">
                {ticket?.manager.id === user.id && (
                  <Box className="remarqs-contents">
                    <Box className="comitment-request-details-container">
                      <h2 className="fW600 comitment-request-detail-title">
                        {t("commitmentRequests.detail.section2.title")}
                      </h2>
                      <Box className="remarqs-titles-actions">
                        <Box className="fW600">{ticket?.remark} </Box>
                        <Box
                          className="fs11 cursor coltext"
                          onClick={() => setVisible(true)}
                        >
                          {t("commitmentRequests.detail.section2.label0")}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
          </Box>
          <Box bgColor="white" px="15px" mr="10px" className="details-2-analytics-codes-container"  overflowX="auto">
            <h2 className="fW600 nomar">
              {t("commitmentRequests.detail.section1.title")}
            </h2>
            <Box className="tabs-container">
                <Tabs defaultActiveKey="1" className="clienttab pay"
                    items = {[
                      {
                        label : t("commitmentRequests.detail.section1.tab0.title"),
                        children : <Spending
                          ticket={ticket}
                          addNewSpending={addNewSpending}
                          iduser={user.id}
                          t={t}
                          
                          idticket={idticket}
                          addNewCodes={addNewCodes}
                        />,
                        key : 1,
                      },
                      {
                        label : t("commitmentRequests.detail.section1.tab1.title"),
                        children : <Payments ticket={ticket} t={t} />,
                        key : 2,
                      },
                      {
                        label : t("commitmentRequests.detail.section1.tab2.title"),
                        children : <Attachments
                          ticket={ticket}
                          addNewAttachment={addNewAttachment}
                          canAddAttachment={!!!isClosed}
                          t={t}
                          onDeleteFile={(e)=>{
                            refetch();
                          }}
                        />,
                        key : 3,
                      },
                      {
                        label: t("commitmentRequests.detail.section1.tab3.title"),
                        children : <Comments
                          ticket={ticket}
                          iduser={user.id}
                          idticket={idticket}
                          addNewComment={addNewComment}
                          t={t}
                        />,
                        key : 4,
                      },
                      {
                        key : 5,
                        label : t("commitmentRequests.detail.section1.tab4.title"),
                        children : ticket?.events.length > 0 ? (
                          <Steps progressDot current={0} direction="vertical"
                            items = {ticket.events.map((e,key) => (
                              {
                                key,
                                title : moment(e.date).format("D MMM-YY HH:mm") +
                                " / " +
                                e.author?.firstname +
                                " " +
                                e.author?.lastname,
                                description : i18nTObj(e,"name"), 
                              }
                            ))}
                          />
                        ) : (
                          <Empty className="u-mar-top-l" />
                        )
                      }
                    ]}
                  />
              </Box>
          </Box>
        </Box>
      </Spin>
      <Modal
        width={500}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 280 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentRequests.detail.section2.popup.title")}
        </h2>

        <Form
          layout="vertical"
          className=" itemcenter"
          onFinish={onSubmit}
        >
          <Form.Item
            name="remark"
            style={{ marginTop: 28 }}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="btn" style={{ marginTop: 28 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
              className="rad8"
            >
              <span className="fs16 av-heavy"> {t("add.button")} </span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </LayoutPage>
  );
}
