export const services = [
  {
    name: "LOGISTIQUE",
    id: 0,
  },
  {
    name: "RESSOURCES HUMAINES",
    id: 1,
  },
  {
    name: "COMPTABILITÉ",
    id: 2,
  },
  {
    name: "Partenariat programmes",
    id: 3,
  },
];

export const catRh = [
  { name: "Marché de services intellectuels", id: 0 },
  { name: "Salaire", id: 1 },
];

export const catLogistic = [
  { name: "Marché de services", id: 2 },
  { name: "Marché de travaux", id: 3 },
  { name: "Marché de fournitures", id: 4 },
  { name: "Marché mixte", id: 5 },
];

export const docs = [
  {
    name: "Termes de référence",
    id: 0,
  },
  {
    name: "DAO",
    id: 1,
  },
  {
    name: "Profil de poste",
    id: 2,
  },
  {
    name: "Demande de dérogation",
    id: 3,
  },
  {
    name: "Factures proformas",
    id: 4,
  },
  {
    name: "PV de comparaison des offres",
    id: 5,
  },
  {
    name: "Liste des fournisseurs invités à soumissionner",
    id: 6,
  },
  {
    name: "Lettres d'invitation à soumissionner déchargées",
    id: 7,
  },
  {
    name: "Termes de références de l'appel",
    id: 8,
  },
  {
    name: "Offres des fournisseurs",
    id: 9,
  },
  {
    name: "PV de commission d'évaluation des offres",
    id: 10,
  },
  {
    name: "Fournisseurs non retenus déchargées",
    id: 11,
  },
  {
    name: "Avis d'appel d'offre",
    id: 12,
  },
  {
    name: "Liste des canaux de diffusion",
    id: 13,
  },
  {
    name: "Copie des parutions dans les journaux",
    id: 14,
  },
  {
    name: "Bon de commande",
    id: 15,
  },
  {
    name: "Contrat signé",
    id: 16,
  },
  {
    name: "Conditions d'achats",
    id: 17,
  },
  {
    name: "Bordereau de livraison",
    id: 18,
  },
  {
    name: "Réception provisoire",
    id: 19,
  },
  {
    name: "Réception définitive",
    id: 20,
  },
  {
    name: "Rapport d'exécution",
    id: 21,
  },
  {
    name: "Timesheet",
    id: 22,
  },
  {
    name: "Facture",
    id: 23,
  },
  {
    name: "Autre document",
    id: 24,
  },
];
