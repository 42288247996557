import React, { useState, Fragment } from "react";
import Box from "$components/Box";

import { Form, Input, Button, message } from "antd";

import { useRecoilState } from "recoil";
import {getQueryParams} from "$utils/uri";

import { useOtp } from "../../../api/api";
import { useHistory } from "react-router";
import { tokenData, userInfos, otp } from "../../../store";
import { setToken as persistToken,setLocalUser} from "$auth";
import { useTranslation } from "react-i18next";
import {normalizeSignInPath,normalizePath} from "../utils";
import {isNonNullString} from "$utils";

export default function OtpForm({ handleOtp, id }) {
  const [loading, setLoading] = useState(false);
  const [_, setToken] = useRecoilState(tokenData);
  const [__, setUser] = useRecoilState(userInfos);
  const [___, setOtp] = useRecoilState(otp);
  const history = useHistory();
  const {t} = useTranslation();
  const searchParams = getQueryParams(window?.location?.href);
  let callbackPath = searchParams && searchParams.callbackPath;
  if(isNonNullString(callbackPath)){
      callbackPath = callbackPath.trim();
      if(normalizeSignInPath == normalizePath(callbackPath)){
          callbackPath = "/";
      } else {
        callbackPath = "/"+callbackPath.trim().ltrim("/").rtrim("/").trim();
      }
  } else {
    callbackPath = "/";
  }
  const { mutateAsync: sendOtp } = useOtp(id);
  async function onSubmit(values) {
    try {
      setLoading(true);

      setOtp(values.otp);
      const result = await sendOtp(values);
      const token = result?.token;
      const user = result?.employee;
      persistToken(token);
      setLocalUser(user);
      setToken(token);
      
      setUser(user);

      handleOtp(false);
      history.push(callbackPath);

      setLoading(false);
    } catch (e) {
      console.log(e," sending otp form");
      setLoading(false);
      message.warning("Error");
    }
  }

  return (
    <Fragment>
      <Form
        name="normal_login"
        className="login-form w25 w100res w70tab bgwhite column flex itemcenter  u-pad-horizontal-l u-pad-vertical-l rad8 z999"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onSubmit}
        style={{padding:"30px"}}
      >
        <h2>{t("auth.signIn.otpChecking")}</h2>

        <p></p>

        <Box w="100%" mb="15px">
          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                message: t("auth.signIn.enterOTP"),
              },
            ]}
            className="u-mar-bottom-xs w100"
          >
            <Input
              placeholder={t("auth.signIn.enterOTP")}
            />
          </Form.Item>
        </Box>

        <Form.Item className="w100">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            className="rad8 w100"
          >
            <span className="fs18 av-heavy"> {t("auth.signIn.checkOTP")} </span>
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
