import { services } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchServices = (...args)=>{
    return fetch(services,...args);
}

export const getServices = (...args)=>{
    return get(services,...args);
}

export const SelectService = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {services}
        ref = {ref}
    />
});

SelectService.displayName = "SelectService";