import React, { useState,useEffect, Fragment } from "react";
import FirstForm from "./components/FirstForm";
import OtpForm from "./components/OtpForm";
import "./sign.css";
import { CaretLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {removeToken} from "$auth";

export default function Login() {
  const [showOtp, setShowOtp] = useState(false);
  const [userId, setUserId] = useState(false);
  useEffect(()=>{
    removeToken();
  },[])
  const {t} = useTranslation();
  const handleOtp = (value) => {
    setShowOtp(value);
  };

  const handleUser = (value) => {
    setUserId(value);
  };

  return (
    <Fragment>
      <div className="flex column w100 h100  justcenter itemcenter bgwhiteres">
        {showOtp ? (
          <>
            <p className="w25 fs14 cursor" onClick={() => setShowOtp(false)}>
              {" "}
              <CaretLeftOutlined /> {t("auth.signIn.returnToLogin")}{" "}
            </p>

            <OtpForm handleOtp={handleOtp} id={userId} />
          </>
        ) : (
          <FirstForm handleOtp={handleOtp} handleUser={handleUser} />
        )}
      </div>
    </Fragment>
  );
}
