import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import Table from "$components/Table";
import Box from "$components/Box";
import {
  Button,
  Spin,
  Input,
  Drawer,
  Modal,
  Form,
  Row,
  Col,
  Select,
  message,
  Breadcrumb,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import LayoutPage from "../layout";
import {
  useGetProjects,
  useGetAffectations,
  useGetAllAffectations,
  useGetEmployees,
  useGetZones,
  useDeleteAffectation,RETRY
} from "../../api/api";
import axios from "axios";
import FormAffectaion from "./affectationForm";

const { Option } = Select;
const { confirm } = Modal;

export default function Affectations() {
  const { t } = useTranslation();
  const { data: projects } = useGetProjects({}, {});
  const { data: zones } = useGetZones({}, {});
  const { data: accountant } = useGetEmployees({}, {});
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const { data : listData, error, isLoading, refetch } = useGetAllAffectations(pagination, {},RETRY);
  const [visible, setVisible] = useState(false);
  const [current_element, setCurrentElement] = useState(null);
  const [is_update, setInUpdate] = useState(false);

  const { mutateAsync: deleteAffectation } = useDeleteAffectation();

  const affectatinsColums = [
    {
      title: ("assignments.form.label0"),
      dataIndex: "project",
      onFilter: (value, record) => {
        return (
          String(record.project.code)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(i18nTObj(record?.zone))
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.accountant.firstname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.accountant.lastname)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: ({record}) => {
        return <div>{record?.project?.code}</div>;
      },
    },
    {
      title: ("assignments.form.label1"),
      dataIndex: "zone",
      render: ({record}) => {
        return <div>{i18nTObj(record?.zone)}</div>;
      },
    },
    {
      title: ("assignments.form.label2"),
      dataIndex: "accountant",
      render: ({record}) => {
        return <div>{record?.accountant.firstname + " " + record?.accountant.lastname}</div>;
      },
    },
    {
      title: ("list.columns.label16"),
      key: "operation",
      fixed: "right",
      width: "8%",
      render: ({record}) => {
        return (
          <div style={{ display: "flex" }}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                confirmDeletionAffectation(record.id);
              }}
            />
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                openEdit(record);
              }}
            />
          </div>
        );
      },
    },
  ];
  const handleCancel = () => {
    setOpen(false);
  };

  const close = () => {
    setVisible(false);
  };

  const openEdit = (line) => {
    const { id, project, zone, accountant } = line;
    let val = {
      id: id,
      project_id: project.id,
      zone_id: zone.id,
      accountant_id: accountant.id,
    };
    setCurrentElement({ ...val });
    setInUpdate(true);
    setVisible(true);
  };

  const openCreate = () => {
    setCurrentElement({});
    setInUpdate(false);
    setVisible(true);
  };

  const confirmDeletionAffectation = (id) => {
    confirm({
      // title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: t("confirm.delation"),
      okText: t("yes"),
      cancelText: t("No"),
      onOk: () => {
        deleteAffectation(id).then((res) => {
          refetch();
        });
      },
    });
  };

  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[{title:"Affectations"}]}/>
        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-s   blok noshadow nobzone hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <Box w="100%" className="button-actions u-mar-bottom-s" flex flexDirection="row" justifyContent="end">
            <Button
              type="primary"
              size="large"
              className="rad8"
              onClick={openCreate}
            >
              <span className="fs16 av-heavy"> {t("add.button")} </span>
            </Button>
          </Box>
          <Table
            columns={affectatinsColums}
            listData={listData}
            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange = {(pagination)=>setPagination(pagination)}
          />
        </div>
        <Drawer
          height={"100%"}
          placement={"bottom"}
          onClose={() => setVisible(false)}
          open={visible}
          closable={false}
          bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
          destroyOnClose={true}
        >
          <FormAffectaion
            close={close}
            is_update={is_update}
            current_element={current_element}
            projects={projects}
            accountant={accountant}
            zones={zones}
          />
        </Drawer>
      </Spin>
    </LayoutPage>
  );
}
