import React, { useEffect, useState } from "$react";
import moment from "moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import i18n from "$i18n";

import { Button, Spin, Drawer,Input, Modal, Breadcrumb } from "antd";
import Table from "$components/Table";
import Icon from "$components/Icon";

import { useDeletePosts, useGetAllPosts,RETRY } from "../../api/api";

import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import PostForm from "./postForm";
import Box from "$components/Box";
import LayoutPage from "../layout";

moment.locale("fr");

export default function Post() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [current_element, setCurrentElement] = useState(null);
  const [is_update, setInUpdate] = useState(false);

  const { confirm } = Modal;

  const { mutateAsync: deletePost } = useDeletePosts();

  const close = () => {
    setVisible(false);
  };

  const openEdit = (line) => {
    setCurrentElement({ ...line });
    setInUpdate(true);
    setVisible(true);
  };

  const openCreate = () => {
    setCurrentElement({});
    setInUpdate(false);
    setVisible(true);
  };
  const [pagination,setPagination] = useState(null);
  const { data:listData, error, isLoading, refetch } = useGetAllPosts(pagination, {},RETRY);
  const langs = i18n.getLangs();
  const confirmDeletionZone = (id) => {
    confirm({
      // title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: t("confirm.delation"),
      okText: t("yes"),
      cancelText: t("No"),
      onOk: () => {
        deletePost(id).then((res) => {
          refetch();
        });
      },
    });
  };

  const PostsColumns = [
    {
      title: "Id",
      dataIndex: "id",
      width : "0px",
      ellipsis: true,
    },
    {
      title: ("post.form.label0"),
      dataIndex: i18n.field("name"),
      onFilter: (value, record) => {
        for(let i in langs){
          if(String(record[i18n.field("name",langs[i])]).toLowerCase().includes(value.toLowerCase())){
            return true;
          }
        }
        return false;
      },
      ellipsis: true,
    },
    {
      title: ("list.columns.label16"),
      key: "operation",
      fixed: "right",
      width: "80px",
      render: ({record}) => {
        return (
          <div style={{ display: "flex" }}>
            <Icon
              name={"material-edit"}
              size = "25px"
              color="primary"
              mr="10px"
              onClick={(e) => {
                openEdit(record);
              }}
            />
            <Icon
              name = "material-delete"
              size = {"25px"}
              color = "error"
              onClick={(e) => {
                React.stopEventPropagation(e);
                confirmDeletionZone(record.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[
          {
            title : t("post.list.title")
          }
        ]}/>
        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-s   blok noshadow nobzone hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <Box flex flexDirection="row" justifyContent="end">
              <Button
                type="primary"
                size="large"
                className="rad8"
                onClick={openCreate}
              >
                <span className="fs18 av-heavy">{t("add.button")}</span>
              </Button>
            </Box>

            <Drawer
              height={"100%"}
              placement={"bottom"}
              onClose={() => setVisible(false)}
              open={visible}
              closable={false}
              bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
              destroyOnClose={true}
            >
              <PostForm
                close={close}
                is_update={is_update}
                current_element={current_element}
              />
            </Drawer>
          </div>

          <Table
            columns={PostsColumns}
            listData={listData}
            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange = {(pagination)=>setPagination(pagination)}
          />
        </div>
      </Spin>
    </LayoutPage>
  );
}
