import {Select} from "antd";
const { Option } = Select;
import React from "$react";
import PropTypes from 'prop-types';
import {defaultObj,isObjOrArray} from "$utils";
import Input from "$components/Input";
import { Box } from "@chakra-ui/react";

const SimpleSelectComponent = React.forwardRef((props,ref)=>{
    const innerRef = React.useRef(null);
    let {itemValue,compare,containerProps,disabled,disableOnLoading,callOnChangeWithContext,onChange,fetchOptions,items : customItems,renderItem,renderText,defaultValue,...rest} = props;
    const [state,setState] = React.useState({
        isLoading : false,
        disabled : typeof disabled =='boolean' ? disabled : false,
    })
    const data = isObjOrArray(customItems)? customItems : [];
    fetchOptions = defaultObj(fetchOptions);
    containerProps = defaultObj(containerProps);
    const {isLoading} = state;
    renderText = typeof renderText =='function'? renderText : typeof renderItem =='function'? renderItem : x=>x;
    renderItem = typeof renderItem ==='function'? renderItem : typeof renderText =='function'? renderText : x=>x
    const {itemsKeys,options} = React.useMemo(()=>{
        const itemsKeys = {};
        const options = [];
        Object.map(data,(item,index)=>{
            const value = itemValue({item,index});
            const text = renderText({item,index});
            if(typeof value =='object' || typeof value =='undefined' || typeof text =='object' || typeof text =='undefined'){
                return null;
            }
            itemsKeys[value] = index;
            options.push(<Option key={index} value={value}>
                {text.toString()}
            </Option>);
        });
        return {itemsKeys,options};
    },[data]);
    React.useEffect(()=>{
        if(typeof disabled =='boolean' && disabled !== state.disabled){
            setState({...state,disabled});
        }
    },[disabled])
    const updateState = (nState)=>{
        if(nState && typeof nState =='object'){
            setState({...state,...nState})
        }
    };
    const disable = ()=>{
        setState({...state,disabled:true,isLoading:true})
    }
    const enable = ()=>{
        setState({...state,disabled:disabled !== true ? false : true, isLoading:false});
    }
    const _isLoading = rest.loading || isLoading;
    return (<Input
        {...rest}
        render = {({isInvalid,isReadOnly,errorBorderColor,focusBorderColor,isDisabled,...prs})=>{
            return <Box 
                bordered 
                showSearch
                defaultValue={defaultValue}
                {...prs}
                as = {Select}
                disabled = {isDisabled || state.disabled || disableOnLoading && _isLoading? true : false}
                ref = {(el)=>{
                    if(el && typeof el =='object'){
                        el.updateState = updateState;
                        el.disable = disable;
                        el.enable = enable;
                    }
                    innerRef.current = el;
                    React.setRef(ref,el);
                }}
                loading = {_isLoading}
                onChange = {(selected,rest)=>{
                    if(onChange){
                        const index = itemsKeys[selected];
                        let item = null;
                        if(typeof index !== 'undefined'){
                            item = data[index] || null;
                        }
                        const arg = {...defaultObj(rest),context:innerRef.current,selected,value:selected,index,item};
                        onChange(selected,arg);
                    }
                }}
            >
                {options}
            </Box>
        }}
    />)
});

SimpleSelectComponent.displayName = "SimpleSelectComponent";

SimpleSelectComponent.propTypes = {
    itemValue : PropTypes.func,//.isRequired,
    disableOnLoading : PropTypes.bool,//si le composant est désactivé lorsqu'il charge
    onChange : PropTypes.func,
    renderText : PropTypes.func,
    fetchOptions : PropTypes.object,//les options supplémentaires pour effectuer la recherche
    fetcher : PropTypes.func, ///la fonction appelée pour retourner les données
    data : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ])
}

export default SimpleSelectComponent;