import i18next from "i18next"
import "$utils";
import moment from "moment";

export default {
    get timeFormat(){
        return "HH:mm";
    },
    get dateFormat(){
        const lang = i18next.resolvedLanguage;
        if(lang == "fr"){
            return "D MMM-YY";
        }
        return "D MMM-YY";
    },
    get dateTimeFormat(){
        return "{0} {1}".sprintf(this.dateFormat,this.timeFormat);
    },
    get format(){
        return (date,...rest)=>{
            return moment(date,...rest).format(this.dateTimeFormat)
        }
    },
}