import createPDF from "../createPDF";
import {pdfDocInfo,decoration,fillColor,tableCellNoBorder} from "../utils";
import getPageHeader from "../pageHeader";
import {defaultNumber,parseDecimal,isDataURL,defaultObj,defaultStr,} from "$utils";

const SIGNATURE_WIDTH = 50;//65
const marginLeft  = 4;
export default function exportTicket(data,options){
    options = defaultObj(options);
    //const emptyCell = {text:"",border:tableCellNoBorder,rowSpan:tableRowsSpan};
    const thirdAnalyticCell = {text:"",border:tableCellNoBorder};
    const annexesTableItemHeight = 14;
    const mainTableTotalItemsHeight = 56;
    const itemsTable = [];
    (Array.isArray(data.items) && data.items || []).map((item,index)=>{
        if(!item) return;
        const counter = index+1
        const qty = defaultNumber(item.quantity),price = defaultNumber(item.price);
        itemsTable.push([
            {text:counter.formatNumber(),style:"ticketItem"},
            {text:item.description,style:"ticketItem"},
            {text:qty.formatNumber(),style:"ticketItem"},
            {text:item.unity,style:"ticketItem"},
            {text:price.formatNumber(),fillColor,style:"ticketItem"},
            {text:(price*qty).formatNumber(),style:"ticketItem",fillColor},
            emptyCell,
            //analhytiques
            {text:item.project_code,style:"ticketItem"},
            {text:item.project_line,style:"ticketItem"},
            {text:item.financial_code,style:"ticketItem"},
            {text:item.financial_line,style:"ticketItem"},
            emptyCell,
        ]);
    });
    const mainTableHeight = typeof options.tableItemHeight =='number' && options.tableItemHeight > 10 ? options.tableItemHeight : itemsTable.length == 5 ? 15 : 18//itemsTable.length >10 ? 17 : 18;
    const tableRowsSpan = 5 + itemsTable.length + 5;
    const emptyCellRowsSpan = {rowSpan:tableRowsSpan,text:"",border:tableCellNoBorder};
    const getCheckedTex = (key,paddingLeft)=>{
        const val = typeof key =='boolean'? key : typeof key =='string'? data[key] : undefined;
        return {
            text : (val && "x" || ""),
            paddingLeft : typeof paddingLeft =='number'? paddingLeft : 5,
            alignment : 'center',
        };
    }
    const attachments = Array.isArray(data?.attachments)? data.attachments : [];
    const getSignatureData = (name)=>{
        const isD = isDataURL(data[name]);
        return {
            columns :[
                {text:"Signature :     ",width:50,marginTop:isD?12:0,style:'ticketValidationKey'},
                isD ? {
                    image : data[name],
                    width : SIGNATURE_WIDTH,
                } : {},
            ],
            border:[false,false,true,false],
            marginTop : -5,
        };
    }
    const requisitionColSpan = 6, analyticColSpan = 4;
    console.log(data," is data heeee");
    return createPDF({
        content : [
            getPageHeader(),
            {
                columns : [
                    {
                        layout : 'table',
                        table : {
                            body : [
                                [
                                    {
                                    
                                        stack : [
                                            {text : '1) REQUISITION : A REMPLIR PAR LE DEMANDEUR',style:"tableHeaderTitle"},
                                            {text : "Le demandeur peut être le responsable budgétaire.",style:"tableHeaderSubtitle"}
                                        ],
                                        style : "tableHeader",
                                        colSpan : requisitionColSpan,
                                    }, //No Items
                                    emptyCell,//Description détaillée de chaque produit
                                    emptyCell,//Quantité
                                    emptyCell,//Unité 
                                    emptyCell,//Prix unitaire
                                    emptyCell,//Prix total
                                    emptyCellRowsSpan,//emptyCell
                                    ///analytique
                                    {
                                        stack : [
                                            {
                                                text : [
                                                    {text : "CODE DEMANDE : ",style:"tableHeaderTitle"},
                                                    {text : data.fileName,style:"tableHeaderTitle",fontSize:10},
                                                ],
                                                marginLeft: 5,
                                            },
                                            {text : "Le code est rempli par le service responsable du traitement de la demande; qui tient à jour une liste des demandes reçues.",style:"tableHeaderSubtitle"}
                                        ],
                                        style : "tableHeader",
                                        colSpan : analyticColSpan,
                                    },//Code projet,
                                    emptyCell,//Ligne projet
                                    emptyCell,//Code de financement
                                    emptyCell,//Ligne de financement
                                    emptyCellRowsSpan////emptyRowCell
                                ],
                                [
                                    {
                                        columns : [
                                            {
                                                stack : [
                                                    {
                                                        text : [
                                                            {text : "Projet : ",style:"elementTitle"},
                                                            {text:`          ${data.projectName}     `,style:"elementTitle",decoration}
                                                        ],
                                                    },
                                                    {
                                                        text : [
                                                            {text : "Explication du marché :",style:"elementTitle"},
                                                            {text : `       ${data.explanation}    `,style:"elementTitle",decoration}
                                                        ],
                                                        marginTop : 5,
                                                    }
                                                ]
                                            },
                                            {
                                                stack : [
                                                    {
                                                        text : [
                                                            {text : "Date :",style:"elementTitle"},
                                                            {text : `       ${data.date}    `,style:"elementTitle",decoration}
                                                        ],
                                                        marginTop : 5,
                                                    },
                                                    {
                                                        text : [
                                                            {text : "Demandeur : ",style:"elementTitle"},     
                                                            {text:'  Pour les achats de matériel médical, le demandeur doit être le coordinateur programme médical.',alignment:"center",style:"tableHeaderSubtitle"}
                                                        ],
                                                        marginTop : 5,
                                                    },
                                                    {
                                                        marginTop : 5,
                                                        marginLeft : 5,
                                                        text : [
                                                            {text : "Nom :",width:20,italics:true,style:"elementTitle",bold:false}, 
                                                            {text : `     ${data.requesterName}  `,decoration,style:"elementTitle",bold:false}, 
                                                        ],
                                                    },
                                                    {
                                                        marginTop : 5,
                                                        marginLeft : 5,
                                                        text : [
                                                            {text : "Fonction :",italics:true,style:"elementTitle",bold:false},  
                                                            {text : `     ${defaultStr(data.requesterPost).replaceAll("\n"," ")}  `,decoration,style:"elementTitle",bold:false},   
                                                        ],
                                                    },
                                                    {
                                                        ...getSignatureData("requesterSignature"),
                                                        marginLeft : 5,
                                                        marginTop : 0,
                                                        marginBottom : 0,
                                                    },
                                                ]
                                            }
                                        ],
                                        marginTop : 2,
                                        marginLeft:5,
                                        marginBottom : 0,
                                        colSpan : requisitionColSpan,
                                    }, //No Items
                                    emptyCell,//Description détaillée de chaque produit
                                    emptyCell,//Quantité
                                    emptyCell,//Unité 
                                    emptyCell,//Prix unitaire
                                    emptyCell,//Prix total
                                    emptyCellWithoutBorder,//emptyCell
                                    {
                                        stack : [
                                            {text:[{text :"Catégorie ",fontWeight:"bold",style:"elementTitle"},{text:"  Service responsable du traitement de la demande",marginTop:6.5,style:"tableHeaderSubtitle"}],marginLeft:5},
                                            {
                                            table :{
                                                body :  [
                                                    [
                                                        {text:"LOG - Logistique (marché de fournitures / services / travaux) :",style:"codeCommandeItem",border:tableCellNoBorder},
                                                        {...getCheckedTex("logCategory"),border:[true,true,false,true]}
                                                    ],
                                                    [
                                                        {text:"RH - ressources humaines (marché de services de type consultance ou étude) :",style:"codeCommandeItem",border:tableCellNoBorder},
                                                        {...getCheckedTex("rhCategory"),border:[true,true,false,true]}
                                                    ],
                                                    [
                                                        {text:"COMPTA - comptabilité (avance,  déplacement, perdiem) :",style:"codeCommandeItem",border:tableCellNoBorder},
                                                        {...getCheckedTex("accountingCategory"),border:[true,true,false,true]}
                                                    ],
                                                    [
                                                        {text:"PROG - partenariat programmes :",marginTop:5,style:"codeCommandeItem",border:tableCellNoBorder},
                                                        {...getCheckedTex("progCategory"),lineHeight:20,border:[true,true,false,true]}
                                                    ],
                                                ],
                                                widths : ["*",30],
                                                margin : 0,
                                            }}
                                        ],
                                        colSpan : analyticColSpan,
                                    }, //analytique 
                                    emptyCell,//Ligne projet
                                    emptyCell,//Code de financement
                                    emptyCell,//Ligne de financement
                                    emptyCellWithoutBorder
                                ],
                                [
                                    {
                                        stack : [
                                            {text:"2) ESTIMATION DU MARCHÉ : A REMPLIR PAR LE DEMANDEUR",style:"tableHeaderTitle"},
                                            {text:"La liste détaillée est remplie par le responsable budgétaire sur la base des TdR et du budget; la liste regroupe les items d'un même marché (sans fractionnement). En cas d'achat, les prix peuvent être estimés par le chargé de logistique selon la connaissance du marché.",style:"tableHeaderSubtitle"}
                                        ],
                                        style : "tableHeader",
                                        colSpan : requisitionColSpan,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    {
                                        columns : [{text : "3) CODIFICATION: A REMPLIR PAR LE RESPONSABLE BUDGÉTAIRE",style:"tableHeaderTitle",marginTop:1}],
                                        style : "tableHeader",
                                        colSpan : analyticColSpan,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                ],
                                ///les tables des lignes items et analytiques
                                [
                                    {text:"",border:tableCellNoBorder},
                                    {text:"",border:tableCellNoBorder},
                                    {text:"",border:tableCellNoBorder},
                                    {text:"",border:tableCellNoBorder},
                                    {text:"Devise : XAF",fontSize:10,colSpan:2,style:"ticketItemHeader",border:[true,false,true,false]},
                                    {text:"",border:tableCellNoBorder},
                                    
                                    emptyCellWithoutBorder,
                                    //analytiques
                                    {text:"Codes Analytiques",style:"ticketItemHeader",fontSize:10,colSpan:4},
                                    {text:"",border:tableCellNoBorder},
                                    {text:"",border:tableCellNoBorder},
                                    {text:"",border:tableCellNoBorder},
                                    emptyCellWithoutBorder,
                                ],
                                [
                                    {text:"No Items",style:"ticketItemHeader",marginTop:5},
                                    {text:"Description détaillée de chaque produit",marginTop:5,style:"ticketItemHeader"},
                                    {text:"Quantité",style:"ticketItemHeader",marginTop:5},
                                    {text:"Unité",style:"ticketItemHeader",marginTop:5},
                                    {text:"Prix unitaire",style:"ticketItemHeader",marginTop:5},
                                    {text:"Prix total",style:"ticketItemHeader",marginTop:5},
                                    
                                    emptyCell,
                                    //analytiques
                                    {text:"Code projet",style:"ticketItemHeader"},
                                    {text:"Ligne projet",style:"ticketItemHeader"},
                                    {text:"Code de financement",style:"ticketItemHeader"},
                                    {text:"Ligne de financement",style:"ticketItemHeader"},
                                    emptyCell,
                                ],
                                ...itemsTable,
                                //tickets procedures
                                [
                                    {
                                        layout : "ticketProcedure",
                                        table: {
                                            body : [
                                                [
                                                    {
                                                        text:"Procédure à suivre (selon palier d'achats) :",
                                                        marginLeft:5,
                                                        decoration,bold:true,
                                                        fontSize:8,
                                                        marginTop : 2,
                                                        marginBottom:0,
                                                        colSpan : 4,
                                                        border : tableCellNoBorder,
                                                    },
                                                    "",
                                                    "",
                                                    ""
                                                ],
                                                [
                                                    {text:"Dépense / Achat direct",fontSize:8,marginTop:3,alignment:"center",border:tableCellNoBorder},
                                                    {...getCheckedTex("directProcedure"),alignment:"center"},
                                                    {text:"Procédure simplifiée, 3 cotations",fontSize:8,marginTop:3,alignment:"center",border:tableCellNoBorder},
                                                    {...getCheckedTex("simplifiedProcedure"),alignment:"center",border:[true,true,false,false]}
                                                ],
                                                [
                                                    {text:"Procédure négociée, 3 cotations",alignment:"center",fontSize:8,marginTop:1,border:tableCellNoBorder},
                                                    {...getCheckedTex("negociatedProcedure"),alignment:"center"},
                                                    {text:"Appel d'offre ouvert",fontSize:8,alignment:"center",marginTop:3,border:tableCellNoBorder},
                                                    {...getCheckedTex("callTenderProcedure"),alignment:"center",border:[true,true,false,false]}
                                                ]
                                            ],
                                            heights : mainTableTotalItemsHeight/4,
                                            widths : ["*",25,"*",25],
                                        },
                                        colSpan:3,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCell,//quantité
                                    {
                                        layout : "subTable",
                                        table : {
                                            body : [
                                                [
                                                    {text:"Sous-Total",alignment:"center",fontSize:9,marginTop:5,bold:true},
                                                    {text:parseDecimal(data.subTotal).formatNumber(),fontSize:9,alignment:"center",style:"ticketItemHeader"},
                                                ],
                                                [
                                                    {text:"TVA",alignment:"center",fontSize:9,marginTop:5,bold:true},
                                                    {text:parseDecimal(data.tva).formatNumber(),fontSize:9,alignment:"center",style:"ticketItemHeader"},
                                                ],
                                                [
                                                    {text:"MONTANT TOTAL",alignment:"center",marginTop:5,fontSize:9,bold:true},
                                                    {text:parseDecimal(data.totalAmount).formatNumber(),fontSize:9,alignment:"center",style:"ticketItemHeader"},
                                                ]
                                            ],
                                            widths : [111,"*"],
                                            heights : mainTableTotalItemsHeight/3,
                                        },
                                        colSpan : 3,
                                    }, //unité
                                    "", //Prix unitaire
                                    "", //Prix total,
                                    emptyCellWithoutBorder,
                                    {
                                        stack:[
                                            {text:"Remarques : ",fontSize:9},
                                            {text:data.remark,fontSize:8}
                                        ],
                                        colSpan:analyticColSpan,
                                        rowSpan : 2,
                                        marginLeft,
                                    },
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                ],
                                [
                                    {
                                        text : [
                                            {
                                                text:"Composition comité d'évaluation (demandeur, responsable du service de la catégorie, resp. budgétaire): ",
                                                style:"tableHeaderSubtitle",
                                            },
                                            {text:"                  ",decoration},
                                        ]
                                        ,marginLeft : 5,
                                        colSpan:requisitionColSpan,
                                    },
                                    emptyCell,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder
                                ],
                                [
                                    {
                                        stack : [
                                            {text:"4) VALIDATION DE L'ENGAGEMENT  : ",style:"tableHeaderTitle"},
                                            {text:"La validation autorise le démarrage du processus d'achat / de sélection et engage quand au fait que la dépense aura lieu. ",style:'tableHeaderSubtitle'}
                                        ],
                                        style : "tableHeader",
                                        colSpan : 11,
                                    },
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCellWithoutBorder,
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCellWithoutBorder,
                                ],
                                [
                                    {
                                        stack : [
                                            {text:"Validation de Responsable budgétaire",fontSize:9,bold:true},
                                            {text:"La signature confirme la cohérence de la demande avec les objectifs du projet / tdr / besoins. ",style:"ticketValidationRule"}
                                        ],border:[true,false,true,true],
                                        colSpan : 2,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    {
                                        stack : [
                                            {text:"Validation Admin Base/CAFLog",fontSize:9,bold:true},
                                            {text:"La signature confirme la disponibilité budgétaire. ",style:"ticketValidationRule"},
                                            {text : [{text:"Si montant inférieur ou égale à ",style:"ticketValidationRule"},{text:" 500 000 XAF: validation Admim base",bold:true,style:"ticketValidationRule"}]},
                                            {text : [{text:"Si montant supérieur à ",style:"ticketValidationRule"},{text:" 500 000 XAF: validation CAF-Log",bold:true,style:"ticketValidationRule"}]}
                                        ],border:[true,false,true,true],
                                        colSpan : 4,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    {
                                        stack : [
                                            {text:"Validation Chef de Base/Coordinateur général",fontSize:9,bold:true},
                                            {text:"La signature valide la procédure.. ",style:"ticketValidationRule"},
                                            {text : [{text:"Si montant inférieur ou égale à ",style:"ticketValidationRule"},{text:" 500 000 XAF : validation chef de base",bold:true,style:"ticketValidationRule"}]},
                                            {text : [{text:"Si montant supérieur à ",style:"ticketValidationRule"},{text:" 500 000 XAF: validation coordinateur général",bold:true,style:"ticketValidationRule"}]}
                                        ],
                                        border:[true,false,true,true],
                                        colSpan :4,
                                        marginLeft,
                                    },
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCellWithoutBorder,
                                ],
                                [
                                    {
                                        text:[
                                            {text:"Nom :     ",style:'ticketValidationKey'},
                                            {text : data.budgetHolderName,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,false],
                                        colSpan : 2,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    {
                                        text:[
                                            {text:"Nom :     ",style:'ticketValidationKey'},
                                            {text : data.adminFinName,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,false],
                                        colSpan : 4,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    {
                                        text:[
                                            {text:"Nom :     ",style:'ticketValidationKey'},
                                            {text : data.coordinatorName,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,false],
                                        colSpan :4,
                                        marginLeft,
                                    },
                                    emptyCell,
                                    emptyCell,
                                    emptyCell,
                                    emptyCellWithoutBorder,
                                ],
                                [
                                    {
                                        text :[
                                            {text:"Fonction :     ",style:'ticketValidationKey'},
                                            {text : data.budgetHolderPost,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,false],
                                        colSpan : 2,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    {
                                        text:[
                                            {text:"Fonction :     ",style:'ticketValidationKey'},
                                            {text : data.adminFinPost,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,false],
                                        colSpan : 4,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    {
                                        text:[
                                            {text:"Fonction :     ",style:'ticketValidationKey'},
                                            {text : data.coordinatorPost,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,false],
                                        colSpan : 4,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                ],
                                [
                                    {
                                        ...getSignatureData("budgetHolderSignature"),marginLeft,colSpan:2,border:[true,false,true,false],
                                    },
                                    emptyCellWithoutBorder,
                                    {
                                        ...getSignatureData("adminFinSignature"),colSpan : 4,marginLeft,border:[true,false,true,false],
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    {
                                        ...getSignatureData("coordinatorSignature"),
                                        border : tableCellNoBorder,
                                        colSpan : 4,
                                        marginLeft,
                                        border:[true,false,true,false],
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                ],
                                [
                                    {
                                        text :[
                                            {text:"Date :     ",style:'ticketValidationKey'},
                                            {text : data.budgetHolderDate,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,true],
                                        marginTop : -5,
                                        colSpan :2,
                                        marginLeft,
                                    },
                                    emptyCellWithoutBorder,
                                    {
                                        text:[
                                            {text:"Date :     ",style:'ticketValidationKey'},
                                            {text : data.adminFinDate,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,true],
                                        marginTop : -5,
                                        marginLeft,
                                        colSpan : 4,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    {
                                        text:[
                                            {text:"Date :     ",style:'ticketValidationKey'},
                                            {text : data.coordinatorDate,style:"ticketValidationValue"}
                                        ],
                                        border:[true,false,true,true],
                                        marginTop : -5,
                                        marginLeft,
                                        colSpan :4,
                                    },
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                    emptyCellWithoutBorder,
                                ]
                            ],
                            widths : [40,165,50,40,70,75,8,50,50,60,"*",8],
                            heights : mainTableHeight,
                        }
                    },
                    //colonne annexe du dossier
                    {
                        width : 140,
                        layout : "ticketAnnexesMain",
                        table : {
                            body : [
                                [
                                    {
                                        text : [{text : "ANNEXES AU DOSSIER",marginLeft:5,style:"tableHeaderTitle"}],
                                        marginTop : 5,
                                        marginLeft : 5,
                                        style : "tableHeader",
                                    },
                                ],
                                [
                                    {
                                        layout : "ticketAnnexes",
                                        //tableau des annexes du dossier
                                        table : {
                                            body : [
                                                [
                                                    {text:"Etapes 1-4",style:"ticketAnnexTableTitle",colSpan:3,border:tableCellNoBorder},
                                                    "",
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Termes de référence",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[0]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- DAO",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[1]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Profil de poste",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[2]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Demande de dérogation",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[3]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                //Etape 5 - si procédure simplifiée
                                                [
                                                    {text:"Etape 5 - si procédure simplifiée",style:"ticketAnnexTableTitle",colSpan:3,border:tableCellNoBorder},
                                                    "",
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Factures proformas",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[4]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- PV de comparaison des offres",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[5]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                //Etape 5 - si procédure négociée
                                                [
                                                    {text:"Etape 5 - si procédure négociée",style:"ticketAnnexTableTitle",colSpan:3,border:tableCellNoBorder},
                                                    "",
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Liste des fournisseurs invités à soumissionner",style:"ticketAnnexTableLabel",colSpan:3,border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[6])},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Lettres d'invitation à soumissionner déchargées",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[7]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Termes de références de l'appel",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[8]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Offres des fournisseurs",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[9]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- PV de commission d'évaluation des offres",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[10]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Lettres d'information aux fournisseurs non retenus déchargées",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[11]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                
                                                
                                                //Etape 5 - si appel d'offre ouvert
                                                [
                                                    {text:"Etape 5 - si appel d'offre ouvert",style:"ticketAnnexTableTitle",colSpan:3,border:tableCellNoBorder},
                                                    {text:"",style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Avis d'appel d'offre",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[12]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Liste des canaux de diffusion",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[13])},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Copie des parutions dans les journaux",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[14]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                
                                                //Etape 5 - preuve(s) de commande
                                                [
                                                    {text:"Etape 5 - preuve(s) de commande",style:"ticketAnnexTableTitle",colSpan:3,border:tableCellNoBorder},
                                                    "",
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Bon de commande",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[15]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Contrat signé",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[16]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Conditions d'achats",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[17])},
                                                    thirdAnalyticCell,
                                                ],
                                                
                                                
                                                //Preuves d'exécutions
                                                [
                                                    {text:"Preuves d'exécutions",style:"ticketAnnexTableTitle",colSpan:3,border:tableCellNoBorder},
                                                    "",
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Bordereau de livraison",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[18]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Réception provisoire",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[19]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Réception définitive",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[20]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Rapport d'exécution",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[21]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Timesheet",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[22]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                                [
                                                    {text:"- Facture",style:"ticketAnnexTableLabel",border:tableCellNoBorder},
                                                    {...getCheckedTex(attachments[23]),style:"ticketAnnexTableValue"},
                                                    thirdAnalyticCell,
                                                ],
                                            ],
                                            widths : ["*",7,1],
                                            heights : annexesTableItemHeight,
                                        }
                                    },
                                ]
                            ],
                            heights : function (row) {
                                if(row ===0) return 31;
                                return 15;
                            },
                        }
                    }
                ],
            }
        ],  
        ...pdfDocInfo,
    }).open();
}

const emptyCell = {
    text : "",
    
};
const emptyCellWithoutBorder = {
    text : "",
    border : tableCellNoBorder,
}