import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
let fonts = {
	Roboto: {
		normal: 'Roboto-Regular.ttf',
		bold: 'Roboto-Medium.ttf',
		italics: 'Roboto-Italic.ttf',
		bolditalics: 'Roboto-MediumItalic.ttf'
    },
};
pdfMake.fonts = fonts;
export default function createPDF(docDefinition){
    return pdfMake.createPdf(docDefinition,tableLayouts);
};
createPDF.fonts = fonts;


const table = {
  hLineWidth: function (i, node) {
    return 1;
  },
  vLineWidth: function (i) {
    return 1;
  },
  hLineColor: function (i) {
    return 'black';
  },
  paddingLeft: function (i) {
    return 0;
  },
  paddingRight: function (i, node) {
    return 0;
  },
  paddingTop : function(i,node){
    return 0;
  },
  paddingBottom : function(i,node){
    return 0;
  },
  marginLeft : function(i){
    return ;
  },
  marginRight : function(i){
    return 0;
  },
  marginTop : function(i){
    return 0;
  },
  marginBottom : function(i){
    return 0;
  }
};
const subTable = {
  ...table,
  hLineWidth: function (i, node) {
    if(i ==0 || i == node.table.body.length) return 0;
    return 1;
  },
  vLineWidth: function (i,node) {
    if(i ==0 || i == node.table.body[0]?.length) return 0;
    return 1;
  },
};

export const tableLayouts = {
    table,
    subTable,
    ticketAnnexesMain : {
      ...table,
      vLineWidth: function (i,node) {
        //if(i ==0) return 0;
        return 1;
      },
    },
    ticketAnnexes : {
      ...subTable,
      hLineWidth: function (i, node) {
        return 1;
      },
      paddingLeft: function (i) {
        return 3;
      },
      paddingRight: function (i, node) {
        return 2;
      },
    },
    ticketProcedure : {
      ...table,
      hLineWidth: function (i, node) {
        if(i == node.table.body.length) return 0;
        return 1;
      },
    },
  }

pdfMake.tableLayouts = tableLayouts;