import React, { Fragment, useEffect, useState, useCallback } from "react";
import "moment/locale/fr";
import Box from "$components/Box";
import { Col, Row, Spin } from "antd";
import FormExpense from "./form/step1form";

import { CloseOutlined } from "@ant-design/icons";
import { useGetProjects } from "../../api/api";
import { useTranslation } from "react-i18next";

export default function AddOrder({ close, initiator }) {
  const { t} = useTranslation();
  const [projects, setProjects] = useState(null);
  const { data} = useGetProjects({}, {});
  useEffect(() => {
    setProjects(data);
  }, [data]);
  return (
    <Box className="flex  column rel h100">
      <Box
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <Box
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </Box>

        <h2 className="fW600"> {t("commitmentForms.form.title")} </h2>
      </Box>
      <Spin tip="Chargment..." size="large" spinning={false}>
        <Row
          gutter={32}
          className=" w100  u u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres "
        >
          <Col
            span={22}
            offset={1}
            className=" w100  u-pad-top-s u-pad-horizontal-s   h100 blok rad16 u-mar-top-xl-res nopadres noradres "
          >
            <Box
              className="flex mauto w90 column u-mar-top-xl  bgwhite  nomarres u-pad-s-res"
              style={{ height: "max-content" }}
            >
              <FormExpense
                projects={projects}
                userId={initiator}
                close={close}
              />
            </Box>
          </Col>
        </Row>
      </Spin>
    </Box>
  );
}
