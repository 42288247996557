export default {
   "notify.changes" : "Des changements dans {0} demandes vous concernant",
    "auth.signIn.email" : "Entrez votre email",
    "auth.signIn.password" : "Entrez votre mot de passe",
    "main.searchIn" : "Rechercher dans",
    "main.filtersOn" : "Filtres sur",
    "main.cancel" : "Annuler",
    "table.yes" : "Oui",
    "table.No" : "Non",
    ...require("./auth").default,
    ...require("./tickets").default,
    "homePage.menu.dashboard": "Tableau de board",
    "homePage.menu.commitmentForm": "Formulaire d'engagement",
    "homePage.menu.commitmentRequest": "Demande d'Engagement",
    "homePage.menu.paymentRequest": "Demande de paiement",
    "homePage.menu.sites": "Bases",
    "homePage.menu.employees": "Employes",
    "homePage.menu.projects": "Projets",
    "homePage.menu.assignments": "Affectations",
    "homePage.menu.travelRequest": "Demande de deplacement",
    "header.logout": "Deconnexion",
    "homePage.header.profile.settings.label": "Paramètres du compte",
    "homePage.header.profile.settings.page.section1.title":
      "Mes informations",
    "homePage.header.profile.settings.page.section1.label0": "Nom",
    "homePage.header.profile.settings.page.section1.label1": "Prénom",
    "homePage.header.profile.settings.page.section1.label2": "Téléphone",
    "homePage.header.profile.settings.page.section1.button":
      "Enregistrer",
    "homePage.header.profile.settings.page.section2.title":
      "Changer de mot de passe",
    "homePage.header.profile.settings.page.section2.label0":
      "Ancien mot de passe",
    "homePage.header.profile.settings.page.section2.label1":
      "Nouveau mot de passe",
    "homePage.header.profile.settings.page.section2.button":
      "Enregistrer",
  
      yes: "Oui",
      No: "Non",
      no : "Non",

    "dashboard.content.welcome": "Bienvenue",
    "dashboard.content.welcome.date": "Format date en Francais",
    "dashboard.content.requestsCreated": "Demandes créées",
    "dashboard.content.requestsValidated": "Demandes validées",
    "dashboard.content.requestsRejected": "Demandes rejetées",
    "dashboard.content.requestsToProcess": "Demandes à traiter",
    "dashboard.content.commitments.stat": "Engagements de dépenses",
    "dashboard.content.travel.stat": "Déplacements",
    "dashboard.content.payment.stat": "Paiements",
    "commitmentForms.list.title": "Formulaires d'engagement",
    "commitmentForms.list.search.keywords":
      "Rechercher par initiateur, code, zone ...",
    "commitmentForms.form.title":
      "Nouvelle demande d'engagement de dépense",
    "commitmentForms.form.section1.title": "Réquisition",
    "commitmentForms.form.section1.label0":
      "Pour une réalisation projet ?",
    "commitmentForms.form.section1.label1": "Activités",
    "commitmentForms.form.section1.label2": "Projet",
    "commitmentForms.form.section1.label3": "Zone",
    "commitmentForms.form.section1.label4": "Soumettre à",
    "commitmentForms.form.section1.isRequestFromSite" : "Demande au niveau du siège?",
    "commitmentForms.form.section2.title": "Estimation du marché ",
    "commitmentForms.form.section2.label0": "Description de l'article",
    "commitmentForms.form.section2.label1": "Service",
    "commitmentForms.form.section2.label2": "Quantité",
    "commitmentForms.form.section2.label3": "Unité",
    "commitmentForms.form.section2.label4": "Prix.U",
    "commitmentForms.form.section2.label5": "Total",
    "commitmentForms.form.section2.label6": "PRIX TOTAL",
    "commitmentForms.form.section2.label7": "Ajouter un item",
    "commitmentForms.form.section2.popup.title": "Ajouter un item",
    "commitmentForms.form.section2.popup.label0":
      "Sous service",
    "commitmentForms.form.section2.popup.label1": "Sous service pour RH",
    "add.button": "Ajouter",
    "validate.button": "Valider",
    download: "Télécharger",
    delete: "Supprimer",
    "confirm.delete.attachment": "Confirmer la supression",
    "add.attachment": "Ajouter une pièce jointe",
    "commitmentForms.form.section3.title": "Pièces jointes",
    "commitmentForms.form.section3.title1": "Type de pièce jointe",
    "commitmentForms.form.section3.label0": "Type de Document",
    "commitmentForms.form.section3.label1": "Nom du fichier",
    "commitmentForms.form.section3.label2": "Ajouter un TDR",
    "commitmentForms.form.section3.label3": "Selectionner le document",
    "commitmentForms.form.section3.popup.title": "Ajouter un TDR",
    "commitmentForms.form.section3.popup.label0":
      "Sélectionner le document",
    "commitmentForms.form.detail.label0": "Pour le projet ?",
    "commitmentForms.form.detail.label1": "Projets",
    "commitmentForms.form.detail.label2": "Date",
    "commitmentForms.form.detail.label3": "Zone",
    "commitmentForms.form.detail.label4": "Devise",
    "commitmentForms.form.detail.label5": "initiateur",
    "commitmentForms.form.detail.label6": "Activités",
    "commitmentForms.form.detail.label7":
      "Demandes d'engagement de dépense",
    "commitmentForms.form.detail.label8": "Code demande",
    "commitmentForms.form.detail.label10": "Catégorie",
    "commitmentForms.form.detail.label11": "Statut",
    "commitmentForms.form.detail.label12": "Object",
    "commitmentRequests.list.title": "Demandes d'engagement",
    "commitmentRequests.list.search.keywords":
      "Rechercher par code, projet, zone ...",
    "commitmentRequests.list.filters.filter1": "Toutes les demandes",
    "commitmentRequests.list.filters.filter2": "Demandes validées",
    "commitmentRequests.list.filters.filter3": "Demandes rejetées",
    "commitmentRequests.list.filters.filter4": "Demandes à traiter",

    "commitmentRequests.detail.label0": "Service",
    "commitmentRequests.detail.label1": "Projets",
    "commitmentRequests.detail.label2": "Date",
    "commitmentRequests.detail.label3": "Zone",
    "commitmentRequests.detail.label4": "Procédure",
    "commitmentRequests.detail.label5": "initiateur",
    "commitmentRequests.detail.label6": "Activités",
    
    "commitmentRequests.edit.procedure": "Changer le type de procédure",
    "commitmentRequests.edit.project" : "Modifier le project",
    "commitmentRequests.edit.zone" : "Modifier la zone",
    "commitmentRequests.edit.activities" : "Modifier les activités",
    
    
    "commitmentRequests.type.procedure": "type de procédure",
    "commitmentRequests.detail.section1.title": "Détails",
    "commitmentRequests.detail.section1.tab0.title": "Items",
    "commitmentRequests.detail.section1.tab0.label0": "Num",
    "commitmentRequests.detail.section1.tab0.label1": "Description",
    "commitmentRequests.detail.section1.tab0.label2": "Quantité",
    "commitmentRequests.detail.section1.tab0.label3": "Unité",
    "commitmentRequests.detail.section1.tab0.label4": "Prix.U",
    "commitmentRequests.detail.section1.tab0.label5": "Total",
    "commitmentRequests.detail.section1.tab0.label6": "PRIX TOTAL",
    "commitmentRequests.detail.section1.tab0.label7": "TOTAL Dépense",
    "commitmentRequests.activities" : "Activités",
    "commitmentRequests.detail.section1.tab1.title": "Paiements",
    "commitmentRequests.detail.section1.tab1.label0": "Code",
    "commitmentRequests.detail.section1.tab1.label1": "Montant",
    "commitmentRequests.detail.section1.tab1.label2": "Date",
    "commitmentRequests.detail.section1.tab1.label3": "Payé à",
    "commitmentRequests.detail.section1.tab1.label4": "Initiateur",
    "commitmentRequests.detail.section1.tab1.label5": "Statut",
    "commitmentRequests.detail.section1.tab2.title": "Pièces jointes",
    "commitmentRequests.detail.section1.tab2.label0": "Type de Document",
    "commitmentRequests.detail.section1.tab2.label1": "Date d'ajout",
    "commitmentRequests.detail.section1.tab2.label2": "Ajouté par",
    "commitmentRequests.detail.section1.tab3.title": "Commentaires",
    "commitmentRequests.detail.section1.tab3.button":
      "Ajouter un commentaire",
    "commitmentRequests.detail.section1.tab4.title": "Historique",

    "commitmentRequests.detail.section2.title": "Remarques",
    "commitmentRequests.detail.section2.label0": "Ajouter/modifier",
    "commitmentRequests.detail.section2.popup.title":
      "Ajouter une remarque sur les items",
    "commitmentRequests.detail.section2.popup.button":
      "Ajouter la remarque",

    "commitmentRequests.detail.section3.title": "Approbations",
    "commitmentRequests.detail.section3.label0": "Changer le statut",
    "commitmentRequests.detail.section3.label1": "Assigné à",
    "commitmentRequests.detail.section3.label2": "Demandeur",
    "commitmentRequests.detail.section3.label3": "Resp. Budgétaire",
    "commitmentRequests.detail.section3.label4": "Admin Financier",
    "commitmentRequests.detail.section3.label5": "Coord Général",
    "commitmentRequests.detail.section3.label6": "Resp Opérationnel",
    "commitmentRequests.detail.section3.label7": "Chargement en cours ...",
    "commitmentRequests.status.approuved": "Approuvé",
    "commitmentRequests.status.rejected": "Rejeté",
    "commitmentRequests.status.waiting_payment": "Attente de paiement",
    "commitmentRequests.status.Validated": "Validé",
    "commitmentRequests.status.close": "Fermé",

    "commitmentRequests.fill_anal": "Remplir tous les codes analytiques",
    "commitmentRequests.rejected_msg":
      "Demande d'engagement rejetée, modifiez le , et soummettez à nouveau",
    "commitmentRequests.submit_again": "Soumettre à nouveau ",

    "commitmentRequests.detail.section3.modify.popup.title":
      "Changement de poste",
    "commitmentRequests.detail.section3.modify.popup.label0":
      "Changer le responsable du poste",
    "commitmentRequests.detail.section3.modify.popup.label1":
      "Assigner à",
    "commitmentRequests.detail.section3.modify.popup.cancel": "Annuler",
    "commitmentRequests.detail.section3.modify.popup.confirm":
      "Confirmer",

    "commitmentRequests.detail.section3.changeStatus.popup.title":
      "Changer le  statut de la demande",
    "commitmentRequests.detail.section3.changeStatus.popup.label0":
      "Etes vous sûr de modifier le statut à",
    "commitmentRequests.detail.section3.changeStatus.popup.label1":
      "Ajouter un commentaire",
    "commitmentRequests.detail.section3.changeStatus.popup.cancel":
      "Annuler",
    "commitmentRequests.detail.section3.changeStatus.popup.confirm":
      "Confirmer",

    "commitmentRequests.detail.section4.title": "Codes Analytique",
    "commitmentRequests.detail.section4.label0": "Num",
    "commitmentRequests.detail.section4.label1": "projet",
    "commitmentRequests.detail.section4.label2": "Ligne projet",
    "commitmentRequests.detail.section4.label3": "Code fin",
    "commitmentRequests.detail.section4.label4": "Ligne fin",
    "commitmentRequests.detail.section4.popup.title":
      "Ajouter un code analytique",
    "commitmentRequests.detail.section4.popup.label0": "Code projet",
    "commitmentRequests.detail.section4.popup.label1": "Ligne projet",
    "commitmentRequests.detail.section4.popup.label2": "Code financier",
    "commitmentRequests.detail.section4.popup.label3": "Ligne financière",

    "commitmentRequests.delete.item": "Supprimer un item",
    "commitmentRequests.edit.item": "Modifier un item",
    "commitmentRequests.confirm.delete.item":
      "Confirmer la suppression de cet item ",

    "reassignment.label": "Modifier",
    "export.button.printOptions" : "Options d'impression",
    "export.buton.printOptions.tableItemSize" : "Hauteur de lignes des items",
    "export.button.label": "Exporter",
    "export.button.pdf.label" : "Exporter au format pdf",
    "export.button.excel.label" : "Exporter au format excel",
    "submit.button.label": "Soumettre",
    "input.required": "Champ obligatoire",
    "successful.recording": "successful recording",
    "error.recording": "error on recording",
    "paymentRequests.list.title": "Demandes de paiement",
    "paymentRequests.list.search.keywords":
      "Rechercher par code, demandeur ...",
    "paymentRequests.list.filters.filter1": "Tous les paiements",
    "paymentRequests.list.filters.filter2": "Paiements validés",
    "paymentRequests.list.filters.filter3": "Paiements rejetées",
    "paymentRequests.list.filters.filter4": "Paiements à traiter",
    transfer: "Virement",
    check: "Chèque",
    cash: "Cash",
    "paymentRequests.form.title": "Nouvelle demande de paiement",
    "paymentRequests.form.section1.title": "Informations",
    "paymentRequests.form.section1.label0": "Choisir la DED",
    "paymentRequests.form.section1.label1": "Type de paiement",
    "paymentRequests.form.section1.label2": "Explications",
    "paymentRequests.form.section2.title": "Items",
    "paymentRequests.form.section2.label0": "Description de l'article",
    "paymentRequests.form.section2.label1": "Service",
    "paymentRequests.form.section2.label2": "Quantité",
    "paymentRequests.form.section2.label3": "Unité",
    "paymentRequests.form.section2.label4": "Prix.U",
    "paymentRequests.form.section2.label5": "Total",
    "paymentRequests.form.section2.label6": "PRIX TOTAL",

    "paymentRequests.detail.label0": "Code engagement",
    "paymentRequests.detail.label1": "Type",
    "paymentRequests.detail.label2": "Date",
    "paymentRequests.detail.label3": "Payé à",
    "paymentRequests.detail.label4": "Moyen de paiement",
    "paymentRequests.detail.label5": "initiateur",
    "paymentRequests.detail.label6": "Explication",

    "paymentRequests.detail.section1.title": "Détails",
    "paymentRequests.detail.section1.tab0.title": "Items",
    "paymentRequests.detail.section1.tab0.label0": "Num",
    "paymentRequests.detail.section1.tab0.label1": "Description",
    "paymentRequests.detail.section1.tab0.label2": "Quantité",
    "paymentRequests.detail.section1.tab0.label3": "Unité",
    "paymentRequests.detail.section1.tab0.label4": "Prix.U",
    "paymentRequests.detail.section1.tab0.label5": "Total",
    "paymentRequests.detail.section1.tab0.label6": "PRIX TOTAL",
    "paymentRequests.detail.section1.tab1.title": "Pièces jointes",
    "paymentRequests.detail.section1.tab1.label0": "Type de Document",
    "paymentRequests.detail.section1.tab1.label1": "Date d'ajout",
    "paymentRequests.detail.section1.tab1.label2": "Ajouté par",
    "paymentRequests.detail.section1.tab2.title": "Commentaires",
    "paymentRequests.detail.section1.tab2.button":
      "Ajouter un commentaire",
    "paymentRequests.detail.section1.tab3.title": "Historique",

    "paymentRequests.detail.section3.title": "Approbations",
    "paymentRequests.detail.section3.label0": "Changer le statut",
    "paymentRequests.detail.section3.label1": "Assigné à",
    "paymentRequests.detail.section3.label2": "Demandeur",
    "paymentRequests.detail.section3.label3": "Resp. Budgétaire",
    "paymentRequests.detail.section3.label4": "Admin Financier",
    "paymentRequests.detail.section3.label5": "Coord Général",
    "paymentRequests.detail.section3.modify.popup.title":
      "Changement de poste",
    "paymentRequests.detail.section3.modify.popup.label0":
      "Changer le responsable du poste",
    "paymentRequests.detail.section3.modify.popup.label1": "Assigner à",
    "paymentRequests.detail.section3.modify.popup.cancel": "Annuler",
    "paymentRequests.detail.section3.modify.popup.confirm": "Confirmer",

    "paymentRequests.detail.section3.changeStatus.popup.title":
      "Changer le  statut de la demande",
    "paymentRequests.detail.section3.changeStatus.popup.label0":
      "Etes vous sûr de modifier le statut à",
    "paymentRequests.detail.section3.changeStatus.popup.label1":
      "Ajouter un commentaire",
    "paymentRequests.detail.section3.changeStatus.popup.cancel":
      "Annuler",
    "paymentRequests.detail.section3.changeStatus.popup.confirm":
      "Confirmer",

    "paymentRequests.detail.section4.title": "Codes Analytique",
    "paymentRequests.detail.section4.label0": "Num",
    "paymentRequests.detail.section4.label1": "projet",
    "paymentRequests.detail.section4.label2": "Ligne projet",
    "paymentRequests.detail.section4.label3": "Code fin",
    "paymentRequests.detail.section4.label4": "Ligne fin",
    "paymentRequests.detail.section4.popup.title":
      "Ajouter un code analytique",
    "paymentRequests.detail.section4.popup.label0": "Code projet",
    "paymentRequests.detail.section4.popup.label1": "Ligne projet",
    "paymentRequests.detail.section4.popup.label2": "Code financier",
    "paymentRequests.detail.section4.popup.label3": "Ligne financière",

    "travelRequests.list.title": "Demandes de déplacement",
    "travelRequests.list.search.keywords": "Rechercher un déplacement",
    "travelRequests.list.filters.filter1": "Tous les déplacemens",
    "travelRequests.list.filters.filter2": "Déplacemens validées",
    "travelRequests.list.filters.filter3": "Déplacemens rejetées",
    "travelRequests.list.filters.filter4": "Déplacemens à traiter",

    "travelRequests.form.title": "Nouvelle demande de déplacement",
    "travelRequests.form.step1.title": "Etape 1",
    "travelRequests.form.step1.label0": "Pays",
    "travelRequests.form.step1.label1": "Responsable logistique",
    "travelRequests.form.step1.label2": "Objectifs",
    "travelRequests.form.step1.label3": "Premier jour",
    "travelRequests.form.step1.label4": "Dernier jour",
    "travelRequests.form.Error.step1.label41":
      "Entrer d'abord le premier jour",
    "travelRequests.form.step1.Error.label42":
      "le dernier jour doit être supérieure au premier",
    "travelRequests.form.step1.label5": "Lieu de départ",
    "travelRequests.form.step1.label6": "Type de document",
    "travelRequests.form.step1.label7": "Pièces jointes",
    "travelRequests.form.step1.label8": "Membres",
    "travelRequests.form.step1.label9": "Nom complet",
    "travelRequests.form.step1.label10": "Profession",
    "travelRequests.form.step1.button.label": "Ajouter un membre externe",
    "travelRequests.form.step1.nextButton.label": "Suivant",

    "travelRequests.form.step2.title": "Etape 2",
    "travelRequests.form.step2.label0": "Destination",
    "travelRequests.form.step2.label1": "Autre destination",
    "travelRequests.form.step2.label2": "Mode de transport allé",
    "travelRequests.form.step2.label3": "Mode de transport retour",
    "travelRequests.form.step2.label4": "Autre Mode de transport",
    "travelRequests.form.step2.label5": "Hébergement principal",
    "travelRequests.form.step2.label6": "Type de document",
    "travelRequests.form.step2.label7": "Autre hebergement",
    "travelRequests.form.step2.label8": "Contexte",
    "travelRequests.form.step2.label9": "Éléments de risques",
    "travelRequests.form.step2.label10": "Éléments de mitigation",
    "travelRequests.form.previousButton.label": "Précédent",

    "travelRequests.detail.label0": "Pays",
    "travelRequests.detail.label1": "Départ",
    "travelRequests.detail.label2": "Destination",
    "travelRequests.detail.label3": "Autre destination",
    "travelRequests.detail.label4": "Date de départ",
    "travelRequests.detail.label5": "Date de fin",
    "travelRequests.detail.label6": "Mode de transport allé",
    "travelRequests.detail.label7": "Mode de transport retour",
    "travelRequests.detail.label8": "Autre Mode de transport",
    "travelRequests.detail.label9": "Hébergement",
    "travelRequests.detail.label10": "Autre hebergement",
    "travelRequests.detail.label11": "Objectifs",

    "travelRequests.detail.section1.title": "Détails",
    "travelRequests.detail.section1.tab0.title": "Membres",
    "travelRequests.detail.section1.tab0.label0": "Poste",
    "travelRequests.detail.section1.tab0.label1": "Nom",
    "travelRequests.detail.section1.tab0.label2": "Prénom",
    "travelRequests.detail.section1.tab1.title": "Membres externes",
    "travelRequests.detail.section1.tab1.label0": "Nom",
    "travelRequests.detail.section1.tab1.label1": "Profession",
    "travelRequests.detail.section1.tab2.label0": "Type de Document",
    "travelRequests.detail.section1.tab2.label1": "Date d'ajout",
    "travelRequests.detail.section1.tab2.label2": "Ajouté par",
    "travelRequests.detail.section1.tab3.title": "Commentaires",
    "travelRequests.detail.section1.tab3.button":
      "Ajouter un commentaire",
    "travelRequests.detail.section1.tab4.title": "Historique",

    "travelRequests.detail.button1": "Ajouter une pièce jointe",
    "travelRequests.detail.addMemberText": "Ajouter des membres",
    "travelRequests.detail.Description.title":
      "Informations sur le déplacment",
    "travelRequests.detail.placeholder.file":
      "Selectionner le type de fichier",
    "travelRequests.upload.btn.text": "Choisir",
    "travelRequests.validation.approve": "Approuver",
    "travelRequests.validation.authorise": "Autoriser",
    "travelRequests.validation.reject": "Rejecter",
    "travelRequests.validation.Reason.reject":
      "Veuillez entrer la raison du rejet",

    "travelRequests.detail.section3.title": "Approbations",
    "travelRequests.detail.section3.label0": "Changer le statut",
    "travelRequests.detail.section3.label1": "Assigné à",
    "travelRequests.detail.section3.label2": "Demandeur",
    "travelRequests.detail.section3.label3": "Logisticien",
    "travelRequests.detail.section3.label4": "Admin Financier",
    "travelRequests.detail.section3.label5": "Coord Général",

    "travelRequests.detail.section3.modify.popup.title":
      "Changement de poste",
    "travelRequests.detail.section3.modify.popup.label0":
      "Changer le responsable du poste",
    "travelRequests.detail.section3.modify.popup.label1": "Assigner à",
    "travelRequests.detail.section3.modify.popup.cancel": "Annuler",
    "travelRequests.detail.section3.modify.popup.confirm": "Confirmer",

    "travelRequests.detail.section3.changeStatus.popup.title":
      "Changer le  statut de la demande",
    "travelRequests.detail.section3.changeStatus.popup.label0":
      "Etes vous sûr de modifier le statut à",
    "travelRequests.detail.section3.changeStatus.popup.label1":
      "Ajouter un commentaire",
    "travelRequests.detail.section3.changeStatus.popup.cancel": "Annuler",
    "travelRequests.detail.section3.changeStatus.popup.confirm":
      "Confirmer",

    "zone.list.title": "Bases",
    "zone.list.search.keywords": "Rechercher par nom",
    "zone.form.title": "Nouvelle zone",
    "zone.form.update": "Modifier zone",
    "zone.form.label0": "Nom",
    "zone.form.Error.label0":
      "Le premier caractère doit être en majuscule",

    "employees.list.title": "Employés",
    "employees.list.search.keywords": "Rechercher par nom",
    "employees.form.title": "Nouvel employé",
    "employees.form.header-worker": "L'employé travaille sur site?",
    "employees.form.label0": "Nom",
    "employees.form.label1": "Prénom",
    "employees.form.label2": "Poste",
    "employees.form.label3": "Role",
    "employees.form.label4": "Télephone",
    "employees.detail.label0": "Télephone",
    "employees.detail.label1": "Lieu de naissance",
    "employees.detail.label2": "Date de naissance",
    "employees.detail.label3": "Poste",
    "employees.detail.label4": "E-mail",
    "employees.detail.label5": "Mot de passe",
    "employees.detail.label6": "Pas de signature enregistrée",
    "employees.detail.upload.button": "Modifier la signature",
    "employees.delete.button": "Supprimer le compte",
    "employees.edit.button": "Modifier le profil",

    "projects.list.title": "Projets",
    "projects.list.search.keywords": "Rechercher par code",
    "projects.form.title": "Nouveau projet",
    "projects.form.title1": "Modifier projet",
    "projects.form.label0": "Code du projet",
    "projects.form.Error.label01": "Entrez sept caractères au maximum",
    "projects.form.label1": "Nom du projet",
    "projects.form.label2": "Chef de projet",
    "projects.form.label3": "Date de début",
    "projects.form.Error.label3":
      "Entrer une date supérieure à aujourd'hui",
    "projects.form.label4": "Date de fin",
    "projects.form.Error.label41": "Entrer d'abord la date de début",
    "projects.form.Error.label42":
      "la date de fin doit être supérieure à la date de début",
    "projects.form.label5": "Paliers spécifiques ?",
    "projects.form.label6": "Achat direct :",
    "projects.form.label7": "Procédure simplifiée :",
    "projects.form.Error.label71": "Veuillez entrer un nombre",
    "projects.form.Error.label72":
      "La valeur doit être supérieure à celle de l'achat",
    "projects.form.label8": "Procédure négociée :",
    "projects.form.Error.label81":
      "La valeur doit être supérieure à celle de la procédure simplifiée",
    "projects.form.label9": "Appel d'offre :",

    "assignments.list.title": "Affectations",
    "assignments.list.search.keywords": "Rechercher par code",
    "assignments.form.title": "Nouvelle affectation",
    "assignments.form.update": "Modifier affectation",
    "assignments.form.label0": "Projet",
    "assignments.form.label1": "Zone",
    "assignments.form.label2": "Compt/Log/rh",

    "list.columns.label0": "Code Unique",
    "list.columns.label1": "Initiateur",
    "list.columns.label2": "Date",
    "list.columns.label3": "Zone",
    "list.columns.label4": "Pour Projet?",
    "list.columns.label5": "Demande",
    "list.columns.label6": "Payé à",
    "list.columns.label7": "Type",
    "list.columns.label8": "Moyen de paiement",
    "list.columns.label9": "Statut",
    "list.columns.label10": "Nom",
    "list.columns.label11": "Téléphone",
    "list.columns.label12": "E-mail",
    "list.columns.label13": "Profession",
    "list.columns.label15": "Chef de Projet",
    "list.columns.label16": "Opérations",
    "list.columns.label17": "Projet",
    "list.columns.label18": "Pays",
    "list.columns.label19": "Vérificateur",
    "list.columns.label20": "Départ",
    "list.columns.label21": "Arrivée",
    "list.columns.label22": "Hébergement",
    "list.columns.label23": "Emeteur",
    "required": "Ce champs est requis",
    "yes": "Oui",
    "No": "Non",
    "payment.type.advance": "Avance",
    "payment.type.payment": "Paiement",
    "confirm.delation":
      "Etes vous sûres de vouloir supprimer cet élément?",
    "nodata": "Pas d'élémént",
    "error": "Erreur",
    "error.text": "Veuillez remplir les éléments d'analyse des risques",

    "post.list.title": "Poste",
    "post.list.search.keywords": "Rechercher par nom",
    "post.form.title": "Nouveau poste",
    "post.form.update": "Modifier poste",
    "post.form.label0": "Nom",
    "post.form.Error.label0":
      "Le premier caractère doit être en majuscule",
    "menu.post": "Poste",
    "uploader.closeModal" : "Fermer",
    "uploader.isNotAValidFile" : "Le fichier {0} n'est pas une image/pdf valide. Veuillez sélectionner une image ou un fichier pdf valide",
    "userNotGrantedNotifications" : "Notifications Bloqués par l'utilisateur, veuillez autoriser l'affichage des notifications à partir du navigateur.",
    "notiificationNotSupportedByBrowser" : "Désolé, votre navigateur ne supporte pas l'affichage des notifications windows",
    "addOtherDocument" : "Ajouter|Autre document",
  }