import React from "$react";
import {Button} from "antd";
import {classNames} from "$utils"
import {
    DownloadOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { useGetDownload } from "./hooks";
import { useTranslation } from "react-i18next";
  
  export default function AttachementDownloadComponent({className,...props}){
    const [isLoading,setIsLoading] = React.useState(false);
    const downloadFile = useGetDownload({...props,save:true});
    const {t} = useTranslation();
    return <Button
        className={classNames(className,"attachment-download")}
        loading = {isLoading}
        icon={<DownloadOutlined />}
        title={t("download")}
        onClick={() => {
          setIsLoading(true);
          downloadFile().finally(()=>setIsLoading(false));
        }}
      />   
  }
  
  AttachementDownloadComponent.propTypes = {
    downloadLink : PropTypes.string,//le lien de téléchargement du fichier
    download : PropTypes.func,
  }