
/**** build flatten style from array of styles object properties
 * 
 */
export default function flattenStyle(...styles) {
    if (styles == null || typeof styles !== 'object') {
      return  {};
    }
    const flatArray = styles.flat(Infinity);
    const result = {};
    for (let i = 0; i < flatArray.length; i++) {
      const style = flatArray[i];
      if (style != null && typeof style === 'object') {
        Object.assign(result, style);
      }
    }
    return result;
}