export default function sprintf(args) {
  args =
    typeof args == "object" && args
      ? args
      : Array.prototype.slice.call(arguments);
  let str = typeof args[0] == "string" ? args[0] : "";
  if (!str) return str;
  for (let i = 1; i < args.length; i++) {
    const t =
      typeof args[i] == "boolean" ||
      typeof args[i] == "number" ||
      typeof args[i] == "string"
        ? args[i] + ""
        : "";
    str = str.replaceAll("{" + (i - 1) + "}", t);
  }
  return str;
}
