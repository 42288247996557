import React, { useState, useEffect } from "react";

import axios from "axios";

import {
  Button,
  Select,
  Form,
  Col,
  message,
  Row,
  Input,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useAddZone, useEditZone } from "../../api/api";
import { useTranslation } from "react-i18next";

const { Option } = Select;
export default function FormZone({ close, current_element, is_update }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [id, setId] = useState(null);

  const { mutateAsync: addZone } = useAddZone();
  useEffect(() => {
    if (is_update) {
      setId(current_element.id);
    } else {
      setId(0);
    }
  }, []);
  const { mutateAsync: updateZone } = useEditZone(id);

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        //console.log(values)

        try {
          setLoading(true);
          if (is_update) {
            //console.log("new values", values)
            await updateZone(values);
          } else {
            await addZone(values);
          }

          //je ferme le drawer
          close();

          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <div className="flex  column rel h100">
      <div
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <div
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </div>

        <h2 className="fW600">
          {is_update ? t("zone.form.update") : t("zone.form.title")}
        </h2>

        <div className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            loading={loading}
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("validate.button")} </span>
          </Button>
        </div>
      </div>

      <div className="u-mar-vertical-xxl  w50 mauto">
        <Form
          layout="vertical"
          className="rel"
          form={form}
          style={{ padding: "0px 150px" }}
          initialValues={{ ...current_element }}
        >
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t("zone.form.label0")}
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (
                          value.charAt(0) != value.charAt(0).toLocaleUpperCase()
                        ) {
                          return Promise.reject(
                            new Error(t("zone.form.Error.label0"))
                          );
                        }
                        if (!value) {
                          return Promise.reject(new Error(t("required")));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </div>
    </div>
  );
}
