import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { useRecoilValue, useRecoilState } from "recoil";
import Box from "$components/Box";
import SimpleGrid from "$components/SimpleGrid";

import {
  Button,
  Layout,
  DatePicker,
  Drawer,
  Form,
  Col,
  Row,
  Select,
  Spin,
  Menu,
  Divider,
  message,
  Input,
} from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import FormPwd from "./formpwd";
import LayoutPage from "../layout";
import { useGetEmployee, useEditEmployee } from "../../api/api";
import { userInfos } from "../../store";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;
const { Content } = Layout;
const { Option } = Select;
export default function Compte() {
  const userData = useRecoilValue(userInfos);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(1);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const [user, setUser] = useState(null);
  const [__, setUserR] = useRecoilState(userInfos);

  /**
   * handleOnUpload
   */

  /*     const  handleOnUpload =   async (error, result, widget) => {
      if ( error ) {
        updateError(error);
        widget.close({
          quiet: true
        });
        return;
      }
      await update({image:result?.info?.secure_url});
     
      setUser({...user,image:result?.info?.secure_url});

    } */

  const { data, error, isLoading, refetch } = useGetEmployee(userData.id);

  const { mutateAsync: updateEmployee } = useEditEmployee(userData.id);

  useEffect(() => {
    setUser(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const close = () => {
    setVisible(false);
    setReload(reload + 1);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const respo = await updateEmployee(data);
      setUserR(respo);
      setLoading(false);
      message.success(t("successful.recording"));
      setUser(respo);
    } catch (e) {
      console.log(e);
      message.error(t("error.recording"));
      setLoading(false);
    }
  };
  return (
    <LayoutPage>
      {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Box className="grille w100  flex column noshadow noborder hauto rad16 u-mar-top-xl-res nopadres itemcenter justcenter">
          <Box className="block w60 cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res">
            <Box className="  flex column  w100  " style={{ top: 0 }}>
              <h3 className="fW600 fs20 colprim u-pad-left-l  u-mar-top-s">
                {" "}
                {t("homePage.header.profile.settings.page.section1.title")}{" "}
              </h3>
            </Box>

            {user && (
              <Form
                layout="vertical"
                className="u-pad-horizontal-l"
                initialValues={{
                  firstname: user.firstname,
                  lastname: user.lastname,
                  date_of_birth: moment(user.date_of_birth),
                  place_of_birth: user.place_of_birth,
                  phone: user.phone,
                }}
                onFinish={onSubmit}
              >
                <Box w="100%" flex alignItems="center" textAlign="center" justifyContent="center" className="avatar-container">
                    <img
                        src="/avatar.png"
                        alt="avatar"
                        height={90}
                        width={90}
                        className="u-mar-right-xs"
                      />
                </Box>
                <Divider className="u-mar-vertical-xs" />
                <Box className="infos-container" alignItems="center" justifyContent="center" flexDirection="column" display="flex">
                  <span className="fs12">{user?.user.email}</span>
                  <Box mx="10px" my="10px" className="flex u-mar-vertical-xs">
                    <span className="btnbranch"> {i18nTObj(user?.post)}</span>
                  </Box>
                  <span className="fs12 fW500 coltext">
                    {i18nTObj(user?.user.role)}
                  </span>
                </Box>
                <SimpleGrid>
                  <Form.Item
                      name="firstname"
                      label={t(
                        "homePage.header.profile.settings.page.section1.label0"
                      )}
                      rules={[
                        { required: true, message: t("input.required") },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                </SimpleGrid>
                <Form.Item
                    name="lastname"
                    label={t(
                      "homePage.header.profile.settings.page.section1.label1"
                    )}
                  >
                    <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label={t(
                    "homePage.header.profile.settings.page.section1.label2"
                  )}
                  rules={[{ message: t("input.required") }]}
                >
                  <Input />
                </Form.Item>
                <Row gutter={16}>

                  <Col span={18}>

                    <Row gutter={16}>
                      <Col className="flex flexend w100">
                        <Form.Item className="w100">
                          <Box
                            style={{ textAlign: "right" }}
                            className="u-mar-top-xs"
                          >
                           {/*** <Button
                              size={"large"}
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="rad8 u-pad-horizontal-l"
                            >
                              {t(
                                "homePage.header.profile.settings.page.section1.button"
                              )}
                            </Button> */}
                          </Box>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Box>

          <FormPwd email={user?.user.email} id={user?.id} t={t} />
        </Box>
      </Spin>
    </LayoutPage>
  );
}
