import React, { useState, useEffect } from "react";
import Box from "$components/Box";
import i18n from "$i18n";

import {
  Button,
  Form,
  Col,
  message,
  Row,
  Input,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useAddPosts, useEditPosts } from "../../api/api";
import { useTranslation } from "react-i18next";

export default function PostForm({ close, current_element, is_update }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  const { mutateAsync: addPost } = useAddPosts();
  useEffect(() => {
    if (is_update) {
      setId(current_element.id);
    } else {
      setId(0);
    }
  }, []);
  const { mutateAsync: updatePost } = useEditPosts(id);

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async function (values) {
        try {
          setLoading(true);
          if (is_update) {
            await updatePost(values);
          } else {
            await addPost(values);
          }

          close();

          message.success(t("successful.recording"));
          setLoading(false);
        } catch (e) {
          console.log(e," is error");
          setLoading(false);
          message.warning(t("error.recording"));
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  return (
    <Box className="flex  column rel h100">
      <Box
        className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s"
        style={{ top: 0, borderBottom: "1px solid #DDD", paddingBottom: 10 }}
      >
        <Box
          className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs"
          style={{ left: 34 }}
          onClick={() => close()}
        >
          <span className="fs15  " style={{ color: "#1a1a1a" }}>
            <CloseOutlined />
          </span>
        </Box>
        <h2 className="fW600">
          {is_update ? t("post.form.update") : t("post.form.title")}
        </h2>
        <Box className="flex itemcenter abs" style={{ right: 34 }}>
          <Button
            type="primary"
            size="large"
            loading={loading}
            className="rad8"
            onClick={() => handleSubmit()}
          >
            <span className="fs16 av-heavy"> {t("validate.button")} </span>
          </Button>
        </Box>
      </Box>
      <Box className="form-post-container u-mar-vertical-xxl" p="30px"  display="flex" justifyContent="start" alignItems="center" flexDirection="row" flexWrap="wrap" >
        <Form
          layout="vertical"
          className="rel"
          form={form}
          initialValues={{ ...current_element }}
        >
          {i18n.fields("name",true).map(({name,lang})=>{
            return <Form.Item
                key = {name}
                name={name}
                label={`${t("post.form.label0")} [${lang}]`}
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (
                        value.charAt(0) != value.charAt(0).toLocaleUpperCase()
                      ) {
                        return Promise.reject(
                          new Error(t("post.form.Error.label0"))
                        );
                      }
                      if (!value) {
                        return Promise.reject(new Error(t("required")));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
            </Form.Item>
          })}
        </Form>
      </Box>
    </Box>
  );
}
