import React, { useEffect, useState } from "react";
import "moment/locale/fr";
import Table from "$components/Table";
import {
  Button,
  Spin,
  Input,
  Drawer,
  Modal,
  Breadcrumb,
  Tag,
  Menu,
  Dropdown,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import LayoutPage from "../layout";
import {
  useGetEmployees,
  useGetMovements,
  useGetAllMovements,
  useGetDocumentsTypes,RETRY
} from "../../api/api";
import { useRecoilValue } from "recoil";
import { userInfos } from "../../store";
import MovementForm from "./movementForm";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "$components/Box";

export default function Movements() {
  const { t } = useTranslation();
  const [current_element, setCurrentElement] = useState(null);
  const [is_update, setInUpdate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState(null);
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [movementStatus, setMovementStatus] = useState(params.get("status"));
  const [checker, setChecker] = useState(params.get("checker_id"));

  const resetFilters = () => {
    setChecker(null);
    setMovementStatus(null);
  };

  const setStatus = (t) => {
    setChecker(null);
    setMovementStatus(t);
  };
  const user = useRecoilValue(userInfos);
  const history = useHistory();
  const { data : listData, error, isLoading, refetch } = useGetAllMovements(
    movementStatus,
    checker,
    pagination,
    {},RETRY
  );
  const { data: initiator } = useGetEmployees({}, {});
  const { data: documents } = useGetDocumentsTypes({}, {});
  const filterMenu = [
    {
      label : <div
        key="1"
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => resetFilters()}
      >
        <div className="  itemcenter justbtw flex">
          <span className="av-heavy fs14">
            {t("travelRequests.list.filters.filter1")}
          </span>
        </div>
      </div>,
    },
    {
      label : <div
        key="2"
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setStatus(1)}
      >
        <span className="av-heavy fs14">
          {t("travelRequests.list.filters.filter2")}
        </span>
      </div>,
    },
    {
      label : <div
        key="3"
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setStatus(2)}
      >
        <span className="av-heavy fs14">
          {t("travelRequests.list.filters.filter3")}
        </span>
      </div>,
    },
    {
      label : <div
        key=""
        className="flex itemcenter rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setChecker(user.id)}
      >
        <span className="av-heavy fs14">
          {t("travelRequests.list.filters.filter4")}
        </span>
      </div>,
    }
  ]
  
  useEffect(() => {
    if(pagination && (pagination?.page || pagination?.size)){
      setPagination({...pagination,current:0,page:0});
      setTimeout(refetch,200);
      return;
    }
    refetch();
  }, [movementStatus, checker]);

  const movementsColumns = [
    {
      title: "Code",
      dataIndex: "code",
      onFilter: (value, record) => {
        return (
          String(record.code).toLowerCase().includes(value.toLowerCase()) ||
          String(record.country).toLowerCase().includes(value.toLowerCase()) ||
          String(i18nTObj(record.status)).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: ("Date"),
      dataIndex: "date",
      type : "date"
    },
    {
      title: ("travelRequests.detail.section3.label2"),
      dataIndex: "initiator",
      onFilter : (value,item)=>{
        return "{0} {1}".sprintf(item?.initiator?.firstname,item?.initiator?.lastname).toLowerCase().trim().includes(String(value).toLowerCase());
      },
      render: ({item}) => {
        return <div>{item?.initiator.firstname + " " + item?.initiator.lastname}</div>;
      },
    },
    {
      title: ("commitmentRequests.detail.section3.label1"),
      dataIndex: "checker",
      onFilter : (value,item)=>{
        return "{0} {1}".sprintf(item?.checker?.firstname,item?.checker?.lastname).toLowerCase().trim().includes(String(value).toLowerCase());
      },
      render: ({item}) => {
        return <div>{item?.checker.firstname + " " + item?.checker.lastname}</div>;
      },
    },
    {
      title: ("travelRequests.form.step1.label3"),
      dataIndex: "startDate",
      type : "date",
    },
    {
      title : ("travelRequests.form.step1.label4"),
      dataIndex : "endDate",
      type : "date",
    },
    {
      title: ("travelRequests.form.step1.label5"),
      dataIndex: "starting",
    },
    {
      title: ("travelRequests.detail.label2"),
      dataIndex: "destination",
    },
    {
      title: ("list.columns.label22"),
      dataIndex: "hosting",
    },
    {
      title: ("list.columns.label9"),
      dataIndex: ["status","id"],
      onFilter : (value,record)=>{
        return String(i18nTObj(record?.status)).toLowerCase().includes(String(value).toLowerCase().trim());
      },
      render: ({item}) => {
        return (
          <Tag
            className="u-mar-left-xs"
            color={
              item?.status?.id == 0
                ? "#ff9e01"
                : item?.status?.id == 1
                ? "#87d068"
                : item?.status?.id == 2
                ? "#ff5500"
                : "#108ee9"
            }
          >
            {i18nTObj(item?.status)}
          </Tag>
        );
      },
    },
  ];

  const close = () => {
    setVisible(false);
  };

  const openCreate = () => {
    setCurrentElement({});
    setInUpdate(false);
    setVisible(true);
  };

  return (
    <LayoutPage>
      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb
          items = {[
            {
              title : t("travelRequests.list.title"),
            }
          ]}
        />
        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-s   blok noshadow nobzone hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <Box flex flexDirection="row" pt="5px" px="5px">
              <Dropdown
                menu={{items:filterMenu}}
                style={{ width: "200px" }}
                className="cursor u-mar-right-xs"
                trigger={["click"]}
              >
                <div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
                  <span
                    className="fs15 av-heavy u-mar-right-xs"
                    style={{ color: "#1a1a1a" }}
                  >
                    {movementStatus == 2
                      ? t("travelRequests.list.filters.filter3")
                      : movementStatus == 1
                      ? t("travelRequests.list.filters.filter2")
                      : checker != null
                      ? t("travelRequests.list.filters.filter4")
                      : t("travelRequests.list.filters.filter1")}
                  </span>
                  <span style={{ marginTop: "4.5px" }}>
                    <CaretDownOutlined />
                  </span>
                </div>
              </Dropdown>
            </Box>

            <Button
              type="primary"
              size="large"
              className="rad8"
              onClick={openCreate}
            >
              <span className="fs16 av-heavy"> {t("add.button")} </span>
            </Button>
          </div>
          <Table
            columns={movementsColumns}
            listData = {listData}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(`/movement_request/${record.id}`);
                },
              };
            }}
            size="default"
            pagination={{ showSizeChanger: false, size: "small" }}
            showSizeChanger={false}
            onPaginationChange = {setPagination}
          />
        </div>
        <Drawer
          height={"100%"}
          placement={"bottom"}
          onClose={() => setVisible(false)}
          open={visible}
          closable={false}
          bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
          destroyOnClose={true}
        >
          <MovementForm
            close={close}
            is_update={is_update}
            current_element={current_element}
            initiator={initiator}
            documents={documents}
            members={initiator}
          />
        </Drawer>
      </Spin>
    </LayoutPage>
  );
}
