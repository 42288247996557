import { categories } from "./types";
import {fetch,get,SelectComponent} from "./common";
import {forwardRef} from "react";

export const fetchCategories = (...args)=>{
    return fetch(categories,...args);
}

export const getCategories = (...args)=>{
    return get(categories,...args);
}

export const SelectCategory = forwardRef((props,ref)=>{
    return <SelectComponent
        {...props}
        type = {categories}
        ref = {ref}
    />
});

SelectCategory.displayName = "SelectCategory";