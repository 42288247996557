import {Input,InputGroup,Textarea,FormLabel,FormErrorMessage,InputLeftElement,InputRightElement} from "$ui";
import React from "$react";
import PropTypes from "prop-types";
import {classNames,defaultStr,uniqid,defaultVal,isNumber,isNonNullString } from "$utils";
import Box from "$components/Box";
import Text from "$components/Text";
import {parseDecimal} from "./utils";
import theme,{Colors} from "$theme";
import Icon from "$components/Icon";

const InputComponent = React.forwardRef(({render,formFieldState,toCase:customToCase,autoFocus,as:Component,autoComplete,multiple,length,isDisabled,maxLength,affix,defaultValue,onChange,disabled,isReadOnly,readOnly,containerProps,contentContainerProps,type,upperCase,format,formatValue:customFormatValue,labelAsPlaceholder,left,leftProps,right,rightProps,label,placeholder,labelProps,id:customId,helperText,isInvalid,error,errorText,emptyValue,...props},ref)=>{
    const innerRef = React.useRef(null);
    labelProps = Object.assign({},labelProps);
    const upper = !!upperCase;
    type = defaultStr(type,"text");
    const _type = type.toLowerCase();
    const isPassword = _type ==="password";
    const [isPasswordVisible,setIsPasswordVisible] = React.useState(false);
    const prevIsPasswordVisible = React.usePrevious(isPasswordVisible);
    format = defaultStr(format).toLowerCase();
    const pName = defaultStr(props.name);
    const canValueBeDecimal =  (_type =='number');
    const isMoneyFormat = format =='money' ?true : false;
    const hasError = isInvalid || !!(error);
    const isEditable = !disabled && !readOnly;
    const pointerEvents = isEditable?"auto":'none';
    disabled = !!isDisabled || !!(disabled);
    readOnly = !!isReadOnly || !!(readOnly);
    const canValueBeFormattable = customFormatValue === false ? false : (typeof customFormatValue ==='function' || canValueBeDecimal || isMoneyFormat || format == 'number');
    emptyValue = defaultVal(emptyValue,canValueBeFormattable?"0":"");
    const formatValue = customFormatValue === false ? x=> x : (val)=>{
        if(val === undefined || val === null){
            val = canValueBeDecimal ? 0 : '';
        }
        if(canValueBeDecimal || (format !='custom')){
            val = parseDecimal(val,type);
        }
        let r = val;
        if(typeof(customFormatValue) == 'function'){
            r = customFormatValue({value:r,isFilter,formattedValue:val,inputRef})
            if(isNonNullString(r)){
                val = r;
            }
        }
        if(isNumber(val)){
            if(isMoneyFormat){
                val = val.formatMoney();
            } else {
                val = val.formatNumber()
            }
        }
        return val;
    }
    const parseValueToDecimal = canValueBeFormattable ? (x) => parseDecimal(x,type,true) : x => x; 
    const [isFocused, setIsFocused] = React.useState(false);
    const toCase = (t) =>  {
        if(isNumber(t)) t+="";
        if(t === emptyValue){
            t = "";
        }
        const ret = (upper !== true) ? (typeof t =='string'? t : "") : isNonNullString(t)? (upper ? t.toUpperCase() : t) : "";
        if(customToCase){
            return customToCase(ret);
        }
        return ret;
    };
    const [text, _setText] = React.useState(toCase(defaultValue));

    const parsedValue = canValueBeDecimal ? parseValueToDecimal(text):text;
    const formattedValue = formatValue(text);
    let displayText = isFocused ? (parsedValue+"") : formattedValue;
    if(isFocused && displayText ==emptyValue){
        displayText = "";
    }
    displayText +="";
    if(typeof parsedValue =='number' && text){
        if(text.endsWith(".")){
            displayText = (parsedValue+"").rtrim(".")+".";
        } 
        if(text.endsWith(",")){
            displayText = (parsedValue+"").rtrim(",")+",";
        }
        if(isFocused && (text.contains('.') || text.contains(','))){
            displayText = text;
        }
    }
    
    containerProps = Object.assign({},containerProps);
    contentContainerProps = Object.assign({},contentContainerProps);
    const id = customId || React.useRef(uniqid("input-"+(pName && (pName+'-') ||''))).current;
    const finalType = isPassword && !isPasswordVisible ? "password" : (isFocused || _type !='number') && !isPasswordVisible ? type :  "text";
    const className = classNames("input-"+_type,isFocused && 'focused isFocused',hasError && 'error isInvalid',disabled && "disabled",readOnly && "readonly isReadOnly",isEditable && "editable isEditable" || "not-editable",isPasswordVisible && "password-visible");
    const inputProps = {
        //size : "lg",
        borderRadius : "8px",
        errorBorderColor : "error",
        focusBorderColor : "primary",
        pointerEvents,
        ...props,
        type : finalType,
        className : classNames(props.className,className),
        isDisabled : disabled,
        isReadOnly : readOnly,
        isInvalid : hasError,
        placeholder : typeof placeholder ==='string' && placeholder || labelAsPlaceholder && React.getTextContent(label),
        id,
        onBlur : (e)=>{
            setIsFocused(false)
            if(props.onBlur){
                props.onBlur(e);
            }
        },
        onFocus : (e)=>{
            setIsFocused(true);
            if(props.onFocus){
                props.onFocus(e);
            }
        },
        onKeyUp : (e)=>{
            if(!isFocused){
                setIsFocused(true);
            }
            if(props.onKeyUp){
                props.onKeyUp(e);
            }
        },
        onChange : (event)=>{
            const value = event.target.value;
            const previousValue = parseValueToDecimal(text);
            setText(value,true);
            ///calling onchange function
            const nV = toCase(value);
            typeof onChange =='function' && onChange({value:parseValueToDecimal(nV),previousValue,event,target:event.target||innerRef.current,text,displayText,text,parsedValue})
        },
        value : displayText == emptyValue ? "" : displayText,
    }
    const alphaColor = Colors.setAlpha(theme.colors.text,0.54);
    const disabledColor = disabled && Colors.isValid(theme.colors.disabled)?theme.colors.disabled : alphaColor;
    let labelColor = hasError ? theme.colors.error : !isEditable ? disabledColor : isFocused ?  theme.colors.primary : labelProps.color || alphaColor;
    let inputColor = (isFocused || hasError) ? labelColor : disabled && disabledColor || theme.colors.text;
    inputProps.color = inputColor;
    const colorsProps = {
        color : inputColor,
    };
    formFieldState = Object.assign({},formFieldState);
    formFieldState.hasError = hasError;
    formFieldState.isEditable = isEditable;
    formFieldState.disabled = disabled;
    formFieldState.readOnly = readOnly;
    right = typeof right ==='function'?right(colorsProps,formFieldState) : right;
    left = typeof left =='function'? left(colorsProps,formFieldState) : left;
    right = React.isValidElement(right,true)? right : null;
    leftProps = Object.assign({},leftProps);
    rightProps = Object.assign({},rightProps);
    labelProps.color = labelColor;
    label = React.isValidElement(label,true) && <FormLabel w={"100%"}  htmlFor={id} {...labelProps} className={classNames("input-label",labelProps.className)}>{label}</FormLabel> || null;
    
    affix = multiple ? false : typeof affix =='boolean'? affix :  false//!isPassword;
    let affixContent = null;

    if(isFocused && !disabled && !readOnly){
        if(!canValueBeDecimal){
            let aff = isNonNullString(text)? text.length.formatNumber(): "";
            const hasM = isNumber(maxLength);
            if(hasM && aff){
                aff += ((isNumber(length)?"-":"/")+maxLength.formatNumber());
            }
            const affStyle = {color:inputColor,whiteSpace:"nowrap",paddingLeft:"10px"};
            if(right || hasM){
                affStyle.paddingRight = "10px";
            }
            affixContent = <span className="input-affix" children={aff} style={affStyle} />;
            if(affix){
                right = right ? <>{affixContent}{right}</> : affixContent;
            }
        }
    } else affix = undefined;

    if(isPassword && !disabled && !readOnly){
        const passwordIcon = <Icon boxSize={"25px"} {...colorsProps} onClick={(e)=>{
            setIsPasswordVisible(!isPasswordVisible)
        }} name={isPasswordVisible?"fa-FaEyeSlash":"fa-FaEye"}/>
        right = right ? <>{passwordIcon}{right}</> : passwordIcon;
    }
    const focus = ()=>{
        if(!innerRef.current || typeof innerRef.current.focus !=='function'|| isFocused || !isEditable) return;
        innerRef.current.focus();
    }
    React.useEffect(()=>{
        if(!isPassword || prevIsPasswordVisible == isPasswordVisible) return;
        focus();
    },[isPasswordVisible])
    const setText = function(text1,force){
        text1 = toCase(text1);
        if(force!== true && text1 === text) return;
        _setText(text1);
    }
    React.useEffect(()=>{
        if(autoFocus){
            focus();
        }
    })
    React.useEffect(()=>{
        defaultValue = toCase(defaultValue);
        if(defaultValue === toCase(emptyValue)){
            defaultValue = "";
        }
        if(defaultValue ===text || parseValueToDecimal(defaultValue) === parsedValue) return;
        _setText(defaultValue);
    },[defaultValue])
    React.useEffect(()=>{
        if(typeof onMount =='function'){
            onMount({value:toCase(text),toCase,setText,innerRef})
        }
        return ()=>{
            if(typeof onUnmount =="function"){
                onUnmount({value:toCase(text),toCase,innerRef})
            }
        }
    },[]);
    inputProps.autoComplete = isPassword ? (autoComplete||"new-password") : autoComplete || "off";
    const InputComponentR = multiple ? Textarea : Input;
    const input = typeof render ==="function" ? render(inputProps,React.useMergeRefs(ref,innerRef)) : React.isComponent(Component)?<Component {...inputProps} ref={React.useMergeRefs(ref,innerRef)}/> : <InputComponentR {...inputProps} ref = {React.useMergeRefs(ref,innerRef)}/>
    const rIcon = null;//<Icon color={inputColor} name={!isInvalid?"check":"material-error"}/> : null;
    right= hasError && rIcon && (right? <>{right}{rIcon}</> : rIcon) || right;
    return <Box pointerEvents = {pointerEvents} {...containerProps}  className={classNames(containerProps.className,className,"input-container")}>
        {label}
        <InputGroup pointerEvents={pointerEvents} justifyContent={"center"} alignItems="start" {...contentContainerProps} className={classNames("input-group","input-content-container",contentContainerProps.className)}>
            {React.isValidElement(left,true) && <InputLeftElement h={"100%"} {...leftProps} children={left}/>}
            {input}
            {React.isValidElement(right,true) && <InputRightElement h={"100%"} {...rightProps} children={right}/>}
        </InputGroup>
        {React.isValidElement(helperText,true) && <Text>{helperText}</Text>}
        {hasError && React.isValidElement(errorText,true) && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </Box>
});

const labelType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
]);

InputComponent.displayName = "InputComponent";
InputComponent.propTypes = {
    ...Object.assign({},Input.propTypes),
    as : PropTypes.oneOfType([
        PropTypes.elementType,
    ]),
    upperCase : PropTypes.bool,//if the text transform will be uppercase or not
    defaultValue : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    disabled : PropTypes.bool,
    isDisabled : PropTypes.bool,
    isReadOnly : PropTypes.bool,
    readOnly : PropTypes.bool,
    /***(e: SyntheticEvent) => void */
    onChange : PropTypes.func,
    /***(e: SyntheticEvent) => void */
    onBlur : PropTypes.func,
    /*** props of input container */
    containerProps : PropTypes.shape(Object.assign({},Box.propTypes)),
    label : labelType,
    helperText : labelType,
    left : PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
    ]),
    leftProps : PropTypes.shape({
        ...Object.assign({},InputLeftElement.propTypes)
    }),
    right : PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
    ]),
    rightProps : PropTypes.shape({
        ...Object.assign({},InputRightElement.propTypes)
    }),
    errorText : labelType,
    labelAsPlaceholder : PropTypes.bool,//if label will be use as placeholder
    id : PropTypes.string,
    ///function to render final input
    render : PropTypes.func, //(props,ref)=>React.node,
    emptyValue : PropTypes.string,//value that is considarated as empty
    formatValue : PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,///if boolean then the value cannot be formatted if formatValue is false;
    ]),
    /*** input group propTypes */
    contentContainerProps : PropTypes.shape({
        ...Object.assign({},InputGroup.propTypes)
    }),
    toCase : PropTypes.func,//
    formFieldState : PropTypes.object,//state of formField on input rendering
    multiple : PropTypes.bool,//if input is rendering multiple value
}

export default InputComponent;
