export default {
    "auth.signIn.youWillRedirectDueToSignIn" : "You are not logged in. You have been redirected to the login page",
    "auth.signIn.forgotPassword" : "Forgot password",
    "auth.signIn.login" : "Login",
    "auth.signIn.enterOTP" : "Enter the code you received by mail",
    "auth.signIn.checkOTP" : "Check",
    "auth.signIn.returnToLogin" : "Return to login",
    "auth.signIn.otpChecking" : "OTP Checking",
    "auth.signIn.enterOTP" : "Enter OTP",
    
    "auth.notAlowed.notAllowed" : "Not Allowed",
    "auth.notAlowed.header" : "Sorry, You are not allowed to see the needed content.",
    "auth.notAlowed.homepage" : "home page",
    "auth.notAlowed.returnTo" : "You can return to",
    "auth.notAlowed.content" : "if you can’t find what you are looking for."
}