import React,{useState,useEffect} from "$react";
import {isNonNullString,uniqid,getMaxZindex} from "$utils";
import Portal from "$components/Portal";
import {Image} from "$ui";
import Box from "$components/Box";
import { useTranslation } from "react-i18next";
import Dimensions from "$dimensions";
import { Button } from "antd";
const PDFObject = require("pdfobject");
import { getFileContent,convertToDataUrl,pdfDataUrl,parseFile } from "./utils";
import Icon from "$components/Icon";
import theme from "$theme";

export default function UploadViewerComponent({file,visible,onClose,...props}){
    const {t} = useTranslation();
    const isControlled = typeof visible =='boolean';
    const fileContent = getFileContent(file);
    const prevFile = React.usePrevious(file);
    const hasFile = !!fileContent.content;
    const [previewFile,setPreviewFile] = useState(isControlled? visible : !!fileContent.content);
    const close = (...args)=>{
        if(isControlled){
            setPreviewFile(false);
        }
        if(typeof onClose =='function'){
            onClose(...args);
        }
    }
    useEffect(()=>{
        if(!isControlled || previewFile === visible) return;
        setPreviewFile(visible);
    },[visible,isControlled]);
    useEffect(()=>{
        if(isControlled || hasFile === previewFile) return;
        setPreviewFile(hasFile);
    },[file,isControlled,hasFile])
    const pdfIdRef = React.useRef(uniqid("preview-pdf-id"));
    const pdfInstanceRef = React.useRef(null);
    const parentId = pdfIdRef.current+"parent";
    const resetPdfPreview = (remove)=>{
        const parent = document.querySelector(`#${parentId}`);
        if(!parent) return null;
        try {
          if(typeof pdfInstanceRef.current?.remove ==='function'){
            pdfInstanceRef.current?.remove();
          }
        } catch{}
        const dom = document.querySelector(`#${pdfIdRef.current}`);
        if(dom && !remove){
          const {width,height} = Dimensions.get("window");
          dom.id = pdfIdRef.current;
          dom.style.width = width+"px";
          dom.style.height = height+"px";
        }
        return dom;
      }
      React.useEffect(()=>{
        if(!file || !previewFile) return;
        const dom = resetPdfPreview();
        const fileContent = getFileContent(file);
        if(!fileContent.content || !fileContent.pdf) return; 
        if((dom)){
            try {
                pdfInstanceRef.current = PDFObject.embed(fileContent.content,dom);
            } catch (e){
                console.log(e,' error on loading pdf file')
            }
        }
      },[file,previewFile]);
      React.useEffect(()=>{
         return ()=>{
           resetPdfPreview(true);;
         }
      },[]);
    return <Portal>
        <Box.Animated 
          width={"100%"} 
          height="100%" 
          animate = {previewFile?"enter":"exit"}
          variants = {{
            enter: {
              transition: {
                duration: 0.5,
                delay : 0,
              },
              opacity : 1,
            },
            exit: {
              transition: {
                duration: 5,
              },
              transitionEnd: {
                opacity : 0,
              },
            }
          }}
          initial = {"exit"}
          opacity = {previewFile?1:0}
          position={previewFile?"fixed":"relative"}
          left = "0"
          top = "0"
          bottom = "auto"
          right = "auto"
          className="uploader-preview-container"
          bgColor = "white"
          display = {previewFile?undefined:"none"}
          zIndex={previewFile?String(Math.max(getMaxZindex(),10000)):1}
        >
            <Box h="100%" w="100%" className="uploader-preview-content-container" position="relative">
              {fileContent.content && <Box id={parentId} className='uploader-preview-image-container' w={'100%'} h={'100%'} flex flexDirection="column" justifyContent="center" alignItems="center">
                  {fileContent.image && <Image className="uploader-preview-image" src={fileContent.content}/>}
                  {fileContent.pdf && <Box id={pdfIdRef.current} className="preview-pdf-docm-id" w="100%" h="100%"/>}
              </Box>}
              {previewFile && <Box position="absolute" py="10px" px="10px" bgColor="white" borderColor={theme.colors.divider} borderTop="1px solid" bottom="0" top="auto" left="0" right="auto" w="100%" flex flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box ml="10px">{React.isValidElement(file?.name,true) && file?.name ||null}</Box>
                  <Box as={Button} icon={<Icon name="material-close" color="text" _hover={{color:"#1F70F2"}}/>} marginRight="20px" marginY="10px" children={t("uploader.closeModal")} onClick={close}/>
              </Box>}
            </Box>
        </Box.Animated>
  </Portal>
}