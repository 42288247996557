import React, { useState } from "react";
import "moment/locale/fr";

import { Button, Form, Col, Row, message, Input } from "antd";
import { useUpdatePassword } from "../../api/api";
import Password from "antd/lib/input/Password";
import { useHistory } from "react-router";

export default function Formpwd({ email, id, t }) {
  const [loading, setLoading] = useState(false);

  const { mutateAsync: update } = useUpdatePassword(id);
  const history = useHistory();
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      data.email = email;
      await update(data);
      message.success(t("successful.recording"));
      setLoading(false);
      history.push("/");
    } catch (e) {
      setLoading(false);
      message.warning(t("error.recording"));
    }
  };

  return (
    <div className="block w60 cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res">
      <div className="  flex column  w100  " style={{ top: 0 }}>
        <h3 className="fW600 fs20 colprim u-pad-left-l  u-mar-top-s">
          {" "}
          {t("homePage.header.profile.settings.page.section2.title")}{" "}
        </h3>
      </div>

      <Form
        layout="vertical"
        className="u-pad-horizontal-l"
        onFinish={onSubmit}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={24} className="u-mar-top-s">
                <Form.Item
                  name="password"
                  label={t(
                    "homePage.header.profile.settings.page.section2.label0"
                  )}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="password" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="new_password"
                  label={t(
                    "homePage.header.profile.settings.page.section2.label1"
                  )}
                  rules={[{ required: true, message: t("input.required") }]}
                >
                  <Input type="password" />
                </Form.Item>
              </Col>

              <Col className="flex flexend w100">
                <Form.Item className="w100">
                  <div style={{ textAlign: "right" }} className="u-mar-top-xs">
                    <Button
                      size={"large"}
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="rad8 u-pad-horizontal-l"
                    >
                      {t(
                        "homePage.header.profile.settings.page.section1.button"
                      )}
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
