import React from "$react";
import Box from "$components/Box";
import {Input,FormLabel} from "$ui";
import PropTypes from "prop-types";
import {classNames,uniqid,defaultStr} from "$utils";
import "./styles.css";
import Icon from "$components/Icon";

const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

const OTPComponent = React.forwardRef(({length,type,toCase,name,id,onChange,children,onBlur,disabled,readOnly,defaultValue,label,labelProps,value,containerProps,contentContainerProps,...props},ref)=>{
    containerProps = Object.assign({},containerProps);
    contentContainerProps = Object.assign({},contentContainerProps);
    labelProps = Object.assign({},labelProps);
    length = Math.ceil(length);
    const inputID = defaultStr(id) || React.useRef(uniqid("verify-otp-id")).current
    const inputRefs = React.useMemo(()=>{
        const refs = [];
        for(let i = 0; i<length;i++){
            refs.push({current:null});
        }
        return refs;
    },[length])
    const [values,setValues] = React.useState([]);
    const activeIndexRef = React.useRef(0);
    const isRenderRef = React.useRef(false);
    React.useEffect(()=>{
        isRenderRef.current = true;
        return ()=>{
            isRenderRef.current = false;
        }
    },[])
    const prevValues = React.usePrevious(values.join(""));
    React.useEffect(()=>{
        const value = values.join("");
        if(value == prevValues || value.length !== length){
            return;
        }
        if(onChange){
            onChange({value,values});
        }
    },[values])
    const focusInput = (index)=>{
        if(typeof index !=="number" || index<0 || index>length-1) return;
        const inputRef = inputRefs[index];
        const input = document.querySelector(`#${inputID}${index}`) || inputRef?.current;
        if( input && input?.focus){
            input.focus();
            activeIndexRef.current = index;
        }
    }
    const focusPrevInput = ()=>{
        return focusInput(activeIndexRef.current-1);
    }
    const focusNextInput = ()=>{
        return focusInput(activeIndexRef.current+1);
    }
    React.useEffect(()=>{
        if(!isRenderRef.current) return;
        focusInput(activeIndexRef.current);
    },[activeIndexRef.current])
    const changeCodeAtFocus = (text)=>{
        const index = activeIndexRef.current;
        const vals = [...values];
        vals[index] = defaultStr(text);
        setValues(vals);
    }
    const activeIndex = activeIndexRef.current;
    const isNumber = defaultStr(type).toLowerCase() =="number";
    label = label === null ? null : React.isValidElement(label,true) && <FormLabel disabled={disabled||props.isDisabled} w={"100%"}  htmlFor={`${inputID}${activeIndexRef.current}`} {...labelProps} className={classNames("verify-otp-input-label","cursor-pointer",labelProps.className)}>{label}</FormLabel> || null;
    return <Box id={inputID+"-container"} {...containerProps} className={classNames(containerProps.className,"verify-otp-input-container")}>
        {label}
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" justifyContent="flex-start" {...contentContainerProps}  className={classNames("verify-otp-input-content-container",contentContainerProps.className)} >
            {inputRefs.map((ref,index)=>{
                const isActive = index === activeIndex;
                return <Input
                    w = {'58px'}
                    h = {"54px"}
                    key = {index}
                    borderRadius="0"
                    borderWidth = {1}
                    borderColor = {"divider"}
                    borderLeftWidth = {isActive ? undefined  : (index==0 ? "1px":"0px")}
                    fontSize={"2rem"}
                    fontWeight = {"bold"}
                    {...props}
                    type = {type}
                    onKeyDown = {(e)=>{
                        if(!e || !e.keyCode) return;
                        if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
                            e.preventDefault();
                            changeCodeAtFocus('');
                            focusPrevInput();
                          } else if (e.keyCode === DELETE || e.key === 'Delete') {
                            e.preventDefault();
                            changeCodeAtFocus('');
                          } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
                            e.preventDefault();
                            focusPrevInput();
                          } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
                            e.preventDefault();
                            focusNextInput();
                          } else if (e.keyCode === SPACEBAR || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
                            e.preventDefault();
                          }
                    }}
                    autoComplete="off"
                    id = {inputID+index}
                    isDisabled = {disabled || props.isDisabled}
                    isReadOnly = {readOnly || props.isReadOnly}
                    value = {defaultStr(values[index])}
                    className = {classNames(props.className,"verify-otp-input","verify-otp-active","verify-otp-input-"+index)}
                    ref={ref}
                    onChange = {(e)=>{
                        let targetValue = defaultStr(e?.target?.value).trim();
                        if(isNumber){
                            targetValue = targetValue.replace(/[^\s0-9]/g, '');
                        }
                        const prevValue = defaultStr(values[index]).trim();
                        activeIndexRef.current = index;
                        const split = prevValue && targetValue && targetValue.split(prevValue) || [];
                        let value = prevValue && targetValue.length>1? defaultStr(split[1],prevValue) : targetValue;
                        if(toCase){
                            value = toCase(value);
                        }
                        if(value.length==1){
                            activeIndexRef.current = index >= length-1 ? activeIndex.current : index+1;
                        } else if(value.length > 1){
                            return;
                        }
                        const vals = [...values];
                        vals[index] = value;
                        setValues(vals);
                    }}
                />
            })}
            {<Box ml="10px" display={values.length !== length && "none"||undefined}>
                <Icon size="30px" name="material-MdClear" title="Click to reset values" onClick={e=>{
                    setValues(values.map(x=>""));
                    focusInput(0);
                }}/>
            </Box>}
            {children}
        </Box>
    </Box>
});

OTPComponent.displayName = "OTPComponent";

OTPComponent.propTypes = {
    ...Object.assign({},Input.propTypes),///props of each input element
    label : PropTypes.oneOfType([
        PropTypes.node,
    ]),
    toCase : PropTypes.func,//function to update current input value
    labelProps : PropTypes.object,
    containerProps : PropTypes.object, //box container props
    /*** the length of code to verify */
    length : PropTypes.number.isRequired,
}

export default OTPComponent;