
import {isNonNullString,isPromise,isBlobOrObjectUrl,defaultStr} from "$utils";
import { fetchBlob } from "$api";
import PropTypes from "prop-types";
import React from "$react";

/**** hook pour retourner la fonction de téléchargement d'unf ichier distant
    retourne une fonction de rappel, appelée pour télécharger un fichier selon les props
*/
export const useGetDownload = ({downloadLink,downloadFile,fileName,...props})=>{
    const {save} = props;
    return React.useCallback(()=>{
        const saveResult = (url)=>{
            if(save && isBlobOrObjectUrl(url)){
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${defaultStr(fileName)}`);
                document.body.appendChild(link);
                link.click();
            }
            return url;
        }
        if(isNonNullString(downloadLink)){
            return new Promise((resolve,reject)=>{
                fetchBlob(downloadLink).then(({data:blob})=>{
                    const url = window.URL.createObjectURL(
                        new Blob([blob], { type: "application/pdf" })
                    );
                    saveResult(url);
                    resolve(url);
                }).catch(reject);
            })
        }
        if(typeof downloadFile =='function'){
            const ret = downloadFile(props);
            if(isPromise(ret)){
                return ret.then(saveResult);
            } else {
                return Promise.resolve(saveResult(ret));
            }
        }
        return typeof downloadFile =='function'? Promise.resolve(ret) : PropTypes.reject({message:'Not download method defined for useGetDownload hook',props});
    },[downloadLink,downloadFile,save]);
}

useGetDownload.propTypes = {
    save : PropTypes.bool,//si le fichier téléchargé sera enregistré en local
    downloadLink : PropTypes.string,//le lien de téléchargement du fichier
    downloadFile : PropTypes.func, //la fonction de téléchargement du fichier, si le lien downloadLink n'est pas définie,
    fileName : PropTypes.string, //le nom du fichier qui sera téléchargé
}