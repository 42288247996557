import React from "$react";
import PropTypes from "prop-types";
import {flattenStyle} from "$theme";
import {chakra,shouldForwardProp,Flex} from "$ui";
import { motion, isValidMotionProp } from 'framer-motion'

const BoxComponent = React.forwardRef(({onLayout,style,...props},ref)=>{
    const hostRef = React.usePlatformMethods(props);
    React.useElementLayout(hostRef,onLayout);
    React.useEffect(()=>{
        React.setRef(ref,hostRef.current);
    });
    if(Array.isArray(style)){
        style = flattenStyle(style);
    }
    return <Flex {...props} style={style} ref={hostRef}/>
});

BoxComponent.propTypes = {
    ...Object.assign({},Flex.propTypes),
    onLayout : PropTypes.func,
};

BoxComponent.displayName = "BoxComponent";

export const Animated = chakra(motion.div, {
    shouldForwardProp: p=>isValidMotionProp(p) || shouldForwardProp(p),
});
Animated.propTypes = {
    ...BoxComponent.propTypes,
    ...Object.assign({},motion?.div.propTypes)
};
Animated.displayName = "AnimatedBoxComponent";

BoxComponent.Animated= Animated;
export default BoxComponent;