import React from "react";
import LeftSide from "../components/sider/sider";
import { Layout } from "antd";
import Header from "../components/header/header";
const { Content } = Layout;
import {classNames} from "$utils";
import Box from "$components/Box";

export default function LayoutPage({ children }) {
  return (
    <Layout className={classNames("bgapp main-layout")}>
      {<LeftSide />}
      <Content>
        <Header />
        <Box className="flex main-layout-container" p="15px" pt="0" flexDirection="column">
          {children}
        </Box>
      </Content>
    </Layout>
  );
}
