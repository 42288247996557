import React, { Fragment, useEffect, useState } from "react";
import Upload from "$components/Upload";
import {
  Modal,
  Form,
  Button,
  Select,
} from "antd";

import {
  PlusCircleFilled,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;
export default function Step3Form({ docs, chargeAnnexe }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [listAnnex, setListAnnex] = useState([]);

  const dum = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  function onSubmit(values) {
    console.log(values);

    setVisible(false);
    setListAnnex(values);

    chargeAnnexe(values);
  }

  return (
    <div className="appro rel">
      <div className="flex justbtw w100 u-mar-top-xl">
        <h3 className="fW600 nomar">
          {t("commitmentForms.form.section3.title")}
        </h3>

        <h3
          className="fW600 coltext cursor nomar"
          onClick={() => setVisible(true)}
        >
          {" "}
          <PlusCircleFilled /> {t("commitmentForms.form.section3.label2")}
        </h3>
      </div>
      <div className="w100">
        <div className="flex itemcenter">
          <div className="u-mar-right-s"></div>
        </div>

        <div className="w100">
          <div
            className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s"
            style={{
              borderTop: "1px #f2f2f2 solid",
              borderBottom: "1px #b3b3b3 solid",
            }}
          >
            <span className="grille4 fW500">
              {" "}
              {t("commitmentForms.form.section3.label1")}
            </span>
          </div>

          {listAnnex.length != 0 && (
            <Fragment>
              <ul className="cart-list" style={{ height: "auto" }}>
                <li className="section-cart flex itemcenter justbtw  u-pad-left-xs ">
                  <span className="grille4 av-heavy fs14 ">
                    {listAnnex.upload.file.name}
                  </span>

                  <span className="abs" style={{ right: -30 }}>
                    <CloseOutlined
                      className="rmv cursor fs15"
                      onClick={() => setListAnnex([])}
                    />
                  </span>
                </li>
              </ul>
            </Fragment>
          )}
        </div>
      </div>
      <Modal
        width={400}
        onCancel={() => setVisible(false)}
        open={visible}
        bodyStyle={{ paddingBottom: 0, paddingTop: 12, height: 300 }}
        destroyOnClose={true}
        closable={true}
        footer=""
        maskClosable={true}
      >
        <h2 className="fW500 w100 center">
          {" "}
          {t("commitmentForms.form.section3.label2")}
        </h2>

        <Form
          layout="vertical"
          className=" itemcenter u-mar-top-xl"
          onFinish={onSubmit}
        >
          <Form.Item
            name="upload"
            style={{ marginTop: 28 }}
            rules={[{ required: true, message: t("input.required") }]}
          >
            <Upload
              customRequest={dum}
              maxCount={1}
              onChange={handleChange}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>
                {t("commitmentForms.form.section3.label3")}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item name="btn" style={{ marginTop: 28 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="rad8"
            >
              <span className="fs16 av-heavy"> {t("add.button")} </span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
