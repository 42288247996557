import isNonNullString from "$utils/isNonNullString";
import isDOMElement from "./isDOMElement";

export { isDOMElement };

/*** get max z-index value */
export function getMaxZindex() {
  let highestZIndex = 0;
  // later, potentially repeatedly
  highestZIndex = Math.max(
    highestZIndex,
    ...Array.from(
      document.querySelectorAll("body *:not([data-highest]):not(.yetHigher)"),
      (elem) => parseFloat(getComputedStyle(elem).zIndex)
    ).filter((zIndex) => !isNaN(zIndex))
  );
  return highestZIndex;
}

export function hasClassName(elem, className) {
  if (!isDOMElement(elem)) return false;
  return new RegExp(" " + className + " ").test(" " + elem.className + " ");
}

/***
 * addClassName(elem,c1,c2,c3)
 */
export function addClassName() {
  let args = Array.prototype.slice.call(arguments, 0);
  let elem = args[0];
  if (!isDOMElement(elem)) return;
  for (let i = 1; i < args.length; i++) {
    let className = args[i];
    if (isNonNullString(className) && !hasClassName(elem, className)) {
      elem.className += " " + className;
    }
  }
}
export function removeClassName() {
  let args = Array.prototype.slice.call(arguments, 0);
  let elem = args[0];
  if (!elem || !isDOMElement(elem)) return;
  for (let i = 1; i < args.length; i++) {
    let className = args[i];
    if (isNonNullString(className) && isNonNullString(elem.className)) {
      var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
      elem.className = elem.className.replace(reg, " ");
      elem.className = elem.className.replace(className, "");
    }
  }
}
