import React, { useEffect,useRef, useState } from "$react";
import Table from "$components/Table";
import { Dropdown, Spin, Input, Tag, Breadcrumb } from "antd";
import { useGetAllTickets,RETRY } from "../../api/api";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Box from "$components/Box";
import {isNonNullString,defaultObj} from "$utils";

import { useRecoilValue } from "recoil";

import { useHistory, useLocation } from "react-router";
import LayoutPage from "../layout";

import { userInfos } from "../../store";


export default function Tickets() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useRecoilValue(userInfos);
  const [pagination,setPagination] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [dedStatus, setDedStatus] = useState(params.get("status"));
  const [checker, setChecker] = useState(params.get("checker_id"));

  const resetFilters = () => {
    setChecker(null);
    setDedStatus(null);
  };

  const setStatus = (t) => {
    setChecker(null);
    setDedStatus(t);
  };

  const { data:listData, error, isLoading,refetch } = useGetAllTickets(
    dedStatus,
    checker,
    pagination,
    {},
    RETRY,
  );
  console.log("getting pagination ",pagination);
  const menuDedItems = [
    {
      label : <div onClick={() => resetFilters()} className="flex rad8 u-pad-vertical-s u-pad-left-s">
        <div className="  justbtw flex">
          <span className="av-heavy fs14">
            {t("commitmentRequests.list.filters.filter1")}
          </span>
        </div>
      </div>,
    },
    {
      label : <div
        key="2"
        className="flex itemcenter1 rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setStatus(5)}
      >
          <span className="av-heavy fs14">
            {t("commitmentRequests.list.filters.filter2")}
          </span>
      </div>
    },
    {
      label : <div
        key="3"
        className="flex itemcenter1 rad8 u-pad-vertical-s u-pad-left-s"
        onClick={() => setStatus(2)}
      >
        <span className="av-heavy fs14">
          {t("commitmentRequests.list.filters.filter3")}
        </span>
      </div>,
    },
    {
      label :  <div
      className="flex itemcenter1 rad8 u-pad-vertical-s u-pad-left-s"
      onClick={() => setChecker(user.id)}
    >
      <span className="av-heavy fs14">
        {t("commitmentRequests.list.filters.filter4")}
      </span>
    </div>
    }
  ]

  useEffect(() => {
    if(pagination && (pagination?.page || pagination?.size)){
      setPagination({...pagination,current:0,page:0});
      setTimeout(refetch,200);
      return;
    }
    refetch();
  }, [dedStatus, checker]);

  const change = (r) => {
    history.push("/tickets/" + r.id);
  };
  const columns = [
    {
      title: "Unique Code",
      key : "code",
      onFilter: (value, record) => {
        return (
          String(record.code).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.demand?.initiator?.lastname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.checker?.lastname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.checker?.firstname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.demand?.initiator?.firstname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(i18nTObj(record?.demand?.affectation?.zone))
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(i18nTObj(record.status)).toLowerCase().includes(value.toLowerCase())
        );
      },
      render: ({item}) => (
        <>
          <span className="coltext fW500 cursor">{item.code}</span>
        </>
      ),
    },

    {
      title: "Date ",
      key: "date",
      type : "date",
    },

    {
      title: ("list.columns.label4"),
      dataIndex: ["demand","for_project"],
      type : "switch",
      render: ({item}) => (
        <>
          {item?.demand?.for_project == true ? (
            <span className="u-pad-xxs greenstatus rad4 fs12"> YES </span>
          ) : (
            <span className="u-pad-xxs graystatus rad4 fs12"> NO </span>
          )}
        </>
      ),
    },

    {
      title: ("list.columns.label1"),
      dataIndex: ["demand","initiator","lastname"],
      onFilter : (value,record)=>{
        return "{0} {1}".sprintf(record?.demand?.initiator?.lastname,record?.demand?.initiator?.firstname).toLowerCase().trim().includes(String(value).toLowerCase());
      },
      render: ({item}) => (
        <>
          {item.demand.initiator.lastname +
            " " +
            item.demand.initiator.firstname}
        </>
      ),
    },

    {
      title: ("commitmentRequests.detail.section3.label1"),
      dataIndex : ["checker","lastname"],
      onFilter : (value,item)=>{
        return "{0} {1}".sprintf(item?.checker?.lastname,item?.checker?.firstname).toLowerCase().trim().includes(String(value).toLowerCase());
      },
      render: ({item}) => (
        <>
          {item?.checker
            ? item?.checker?.lastname + " " + item?.checker?.firstname
            : "--"}
        </>
      ),
    },

    {
      title: "Total",
      dataIndex: "total",
      format : "money",
    },

    {
      title: ("commitmentRequests.activities"),
      dataIndex: "expense",
      render : ({item})=>{
        return (Array.isArray(item?.demand?.activities) && item?.demand?.activities || []).map((a,index)=>{
          if(!isNonNullString(a?.name)) return null;
          let c = 0;
          const name = a.name.split(" ").filter((m)=>{
            if(!m.trim()) return false;
            c++;
            return c <=5;
          }).join(" ");
           return <Box w="100%" pl={"5px"} className={`ticket-activities-item ticket-activities-item${a?.id||index}`}>
              {name}{c>5 && " ..."}
           </Box>
        });
      },
      onFilter : (value,item)=>{
        if(!Array.isArray(item?.demand?.activities)) return false;
        value = String(value).toLowerCase().trim();
        for(let i in item?.demand.activities){
           const a = item?.demand.activities[i];
           if(!a || !isNonNullString(a?.name)) continue;
           if(a?.name?.toLowerCase().includes(value)) return true;
        }
        return false;
      },
    },

    {
      title: ("commitmentRequests.detail.label1"),
      dataIndex : "id",
      filter : false,
      render: ({item}) => (
        <>
          {item?.demand?.projects.map((r) => (
            <h4>{r.code}</h4>
          ))}
        </>
      ),
    },

    {
      title: ("commitmentRequests.detail.label3"),
      onFilter : (value,item)=>{
        return String(item.demand.affectation?.zone).toLowerCase().includes(String(value).toLowerCase().toLowerCase());
      },
      //dataIndex: "",
      render: ({item}) => <>{i18nTObj(item.demand.affectation?.zone)}</>,
    },

    {
      title: "Status",
      dataIndex : ["status","id"],
      onFilter : (value,record)=>{
        return String(i18nTObj(record?.status)).toLowerCase().includes(String(value).toLowerCase().trim());
      },
      render: ({item}) => (
        <>
          <Tag
            className="u-mar-left-xs"
            color={
              item?.status?.id == 0
                ? "#ff9e01"
                : item?.status?.id == 1
                ? "#87d068"
                : item?.status?.id == 2
                ? "#ff5500"
                : item?.status.id == 3
                ? "red"
                : item?.status.id == 5
                ? "#87d068"
                : "#108ee9"
            }
          >
            {i18nTObj(item?.status)}
          </Tag>
        </>
      ),
    },
  ];
  return (
    <LayoutPage>
      {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

      <Spin tip="Chargment..." size="large" spinning={isLoading}>
        <Breadcrumb items={[
          {
            title : t("commitmentRequests.list.title"),
          }
        ]}/>

        <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">
          <div className="flex justbtw u-mar-bottom-s">
            <div className="flex ">
              <Dropdown
                menu={{items:menuDedItems}}
                className="cursor u-mar-right-xs"
                trigger={["click"]}
                style={{ width: "200px" }}
              >
                <div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
                  <span
                    className="fs15 av-heavy u-mar-right-xs"
                    style={{ color: "#1a1a1a" }}
                  >
                    {dedStatus == 2
                      ? t("commitmentRequests.list.filters.filter3")
                      : dedStatus == 5
                      ? t("commitmentRequests.list.filters.filter2")
                      : checker != null
                      ? t("commitmentRequests.list.filters.filter4")
                      : t("commitmentRequests.list.filters.filter1")}
                  </span>

                  <span style={{ marginTop: "4.5px" }}>
                    <CaretDownOutlined />
                  </span>
                </div>
              </Dropdown>
            </div>

            <div className="flex itemcenter">
              <div className="flex itemcenter ">
                {/*   <SelectDate /> */}

                <div className="separator nonedisplayres"></div>
              </div>
            </div>
          </div>
          <Table
              columns={columns}
              listData={listData}
              onRow={(record) => ({
                onClick: () => {
                  change(record);
                },
              })}
              size="default"
              pagination={{ showSizeChanger: false, size: "small" }}
              onPaginationChange = {(pagination)=>setPagination(pagination)}
              showSizeChanger={false}
            />
        </div>
      </Spin>
    </LayoutPage>
  );
}
