import React, { useState } from "react";
import moment from "moment";

import { Comment } from "@ant-design/compatible";

import { Button, Form, Input, message } from "antd";
import { useAddCommentTicket } from "../../../api/api";

const { TextArea } = Input;
export default function Comments({
  iduser,
  ticket,
  idticket,
  addNewComment,
  t,
}) {
  const { mutateAsync: addComment } = useAddCommentTicket(idticket);

  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const handleSubmit = async () => {
    if (!value) return;

    let payload = {
      message: value,
    };

    try {
      setSubmitting(true);
      const resp = await addComment(payload);

      addNewComment(resp);

      message.success(t("successful.recording"));
    } catch (e) {
      setSubmitting(false);
      message.warning(t("error.recording"));
    }

    setSubmitting(false);
    setValue("");
  };

  const CommentList = ({ comments }) =>
    comments.map((comment,key) => (
      <Comment
        key = {key}
        author={
          <span>
            {comment.commentator.lastname + " " + comment.commentator.firstname}
          </span>
        }
        avatar={<img src="/avatar.png" alt="avatar" height={40} width={40} />}
        content={<p>{comment.message}</p>}
        datetime={<span> {moment(comment.date).format("D MMM-YY HH:mm")}</span>}
      />
    ));

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Comment
        avatar={<img src="/avatar.png" alt="avatar" height={40} width={40} />}
        content={
          <>
            <Form.Item>
              <TextArea
                rows={1}
                onChange={(e) => handleChange(e)}
                value={value}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleSubmit}
                type="primary"
                style={{ height: "35px" }}
              >
                {t("commitmentRequests.detail.section1.tab3.button")}
              </Button>
            </Form.Item>
          </>
        }
      />

      <CommentList comments={ticket.comments} />
    </>
  );
}
