import React,{ useEffect,useRef,useState } from "$react";
import Box from "$components/Box";
import {usePrevious} from "$react";
import {classNames,defaultStr} from "$utils";
import { Menu,Badge,Divider,List , Dropdown,Avatar, Drawer } from "antd";
import Icon from "$components/Icon";
import {isSignedIn} from "$auth/session";
import {useGetNotifications,deleteNotifications} from "$api";
import DateLib from "$src/libs/date";
import Link from "$components/Link";
import notify from "$notify";
import { useTranslation } from "react-i18next";

const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];


export default function MainNotificationAppComponen({className,...props}){
    if(!isSignedIn()) return null;
    const { t } = useTranslation();
    const { data, error, isLoading,refetch } = useGetNotifications();
    const [visiblenotif, setVisiblenotif] = useState(false);
    const notifs= Array.isArray(data)? data : [];
    const toDeleteNotif = useRef([]);
    const prevVisible = usePrevious(visiblenotif);
    const setToDelete = (item)=>{
      if(!toDeleteNotif?.current?.includes(item?.id)){
        toDeleteNotif?.current.push(item.id);
      }
    }
    const count = notifs.length;
    const prevCount = React.usePrevious(count);
    const deleteNotif = (item)=>{
      setVisiblenotif(false);
      deleteNotifications([item?.id]).then(()=>{
        refetch();
      })
    }
    useEffect(()=>{
      if(count === prevCount) return;
      notify.sendDesktop(t("notify.changes").sprintf(count.formatNumber()));
    },[notifs,count]);
    useEffect(()=>{
      if(visiblenotif === prevVisible || !toDeleteNotif.current?.length) return;
      deleteNotifications(toDeleteNotif.current);
    },[visiblenotif]);
    return <Box className={classNames(className,"main-notification-component")}>
        <Box
            className="notification-icon-container"
            onClick={() => count && setVisiblenotif(true)}
            mt = {"14px"}
            ml="5px"
            mr={`${String(count).length*5+5}px`}
            cursor={count?"pointer":"not-allowed"}
        >
            <Badge count={count}>
              <Avatar src={<Icon size={"25px"} primary={!!count} name={count?"material-MdNotifications":"material-MdNotificationsOff"}/>} size="large" />
            </Badge>
        </Box>
        {/* Drawer des notifications */}
      <Drawer
        onClose={() => setVisiblenotif(false)}
        width={450}
        open={visiblenotif}
        closable={false}
        bodyStyle={{ paddingBottom: 0,overflowX:"hidden", marginTop: 10,marginLeft:10,marginRight:0, }}
        destroyOnClose={true}
      >
          <Box flex direction="row" justifyContent="space-beetween" alignItems="center" className="notifications-colosabled icon">
            <Icon name={"material-MdClose"} size={"40px"} onClick={(e)=>setVisiblenotif(false)}/>
            <Box className="pp" fontWeight="bold" color={"primary"} marginLeft="10px">
              <span className="st-not"> {count.formatNumber()}{" "} Notification{count>1?"s":""} </span>
            </Box>
          </Box>
          <Divider style={{margin:'2px'}}/>
          <List
            itemLayout="horizontal"
            dataSource={notifs}
            renderItem={(item, index) => {
              const date = DateLib.format(item.date);
              const author = defaultStr(item.author).trim();
              const avatar = author.length >2 ? author.substring(0,2) : author[0] || ""
              const color = index < ColorList.length ? ColorList[index] : ColorList[index%(ColorList.length)]
              const title = `${date}${author?" - ":""} ${author}`;
              return <List.Item onClick={(e)=>{
                deleteNotif(item);
              }}
                onMouseEnter={()=>{
                  setToDelete(item);
                }}
              >
                <List.Item.Meta
                  avatar={<Avatar style={{backgroundColor:color}} children={avatar}/>}
                  title={item.link && <Link to={item.link} href={item.link}>{title}</Link> || title}  
                  description={<Box className={classNames("notifications-item",'notification-item-'+index)}>
                      {i18nTObj(item,"message")}
                  </Box>}
                />
              </List.Item>;
            }}
          />
      </Drawer>
    </Box>
}