import React, { Fragment, useEffect, useState } from "$react";
import Flex from "$components/Flex";
import Box from "$components/Box";
import notify from "$notify";
import {ADMIN,COORDO_PROGRAM,USER,RAF, ROLE_MANAGER} from "$roles";
import {classNames} from "$utils";
import {fetch,useGetOne} from "$api";
import {
  Switch,
  Form as AntForm,
  Button,
  Divider,
  Input,
  message,
} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import Select from "$components/Select";

import { services, catLogistic, catRh, docs } from "../staticData/data";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";

import {
  useGetProjects,
  useAddCommitment,
  useAddAttachmentDemand,
  useGetProject,
  useGetEmployees,
} from "$api/api";
import FormProd from "./step2form";

import FormAnnexe from "./step3form";
import {getSignedUser} from "$auth";
import Auth from "$auth";

const { Option } = Select;

const rolesByIds = {};

export default function Step1Form({ userId, close }) {
  const { t } = useTranslation();
  const isWorkingOnSite = Auth.isHeadQuarter();
  const isProjectManager = Auth.isProjectManager();
  const [projects, setProjects] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [items, setItems] = useState(null);
  const [annexes, setAnnexes] = useState(null);
  
  const user = getSignedUser();
  const role = user?.user?.role;
  const isAdminRole = role?.id ===  ADMIN || role?.id === COORDO_PROGRAM || role?.id === ROLE_MANAGER;
  
  const canBeRelatedToProject = isWorkingOnSite ? isAdminRole || isProjectManager: true;
  const [isAccess, setIsAccess] = useState(canBeRelatedToProject);
  const FormComponent = isAccess?RelatedToProject : NotRelatedToProject;
  const [loading, setLoading] = useState(false);
  const addAttachmentRef = React.useRef(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [id, setId] = useState(null);
  const [idProject, setIdProject] = useState(null);
  const [document, setDocument] = useState(null);
  const [activitydisabled, setActivityDisabled] = useState(true);
  const { data} = useGetProjects({}, {});

  const { data: projectData, refetch: refetchData} =  useGetProject(idProject);
  const { data: dataEmployees } = useGetEmployees({}, {});

  const [form] = AntForm.useForm();

  const { mutateAsync: addComit } = useAddCommitment();
  const { mutateAsync: addAttachment } = useAddAttachmentDemand(id, document);

  const changeItem = (items) => {
    setItems(items);
  };
  const chargeAnnexe = (items) => {
    setAnnexes(items);
  };
  const getSelectedProject = ()=>{
    if(!Array.isArray(projects) || !form) return null;
    let project = form.getFieldValue("project");
    if(Array.isArray(project)){
      project = project[0];
    } 
    if(typeof project !=='number') return null;
    for(let i in projects){
      const p = projects[i];
      if(p && p?.id == project) {
        return p;
      }
    }  
    return null;
  }
  function onSwitchProject(checked) {
    form.resetFields();
    setIsAccess(checked);
  }
  const onChangeProject = (v, t,isFromSiteRequest) => {
    if(!form) return;
    if(isFromSiteRequest || (!isAccess && isWorkingOnSite)){
        if(!Array.isArray(v) || !v.length){
          form.setFields([
            {
              name: "employee",
              value : [],
            },
          ]);
          return;
        } 
        const roleId = isFromSiteRequest?COORDO_PROGRAM:RAF;
        return new Promise((resolve)=>{
          if(rolesByIds[roleId]){
              return resolve(rolesByIds[roleId]);
          }
          return fetch(`api/employees/role/${roleId}`).then((d)=>{
            rolesByIds[roleId] = d;
            resolve(d);
          })
        }).then((role)=>{
          form.setFields([
            {
              name: "employee",
              value : role.id,
            },
          ]);
        })
        //soumettre a sera coordo programme (utiliser la route /api/employees/role/id)
        
        return
    } 
    if(Array.isArray(v)){
      if(v.length === 1){
        v = v[0];
      }
    }
    if(typeof v =="number"){
      form.setFields([{ name: "zone", value: null }]);
      setIdProject(v);
      setActivityDisabled(false);
      form.setFields([
        {
          name: "employee",
          value: projects.filter((p) => p.id == v)[0]?.manager?.id,
        },
      ]);
    }
  };

  React.useEffect(()=>{
    if(typeof document !=='number' || typeof id !=='number') return;
    const data = new FormData();
    data.append("file", annexes?.upload.fileList[0].originFileObj);
    addAttachment(data);
  },[addAttachmentRef.current,id,document])
  const handleSubmit = async () => {
    form.submit();

    if (items == null || items?.length == 0 || (annexes == null && isAccess)) {
      if (annexes == null) {
        notify.error({
          message: t("commitmentForms.form.section3.popup.title"),
        });
      } else {
        notify.error({
          message: t("commitmentForms.form.section2.label7"),
        });
      }
    } else {
      form
        .validateFields()
        .then(async function (values) {

          values.items = [];

          let obj = {};

          let temp = [];

          items.map((p, id) => {
            const item = {
              ...p,
              quantity: parseInt(p.quantity),
              price: parseInt(p.u_price),
              description: p.description,
              unity: p.unity,
              //service: services.filter((u) => u.name == p.service)[0].id,
            };

            temp.push(item);
          });
          obj.for_project = isAccess;
          obj.devise = "XAF";
          obj.items = temp;
          obj.zone_id = values.zone;
          obj.activities = Array.isArray(values.activity)
            ? values.activity?.map((a) => ({ name: a }))
            : [{ name: values.activity }];
          obj.project_ids = Array.isArray(values.project)
            ? values.project
            : [values.project];
          obj.manager_id = values.employee;
          
          try {
            setLoading(true);

            const request = await addComit(obj);

            setId(request.id);

            if (annexes != null) {
              addAttachmentRef.current = true;
              setDocument(0);
            }

            close();
            message.success(t("successful.recording"));
            //history.push('/orders')
            setLoading(false);
          } catch (e) {
            console.log(e," is errror handle sumbit form step 1");
            setLoading(false);
            message.warning(t("error.recording"));
          }
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };
  useEffect(() => {
    setProjects(data);
  }, [data]);

  useEffect(() => {
    setEmployees(dataEmployees);
  }, [dataEmployees]);

  useEffect(() => {
    setProjectDetails(projectData);
  }, [projectData]);

  useEffect(() => {
    refetchData();
  }, [idProject]);
  return (
    <AntForm form={form} autoComplete="off" layout="vertical">
      <Fragment>
        <h3 className="fW600 nomar">Requisition</h3>
        <Divider className="u-mar-top-s" />
        {<Box style={[!canBeRelatedToProject && {pointerEvents:"none",opacity:0.5}]} ml="10px" disabled={!canBeRelatedToProject} pointerEvents={!canBeRelatedToProject?"node":"auto"} className="project-switch-item u-mar-bottom-l form-item-is-for-project">
            <span className="fs18 av-roman u-mar-bottom-m  u-mar-right-l">
              {t("commitmentForms.form.section1.label0")}
            </span>
            <span>
              <Switch
                onChange={onSwitchProject}
                defaultChecked={isAccess}
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </span>
        </Box>}
        <FormComponent
          flexWrap="wrap" 
          justifyContent="start"
          flex
          className="u-mar-bottom-l activity-field-container"
          role={role}
          pb="20px"
          pt="10px"
          projects={projects}
          employees={employees}
          projectDetails={projectDetails}
          activitydisabled={activitydisabled}
          isWorkingOnSite={isWorkingOnSite}
          form={form}
          getSelectedProject={getSelectedProject}
          onChangeProject={onChangeProject}
          />
        <>
          <FormProd
            services={services}
            catLogistic={catLogistic}
            catRh={catRh}
            chargeItems={changeItem}
          />

          {isAccess && <FormAnnexe docs={docs} chargeAnnexe={chargeAnnexe} />}
        </>
      </Fragment>

      <div
        className="flex itemcenter fixed"
        style={{ right: 34, top: 10, zIndex: 999 }}
      >
        <Button
          type="primary"
          size="large"
          className="rad8"
          loading={loading}
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          <span className="fs16 av-heavy"> {t("submit.button.label")} </span>
        </Button>
      </div>
    </AntForm>
  );
}


const SelectEmployee = ({employees,className,isAccess,...props})=>{
  const { t } = useTranslation();
  return <Select
      showSearch
      allowClear
      placeholder={t("commitmentForms.form.section1.label4")}
      optionFilterProp="children"
      className={classNames(className,"select-employee-form-item",`select-employee-form-item-${isAccess?"-is-access":"-not-is-access"}`)}
      {...props}
    >
      {Array.isArray(employees) && employees.map((employee, key) => (
          <Option value={employee.id} key={employee.id}>
            {" "}
            {employee.firstname + " " + employee.lastname}{" "}
          </Option>
        )) || null}
    </Select>
}


/*** la demande est reliée à un projet */
const RelatedToProject = ({isWorkingOnSite,activitydisabled,projects,projectDetails,employees,onChangeProject,role,getSelectedProject,form,...props})=>{
    const {t} = useTranslation();
    const isAdminRole = role?.id == ADMIN || role?.id == COORDO_PROGRAM || role?.id === ROLE_MANAGER;
    const [isFromSiteRequest,setIsFromSiteRequest] = React.useState(false);
    const isSisteUserAndAdmin = isWorkingOnSite && isAdminRole; // si c'est un user du siege et (role == ADMIN ou COORDO_PROGRAM) alors:
    return <Box {...props} w="100%" className="releated-to-project-container">
        {isSisteUserAndAdmin && <Box mb="20px" w="100%" ml="10px" flex className="request-from-site-checkbox-container">
          <Box fontSize="16px" mr="10px">{t("commitmentForms.form.section1.isRequestFromSite")}</Box>
            <Switch
              onChange={(v,t)=>{
                setIsFromSiteRequest(v);
              }}
            />
        </Box>}
        <Box className="form-item-activity" {...formItemProps} minW={{base:"100%",md:"30%",lg:"30%"}}>
            <AntForm.Item
              name="activity"
              label={t("commitmentForms.form.section1.label1")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <Select
                mode="tags"
                className="form-item-activity-select"
                style={{
                  width: "100%",
                  marginRight:"15px"
                }}
              />
            </AntForm.Item>
        </Box>

          <Box className="form-item-project-container" {...formItemProps} minW={{base:"100%",md:"50%",lg:"40%"}}>
            <AntForm.Item
              name="project"
              label={t("commitmentForms.form.section1.label2")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <Select
                showSearch
                className = "multiple-select-role"
                allowClear
                multiple = {(isFromSiteRequest)}
                optionFilterProp="children"
                onChange={(v, t) => onChangeProject && onChangeProject(v, t,isFromSiteRequest)}
                style = {{marginRight:"10px"}}
              >
                {Array.isArray(projects) && projects.map((project, key) => (
                    <Option value={project.id} key={project.id}>
                      {" "}
                      {project.code}{" "}
                    </Option>
                  )) || null}
              </Select>
            </AntForm.Item>
          </Box>

          {
            <>
              {!isFromSiteRequest && (
                <Box className="form-item-zone-container" {...formItemProps} minW={{base:"100%",md:"20%",lg:"30%"}}>
                  <AntForm.Item
                    name="zone"
                    label={t("commitmentForms.form.section1.label3")}
                    rules={[
                      {
                        required: true,
                        message: t("input.required"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      disabled={activitydisabled}
                      optionFilterProp="children"
                    >
                      {projects != null &&
                        projectDetails?.affectations?.map((a, key) => (
                          <Option value={a.zone.id} key={key}>
                            {" "}
                            {a.zone.name}{" "}
                          </Option>
                        ))}
                    </Select>
                  </AntForm.Item>
                </Box>
              )}

              <Box className="form-item-employee2" {...formItemProps} minW={{base:"100%",md:"40%",lg:"30%"}}>
                <AntForm.Item
                  name="employee"
                  label={t("commitmentForms.form.section1.label4")}
                  rules={[
                    {
                      required: true,
                      message: t("input.required"),
                    },
                  ]}
                >
                  <SelectEmployee  isAccess employees={employees}/>
                </AntForm.Item>
              </Box>
            </>
          }
    </Box>
}

///la demande n'est pas reliée à un projet : 2 cas de figures
const NotRelatedToProject = ({isWorkingOnSite,form,role,onChangeProject,projects,projectDetails,employees,activitydisabled,getSelectedProject,...props})=>{
    const { t } = useTranslation();
    return <Box w="100%" className="not-releated-to-project-container" {...props}>
        <Box className="activity-item"{...formItemProps}>
            <AntForm.Item
              name="activity"
              label={t("commitmentForms.form.detail.label12")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              {role?.id !== undefined && role?.id !== USER ? (
                <Select
                  mode={"tags"}
                  className="colu"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                <Input />
              )}
            </AntForm.Item>
          </Box>

          <Box className="project-item" {...formItemProps}>
            <AntForm.Item
              name="project"
              label={t("commitmentForms.form.detail.label1")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                multiple = {!!isWorkingOnSite/* //si l'utilisateur est du siège, donner la possibilité de saisir plusieurs projets qui s'adresse à a ADMIN FIN */}
                optionFilterProp="children"
                onChange={(v, t) => onChangeProject && onChangeProject(v, t)}
              >
                {Array.isArray(projects) && projects.map((project, key) => (
                    <Option value={project.id} key={key}>
                      {project.code}
                    </Option>
                  )) || null}
              </Select>
            </AntForm.Item>
          </Box>

          {!isWorkingOnSite /**La zone est affichée uniquement si l'utilisateur n'est pas du siège */ && (
            <Box className="form-item-comitment form-item-zone-not-is-acess" {...formItemProps}>
              <AntForm.Item
                name="zone"
                label={t("commitmentForms.form.section1.label3")}
                rules={[
                  {
                    required: true,
                    message: t("input.required"),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  disabled={activitydisabled}
                  placeholder={t("commitmentForms.form.section1.label3")}
                  optionFilterProp="children"
                >
                  {projects != null &&
                    projectDetails?.affectations?.map((a, key) => (
                      <Option value={a.zone.id} key={key}>
                        {" "}
                        {a.zone.name}{" "}
                      </Option>
                    ))}
                </Select>
              </AntForm.Item>
            </Box>
          )}

          <Box className="form-item-employee" {...formItemProps}>
            <AntForm.Item
              name="employee"
              label={t("commitmentForms.form.section1.label4")}
              rules={[
                {
                  required: true,
                  message: t("input.required"),
                },
              ]}
            >
              <SelectEmployee 
                isAccess={false}
                employees={employees}
              />
            </AntForm.Item>
          </Box>
    </Box>
}

const formItemProps = {px:"10px",minW:{base:"100%",md:"50%",lg:"33%"},mt:"0",mb:"0px"};